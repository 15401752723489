import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const BackArrowIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 14.997 29.381 L 0.454 14.839 L 14.997 0.296 L 16.905 2.205 L 5.635 13.476 L 29.539 13.476 L 29.539 16.202 L 5.635 16.202 L 16.905 27.472 L 14.997 29.381 Z"></path>
    </IconSvg>
  );
};
