export type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'b';
export type Typography = Record<TypographyVariant, any>;

interface Color {
  primary: string;
  secondary: string;
  tertiary?: string;
}

export interface N1LightTheme {
  palette: {
    background: Color;
    functions: {
      success: Color;
      warning: Color;
      error: Color;
      action: Color;
    };
    grey: {
      black5: string;
      black10: string;
      black20: string;
      black40: string;
      black60: string;
      black90: string;
    };
    main: {
      yellow: Color;
      black: Color;
    };
    text: Color;
  };
  spacing: (value: number | string) => string;
  zIndex: {
    enviromentIndicator: number;
    toast: number;
    tooltip: number;
    dialogTools: number;
    dialog: number;
    backdrop: number;
    kebabMenu: number;
    loading: number;
    main: number;
    default: number;
    negative: number;
  };
  typography: Typography & {
    h1: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
    };
    h2: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
    };
    h3: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
    };
    h4: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
    };
    h5: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
    };
    h6: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
    };
    p: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number | string;
      letterSpacing?: number | string;
    };
    span: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      letterSpacing?: number | string;
      lineHeight: number | string;
    };
    b: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      letterSpacing?: number | string;
      lineHeight: number | string;
    };
  };
}

const n1LightTheme: N1LightTheme = {
  palette: {
    background: {
      primary: '#fff',
      secondary: '#f2f2f2',
      tertiary: '#F7F7F7'
    },
    functions: {
      success: {
        primary: '#287D3C',
        secondary: '#539763',
        tertiary: '#EDF9F0'
      },
      warning: {
        primary: '#F47518',
        secondary: '#F69146',
        tertiary: '#FFF4EC'
      },
      error: {
        primary: '#DA1414',
        secondary: '#E14343',
        tertiary: '#FEEFEF'
      },
      action: {
        primary: '#0085FF',
        secondary: '#339DFF',
        tertiary: '#EEF2FA'
      }
    },
    grey: {
      black5: '#f2f2f2',
      black10: '#dbdbdb',
      black20: '#b8b8b8',
      black40: '#a5a5a5',
      black60: '#5c5c5c',
      black90: '#343434'
    },
    main: {
      yellow: {
        primary: '#FFD400',
        secondary: '#FFDD33',
        tertiary: '#FFF6CC'
      },
      black: {
        primary: '#000000',
        secondary: '#333333'
      }
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
      tertiary: '#58ACC4'
    }
  },
  spacing: (value: number | string) => (typeof value === 'string' ? value : `${value * 4}px`),
  zIndex: {
    enviromentIndicator: 10000,
    toast: 1400,
    tooltip: 1300,
    dialogTools: 1200,
    dialog: 1000,
    backdrop: 999,
    kebabMenu: 100,
    loading: 10,
    main: 1,
    default: 0,
    negative: -1
  },
  typography: {
    h1: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '42px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    h2: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '26px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    h4: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    h5: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    h6: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    p: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    span: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5
    },
    b: {
      fontFamily: 'Overpass,Arial,"sans-serif"',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1.5
    }
  }
};

export default n1LightTheme;
