import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SyncIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 11.964 3.205 L 11.964 0.029 L 7.729 4.264 L 11.964 8.5 L 11.964 5.323 C 15.469 5.323 18.318 8.171 18.318 11.675 C 18.318 12.746 18.053 13.762 17.576 14.64 L 19.122 16.185 C 19.948 14.884 20.435 13.337 20.435 11.675 C 20.435 6.995 16.645 3.205 11.964 3.205 Z M 11.964 18.028 C 8.461 18.028 5.613 15.18 5.613 11.675 C 5.613 10.605 5.876 9.589 6.353 8.711 L 4.807 7.165 C 3.981 8.467 3.494 10.013 3.494 11.675 C 3.494 16.356 7.285 20.146 11.964 20.146 L 11.964 23.322 L 16.2 19.087 L 11.964 14.851 L 11.964 18.028 Z"
        transform="matrix(0, -1, 1, 0, 0.288999, 23.640001)"
      ></path>
    </IconSvg>
  );
};
