import { TabProps } from '../../../../../../blocks/tabs-vertical/TabsVertical';
import { MeterWORKASSUMPTION, MeterWorkTaskDTO, RequiredReadingDTO, MeterStatus, MeterDTO } from '../../../../../../api/api';
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import MetertaskService from '../../../../../../services/MeterTasksService';
import Button from '../../../../../../components/button/Button';
import { EyeIcon } from '../../../../../../assets/icons/EyeIcon';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import NotificationService from '../../../../../../services/NotificationService';
import MeterResume, { MeterInstallation } from '../Components/MeterResume';
import { log } from '../../../../../../utils/logging/log';
import StatusIndicator, { STATUS } from '../../../../../../components/status-indicator/StatusIndicator';
import {
  TextColumnContainer,
  Label,
  Value,
  StyledTextField,
  Subsection,
  SubsectionHeader,
  Row,
  Section,
  SectionHeader,
  GreyContainer,
  Container,
  SubHeader,
  Footer,
  ScanButton
} from '../MeterInstallation/MeterInstallationStep';
import styled from 'styled-components';
import Alert from '../../../../../../components/alert/Alert';

interface Props extends TabProps {
  task?: MeterWorkTaskDTO;
}

export interface ExtendedMeter extends MeterWORKASSUMPTION {
  selected?: boolean;
}

const MeterUninstallationStep = (props: Props) => {
  const { task } = props;

  const [isScanButtonLoading, setIsScanButtonLoading] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [requiredReadings, setRequiredReadings] = useState<RequiredReadingDTO[]>([]);
  const [showCamera, setShowCamera] = useState(false);
  const [existingInstallation, setExistingInstallation] = useState<MeterDTO>();
  const [barcodeCannotBeScanned, setBarcodeCannotBeScanned] = useState(false);
  const [meterDoesNotExist, setMeterDoesNotExist] = useState(false);
  const [meterVerified, setMeterVerified] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const [completedMeterInstallation, setCompletedMeterInstallation] = useState<MeterInstallation>();

  useEffect(() => {
    if (!task?.id || !task.existingMeterId) return;

    if (task.meterStatus === MeterStatus.None) {
      MetertaskService.getRequiredReadings(parseInt(task.id))
        .then((data) => {
          setRequiredReadings(data.filter((r) => r.meterReadingRequired));
        })
        .catch((error) => {
          log(error);
          NotificationService.error('Kunne ikke hente tællerstande');
        });

      MetertaskService.getMeterByMeterId(task.existingMeterId)
        .then((data) => {
          setExistingInstallation(data);
        })
        .catch((error) => {
          log(error);
          NotificationService.error('Kunne ikke hente målerdata');
        });
    }
  }, [task?.existingMeterId, task?.id, task?.meterStatus]);

  const scan = async (_meterNumber: string) => {
    if (!task?.id) return;
    if (_meterNumber === existingInstallation?.meterNumber) {
      setMeterVerified(true);
      setShowError(false);
    } else {
      setMeterVerified(false);
      setShowError(true);
    }
  };

  const barcodeCannotBeScannedHandler = (value: boolean) => {
    setBarcodeCannotBeScanned(value);
    value && setMeterDoesNotExist(!value);
    setMeterVerified(value);
    setShowError(false);
  };

  const meterNumberDoesNotExistHandler = (value: boolean) => {
    setMeterDoesNotExist(value);
    value && setBarcodeCannotBeScanned(!value);
    setMeterVerified(value);
    setShowError(false);
  };

  const setRequiredReadingHandler = (name: string | undefined, readingValue?: number) => {
    if (!name) return;
    setRequiredReadings((prev) =>
      prev.map((r) => {
        if (r.name === name) {
          return { ...r, readingValue };
        } else {
          return r;
        }
      })
    );
  };

  const onCodeScannedHandler = (code: string) => {
    setIsScanButtonLoading(true);
    setShowCamera(false);
    scan(code).finally(() => {
      setIsScanButtonLoading(false);
    });
  };

  const submit = () => {
    if (!task?.id) return;
    if (!meterVerified) {
      setShowError(true);
      return;
    }

    setIsSubmitButtonLoading(true);
    MetertaskService.uninstallMeter(parseInt(task.id), requiredReadings)
      .then(() => {
        setCompletedMeterInstallation({
          requiredReadings: requiredReadings,
          meter: existingInstallation
        });
        NotificationService.success('Nedtagning registreret.');
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke registrere nedtagning.');
        log(error);
      })
      .finally(() => {
        setIsSubmitButtonLoading(false);
      });
  };

  const getContent = (status: MeterStatus) => {
    switch (status) {
      case MeterStatus.None:
        return (
          <Container>
            {completedMeterInstallation ? (
              <MeterResume completedMeterInstallation={completedMeterInstallation} sectionHeader="Nedtaget måler" />
            ) : (
              <>
                <Section>
                  <Row>
                    <StatusIndicator status={STATUS.GREY} />
                    <SectionHeader>Målernedtagning</SectionHeader>
                  </Row>
                  <Subsection>
                    <Row>
                      <StatusIndicator status={meterVerified ? STATUS.GREEN : STATUS.GREY} />
                      <SectionHeader hasError={showError}>Måler til nedtagning</SectionHeader>
                    </Row>
                    <Row>
                      <SubHeader>Find og scan måleren med det viste nummer</SubHeader>
                    </Row>
                    <Divider />
                    <Row>
                      <TextColumnContainer>
                        <Label>Målernummer</Label>
                        <Value>{existingInstallation?.meterNumber}</Value>
                      </TextColumnContainer>
                      <TextColumnContainer>
                        <Label>Fabrikationsnummer</Label>
                        <Value>{existingInstallation?.fabricationNumber}</Value>
                      </TextColumnContainer>
                      <TextColumnContainer>
                        <Label>Producent</Label>
                        <Value>{existingInstallation?.manufacturer}</Value>
                      </TextColumnContainer>
                      <TextColumnContainer>
                        <Label>Type</Label>
                        <Value>{existingInstallation?.fabricationType}</Value>
                      </TextColumnContainer>
                    </Row>
                    <Row>
                      <StyledSubContainer>
                        <ScanButton
                          isLoading={isScanButtonLoading}
                          onClick={() => setShowCamera(true)}
                          disabled={barcodeCannotBeScanned || meterDoesNotExist}
                          variant="secondary"
                        >
                          <EyeIcon />
                          Scan måler
                        </ScanButton>
                        <Checkbox
                          checked={barcodeCannotBeScanned}
                          onChange={(event) => barcodeCannotBeScannedHandler(event.target.checked)}
                          label="Stregkode kan ikke scannes"
                        />
                        <Checkbox
                          checked={meterDoesNotExist}
                          onChange={(event) => meterNumberDoesNotExistHandler(event.target.checked)}
                          label="Måler eksisterer ikke"
                        />
                      </StyledSubContainer>
                    </Row>
                    <Row>
                      {barcodeCannotBeScanned && (
                        <Row>
                          <Alert margin="0px 10px" severity="warning" height="42px">
                            Husk at tjekke, om målernummeret passer med det viste ovenfor.
                          </Alert>
                        </Row>
                      )}
                      {showError && (
                        <Row>
                          <Alert margin="0px 10px" severity="error" height="42px">
                            Målernummeret passer ikke med arbejdsordren. Tjek målernummer og prøv evt. igen
                          </Alert>
                        </Row>
                      )}
                    </Row>
                  </Subsection>
                  {requiredReadings.length > 0 && !meterDoesNotExist && (
                    <Subsection>
                      <SubsectionHeader>Tællerværk</SubsectionHeader>
                      <SubHeader>Tilføj tællerværk</SubHeader>
                      {requiredReadings.map((reading, index) => (
                        <GreyContainer>
                          <Row>
                            <SubsectionHeader>
                              Tællerværk
                              <span>{` ${index + 1} - ${reading.name} (${reading.code})`}</span>
                            </SubsectionHeader>
                          </Row>
                          <Divider />
                          <Row>
                            <SubHeader>Tællerstand</SubHeader>
                          </Row>
                          <Row>
                            <Checkbox
                              checked={reading.readingValue === undefined}
                              onChange={(e) => setRequiredReadingHandler(reading.name, e.target.checked ? undefined : 0)}
                              label="Måler har blankt display"
                            />
                          </Row>
                          {reading.readingValue !== undefined && (
                            <Row>
                              <StyledTextField
                                fullWidth
                                InputProps={{
                                  inputProps: { min: 0 }
                                }}
                                value={reading.readingValue}
                                type="number"
                                onChange={(e) => setRequiredReadingHandler(reading.name, parseInt(e.target.value))}
                                disabled={reading.readingValue === undefined}
                              ></StyledTextField>
                            </Row>
                          )}
                          <Row>
                            <SubHeader>Billededokumentation: Billede af tællerstand</SubHeader>
                          </Row>
                          <Row>
                            <Button variant="secondary">Upload</Button>
                          </Row>
                        </GreyContainer>
                      ))}
                    </Subsection>
                  )}
                </Section>
                <Footer>
                  <Button isLoading={isSubmitButtonLoading} onClick={submit}>
                    Registrer nedtagning
                  </Button>
                </Footer>
              </>
            )}
          </Container>
        );
      case MeterStatus.UninstallationRegistered:
        return (
          <>
            <Container>
              <Section>
                <Row>
                  <StatusIndicator status={STATUS.GREEN} />
                  <SectionHeader>Måler er allerede nedtaget</SectionHeader>
                </Row>
              </Section>
            </Container>
          </>
        );
    }
  };

  return <>{showCamera ? <>not implemented</> : task?.meterStatus && getContent(task?.meterStatus)}</>;
};

const StyledSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
`;

export default MeterUninstallationStep;
