import { useCallback, useEffect } from 'react';
import { IdType, TableInstance, Row as ReactTableRow } from 'react-table';
import styled, { css } from 'styled-components';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import { Fade } from '../../components/fade/Fade';
import IconButton from '../../components/icon-button/IconButton';
import GlobalSearchField from './table-components/GlobalSearchField';
import ColumnFilterButton from './table-components/ColumnFilterButton';
import ExcelExportButton from './table-components/ExcelExportButton';
import Row from './table-components/Row';
import TableHeader from './table-components/TableHeader';
import { TableTab } from './TableUtils';
import { isDevice } from '../../utils/device-handling/deviceDetectionUtils';
import { UpdateIcon } from '../../assets/icons/UpdateIcon';
import { GreyAreaHorizontalPadding, WhiteAreaHorizontalPadding, WhiteAreaTopPadding } from '../../styling/StylingConstants';
import { DepartmentDTO, TaskListCustomFilterDTO2 } from '../../api/api';
import { FilterIcon } from '../../assets/icons/FilterIcon';
import { FilterValues } from '../../views/task-view/task-list-view/useTaskList';

interface Props<T extends object> {
	department?: DepartmentDTO;
	loading: boolean;
	noDataText?: string;
	multipleTypeName?: string;
	tableMenuBar?: JSX.Element;
	showPagination?: boolean;
	showCount?: boolean;
	showGlobalSearch?: boolean;
	showExcelExport?: boolean;
	showColumnFiltering?: boolean;
	showClearFilter?: boolean;
	onSaveFilter?: (filterName: string, onSuccessfulSave: () => void) => void;
	onDeleteFilter?: (filter: TaskListCustomFilterDTO2, successfulDeleteCallback: () => void) => void;
	onSelectCustomFilter?: (filter: TaskListCustomFilterDTO2) => void;
	selectedFilter?: TaskListCustomFilterDTO2;
	savingFilter?: boolean;
	sessionFilter?: FilterValues[];
	activeSessionFilter?: boolean;
	showUpdateTable?: boolean;
	alwaysShowSort?: boolean;
	tableInstance: TableInstance<T>;
	onClickRow?: (rowData: any) => void;
	onDoubleClickRow?: (rowData: any) => void;
	onColumFilterChange?: (columns: IdType<T>[]) => void;
	onClearFilters?: () => void;
	onUpdateTable?: () => void;
	excelExportFileNameFunction?: () => string;
	excelExportRowFilterFunction?: (row: ReactTableRow<T>, activeTab: string) => boolean;
	tabs?: JSX.Element;
	activeTab?: TableTab;
	className?: string;
	noPadding?: boolean;
	useExpand?: boolean;
	onColumnSwap?: (dragItem?: number, dragOverItem?: number) => void;
	renderFullHeight?: boolean;
}

const Table = <T extends object>(props: Props<T>) => {
	const {
		department,
		activeTab,
		loading,
		noDataText,
		multipleTypeName,
		tableMenuBar,
		showPagination,
		showCount,
		showGlobalSearch,
		showExcelExport,
		showClearFilter,
		showUpdateTable,
		showColumnFiltering,
		selectedFilter,
		sessionFilter,
		activeSessionFilter,
		alwaysShowSort,
		tableInstance,
		onClickRow,
		onDoubleClickRow,
		onColumFilterChange,
		onClearFilters,
		onUpdateTable,
		excelExportFileNameFunction,
		excelExportRowFilterFunction,
		tabs,
		className,
		noPadding,
		useExpand,
		onColumnSwap,
		renderFullHeight = false
	} = props;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		filteredRows,
		state: { pageIndex, pageSize, selectedRowIds },
		setGlobalFilter,
		allColumns,
		visibleColumns
	} = tableInstance;

	const isDesktop = !isDevice();

	useEffect(() => {
		if (!loading) {
			if (activeTab?.header !== undefined && activeSessionFilter && sessionFilter) {
				tableInstance.setAllFilters(sessionFilter);
			} else {
				department !== undefined && activeTab?.filter(department);
			}
		}
	}, [activeTab, loading, selectedFilter, tableInstance, department, sessionFilter, activeSessionFilter]);

	const filterFunction = useCallback(
		(row: ReactTableRow<T>) => {
			if (!excelExportRowFilterFunction || !activeTab?.header) return;

			return excelExportRowFilterFunction(row, activeTab?.header);
		},
		[activeTab?.header, excelExportRowFilterFunction]
	);

	const renderPageCounts = useCallback(
		(pageSize: number, pageIndex: number, totalCount: number = 0) => {
			const pageMin = pageSize * pageIndex + 1;
			const pageMax = pageSize * pageIndex + pageSize;
			const total = Math.min(totalCount, pageMax);

			return <span>{`${pageMin} - ${total} af ${totalCount} ${multipleTypeName ?? ''}`}</span>;
		},
		[multipleTypeName]
	);

	const renderNoData = () => {
		return loading ? (
			<tr>
				<td></td>
			</tr>
		) : (
			<NoDataRow>{noDataText}</NoDataRow>
		);
	};

	const renderPageOptions = useCallback(
		(pageOptions: number[], pageIndex: number, gotoPage: (updater: number | ((pageIndex: number) => number)) => void) => (
			<>
				{pageOptions.map((page) => {
					if (pageIndex === page)
						return (
							<PageNumber onClick={() => gotoPage(page)} key={page}>
								<strong>{page + 1}</strong>
							</PageNumber>
						);
					else if (pageIndex < 3) {
						if (page < 5)
							return (
								<PageNumber onClick={() => gotoPage(page)} key={page}>
									{page + 1}
								</PageNumber>
							);
					} else if (pageOptions.length - 3 < pageIndex) {
						if (page > pageOptions.length - 1 - 5)
							return (
								<PageNumber onClick={() => gotoPage(page)} key={page}>
									{page + 1}
								</PageNumber>
							);
					} else if (page > pageIndex - 3 && page < pageIndex + 3)
						return (
							<PageNumber onClick={() => gotoPage(page)} key={page}>
								{page + 1}
							</PageNumber>
						);
					return '';
				})}
			</>
		),
		[]
	);

	return (
		<TableOuterContainer
			noPadding={noPadding}
			className={className + ' table-outer-container'}
			renderFullHeight={renderFullHeight}
		>
			{tabs}
			<ContentContainer
				noPadding={noPadding}
				tabs={!!tabs}
				footer={showPagination === true}
				noBorderRadiusUpperLeft={!tabs}
				className="table-content-container"
			>
				{(showGlobalSearch || showColumnFiltering || showExcelExport || showUpdateTable) && (
					<ToolbarContainer justify={showGlobalSearch ? 'space-between' : 'flex-end'}>
						<GlobalSearchAndCustomFilterSection>
							{showGlobalSearch && <GlobalSearchField setGlobalFilter={setGlobalFilter} />}
							{showClearFilter && onClearFilters && (
								<StyledIconButton variant="outlined" onClick={onClearFilters}>
									<FilterIcon size="16px" />
									Nulstil filtrer
								</StyledIconButton>
							)}
						</GlobalSearchAndCustomFilterSection>
						<ButtonContainer>
							{showUpdateTable && (
								<StyledIconButton variant="outlined" onClick={onUpdateTable}>
									<UpdateIcon size="16px" />
								</StyledIconButton>
							)}
							{showColumnFiltering && (
								<ColumnFilterButton
									allColumns={allColumns as any}
									activeColumns={visibleColumns.length}
									onColumFilterChange={onColumFilterChange}
								/>
							)}
							{showExcelExport && excelExportFileNameFunction && (
								<ExcelExportButton
									fileNameFunction={excelExportFileNameFunction}
									data={
										excelExportRowFilterFunction
											? filteredRows.filter(filterFunction).map((row) => row.original)
											: filteredRows.map((row) => row.original)
									}
									headers={visibleColumns
										.map((column) => {
											return { label: column.Header as string, key: column.id };
										})
										.slice(1)}
								/>
							)}
						</ButtonContainer>
					</ToolbarContainer>
				)}
				<TableContainer>
					<StyledTable {...getTableProps()}>
						<TableHeader
							onColumnSwap={onColumnSwap}
							alwaysShowSort={alwaysShowSort}
							headers={headerGroups[0].headers}
							getHeaderGroupProps={headerGroups[0].getHeaderGroupProps}
							disabledColumns={activeTab?.disabledColumns}
						/>
						<TableBody
							{...getTableBodyProps()}
							data-testid="table-body"
							isDesktop={isDesktop}
							renderFullHeight={renderFullHeight}
						>
							{page.length
								? page.map((row: any) => {
										prepareRow(row);
										return (
											<Row
												rowData={row.original}
												getRowProps={row.getRowProps}
												cells={row.cells}
												key={`table-body-row-${row.id}`}
												selected={row.isSelected}
												handleDoubleClick={onDoubleClickRow}
												handleClick={onClickRow}
												useExpand={useExpand}
												canExpand={row.canExpand}
												showError={row?.original?.showError && row.original.showError}
											/>
										);
								  })
								: renderNoData()}
						</TableBody>
					</StyledTable>
				</TableContainer>
				{loading && <CircularProgress margin="150px 0" position="absolute" />}

				{tableMenuBar && !!Object.keys(selectedRowIds).length && (
					<Fade in={!!Object.keys(selectedRowIds).length}>{tableMenuBar}</Fade>
				)}
			</ContentContainer>
			<TableFooter className="table-footer">
				{showCount && renderPageCounts(pageSize, pageIndex, filteredRows?.length)}

				{showPagination && (
					<PaginationContainer>
						<StyledIconButton variant="outlined" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							<ChevronLeft size="10px" />
							<ChevronLeft size="10px" />
						</StyledIconButton>
						<StyledIconButton variant="outlined" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<ChevronLeft size="10px" />
						</StyledIconButton>
						{renderPageOptions(pageOptions, pageIndex, gotoPage)}
						<StyledIconButton variant="outlined" onClick={() => nextPage()} disabled={!canNextPage}>
							<ChevronRight size="10px" />
						</StyledIconButton>
						<StyledIconButton variant="outlined" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
							<ChevronRight size="10px" />
							<ChevronRight size="10px" />
						</StyledIconButton>
						<StyledSelect value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
							{[25, 50, 100, 200, 500].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</StyledSelect>
					</PaginationContainer>
				)}
			</TableFooter>
		</TableOuterContainer>
	);
};

const tabsHeight = 40;
const tableHeaderHeight = 71;
const scrollbarWidth = 17;
const footerHeight = 48;

const ToolbarContainer = styled.div<{ justify: 'flex-end' | 'space-between' }>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justify};
	padding-bottom: 24px;
`;

const ButtonContainer = styled.div`
	display: flex;
	column-gap: 16px;
`;

export const Tabs = styled.div`
	display: flex;
`;

export const Tab = styled.div<{ active?: boolean; disabled?: boolean }>`
	position: relative;
	overflow: hidden;
	padding: 10px 42px 8px 24px;

	border-radius: 16px 8px 0 0;
	color: ${(props) => (props.active ? '#000000' : props.theme.palette.grey.black60)};
	cursor: pointer;

	:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${(props) => (props.active ? '#ffffff' : props.theme.palette.grey.black5)};
		transform: skewX(30deg);
		z-index: ${(props) => props.theme.zIndex.negative};
		transform-origin: 100% 100%;
		border-radius: 16px 8px 0 0;
	}

	${({ disabled }) =>
		disabled &&
		css`
			color: ${(props) => props.theme.palette.grey.black40};
			cursor: not-allowed;
			font-weight: 400;
		`}
`;

const TableContainer = styled.div`
	overflow-y: hidden;
	overflow-x: auto;
	height: 100%;
	z-index: ${(props) => props.theme.zIndex.main};
`;

const StyledTable = styled.table`
	box-sizing: border-box;
	display: block;
	height: 100%;
	width: 100%;
	overflow: hidden;

	border-spacing: 0;
	border: 1px solid ${(props) => props.theme.palette.grey.black10};
	border-radius: 16px;

	th {
		padding-top: 16px;
		padding-bottom: 16px;
	}
	th:first-child,
	td:first-child {
		padding-left: 16px;
	}
	th:last-child,
	td-last-child {
		padding-right: 16px;
	}
`;

const TableBody = styled.tbody<{ isDesktop: boolean; renderFullHeight?: boolean }>`
	height: calc(100% - ${tableHeaderHeight}px);
	overflow-y: ${(props) => (props.renderFullHeight ? 'hidden' : 'scroll')};
	overflow-x: hidden;

	tr {
		margin-right: ${(props) => props.isDesktop && `-${scrollbarWidth}px`};
		align-items: center;
	}
`;

const NoDataRow = styled.p`
  width: 100%;
  text-align: center;
  padding: 22px;
  color ${(props) => props.theme.palette.grey.black40};
`;

const TableFooter = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
`;

const StyledIconButton = styled(IconButton)`
	&& {
		border-radius: 8px;
		padding: 10px;
		height: 37px;
		column-gap: 10px;
		${(props) => ({ ...props.theme.typography.p })};
		font-weight: 700;
	}
`;

const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 16px;
`;

const StyledSelect = styled.select`
	height: 100%;
	margin-right: 8px;
	border-radius: 8px;
	border: ${(props) => `1px solid ${props.theme.palette.grey.black20}`};
	box-sizing: content-box;
	padding: 0 5px;
`;

const PageNumber = styled.span`
	margin: 0 8px;
	padding: 0 6px;
	cursor: pointer;
`;

const TableOuterContainer = styled.div<{ noPadding?: boolean; renderFullHeight?: boolean }>`
	text-align: left;
	box-sizing: border-box;
	height: ${(props) => (props.renderFullHeight ? '' : '90%')};
	padding: ${(props) => (props.noPadding ? 0 : ` ${GreyAreaHorizontalPadding}px ${GreyAreaHorizontalPadding}px 0px`)};

	thead,
	tbody {
		display: block;
	}
`;

const ContentContainer = styled.div<{
	noBorderRadiusUpperLeft?: boolean;
	tabs?: boolean;
	footer?: boolean;
	noPadding?: boolean;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: calc(100% - ${(props) => (props.footer ? footerHeight : 0)}px - ${(props) => (props.tabs ? tabsHeight : 0)}px);
	padding: ${(props) => (props.noPadding ? 0 : ` ${WhiteAreaTopPadding}px ${WhiteAreaHorizontalPadding}px 0px`)};

	border-radius: ${(props) => (props.noBorderRadiusUpperLeft ? '16px 16px 16px 16px' : '0px 16px 16px 16px')};
	background: ${(props) => props.theme.palette.background.primary};
`;

const GlobalSearchAndCustomFilterSection = styled.div`
	display: flex;
	align-items: center;
	column-gap: 12px;
`;

export default Table;
