import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SearchIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="27.8 31.307 29.616 27.283" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="38.94"
        cy="42.42"
        r="9.75"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.4"
      ></circle>
      <path fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.4" d="M56.14 57.35l-9.61-8.3"></path>
    </IconSvg>
  );
};
