import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { AddIcon } from '../../../assets/icons/AddIcon';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { MinimizeIcon } from '../../../assets/icons/MinimizeIcon';
import IconButton from '../../../components/icon-button/IconButton';
import TextField from '../../../components/text-field/TextField';
import { SelectedGoodsData } from '../../../models/Goods';

interface Props {
  setGoodsAmount?: (value: number, goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  removeGoods?: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  maxAvailableStock?: number;
  minValue?: number;
  row: SelectedGoodsData;
  qty?: string;
}

const QuantityCell = (props: Props) => {
  const { goodsId, goodsBatch, goodsSerial } = props.row;
  const { qty, setGoodsAmount, removeGoods, maxAvailableStock, minValue } = props;

  const [value, setValue] = useState<number>(qty ? parseFloat(qty) : 0);

  const hasAvailableStock = useCallback((): boolean => {
    if (maxAvailableStock === undefined) return true;

    if (maxAvailableStock === 0) return false;

    if (value >= maxAvailableStock) return false;

    return true;
  }, [value, maxAvailableStock]);

  const handleChange = useCallback((value: string) => {
    const newValue = value ? parseFloat(value) : 0;
    setValue(newValue);
  }, []);

  const handleBlur = useCallback(() => {
    if (maxAvailableStock !== undefined && value > maxAvailableStock) {
      setValue(maxAvailableStock);
      setGoodsAmount && setGoodsAmount(maxAvailableStock, goodsId, goodsBatch, goodsSerial);
    } else {
      setGoodsAmount && setGoodsAmount(value, goodsId, goodsBatch, goodsSerial);
    }
  }, [maxAvailableStock, value, setGoodsAmount, goodsId, goodsBatch, goodsSerial]);

  const handleIncrementGoods = useCallback(() => {
    if (maxAvailableStock !== undefined && value + 1 > maxAvailableStock) {
      setGoodsAmount && setGoodsAmount(maxAvailableStock, goodsId, goodsBatch, goodsSerial);
      setValue(maxAvailableStock);
    } else {
      setGoodsAmount && setGoodsAmount(value + 1, goodsId, goodsBatch, goodsSerial);
      setValue((prev) => prev + 1);
    }
  }, [maxAvailableStock, value, setGoodsAmount, goodsId, goodsBatch, goodsSerial]);

  const handleDecrementGoods = useCallback(() => {
    if (value - 1 < 0) {
      setGoodsAmount && setGoodsAmount(0, goodsId, goodsBatch, goodsSerial);
      setValue(0);
    } else {
      const newValue = value > 0 ? value - 1 : 0;
      setGoodsAmount && setGoodsAmount(newValue, goodsId, goodsBatch, goodsSerial);
      setValue(newValue);
    }
  }, [value, setGoodsAmount, goodsId, goodsBatch, goodsSerial]);

  return (
    <ButtonContainer>
      <IconButton disabled={value === minValue} onClick={handleDecrementGoods} padding="8px" variant="primary">
        <MinimizeIcon size="14px" />
      </IconButton>
      <TextField
        hideButtons
        key={`test-${goodsId}`}
        onBlur={handleBlur}
        sx={{ width: '70px' }}
        compact
        type="number"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
      <IconButton disabled={!hasAvailableStock()} onClick={handleIncrementGoods} padding="8px" variant="primary">
        <AddIcon size="14px" />
      </IconButton>
      {removeGoods && (
        <IconButton onClick={() => removeGoods(goodsId, goodsBatch, goodsSerial)}>
          <DeleteIcon margin="0 0 0 -10px" size="18px" />
        </IconButton>
      )}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  float: right;
  margin-right: 50px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: -8px 28px -8px 0;
`;

export default QuantityCell;
