import { Route } from 'react-router-dom';

import {
  ADMIN_QUESTIONS,
  ADMIN_REASONCODES,
  ADMIN_REUSABLECOMPONENTS,
  ADMIN_TASKTYPES,
  GOODS_ROUTE,
  INTERNAL_TIME_OFFICE,
  INTERNAL_TIME_TECHNICIAN,
  TASKS_ROUTE,
  CALENDAR_ROUTE,
  WORKPLAN_ROUTE,
  TASKS_DETAILS,
  MAP_TASK_LIST_ROUTE,
  COUNT_GOODS_ROUTE,
  ORDER_GOODS_ROUTE,
  MOVE_GOODS_ROUTE,
  ADMIN_PORTAL_LINKS,
  TECHNICIAN_TASKLIST_ROUTE,
  RETURN_GOODS_ROUTE,
  RECEIPT_ROUTE,
  NEWS_ROUTE,
  ADMIN_NEWS
} from './utils/constants';

import WorkPlanCalendarView from './views/task-view/calendar-view/CalendarView';
import InternalTimeOfficeView from './views/internal-time-office-view/InternalTimeOfficeView';
import InternalTimeTechnicianView from './views/internal-time-technician-view/InternalTimeTechnicianView';
import GoodsView from './views/goods-view/GoodsView';
import AdminTaskTypesView from './views/admin/admin-task-types/AdminTaskTypesView';
import AdminReusableComponentsView from './views/admin/admin-reusable-components/AdminResusableComponentsView';
import AdminReasonCodesView from './views/admin/admin-reasoncodes-view/AdminReasonCodesView';
import AdminChecklistQuestionView from './views/admin/admin-checklist-questions-view/AdminChecklistQuestionView';
import TaskList from './views/task-view/task-list-view/TaskList';
import WorkPlanView from './views/task-view/workplan-view/WorkPlanView';
import TaskDetailsView from './views/task-view/task-details-view2/TaskDetailsView';
import MapView from './views/task-view/map-view/MapView';
import CountGoodsView from './views/goods-view/count-goods-dialog/CountGoodsView';
import useUserAccess from './hooks/useUserAccess';
import OrderGoodsView from './views/goods-view/order-goods-dialog/OrderGoodsView';
import MoveGoodsView from './views/goods-view/move-goods-dialog/MoveGoodsView';
import AdminPortalLinksView from './views/admin/admin-checklist-questions-view copy/AdminPortalLinksView';
import TechnicianTaskList from './views/task-view/technician-task-list-view/TechnicianTaskList';
import ReturnGoodsView from './views/goods-view/return-goods-dialog/ReturnGoodsView';
import ReceiptView from './views/goods-view/goods-view-tabs/return-goods-tab/ReceiptView';
import { useNavigate } from './hooks/useNavigate';
import AdminNewsView from './views/admin/admin-news/AdminNewsView';
import NewsView from './views/news-view/NewsView';

const UserRoleRoutes = () => {
  const { technicianUserGroupAllowed, officeUserGroupAllowed, adminUserGroupAllowed } = useUserAccess();

  const navigate = useNavigate();

  return (
    <>
      <Route path={NEWS_ROUTE} element={<NewsView />} />
      {technicianUserGroupAllowed() && (
        <>
          <Route path={WORKPLAN_ROUTE + '/*'} element={<WorkPlanView />} />
          <Route path={CALENDAR_ROUTE + '/*'} element={<WorkPlanCalendarView />} />
          <Route path={TECHNICIAN_TASKLIST_ROUTE + '/*'} element={<TechnicianTaskList />} />
          <Route path={INTERNAL_TIME_TECHNICIAN} element={<InternalTimeTechnicianView />} />
          <Route path={TASKS_DETAILS} element={<TaskDetailsView />} />
          <Route path={GOODS_ROUTE} element={<GoodsView />} />
          <Route path={COUNT_GOODS_ROUTE} element={<CountGoodsView />} />
          <Route path={ORDER_GOODS_ROUTE} element={<OrderGoodsView />} />
          <Route path={MOVE_GOODS_ROUTE} element={<MoveGoodsView />} />
          <Route path={RETURN_GOODS_ROUTE} element={<ReturnGoodsView showReceipt={() => navigate(RECEIPT_ROUTE)} />} />
          <Route path={RECEIPT_ROUTE} element={<ReceiptView />} />
        </>
      )}

      {officeUserGroupAllowed() && (
        <>
          <Route path={TASKS_ROUTE + '/*'} element={<TaskList />} />
          <Route path={INTERNAL_TIME_OFFICE} element={<InternalTimeOfficeView />} />
          <Route path={MAP_TASK_LIST_ROUTE} element={<MapView />} />
        </>
      )}

      {adminUserGroupAllowed() && (
        <>
          <Route path={ADMIN_NEWS} element={<AdminNewsView />} />
          <Route path={ADMIN_TASKTYPES} element={<AdminTaskTypesView />} />
          <Route path={ADMIN_REUSABLECOMPONENTS} element={<AdminReusableComponentsView />} />
          <Route path={ADMIN_REASONCODES} element={<AdminReasonCodesView />} />
          <Route path={ADMIN_QUESTIONS} element={<AdminChecklistQuestionView />} />
          <Route path={ADMIN_PORTAL_LINKS} element={<AdminPortalLinksView />} />
        </>
      )}
    </>
  );
};

export default UserRoleRoutes;
