import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { DownloadIcon } from '../../assets/icons/DownloadIcon';
import { EyeIcon } from '../../assets/icons/EyeIcon';
import { FileIcon } from '../../assets/icons/FileIcon';
import { ImageIcon } from '../../assets/icons/ImageIcon';
import DialogView from '../dialog-view/DialogView';
import IconButton from '../icon-button/IconButton';
import LoadingOverlay from '../loading-overlay/LoadingOverlay';
import { formatDateString } from '../../utils/dateHandling';
import { isDevice } from '../../utils/device-handling/deviceDetectionUtils';
import { isImageType } from '../../utils/stringHandling';
import { downloadFileFromBlob } from '../../utils/fileUtils';
import NotificationService from '../../services/NotificationService';
import { Link } from 'react-router-dom';
import ImageService from '../../services/ImageService';
import { DownloadInfo } from '../../models/DownloadInfo';

const TextColumn = (props: { title?: string; value: string; flex: string; error?: boolean }) => {
  const { title, value, flex, error } = props;

  return (
    <ColumnContainer error={error} flex={flex}>
      <ColumnHeader>{title}</ColumnHeader>
      <ColumnValue>{value}</ColumnValue>
    </ColumnContainer>
  );
};

interface Props {
  name: string;
  src?: string;
  date?: string;
  uploadedBy?: string;
  errorMessage?: string;
  type?: string;
  isImage?: boolean;
  handleRemove?: () => void;
  handleDownload?: (fileName: string) => DownloadInfo;
  handleThumbnail?: () => Promise<string>;
}

const FilePreview = (props: Props) => {
  const { src, name, date, uploadedBy, errorMessage, type, isImage, handleRemove, handleDownload, handleThumbnail } = props;
  const [imgSrc, setImgSrc] = useState(src);
  const [imageSource, setImageSource] = useState<string | undefined>();
  const [isFetchingImage, setIsFetchingImage] = useState(false);
  const [deeplink, setDeeplink] = useState<string>('');
  const isTablet = isDevice();

  useEffect(() => {
    if (isImage) {
      handleThumbnail &&
        handleThumbnail()
          .then((res) => {
            setImgSrc(res);
          })
          .catch(() => NotificationService.error('Kunne ikke hente thumbnail.'));

      if (handleDownload && !isTablet) {
        const downloadInfo = handleDownload(name);
        const link = ImageService.getDeeplink(downloadInfo);
        setDeeplink(link);
      }
    }
  }, []);

  const renderPlaceholderIcon = () => {
    if (isImageType(type)) {
      return <StyledImageIcon size="62px" />;
    } else {
      return <StyledFileIcon size="62px" />;
    }
  };

  const viewFile = () => {
    if (!handleDownload) return;
    setIsFetchingImage(true);
    const downloadInfo = handleDownload(name);
    ImageService.downloadImage(downloadInfo)
      .then((res) => {
        const fileURL = URL.createObjectURL(res.data);
        if (isTablet) {
          setImageSource(fileURL);
        }
      })
      .catch(() => NotificationService.error('Kunne ikke hente filen. Prøv igen.'))
      .finally(() => setIsFetchingImage(false));
  };

  const downloadFile = () => {
    if (!handleDownload) return;
    setIsFetchingImage(true);
    const downloadInfo = handleDownload(name);
    ImageService.downloadImage(downloadInfo)
      .then((res) => {
        downloadFileFromBlob(name, res.data);
      })
      .catch(() => NotificationService.error('Kunne ikke hente filen. Prøv igen.'))
      .finally(() => {
        setIsFetchingImage(false);
      });
  };

  return (
    <Container>
      {imageSource && (
        <DialogView
          open={!!imageSource}
          onClick={() => setImageSource(undefined)}
          handleClose={() => setImageSource(undefined)}
          children={<img src={imageSource} height={'100%'} width={'100%'} alt="" />}
        />
      )}
      {imgSrc ? (
        <ImgContainer>
          <StyledImg src={imgSrc} />
        </ImgContainer>
      ) : (
        renderPlaceholderIcon()
      )}
      <TextContainer>
        {errorMessage ? (
          <>
            <TextColumn error flex="4" title="Filnavn" value={name} />
            <TextColumn error flex="4" title="Error" value={errorMessage} />
            <DivFlex2 />
          </>
        ) : (
          <>
            <TextColumn flex="4" title="Filnavn" value={name} />
            {date && <TextColumn flex="4" title="Dato for upload" value={formatDateString(date)} />}
            {uploadedBy && <TextColumn flex="3" title="Uploadet af" value={uploadedBy} />}
          </>
        )}
      </TextContainer>
      <>
        {isFetchingImage ? (
          <LoadingOverlay />
        ) : (
          <ButtonContainer>
            {isImage && isTablet && (
              <IconButton onClick={viewFile}>
                <EyeIcon size="20px" />
              </IconButton>
            )}
            {isImage && !isTablet && deeplink && (
              <StyledLink target="_blank" to={deeplink}>
                <EyeIcon size="20px" />
              </StyledLink>
            )}
            <IconButton onClick={downloadFile}>
              <DownloadIcon size="16px" />
            </IconButton>
            {handleRemove && (
              <IconButton onClick={() => handleRemove && handleRemove()}>
                <DeleteIcon size="16px" />
              </IconButton>
            )}
          </ButtonContainer>
        )}
      </>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DivFlex2 = styled.div`
  flex: 2;
`;

const StyledImageIcon = styled((props) => <ImageIcon {...props} />)`
  margin-right: ${(props) => props.theme.spacing(6)};
  fill: ${(props) => props.theme.palette.grey.black20};
`;

const StyledFileIcon = styled((props) => <FileIcon {...props} />)`
  margin-right: ${(props) => props.theme.spacing(6)};
  fill: ${(props) => props.theme.palette.grey.black20};
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing(6)};
  width: ${(props) => props.theme.spacing(16)};
  height: ${(props) => props.theme.spacing(16)};
  overflow: hidden;
`;

const StyledImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const TextContainer = styled.div`
  flex: 6;
  display: flex;
  column-gap: 48px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  width: 168px;
  justify-content: flex-end;
`;

const ColumnContainer = styled.div<{ flex: string; error?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  & span {
    color: ${(props) => (props.error ? 'red !important' : '')};
  }
`;
export const ColumnHeader = styled.span`
  color: ${(props) => props.theme.palette.grey.black60};
  font-weight: 600;
  font-size: 13px;
`;
export const ColumnValue = styled.span`
  color: ${(props) => props.theme.palette.main.black.primary};
  word-break: break-word;
`;

export const StyledLink = styled(Link)`
  padding: ${(props) => props.theme.spacing(3)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export default FilePreview;
