import { Cell as ReactCell } from 'react-table';
import styled, { css } from 'styled-components';
import TruncatableCell from './TruncatableCell';
import Cell from './Cell';

interface Props<T extends object> {
  getRowProps: any;
  cells: ReactCell<T, any>[];
  rowData: any;
  selected?: boolean;
  handleDoubleClick?: (rowData: any) => void;
  handleClick?: (rowData: any) => void;
  truncatableCells?: (keyof T)[];
  canExpand?: boolean;
  useExpand?: boolean;
  showError?: boolean;
}

const Row = <T extends object>(props: Props<T>) => {
  const {
    getRowProps,
    cells,
    rowData,
    selected,
    handleDoubleClick,
    handleClick,
    truncatableCells,
    canExpand,
    useExpand,
    showError
  } = props;

  return (
    <StyledRow
      {...getRowProps()}
      onClick={() => handleClick && handleClick(rowData)}
      onDoubleClick={() => handleDoubleClick && handleDoubleClick(rowData)}
      selected={selected}
      canExpand={canExpand}
      useExpand={useExpand}
      hasError={rowData?.exist === false || showError}
      data-testid={`row-${rowData?.id ?? ''}`}
    >
      {cells &&
        cells.map((cell, i) =>
          truncatableCells?.some((x) => x === cell.column.id) ? (
            <TruncatableCell getCellProps={cell.getCellProps} render={cell.render} value={cell.value} key={`${i}-cell`} />
          ) : (
            <Cell getCellProps={cell.getCellProps} render={cell.render} key={`${i}-cell`} />
          )
        )}
    </StyledRow>
  );
};
const StyledRow = styled.tr<{
  selected: boolean;
  canExpand?: boolean;
  useExpand?: boolean;
  hasError?: boolean;
}>`
  background-color: ${(props) => props.selected && props.theme.palette.grey.black5};
  background-color: ${(props) => props.hasError && props.theme.palette.functions.error.tertiary};

  &:hover {
    background-color: ${(props) => (props.useExpand ? props.theme.palette.grey.black10 : props.theme.palette.grey.black5)};
    background-color: ${(props) => props.hasError && props.theme.palette.functions.error.tertiary};
  }

  ${({ canExpand, useExpand }) =>
    !canExpand &&
    useExpand &&
    css`
      background: ${(props) => props.theme.palette.grey.black5};
      color: ${(props) => props.theme.palette.grey.black60};
      td {
        margin: 8px 0;
      }

      td:first-child {
        padding-left: 24px !important;
      }
    `}
`;

export default Row;
