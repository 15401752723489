import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const StretchIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="upload-icon" viewBox="-1 -4 35 35" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M8.50801 15.4766L8.06056 14.977C7.56095 14.4192 3.16678 9.44317 3.16678 6.67424C3.16678 3.72873 5.56251 1.33301 8.50801 1.33301C11.4535 1.33301 13.8492 3.72873 13.8492 6.67424C13.8492 9.44317 9.45506 14.4192 8.95545 14.977L8.50801 15.4766ZM8.50801 2.53689C6.22665 2.53689 4.37066 4.39288 4.37066 6.67424C4.37066 8.4319 7.04128 11.9332 8.50801 13.6588C9.97674 11.9332 12.6454 8.4319 12.6454 6.67424C12.6454 4.39288 10.7894 2.53689 8.50801 2.53689Z"
      />
      <path
        stroke="none"
        d="M8.50796 9.05403C7.08738 9.05403 5.93165 7.8983 5.93165 6.47772C5.93165 5.05714 7.08738 3.90141 8.50796 3.90141C9.92855 3.90141 11.0843 5.05714 11.0843 6.47772C11.0843 7.8983 9.92855 9.05403 8.50796 9.05403ZM8.50796 5.10329C7.75152 5.10329 7.13554 5.71928 7.13554 6.47572C7.13554 7.23216 7.75152 7.84814 8.50796 7.84814C9.2644 7.84814 9.88039 7.23216 9.88039 6.47572C9.88039 5.71928 9.2644 5.10329 8.50796 5.10329Z"
      />
      <path
        stroke="none"
        d="M27.3199 29.7725L26.9467 29.3572C26.5875 28.9559 23.4334 25.3824 23.4334 23.3799C23.4334 21.237 25.177 19.4914 27.3219 19.4914C29.4668 19.4914 31.2105 21.235 31.2105 23.3799C31.2105 25.3844 28.0563 28.9579 27.6971 29.3572L27.3239 29.7725H27.3199ZM27.3199 20.4946C25.7288 20.4946 24.4346 21.7888 24.4346 23.3799C24.4346 24.5938 26.2665 27.0116 27.3199 28.2576C28.3733 27.0116 30.2052 24.5918 30.2052 23.3799C30.2052 21.7888 28.911 20.4946 27.3199 20.4946Z"
      />
      <path
        stroke="none"
        d="M27.3198 25.1494C26.2644 25.1494 25.4076 24.2906 25.4076 23.2372C25.4076 22.1838 26.2664 21.3251 27.3198 21.3251C28.3732 21.3251 29.2319 22.1838 29.2319 23.2372C29.2319 24.2906 28.3732 25.1494 27.3198 25.1494ZM27.3198 22.3283C26.8182 22.3283 26.4108 22.7356 26.4108 23.2372C26.4108 23.7389 26.8182 24.1462 27.3198 24.1462C27.8214 24.1462 28.2287 23.7389 28.2287 23.2372C28.2287 22.7356 27.8214 22.3283 27.3198 22.3283Z"
      />
      <path
        stroke="none"
        d="M23.732 29.0225V30.2263H13.2984C9.86933 30.2263 7.07833 27.4353 7.07833 24.0063C7.07833 20.5772 9.86933 17.7862 13.2984 17.7862H18.5152C19.9539 17.7862 21.1236 16.6164 21.1236 15.1778C21.1236 13.7392 19.9539 12.5694 18.5152 12.5694H14.5023V11.3655H18.5152C20.618 11.3655 22.3275 13.075 22.3275 15.1778C22.3275 17.2806 20.7243 18.8797 18.7159 18.9861C18.6496 18.9881 18.5814 18.9901 18.5152 18.9901H13.2984C10.5315 18.9901 8.28221 21.2394 8.28221 24.0063C8.28221 26.7732 10.5315 29.0225 13.2984 29.0225H23.732Z"
      />
      <path
        stroke="none"
        d="M18.7159 18.9861L18.7162 18.9894L18.5152 18.9901C18.5814 18.9901 18.6496 18.9881 18.7159 18.9861Z"
      />
    </IconSvg>
  );
};
