import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const InfoIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 13.503 7.631 L 16.446 7.631 L 16.446 10.568 L 13.503 10.568 L 13.503 7.631 Z M 13.503 13.506 L 16.446 13.506 L 16.446 22.319 L 13.503 22.319 L 13.503 13.506 Z M 14.974 0.286 C 6.849 0.286 0.254 6.867 0.254 14.974 C 0.254 23.082 6.849 29.663 14.974 29.663 C 23.1 29.663 29.695 23.082 29.695 14.974 C 29.695 6.867 23.1 0.286 14.974 0.286 Z M 14.974 26.725 C 8.483 26.725 3.198 21.452 3.198 14.974 C 3.198 8.497 8.483 3.224 14.974 3.224 C 21.466 3.224 26.75 8.497 26.75 14.974 C 26.75 21.452 21.466 26.725 14.974 26.725 Z"
      ></path>
    </IconSvg>
  );
};
