import {
  CommentDTO,
  CreateWorkTaskDTO2,
  CustomerAppointmentDTO,
  DayTaskDTO,
  FileAnswerUploadDTO,
  MassCreationRequestDTO2,
  MassCreationStretchRequestDTO,
  TaskListRequestDTO,
  UpdateWorkTaskDTO2,
  WorkPlanItemStatusDTO,
  WorkTaskDepartmentDTO2,
  WorkTaskDTO2,
  WorkTaskProjectDTO,
  WorkTaskStatus,
  WorkTaskStatusDTO
} from '../api/api';
import WorkClient from '../api/workClient';
import { formatDateStringISO } from '../utils/dateHandling';

const getWorkTaskListItems = () => {
  return WorkClient.getTasks2();
};

const getWorkTaskList = (body: TaskListRequestDTO, abortController?: AbortController) => {
  return WorkClient.taskListPOST(body, abortController?.signal);
};

const getTaskListInfo = (departmentId: number) => {
  return WorkClient.taskListGET(departmentId);
};

const getTask = async (id: number) => {
  return WorkClient.getBaseTask2(id);
};

const deleteTasks = (taskIds: number[]) => {
  return WorkClient.batchDeleteWorkTasks(taskIds);
};

const getWorkPlan = () => {
  return WorkClient.getWorkPlan5();
};

const setTaskStatus = (tasks: WorkTaskStatusDTO[]) => {
  return WorkClient.setTaskStatus(tasks);
};

const setWorkPlanTaskStatus = (task: WorkPlanItemStatusDTO) => {
  return WorkClient.setWorkPlanTaskStatus(task);
};

const getReasonCodes = (taskTypeId: number, status: WorkTaskStatus) => {
  return WorkClient.getReasonCodes(taskTypeId, status);
};

const suspendTask = (body: DayTaskDTO) => {
  return WorkClient.completeDayOfMultiDayTask(body);
};

const updateWorkTask = (taskId: number, fields: UpdateWorkTaskDTO2) => {
  return WorkClient.updateWorkTask2(taskId, fields);
};

const createTask = (workTask: CreateWorkTaskDTO2): Promise<WorkTaskDTO2> => {
  return WorkClient.createWorkTask2(workTask);
};

const updateDepartments = (departments: WorkTaskDepartmentDTO2[]) => {
  return WorkClient.setTaskDepartment2(departments);
};

const getNearbyTask = (lat: number, lng: number, assetId: string) => {
  return WorkClient.nearby3(lat, lng, assetId);
};

const postComment = (comment: CommentDTO) => {
  return WorkClient.comments(comment);
};

const flagComment = (commentId: number, workTaskId: number, flag: boolean) => {
  return WorkClient.flagComment(commentId, workTaskId, flag);
};

const assignToCaseWorker = (email?: string, name?: string, taskIds?: number[]) => {
  return WorkClient.assignTasksToCaseWorker(email, name, taskIds);
};

const getPdf = (taskId: number, contractorAgreementId: number) => {
  return WorkClient.getPDFV2(taskId, contractorAgreementId);
};

const customerAppointment = (taskId: number, body: CustomerAppointmentDTO) => {
  return WorkClient.customerAppointment(taskId, body);
};

const getTechnicianCalendarTasks = (from: string, to: string) => {
  return WorkClient.getTechnicianCalendarTasks(from, to);
};

const getProjectById = (projectNumber: string) => {
  return WorkClient.getProjectById(projectNumber);
};

const updateProjectNumber = (workTaskId: number, body: WorkTaskProjectDTO | undefined) => {
  return WorkClient.updateProjectNumberForWorkTask(workTaskId, body);
};

const massCreateWorkTasks = (body: MassCreationRequestDTO2) => {
  return WorkClient.massCreateWorkTasks2(body);
};

const massCreateStretchWorkTasks = (body: MassCreationStretchRequestDTO) => {
  return WorkClient.massCreateStretchWorkTasks(body);
};

const addFileAnswers = (body: FileAnswerUploadDTO) => {
  return WorkClient.addFileAnswers(body);
};

const getThumbNail = (taskId: number, fileName: string, answerId: number) => {
  return WorkClient.thumbnail2(taskId, fileName, answerId);
};

const updateWorkTaskTag = (tagId: number | undefined, ids: number[]) => {
  return WorkClient.setWorkTasksTag(tagId, ids);
};

const sendToPlanning = (workTaskIds: number[]) => {
  return WorkClient.sendWorkTasksToPlanning(workTaskIds);
};

const getSchedule = (date: string) => {
  return WorkClient.getPlanningEvents(formatDateStringISO(date));
};

const TaskService = {
  getWorkTaskListItems,
  getTaskListInfo,
  getWorkTaskList,
  getTask,
  deleteTasks,
  getWorkPlan,
  createTask,
  getNearbyTask,
  setTaskStatus,
  setWorkPlanTaskStatus,
  getReasonCodes,
  suspendTask,
  updateDepartments,
  postComment,
  assignToCaseWorker,
  getPdf,
  updateWorkTask,
  customerAppointment,
  getTechnicianCalendarTasks,
  getProjectById,
  updateProjectNumber,
  massCreateWorkTasks,
  addFileAnswers,
  getThumbNail,
  updateWorkTaskTag,
  sendToPlanning,
  getSchedule,
  massCreateStretchWorkTasks,
  flagComment
};

export default TaskService;
