import { MeterBlueprintFieldDTO, MeterBlueprintSectionDTO, UpdateSectionDTO } from '../../../../../../api/api';
import { TabProps } from '../../../../../../blocks/tabs-vertical/TabsVertical';
import styled from 'styled-components';
import TextField from '../../../../../../components/text-field/TextField';
import { useEffect, useState } from 'react';
import Typography from '../../../../../../components/typography/Typography';

interface Props extends TabProps {
  section?: MeterBlueprintSectionDTO;
  onChange: (update: UpdateSectionDTO) => void;
}
const GenericSection = (props: Props) => {
  const { section, onChange } = props;
  const [fields, setFields] = useState<MeterBlueprintFieldDTO[]>([]);

  useEffect(() => {
    const sortedFields = section?.generic?.fields?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    setFields(sortedFields ?? []);
  }, [section]);

  const handleFieldChange = (newField: MeterBlueprintFieldDTO, newValue: string) => {
    setFields((prevFields) => {
      return prevFields.map((prevField) => {
        if (prevField.id === newField.id) {
          return { ...prevField, value: newValue };
        }
        return prevField;
      });
    });
    const update: UpdateSectionDTO = {
      generic: {
        fields: [
          {
            meterTemplateFieldId: newField.id,
            textValue: newValue
          }
        ]
      }
    };
    onChange(update);
  };

  return (
    <>
      <Typography variant="h1">{section?.name}</Typography>
      {fields.map((field) => {
        return (
          <Row key={field.id}>
            <Title>{field.name}</Title>
            <TextField
              required
              fullWidth
              value={field.value}
              onChange={(e) => {
                handleFieldChange(field, e.target.value);
              }}
            ></TextField>
          </Row>
        );
      })}
    </>
  );
};

const Row = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(8)};
`;

const Title = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

export default GenericSection;
