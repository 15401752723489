import styled from 'styled-components';
import Typography from '../typography/Typography';

interface Props {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  size?: number;
  fontSize?: number;
}

const Avatar = (props: Props) => {
  const { text, onClick, size, fontSize } = props;

  return (
    <Root onClick={onClick} size={size}>
      <Typography fontWeight='bold' color="textMenu" fontSize={fontSize}>
        {text}
      </Typography>
    </Root>
  );
};

const Root = styled.div<{ size?: number }>`
  width: ${props => props.size ?? 38}px;
  height: ${props => (props.size ?? 38) - 2}px;
  border-radius: 28px;
  background-color: ${(props) => props.theme.palette.grey.black5};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
  line-height: 52px;
  padding-top: 2px;

  &:hover {
    cursor: pointer;
  }
`;

export default Avatar;
