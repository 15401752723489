import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CommentDTO, MessageDTO, MessageType, WorkTaskDTO2, WorkTaskStatus } from '../../../../api/api';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import Avatar from '../../../../components/avatar/Avatar';
import Button from '../../../../components/button/Button';
import TextField from '../../../../components/text-field/TextField';
import NotificationService from '../../../../services/NotificationService';
import TaskService from '../../../../services/TaskService';
import { getUserInitials } from '../../../../utils/stringHandling';
import { formatDateWithTime, sortDateTime } from '../../../../utils/dateHandling';
import MessageService from '../../../../services/MessageService';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import { log } from '../../../../utils/logging/log';
import CircularProgress from '../../../../components/circular-progress/CircularProgress';
import Toggle from '../../../../components/toggle/Toggle';
import Typography from '../../../../components/typography/Typography';
import n1LightTheme from '../../../../theme/light-theme';
import { CommentIcon } from '../../../../assets/icons/CommentIcon';
import IconButton from '../../../../components/icon-button/IconButton';

interface Props extends TabProps {
  updateTask: React.Dispatch<React.SetStateAction<WorkTaskDTO2 | undefined>>;
  taskId?: string;
  comments?: CommentDTO[];
  comment: string;
  setComment: (comment: string) => void;
}

const getText = (message: MessageDTO) => {
  switch (message.messageType) {
    case MessageType.Comment:
      return message.text;
    case MessageType.DepartmentTransAction:
      return message.department ? `Afdeling skiftet til ${message.department.name}.` : 'Afdeling blev skiftet.';
    case MessageType.StatusTransAction:
      return message.status
        ? `Status skiftet til ${getEnumDisplayValue(message.status as WorkTaskStatus)}. ${message.text ? message.text : ''}`
        : 'Status blev skiftet.';
    default:
      return '-';
  }
};

const TaskMessagesStep = (props: Props) => {
  const { taskId, comment, setComment } = props;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageDTO[]>([]);
  const [messageFlagged, setMessageFlagged] = useState(false);

  const handleCancel = useCallback(() => {
    setComment('');
    setMessageFlagged(false);
  }, [setComment]);

  const handleClickAdd = () => {
    setLoading(true);
    TaskService.postComment({
      text: comment,
      baseWorkTaskId: Number(taskId),
      isFlagged: messageFlagged
    })
      .then((newComment) => {
        setMessages((prevMessages) =>
          newComment.isFlagged
            ? [
                ...prevMessages.map((message) => ({ ...message, isFlagged: false })),
                { ...newComment, messageType: MessageType.Comment }
              ]
            : [...prevMessages, { ...newComment, messageType: MessageType.Comment }]
        );
        setMessageFlagged(false);
        handleCancel();
      })
      .catch((error) => {
        NotificationService.error('Der opstod en fejl ved afsendelse af beskeden');
        log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickFlagExistingMessage = useCallback(
    async (comment: MessageDTO) => {
      setLoading(true);

      try {
        await TaskService.flagComment(comment.commentId ?? 0, +(taskId ?? 0), !comment.isFlagged);

        setMessages((prev) =>
          prev.map((message) => ({ ...message, isFlagged: message.commentId === comment.commentId && !comment.isFlagged }))
        );
      } catch (error) {
        log(error);
        NotificationService.error('Noget gik galt - kunne ikke fremhæve besked');
      }
      setLoading(false);
    },
    [taskId]
  );

  useEffect(() => {
    if (!taskId) return;
    setLoading(true);
    MessageService.getMessages(Number(taskId))
      .then((data) => {
        setMessages(data);
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke hente beskeder.');
        log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [taskId]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <MessagesContainer>
          <CommentField
            fullWidth
            label="Besked"
            multiline
            onChange={(e) => setComment(e.target.value)}
            placeholder="Skriv besked her"
            rows={5}
            value={comment}
          />
          <ButtonContainer>
            <Button variant="secondary" onClick={handleCancel}>
              Ryd
            </Button>
            <ButtonRightContainer>
              <FlagMessageContainer>
                <Toggle
                  handleChange={() => setMessageFlagged((prev) => !prev)}
                  id={'toggleFlaggedId'}
                  checked={messageFlagged}
                  variant="secondary"
                />
                <Typography>Fremhævet besked</Typography>
                <CommentIcon width="20px" color={n1LightTheme.palette.functions.warning.primary} />
              </FlagMessageContainer>
              <Button isLoading={loading} onClick={handleClickAdd}>
                Gem besked
              </Button>
            </ButtonRightContainer>
          </ButtonContainer>

          {messages.length ? (
            <PastMessagesTitle>Tidligere beskeder</PastMessagesTitle>
          ) : (
            <NoMessagesText>Der er ingen beskeder på denne opgave</NoMessagesText>
          )}

          {[...messages]
            .sort((a, b) => sortDateTime(b.timeStamp ?? '', a.timeStamp ?? ''))
            ?.map((message, i) => (
              <MessageItem key={i}>
                <Avatar text={getUserInitials(message.authorName)} />
                <MessageItemSpecs>
                  <MessageItemSpec>{message.authorName}</MessageItemSpec>
                  <MessageItemSpec>{formatDateWithTime(message.timeStamp)}</MessageItemSpec>
                </MessageItemSpecs>
                <MessageItemText type={message.messageType} isFlagged={message.isFlagged}>
                  <Typography>{getText(message)}</Typography>
                  {message.messageType === MessageType.Comment && (
                    <IconButton padding="0" onClick={() => message.commentId && handleClickFlagExistingMessage(message)}>
                      <CommentIcon
                        width="20px"
                        height="20px"
                        color={
                          message.isFlagged
                            ? n1LightTheme.palette.functions.warning.primary
                            : n1LightTheme.palette.grey.black10
                        }
                      />
                    </IconButton>
                  )}
                </MessageItemText>
              </MessageItem>
            ))}
        </MessagesContainer>
      )}
    </>
  );
};

const CommentField = styled(TextField)`
  width: 100%;
  margin-top: 32px !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

const ButtonRightContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(8)};
`;

const FlagMessageContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(2)};
  align-items: center;
`;

const MessagesContainer = styled.div``;

const PastMessagesTitle = styled.h2`
  margin-top: 40px;
`;

const NoMessagesText = styled.h2`
  margin-top: 40px;
  text-align: center;
`;

const MessageItem = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

const MessageItemSpecs = styled.div`
  width: 140px;
  margin-left: 16px;
`;

const MessageItemSpec = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.palette.grey.black60};
  text-transform: capitalize;

  &:first-of-type {
    margin-bottom: 8px;
  }
`;

const MessageItemText = styled.div<{ type?: MessageType; isFlagged?: boolean }>`
  flex-grow: 1;
  padding: 24px;
  margin-left: 32px;
  color: ${(props) =>
    props.type === MessageType.Comment ? props.theme.palette.text.primary : props.theme.palette.grey.black20};
  background-color: ${(props) =>
    props.isFlagged ? props.theme.palette.functions.warning.tertiary : props.theme.palette.background.primary};
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-between;
`;

export default TaskMessagesStep;
