import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const DownloadIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 15.029 22.644 L 6.244 13.859 L 8.201 11.902 L 13.664 17.364 L 13.664 0.476 L 16.395 0.476 L 16.395 17.364 L 21.857 11.902 L 23.815 13.859 L 15.029 22.644 Z M 3.194 29.609 C 2.466 29.609 1.829 29.336 1.282 28.79 C 0.736 28.243 0.463 27.606 0.463 26.878 L 0.463 20.368 L 3.194 20.368 L 3.194 26.878 C 3.194 26.878 3.194 26.878 3.194 26.878 C 3.194 26.878 3.194 26.878 3.194 26.878 L 26.865 26.878 C 26.865 26.878 26.865 26.878 26.865 26.878 C 26.865 26.878 26.865 26.878 26.865 26.878 L 26.865 20.368 L 29.596 20.368 L 29.596 26.878 C 29.596 27.606 29.323 28.243 28.777 28.79 C 28.23 29.336 27.593 29.609 26.865 29.609 L 3.194 29.609 Z"
      ></path>
    </IconSvg>
  );
};
