import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SaveIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 28.638 6.614 L 23.157 1.086 C 22.706 0.632 21.713 0.217 20.994 0.217 L 4.523 0.217 C 2.214 0.217 0.342 2.103 0.342 4.433 L 0.342 25.519 C 0.342 27.847 2.214 29.735 4.523 29.735 L 25.431 29.735 C 27.74 29.735 29.612 27.847 29.612 25.519 L 29.612 8.907 C 29.612 8.182 29.2 7.18 28.638 6.614 Z M 14.977 25.519 C 12.668 25.519 10.795 23.63 10.795 21.302 C 10.795 18.972 12.668 17.083 14.977 17.083 C 17.286 17.083 19.158 18.972 19.158 21.302 C 19.158 23.63 17.283 25.519 14.977 25.519 Z M 21.249 11.812 C 21.249 12.393 20.778 12.866 20.204 12.866 L 5.569 12.866 C 4.991 12.866 4.523 12.393 4.523 11.812 L 4.523 5.487 C 4.523 4.907 4.991 4.433 5.569 4.433 L 20.204 4.433 C 20.778 4.433 21.249 4.907 21.249 5.487 L 21.249 11.812 Z"
        stroke="none"
      ></path>
    </IconSvg>
  );
};
