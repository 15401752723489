import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const UploadIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="upload-icon" viewBox="-4 -3 25 25" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M2.82043 15.8164C1.95585 15.8164 1.28918 15.5846 0.820435 15.1211C0.356893 14.6576 0.125122 13.9935 0.125122 13.1289V4.75391C0.125122 3.89453 0.356893 3.23307 0.820435 2.76953C1.28918 2.30599 1.95585 2.07422 2.82043 2.07422H10.4376L8.32043 4.19141H3.08606C2.81002 4.19141 2.59908 4.25911 2.45325 4.39453C2.31262 4.52995 2.24231 4.74349 2.24231 5.03516V12.8477C2.24231 13.1393 2.31262 13.3529 2.45325 13.4883C2.59908 13.6237 2.81002 13.6914 3.08606 13.6914H11.0001C11.2293 13.6914 11.4116 13.6237 11.547 13.4883C11.6824 13.3529 11.7501 13.1393 11.7501 12.8477V7.66797L13.8673 5.55078V13.1289C13.8673 13.9935 13.6381 14.6576 13.1798 15.1211C12.7215 15.5846 12.0835 15.8164 11.2657 15.8164H2.82043ZM5.87512 10.5039C5.75533 10.5456 5.64075 10.5195 5.53137 10.4258C5.422 10.3268 5.39596 10.207 5.45325 10.0664L6.11731 8.41797L12.4611 2.07422L13.8907 3.50391L7.547 9.83984L5.87512 10.5039ZM14.5001 2.88672L13.0704 1.46484L13.5314 1.01172C13.7085 0.829427 13.9168 0.727865 14.1564 0.707031C14.396 0.68099 14.5939 0.74349 14.7501 0.894531L15.0079 1.14453C15.185 1.31641 15.271 1.52474 15.2657 1.76953C15.2605 2.00911 15.1642 2.22266 14.9767 2.41016L14.5001 2.88672Z"
      />
    </IconSvg>
  );
};
