import Properties from './tabs/TaskTypeProperties/TaskTypeProperties';
import DynamicFields from './tabs/TaskTypeDynamicFields/TaskTypeDynamicFields';
import Checklists from './tabs/TaskTypeChecklist/TaskTypeChecklists';
import DialogView from '../../../components/dialog-view/DialogView';
import TabsVertical from '../../../blocks/tabs-vertical/TabsVertical';
import TaskTypeTaskFlow from './tabs/TaskTypeFlow/TaskTypeTaskFlow';
import Checkbox from '../../../components/checkbox/Checkbox';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import { log } from '../../../utils/logging/log';
import { useCallback, useEffect, useState } from 'react';

import NotificationService from '../../../services/NotificationService';
import TaskTypeService from '../../../services/TaskTypeService';
import AdminService from '../../../services/AdminService';
import {
  QuestionDTO,
  ReasonCodeDTO,
  ReuseableComponentAttributeDTO,
  SectionQuestionDTO,
  TaskComponentDTO,
  TaskTypeCategoryDTO,
  TaskTypeCheckListDTO,
  TaskTypeCheckListTextDTO,
  TaskTypeDetailedDTO2,
  TaskTypeSectionDTO,
  UpdateReasonCodeStatusDTO2
} from '../../../api/api';
import { CreateAndUpdateTaskTypeDTO2Extended } from '../../../models/CreateAndUpdateTaskTypeDTOExtended';
import Typography from '../../../components/typography/Typography';
import AuditInfo from '../../../components/audit-info/AuditInfo';

interface Props {
  taskType: CreateAndUpdateTaskTypeDTO2Extended;
  closeDialog: (reload?: boolean) => void;
  taskTypeCategories: TaskTypeCategoryDTO[];
  specificAssetTypes: string[];
  setTaskTypeId: (id: number) => void;
}

const AdminTaskTypesDialog = (props: Props) => {
  const { taskType, closeDialog, taskTypeCategories, specificAssetTypes, setTaskTypeId } = props;

  const [properties, setProperties] = useState(taskType);

  const [taskTypes, setTaskTypes] = useState<TaskTypeDetailedDTO2[]>([]);
  const [allQuestions, setAllQuestions] = useState<QuestionDTO[]>([]);

  const [dynamicFieldsSections, setDynamicFieldsSections] = useState<TaskComponentDTO[]>();
  const [dynamicFieldsCreatedSectionsIds, setDynamicFieldsCreatedSectionsIds] = useState<number[]>([]);

  const [newSectionIds, setNewSectionIds] = useState<number[]>([]);

  const [checklists, setChecklists] = useState<TaskTypeCheckListTextDTO[]>([]);
  const [newChecklistIds, setNewChecklistIds] = useState<number[]>([]);

  const [footer, setFooter] = useState<
    [React.SetStateAction<JSX.Element> | undefined, React.SetStateAction<JSX.Element> | undefined]
  >([<></>, undefined]);

  const [selectedReasonCodes, setSelectedReasonCodes] = useState<UpdateReasonCodeStatusDTO2[]>([]);
  const [newReasonCodes, setNewReasonCodes] = useState<UpdateReasonCodeStatusDTO2[]>([]);
  const [allReasonCodes, setAllReasonCodes] = useState<UpdateReasonCodeStatusDTO2[]>([]);

  const [allAttributes, setAllAttributes] = useState<ReuseableComponentAttributeDTO[]>();

  const [activePanelId, setActivePanelId] = useState<string>('1');

  const [loading, setLoading] = useState(false);

  const fetchTaskType = useCallback(() => {
    setLoading(true);
    const promises = [
      AdminService.getReusableComponents().then((res) => {
        setAllAttributes(res);
      }),
      AdminService.getChecklistQuestions().then((res) => {
        setAllQuestions(res);
      }),
      TaskTypeService.getAllReasonCodes().then((data) => {
        setAllReasonCodes(data);
      })
    ];

    if (taskType.id) {
      promises.push(
        TaskTypeService.getComponentsForTaskType(taskType.id).then((data) => {
          setDynamicFieldsSections(data);
        })
      );
      promises.push(
        AdminService.getChecklistsForTaskType(taskType.id).then((res) => {
          setChecklists(sortChecklistsSectionsAndQuestionsByOrderNumber(res));
        })
      );
      promises.push(
        TaskTypeService.getReasonCodes(taskType.id).then((_reasonCodes) => {
          setSelectedReasonCodes(sortReasonCodesByOrderNumber(_reasonCodes));
        })
      );
    }

    Promise.allSettled(promises)
      .catch((error) => {
        log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [taskType.id]);

  useEffect(() => {
    fetchTaskType();
  }, [fetchTaskType]);

  const handleSave = (_closeDialog?: boolean) => {
    if (!properties.isDisabled && !properties.flsCallTypeId) {
      NotificationService.error('Opgaven kan ikke aktiveres, før der er tilføjet et FLS Id');
      return;
    }
    setLoading(true);
    if (taskType.id) {
      Promise.all([
        editTaskType(taskType.id),
        saveNewDynamicFields(taskType.id),
        saveNewChecklist(taskType.id),
        saveNewReasons(taskType.id)
      ])
        .then(() => NotificationService.success(`Opgavetypen blev opdateret`))
        .catch(() => NotificationService.error(`Der skete en fejl. Opgavetypen blev ikke opdateret.`))
        .finally(() => {
          setLoading(false);
          _closeDialog ? closeDialog(true) : fetchTaskType();
        });
    } else {
      createNewTaskType().then((id) => {
        if (!id) {
          setLoading(false);
          log('Did not retrive id from backend.');
          return;
        }
        setTaskTypeId(id);
        Promise.all([saveNewDynamicFields(id), saveNewChecklist(id), saveNewReasons(id)])
          .then(() => NotificationService.success(`Opgavetypen blev oprettet`))
          .catch(() => NotificationService.error(`Der skete en fejl. Opgavetypen blev ikke oprettet.`))
          .finally(() => {
            setLoading(false);
            _closeDialog ? closeDialog(true) : fetchTaskType();
          });
      });
    }
  };

  const editTaskType = (id: number) => {
    return TaskTypeService.editTaskType(properties, id)
      .then((res) => {
        const newTaskTypes = taskTypes.map((taskType) => (taskType.id === id ? res : taskType));
        setTaskTypes(newTaskTypes);
      })
      .catch(() => {
        NotificationService.error('Kunne ikke redigere opgavetype.');
      });
  };

  const createNewTaskType = () => {
    return TaskTypeService.addTaskType(properties)
      .then((res) => {
        setTaskTypes([...taskTypes, res]);
        return res.id;
      })
      .catch(() => {
        NotificationService.error('Kunne ikke oprette opgavetype.');
      });
  };

  const saveNewDynamicFields = (id: number) => {
    const updatedSections = removeDynamicFieldsSectionIds(dynamicFieldsSections ?? []);
    return TaskTypeService.setComponentsForTaskType(id, updatedSections).catch(() => {
      NotificationService.error('Kunne ikke opdatere typefelter.');
    });
  };

  const saveNewChecklist = (id: number) => {
    const updatedChecklists = removeChecklistAndSectionIds(updateChecklistsSectionAndQuestionsOrderNumbers());
    return AdminService.setChecklistsForTaskType(id, updatedChecklists).catch(() => {
      NotificationService.error('Kunne ikke opdatere tjekliste.');
    });
  };

  const saveNewReasons = (id: number) => {
    return TaskTypeService.createReasonCode(id, updateReasonCodesOrderNumbers()).catch(() => {
      NotificationService.error('Kunne ikke opdatere flow.');
    });
  };

  const removeDynamicFieldsSectionIds = (selectedSections: TaskComponentDTO[]) => {
    return selectedSections.map((section) => {
      if (section.id && dynamicFieldsCreatedSectionsIds.includes(section.id)) {
        return {
          ...section,
          id: undefined
        };
      } else {
        return section;
      }
    });
  };

  const removeChecklistSectionIds = (selectedSections?: TaskTypeSectionDTO[]) => {
    if (!selectedSections) return selectedSections;
    return selectedSections.map((section) => {
      if (section.sectionId && newSectionIds.includes(section.sectionId)) {
        return {
          ...section,
          sectionId: undefined,
          questions: section.questions as QuestionDTO
        } as TaskTypeSectionDTO;
      } else {
        return { ...section, questions: section.questions as QuestionDTO } as TaskTypeSectionDTO;
      }
    });
  };

  const removeChecklistAndSectionIds: (selectedChecklists: TaskTypeCheckListTextDTO[]) => TaskTypeCheckListDTO[] = (
    selectedChecklists: TaskTypeCheckListTextDTO[]
  ) => {
    return selectedChecklists.map((checklist) => {
      if (checklist.taskTypeCheckListId && newChecklistIds.includes(checklist.taskTypeCheckListId)) {
        return {
          ...checklist,
          taskTypeCheckListId: undefined,
          taskTypeSections: checklist.taskTypeSections?.map((section) => {
            return { ...section, sectionId: undefined, questions: section.questions as QuestionDTO } as SectionQuestionDTO;
          })
        } as TaskTypeCheckListDTO;
      } else {
        return {
          ...checklist,
          taskTypeSections: removeChecklistSectionIds(checklist.taskTypeSections) ?? []
        } as TaskTypeCheckListDTO;
      }
    });
  };

  const updateChecklistsSectionAndQuestionsOrderNumbers = () => {
    return checklists.map((checklist, index) => {
      return {
        ...checklist,
        order: index,
        taskTypeSections: checklist.taskTypeSections?.map((section, index) => {
          return {
            ...section,
            order: index,
            questions: section.questions?.map((question, index) => {
              return {
                ...question,
                order: index
              };
            })
          };
        })
      };
    });
  };

  const updateReasonCodesOrderNumbers = () => {
    return selectedReasonCodes.map((reason, index) => {
      return {
        ...reason,
        order: index
      };
    });
  };

  const sortChecklistsSectionsAndQuestionsByOrderNumber = (checklists: TaskTypeCheckListTextDTO[]) => {
    return [...checklists]
      .sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
        }
        return 0;
      })
      .map((checklist) => {
        return {
          ...checklist,
          taskTypeSections: checklist?.taskTypeSections
            ?.sort((a, b) => {
              if (a.order !== undefined && b.order !== undefined) {
                if (a.order > b.order) {
                  return 1;
                }
                if (a.order < b.order) {
                  return -1;
                }
              }
              return 0;
            })
            .map((section) => {
              return {
                ...section,
                questions: section.questions?.sort((a, b) => {
                  if (a.order !== undefined && b.order !== undefined) {
                    if (a.order > b.order) {
                      return 1;
                    }
                    if (a.order < b.order) {
                      return -1;
                    }
                  }
                  return 0;
                })
              };
            })
        };
      });
  };

  const sortReasonCodesByOrderNumber = (_selectedreasonCodes: ReasonCodeDTO[]) => {
    return [..._selectedreasonCodes].sort((a, b) => {
      if (a.order !== undefined && b.order !== undefined) {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
      }
      return 0;
    });
  };

  const handleSetChecklists = useCallback(
    (checklists: TaskTypeCheckListTextDTO[], newChecklist?: number, newSection?: number) => {
      setChecklists(checklists);
      if (newChecklist) {
        setNewChecklistIds([...newChecklistIds, newChecklist]);
      }
      if (newSection) {
        setNewSectionIds([...newSectionIds, newSection]);
      }
    },
    [newChecklistIds, newSectionIds]
  );

  const getFooter = (
    left?: React.SetStateAction<JSX.Element>,
    right?: React.SetStateAction<JSX.Element>,
    showActive?: boolean
  ) => {
    const leftFooter = left ?? footer[0];
    const rightFooter = right ?? footer[1];
    return (
      <>
        {leftFooter || rightFooter ? (
          <Footer>
            <LeftButtons>{leftFooter}</LeftButtons>
            <RightButtons>
              {rightFooter ?? (
                <>
                  {showActive && (
                    <CheckboxContainer>
                      <Checkbox
                        label="Aktiv"
                        checked={!properties.isDisabled}
                        onChange={() => setProperties({ ...properties, isDisabled: !properties.isDisabled })}
                      />
                    </CheckboxContainer>
                  )}
                  <Button isLoading={loading} variant="primary" onClick={() => handleSave()}>
                    Gem
                  </Button>
                  <StyledButton isLoading={loading} variant="primary" onClick={() => handleSave(true)}>
                    Gem og luk
                  </StyledButton>
                </>
              )}
            </RightButtons>
          </Footer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getHeader = () => {
    const newTask = taskType.id ? '' : 'Ny ';
    return (
      <TitleRow>
        <Typography variant="h3" fontWeight="bold">
          {newTask}
          {properties?.name ? 'Opgavetype: ' + properties.name : 'Unavngivet opgavetype'}
        </Typography>
        <AuditInfo
          placement="bottom-end"
          updatedByEmail={taskType.updatedByEmail}
          size="28px"
          updatedTimeStamp={taskType.updated}
        />
      </TitleRow>
    );
  };

  return (
    <DialogView>
      <StyledDialogContent>
        <TabsVertical
          activePanelId={activePanelId}
          onClose={closeDialog}
          handleActivePanelIdChange={(id: string) => setActivePanelId(id)}
        >
          <Properties
            tabId="1"
            tabHeaderText="Egenskaber"
            properties={properties}
            setProperties={(props) => setProperties(props)}
            specificAssetTypes={specificAssetTypes}
            taskTypeCategories={taskTypeCategories}
            contentHeaderText={getHeader()}
            footerComponent={getFooter(undefined, undefined, true)}
          />
          <DynamicFields
            tabId="2"
            tabHeaderText="Typefelter"
            contentHeaderText={getHeader()}
            footerComponent={getFooter()}
            sections={dynamicFieldsSections ?? []}
            setSections={(sections) => setDynamicFieldsSections(sections)}
            setDynamicFieldsCreatedSectionId={(id) => setDynamicFieldsCreatedSectionsIds((prev) => [...prev, id])}
            allAttributes={allAttributes ?? []}
            setFooter={(leftFooter, rightFooter) => setFooter([leftFooter, rightFooter])}
          />
          <Checklists
            tabId="3"
            tabHeaderText="Tjekliste"
            contentHeaderText={getHeader()}
            checklists={checklists}
            setChecklists={(checklists, newChecklist, newSection) =>
              handleSetChecklists(checklists, newChecklist, newSection)
            }
            setFooter={(leftFooter, rightFooter) => setFooter([leftFooter, rightFooter])}
            allQuestions={allQuestions}
            footerComponent={getFooter()}
          />
          <TaskTypeTaskFlow
            tabId="4"
            tabHeaderText="Opgaveflow"
            contentHeaderText={getHeader()}
            footerComponent={getFooter()}
            setFooter={(leftFooter, rightFooter) => setFooter([leftFooter, rightFooter])}
            properties={properties}
            setProperties={(props) => setProperties(props)}
            selectedReasonCodes={selectedReasonCodes ?? []}
            newReasonCodes={newReasonCodes ?? []}
            setNewReasonCodes={(newReasonCodes) => setNewReasonCodes(newReasonCodes)}
            allReasonCodes={allReasonCodes}
            setSelectedReasonCodes={(selectedReasonCodes) => setSelectedReasonCodes(selectedReasonCodes)}
          />
        </TabsVertical>
      </StyledDialogContent>
    </DialogView>
  );
};

export const StyledBackButtonIcon = styled.button`
  border: none;
  background-color: transparent;

  :hover {
    cursor: pointer;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftButtons = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDialogContent = styled.div`
  && {
    padding: 0;
    height: 85vh;
    width: 85vw;
    max-width: 1400px;
    overflow: hidden;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  margin-right: 30px;
`;

const StyledButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing(4)};
`;

const TitleRow = styled.div`
  display: flex;
`;

export default AdminTaskTypesDialog;
