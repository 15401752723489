import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextFieldProps } from '@mui/material';

import { AddIcon } from '../../assets/icons/AddIcon';
import { MinimizeIcon } from '../../assets/icons/MinimizeIcon';
import n1LightTheme from '../../theme/light-theme';
import IconButton from '../icon-button/IconButton';
import TextField from '../text-field/TextField';

type Props = {
  initialHours: number;
  initialMinutes: number;
  onTimeChange: (hours: number, minutes: number) => void;
} & TextFieldProps;

const TimePicker = (props: Props) => {
  const { initialHours, initialMinutes, onTimeChange, disabled, ...rest } = props;

  const [hours, setHours] = useState<number>(initialHours ?? 0);
  const [minutes, setMinutes] = useState<number>(initialMinutes ?? 0);

  useEffect(() => {
    onTimeChange(hours, minutes);
  }, [hours, minutes, onTimeChange]);

  const handleIncrement = useCallback(() => {
    if (minutes === 45) {
      setMinutes(0);
      setHours((current) => (current < 999 ? current + 1 : current));
    } else {
      setMinutes((current) => current + 15);
    }
  }, [minutes]);

  const handleDecrement = useCallback(() => {
    if (minutes === 0) {
      if (hours !== 0) {
        setHours((current) => current - 1);
        setMinutes(45);
      }
    } else {
      setMinutes((current) => current - 15);
    }
  }, [minutes, hours]);

  const handleChangeHours = useCallback((value: number) => {
    if (value < 1000) {
      setHours(value);
    }
  }, []);

  const handleChangeMinutes = useCallback((value: number) => {
    switch (value) {
      case 0:
        setMinutes(0);
        break;
      case 1:
        setMinutes(15);
        break;
      case 3:
        setMinutes(30);
        break;
      case 4:
        setMinutes(45);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Container>
      <StyledTextField
        {...rest}
        onChange={(e) => handleChangeHours(parseInt(e.target.value))}
        type="number"
        helperText="timer"
        value={String(hours).padStart(3, '0')}
        sx={{ input: { textAlign: 'center' } }}
        width="30px"
        dataTestId="timereg-hours-textfield"
        disabled={disabled}
      />
      <Colon>:</Colon>
      <StyledTextField
        {...rest}
        onChange={(e) => handleChangeMinutes(parseInt(e.target.value))}
        onFocus={(e) => e.target.select()}
        type="number"
        helperText="minutter"
        value={String(minutes).padStart(2, '0')}
        sx={{ input: { textAlign: 'center' } }}
        width="20px"
        disabled={disabled}
      />
      <ButtonContainer>
        <StyledIconButton onClick={handleDecrement} padding="13px" disabled={disabled}>
          <MinimizeIcon color={n1LightTheme.palette.grey.black60} size="16px" />
        </StyledIconButton>
        <StyledIconButton onClick={handleIncrement} padding="13px" disabled={disabled}>
          <AddIcon color={n1LightTheme.palette.grey.black60} size="16px" />
        </StyledIconButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Colon = styled.span`
  margin: 0 8px;
  font-size: 20px;
`;

export const StyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin: 3px 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 16px;
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  overflow: hidden;
`;

const StyledIconButton = styled(IconButton)`
  border-radius: 0;
  :hover {
    background: ${(props) => props.theme.palette.grey.black5};
  }
  :first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

export default TimePicker;
