import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SuccessIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 29.539 5.016 L 12.898 21.701 L 6.715 15.506 L 8.77 13.445 L 12.898 17.58 L 27.482 2.971 L 29.539 5.016 Z M 26.316 12.379 C 26.505 13.212 26.622 14.088 26.622 14.98 C 26.622 21.437 21.401 26.668 14.954 26.668 C 8.508 26.668 3.287 21.437 3.287 14.98 C 3.287 8.523 8.508 3.292 14.954 3.292 C 17.259 3.292 19.388 3.964 21.197 5.118 L 23.297 3.014 C 20.934 1.349 18.061 0.37 14.954 0.37 C 6.904 0.37 0.37 6.915 0.37 14.98 C 0.37 23.045 6.904 29.59 14.954 29.59 C 23.005 29.59 29.539 23.045 29.539 14.98 C 29.539 13.241 29.218 11.576 28.664 10.027 L 26.316 12.379 Z"
      ></path>
    </IconSvg>
  );
};
