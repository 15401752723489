import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ShopIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 21.697 16.538 C 22.8 16.538 23.77 15.935 24.27 15.024 L 29.534 5.482 C 30.078 4.512 29.372 3.306 28.255 3.306 L 6.496 3.306 L 5.114 0.366 L 0.306 0.366 L 0.306 3.306 L 3.246 3.306 L 8.539 14.465 L 6.554 18.053 C 5.481 20.023 6.893 22.419 9.127 22.419 L 26.77 22.419 L 26.77 19.479 L 9.127 19.479 L 10.744 16.538 L 21.697 16.538 Z M 7.892 6.247 L 25.755 6.247 L 21.697 13.598 L 11.377 13.598 L 7.892 6.247 Z M 9.127 23.889 C 7.51 23.889 6.202 25.212 6.202 26.83 C 6.202 28.447 7.51 29.77 9.127 29.77 C 10.744 29.77 12.068 28.447 12.068 26.83 C 12.068 25.212 10.744 23.889 9.127 23.889 Z M 23.829 23.889 C 22.212 23.889 20.904 25.212 20.904 26.83 C 20.904 28.447 22.212 29.77 23.829 29.77 C 25.447 29.77 26.77 28.447 26.77 26.83 C 26.77 25.212 25.447 23.889 23.829 23.889 Z"
      ></path>
    </IconSvg>
  );
};
