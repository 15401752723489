import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ArrowDownIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    </IconSvg>
  );
};
