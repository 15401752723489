import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ShopAddIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 21 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1262 5.5V8.4875H10.1512V5.5V5.4875H10.1387H7.15117V3.5125H10.1387H10.1512V3.5V0.5125H12.1262V3.5V3.5125H12.1387H15.1262V5.4875H12.1387H12.1262V5.5ZM18.571 3.5125H20.8175L16.4277 11.4439L16.4277 11.444C16.2454 11.7755 16.0011 12.0321 15.6948 12.2143C15.3884 12.3964 15.0531 12.4875 14.6887 12.4875H7.23867V12.4741L7.22772 12.494L6.12772 14.494L6.11753 14.5125H6.13867H18.1262V16.4875H6.13867C5.39312 16.4875 4.82632 16.1649 4.43688 15.5185C4.04752 14.8724 4.0351 14.2188 4.39962 13.556C4.39962 13.556 4.39962 13.556 4.39962 13.556L5.74962 11.106L5.75739 11.1103L5.74997 11.0946L2.14997 3.49465L2.16506 3.4875H2.13867H0.151172V1.5125H3.40575L7.65237 10.5053L7.6372 10.5125H7.66367H14.6637V10.5257L14.6746 10.5061L18.571 3.5125ZM6.13867 21.4875C5.59201 21.4875 5.12435 21.293 4.73501 20.9037C4.34567 20.5143 4.15117 20.0467 4.15117 19.5C4.15117 18.9533 4.34567 18.4857 4.73501 18.0963C5.12435 17.707 5.59201 17.5125 6.13867 17.5125C6.68533 17.5125 7.15299 17.707 7.54233 18.0963C7.93167 18.4857 8.12617 18.9533 8.12617 19.5C8.12617 20.0467 7.93167 20.5143 7.54233 20.9037C7.15299 21.293 6.68533 21.4875 6.13867 21.4875ZM16.1387 21.4875C15.592 21.4875 15.1244 21.293 14.735 20.9037C14.3457 20.5143 14.1512 20.0467 14.1512 19.5C14.1512 18.9533 14.3457 18.4857 14.735 18.0963C15.1244 17.707 15.592 17.5125 16.1387 17.5125C16.6853 17.5125 17.153 17.707 17.5423 18.0963C17.9317 18.4857 18.1262 18.9533 18.1262 19.5C18.1262 20.0467 17.9317 20.5143 17.5423 20.9037C17.153 21.293 16.6853 21.4875 16.1387 21.4875Z"
        fill={props.color}
        stroke="#343434"
        stroke-width="0.025"
      />
    </IconSvg>
  );
};
