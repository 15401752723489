import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import styled from 'styled-components';
import Button from '../button/Button';
import { AddIcon } from '../../assets/icons/AddIcon';

interface Props {
  options: { label: string; callback: () => void }[];
}

export default function SplitButton(props: Props) {
  const { options } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup sx={{ boxShadow: 'none' }} variant="contained" ref={anchorRef} aria-label="split button">
        <StyledLeftButton onClick={options[0].callback} data-testid="split-btn-left">
          <AddIcon size="16px" margin="5px" />
          {options[0].label}
        </StyledLeftButton>
        <StyledRightButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDownIcon size="16px" />
        </StyledRightButton>
      </ButtonGroup>
      <Popper
        style={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options
                    .filter((_, i) => i !== 0)
                    .map((option) => (
                      <MenuItem key={option.label} onClick={() => option.callback()}>
                        {option.label}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

const StyledLeftButton = styled(Button)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const StyledRightButton = styled(Button)`
  border-left-color: grey;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 10px;
`;
