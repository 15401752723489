import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../button/Button';
import TextField from '../text-field/TextField';
import { PauseIcon } from '../../assets/icons/PauseIcon';
import { SendIcon } from '../../assets/icons/SendIcon';
import { DoneIcon } from '../../assets/icons/DoneIcon';
import TaskService from '../../services/TaskService';
import {
  BaseWorkTaskTypeEnum,
  DayTaskDTO,
  DepartmentDTO,
  ReasonCodeDTO,
  WorkPlanItemStatusDTO,
  WorkTaskStatus
} from '../../api/api';
import CircularProgress from '../circular-progress/CircularProgress';
import NotificationService from '../../services/NotificationService';
import { getEnumDisplayValue } from '../../utils/enumUtils';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskStatusDialogState } from '../../stateManagement/reducers/taskStatusDialogReducer';
import { useUpdateTaskStateDialog } from '../../hooks/useUpdateTaskStateDialog';
import { log } from '../../utils/logging/log';
import DialogView from '../dialog-view/DialogView';
import TabsVertical, { TabProps } from '../../blocks/tabs-vertical/TabsVertical';
import Typography from '../typography/Typography';
import { CalendarCheckmark } from '../../assets/icons/CalendarCheckmark';
import RadioButtons, { RadioButtonOptions } from '../radio-buttons/RadioButtons';
import Alert from '../alert/Alert';
import TaskChecklistService from '../../services/TaskChecklistService';
import { setChecklistErrors, setShowChecklistErrors } from '../../stateManagement/reducers/taskChecklistReducer';
import { ClockIcon } from '../../assets/icons/ClockIcon';
import DepartmentService from '../../services/DepartmentService';
import TaskTypeService from '../../services/TaskTypeService';
import WorkInvoiceService from '../../services/WorkInvoiceService';
import { workInvoiceResponseDTOPopulated } from '../../views/task-view/task-details-view2/work-invoice-step/WorkInvoiceHelper';

enum TaskStatusPage {
  END_OF_DAY = 'endofday',
  SUSPEND = 'suspend',
  STOP = 'stop',
  PAUSE = 'pause'
}

const TaskStatusOptions: RadioButtonOptions[] = [
  {
    label: 'Dagens arbejde afsluttes',
    subtextElement: <Typography>(Opgaven strækker sig over flere dage)</Typography>,
    icon: <CalendarCheckmark size="32px" />,
    value: TaskStatusPage.END_OF_DAY
  },
  {
    label: 'Opgaven er udført',
    subtextElement: <Typography>(Hele opgaven er udført)</Typography>,
    icon: <DoneIcon size="32px" data-testid="technician-finish-task" />,
    value: TaskStatusPage.STOP
  },
  {
    label: 'Opgave afbrydes',
    subtextElement: <Typography>(Benyttes ved akut opgave fra Planlægningen)</Typography>,
    icon: <ClockIcon size="32px" />,
    value: TaskStatusPage.SUSPEND
  },
  {
    label: 'Opgaven skal pauses',
    subtextElement: <Typography>(Send opgaven tilbage til kontoret)</Typography>,
    icon: <PauseIcon size="32px" data-testid="technician-pause-task" />,
    value: TaskStatusPage.PAUSE
  }
];

const DialogTabContent = ({ children }: TabProps) => <>{children}</>;

const TaskStatusDialog = () => {
  const { baseWorkTaskType, taskId, taskFlsId, taskTypeId, hasFuturePartialTask, taskStatus } =
    useSelector(selectTaskStatusDialogState);
  const dispatch = useDispatch();

  const { updateTaskStatus, cancelUpdateTaskStatus } = useUpdateTaskStateDialog();

  const [activePanelId, setActivePanelId] = useState<string>('1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const [warningMessage, setWarningMessage] = useState('');
  const [workInvoiceError, setWorkInvoiceError] = useState(false);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [filteredTaskStatusOptions] = useState(
    TaskStatusOptions.filter((o) =>
      hasFuturePartialTask ? true : o.value !== TaskStatusPage.SUSPEND && o.value !== TaskStatusPage.END_OF_DAY
    )
  );
  const [selectedTaskStatusOption, setSelectedTaskStatusOption] = useState<RadioButtonOptions | undefined>(undefined);
  const [options, setOptions] = useState<ReasonCodeDTO[] | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState<ReasonCodeDTO>();
  const [reasonText, setReasonText] = useState<string>('');
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);
  const [defaultDepartment, setDefaultDepartment] = useState<DepartmentDTO>();

  const isWorkInvoiceFilled = useCallback(() => {
    if (!taskId) return;

    WorkInvoiceService.getWorkInvoiceId(taskId)
      .then((workInvoiceId) => {
        WorkInvoiceService.getWorkInvoice(workInvoiceId)
          .then((res) => {
            const _workInvoiceError = !workInvoiceResponseDTOPopulated(res.workInvoiceDTO);
            setWorkInvoiceError(_workInvoiceError);
            _workInvoiceError &&
              setWarningMessage('Du kan ikke stoppe opgaven, da du ikke har udfyldt skadevolder/regningsarbejde');
          })
          .catch((err) => log(err));
      })
      .catch((err) => log(err));
  }, [taskId]);

  useEffect(() => {
    if (!taskTypeId) return;
    setIsLoading(true);

    const _options: ReasonCodeDTO[] = [];
    const promises = [];

    if (selectedTaskStatusOption?.value === TaskStatusPage.PAUSE) {
      promises.push(TaskService.getReasonCodes(taskTypeId, WorkTaskStatus.Pause).then((res) => _options.push(...res)));
    } else if (selectedTaskStatusOption?.value === TaskStatusPage.STOP) {
      promises.push(
        TaskService.getReasonCodes(taskTypeId, WorkTaskStatus.Completed).then((res) => _options.push(...res)),
        TaskService.getReasonCodes(taskTypeId, WorkTaskStatus.Processed).then((res) => _options.push(...res))
      );
    }
    isWorkInvoiceFilled();
    promises.push(
      DepartmentService.GetDepartments().then((res) => setDepartments(res)),
      TaskTypeService.getActiveTaskTypes().then((data) =>
        setDefaultDepartment(data.taskTypeDTOs?.find((t) => t.id === taskTypeId)?.defaultDepartment)
      )
    );

    Promise.all(promises)
      .then(() => {
        setOptions(_options);
        setSelectedOption(undefined);
      })
      .catch((err) => log(err))
      .finally(() => setIsLoading(false));
  }, [isWorkInvoiceFilled, selectedTaskStatusOption?.value, taskTypeId]);

  useEffect(() => {
    if (
      warningMessage !== '' &&
      selectedTaskStatusOption &&
      selectedTaskStatusOption.value !== TaskStatusPage.STOP &&
      !workInvoiceError
    ) {
      setWarningMessage('');
    }
  }, [selectedTaskStatusOption, workInvoiceError, warningMessage]);

  const handleConfirm = useCallback(() => {
    setIsUpdating(true);
    let status = selectedOption?.status;
    if (!status && selectedTaskStatusOption?.value === TaskStatusPage.STOP) status = WorkTaskStatus.Processed;
    if (!status) status = WorkTaskStatus.Pause;

    if (
      (status === WorkTaskStatus.Pause || status === WorkTaskStatus.Completed || status === WorkTaskStatus.Processed) &&
      options &&
      options.length > 0 &&
      !selectedOption
    ) {
      setConfirmDisabled(true);
      setWarningMessage('Der skal angives en årsag');
      return;
    } else {
      setConfirmDisabled(false);
    }
    const body: WorkPlanItemStatusDTO = {
      status,
      reasonCode: selectedOption,
      reasonCodeText: reasonText,
      workTaskId: taskId ? taskId : undefined,
      flsId: taskFlsId ?? -1
    };
    TaskService.setWorkPlanTaskStatus(body)
      .then((res) => {
        NotificationService.success(
          `Opdaterede status til ${getEnumDisplayValue(res.status)} og sendt til ${
            departments.find((d) => d.departmentId === res.reasonCode?.sendToDepartmentId)?.name ?? defaultDepartment?.name
          }.`
        );
        updateTaskStatus(res.status);
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke ændre status. ' + error);
        cancelUpdateTaskStatus();
      })
      .finally(() => setIsUpdating(false));
  }, [
    cancelUpdateTaskStatus,
    defaultDepartment?.name,
    departments,
    options,
    reasonText,
    selectedOption,
    selectedTaskStatusOption?.value,
    taskFlsId,
    taskId,
    updateTaskStatus
  ]);

  const validateChecklist = useCallback(() => {
    if (baseWorkTaskType === BaseWorkTaskTypeEnum.WorkTask) {
      //Only validate checklist if type is normal worktask
      setIsLoading(true);
      setWarningMessage('');
      TaskChecklistService.validateChecklists(taskId ?? -1)
        .then((res) => {
          if (res.length === 0) {
            dispatch(setShowChecklistErrors({ showError: false, taskId: undefined }));
            setNextDisabled(false);
          } else {
            setNextDisabled(true);
            setWarningMessage('Opgaven kan ikke stoppes. Udfyld obligatoriske felter i tjeklisten.');
            dispatch(setShowChecklistErrors({ showError: true, taskId: taskId?.toString() }));
          }
          dispatch(setChecklistErrors(res));
        })
        .catch(() => {
          setNextDisabled(true);
          setWarningMessage('Opgaven kan ikke stoppes, da tjeklisten ikke kunne valideres');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setNextDisabled(false);
      dispatch(setShowChecklistErrors({ showError: false, taskId: undefined }));
    }
  }, [baseWorkTaskType, taskId, dispatch]);

  const suspendTask = useCallback(() => {
    if (!taskId || !taskFlsId) return;
    setIsUpdating(true);

    const body = {
      workTaskId: taskId,
      flsId: taskFlsId,
      reasonText: reasonText,
      status: taskStatus
    } as DayTaskDTO;

    TaskService.suspendTask(body)
      .then(() => {
        NotificationService.success(`Opgaven er stoppet`);
        cancelUpdateTaskStatus();
      })
      .catch((err) => {
        NotificationService.error('Opgaven blev ikke stoppet. Prøv igen.');
        log(err);
      })
      .finally(() => setIsUpdating(false));
  }, [cancelUpdateTaskStatus, reasonText, taskFlsId, taskId, taskStatus]);

  const handleSelectReasonCode = useCallback(
    (value: number) => {
      if (!options) return;
      const sOption = options.find((o) => o.reasonCodeId?.toString() === value.toString());
      setConfirmDisabled(false);
      setSelectedOption(sOption);
    },
    [options]
  );

  const handleSetReasonText = useCallback(
    (value: string) => {
      setReasonText(value);
      if (
        selectedTaskStatusOption?.value === TaskStatusPage.SUSPEND ||
        selectedTaskStatusOption?.value === TaskStatusPage.END_OF_DAY ||
        ((options === undefined || options.length <= 0) && value !== '')
      ) {
        setConfirmDisabled(false);
      } else if (options === undefined || options.length <= 0) {
        setConfirmDisabled(true);
      }
    },
    [options, selectedTaskStatusOption?.value]
  );

  const handleSelectTaskStatus = useCallback(
    (value: TaskStatusPage) => {
      if (workInvoiceError) return;
      setWarningMessage('');
      setConfirmDisabled(true);

      const taskStatusOption = TaskStatusOptions.find((ts) => ts.value === value);
      setSelectedTaskStatusOption(taskStatusOption);
      switch (value) {
        case TaskStatusPage.END_OF_DAY:
        case TaskStatusPage.SUSPEND:
          setConfirmDisabled(false);
          break;
        case TaskStatusPage.STOP:
          validateChecklist();
          break;
        case TaskStatusPage.PAUSE:
          break;
      }
    },
    [workInvoiceError, validateChecklist]
  );

  const handleConfirmAndSave = useCallback(() => {
    switch (selectedTaskStatusOption?.value) {
      case TaskStatusPage.END_OF_DAY:
      case TaskStatusPage.SUSPEND:
        suspendTask();
        break;
      case TaskStatusPage.STOP:
      case TaskStatusPage.PAUSE:
        handleConfirm();
        break;
      default:
        setWarningMessage('Der er ikke registret en status i step 1.');
        break;
    }
  }, [suspendTask, handleConfirm, selectedTaskStatusOption?.value]);

  return (
    <DialogView handleClose={cancelUpdateTaskStatus}>
      <StyledDialogContent>
        <TabsVertical
          headerComponent={<></>}
          activePanelId={activePanelId}
          onClose={cancelUpdateTaskStatus}
          handleActivePanelIdChange={(id: string) => setActivePanelId(id)}
        >
          <DialogTabContent
            tabId="1"
            tabHeaderText="Vælg status"
            contentHeaderText={
              <Typography variant="h3" fontWeight="bold">
                Opgave stoppes
              </Typography>
            }
            footerComponent={
              <Footer>
                {warningMessage && (
                  <Alert margin="0px 10px 0px 0px" severity="error" height="42px">
                    {warningMessage}
                  </Alert>
                )}
                <Button
                  data-testid="next-button"
                  disabled={
                    selectedTaskStatusOption === undefined ||
                    (selectedTaskStatusOption.value === TaskStatusPage.STOP && nextDisabled) ||
                    workInvoiceError
                  }
                  onClick={() => setActivePanelId('2')}
                  isLoading={isLoading}
                >
                  Næste
                </Button>
              </Footer>
            }
          >
            <RadioButtons
              label={'Angiv hvorfor opgaven stoppes'}
              value={selectedTaskStatusOption?.value}
              handleValueChange={(value: TaskStatusPage) => handleSelectTaskStatus(value)}
              labelPlacement="start"
              required
              options={filteredTaskStatusOptions}
            />
          </DialogTabContent>

          <DialogTabContent
            tabId="2"
            tabHeaderText="Uddybende årsag"
            hidden={
              selectedTaskStatusOption === undefined ||
              (selectedTaskStatusOption?.value === TaskStatusPage.STOP && nextDisabled)
            }
            contentHeaderText={
              <>
                {selectedTaskStatusOption?.icon}
                <Typography variant="h3" fontWeight="bold">
                  {selectedTaskStatusOption?.label}
                </Typography>
              </>
            }
            footerComponent={
              <Footer>
                {warningMessage && (
                  <Alert margin="0px 10px 0px 0px" severity="error" height="42px">
                    {warningMessage}
                  </Alert>
                )}
                <Button
                  isLoading={isUpdating}
                  onClick={() => handleConfirmAndSave()}
                  data-testid="save-and-send-to-office-btn"
                  disabled={confirmDisabled}
                >
                  <SendIcon size="18px" />
                  Gem og afslut
                </Button>
              </Footer>
            }
          >
            <>
              {isLoading ? (
                <CircularProgress color="white" />
              ) : (
                <ReasonContainer>
                  {options && options.length > 0 && (
                    <OptionsContainer>
                      <RadioButtons
                        label={'Angiv status på opgaven'}
                        value={selectedOption?.reasonCodeId}
                        handleValueChange={(value: number) => handleSelectReasonCode(value)}
                        labelPlacement="start"
                        showDivider
                        options={options.map((option) => ({
                          label: option.reasonText ?? '',
                          value: option.reasonCodeId,
                          subtextElement: (
                            <span>
                              <Typography color="greyedOut">
                                Sendes til:
                                <b>
                                  {` ${
                                    option.sendToCreatingDepartment
                                      ? 'Oprettende afdeling'
                                      : departments.find((d) => d.departmentId === option.sendToDepartmentId)?.name ??
                                        defaultDepartment?.name
                                  }`}
                                </b>
                              </Typography>
                            </span>
                          )
                        }))}
                      />
                    </OptionsContainer>
                  )}
                  <Typography variant="h5" fontWeight="bold">
                    {selectedTaskStatusOption?.value === TaskStatusPage.PAUSE
                      ? 'Angiv hvorfor opgaven skal sættes på pause'
                      : 'Uddybende bemærkning til opgaven'}
                  </Typography>
                  <StyledTextField
                    value={reasonText}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetReasonText(e.currentTarget.value)}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={3}
                    placeholder="Uddybende bemærkning"
                    dataTestId="technician-closing-task-comments"
                  />
                </ReasonContainer>
              )}
            </>
          </DialogTabContent>
        </TabsVertical>
      </StyledDialogContent>
    </DialogView>
  );
};

const StyledDialogContent = styled.div`
  padding: 0;
  height: auto;
  width: 750px;
  overflow: hidden;
`;

const ReasonContainer = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 24px;
  min-width: 300px;
`;

const StyledTextField = styled((props) => <TextField {...props} />)`
  background: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.grey.black60};
  && {
    margin-bottom: 72px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
`;

export default TaskStatusDialog;
