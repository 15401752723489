import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const TextFileIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 9.132 23.408 L 20.896 23.408 L 20.896 21.216 L 9.132 21.216 L 9.132 23.408 Z M 9.132 17.197 L 20.896 17.197 L 20.896 15.005 L 9.132 15.005 L 9.132 17.197 Z M 5.516 29.618 C 4.931 29.618 4.42 29.399 3.982 28.961 C 3.543 28.522 3.324 28.011 3.324 27.426 L 3.324 2.584 C 3.324 1.999 3.543 1.488 3.982 1.05 C 4.42 0.611 4.931 0.392 5.516 0.392 L 18.704 0.392 L 26.705 8.392 L 26.705 27.426 C 26.705 28.011 26.486 28.522 26.048 28.961 C 25.609 29.399 25.098 29.618 24.513 29.618 L 5.516 29.618 Z M 17.608 9.379 L 17.608 2.584 L 5.516 2.584 C 5.516 2.584 5.516 2.584 5.516 2.584 C 5.516 2.584 5.516 2.584 5.516 2.584 L 5.516 27.426 C 5.516 27.426 5.516 27.426 5.516 27.426 C 5.516 27.426 5.516 27.426 5.516 27.426 L 24.513 27.426 C 24.513 27.426 24.513 27.426 24.513 27.426 C 24.513 27.426 24.513 27.426 24.513 27.426 L 24.513 9.379 L 17.608 9.379 Z M 5.516 2.584 L 5.516 9.379 L 5.516 2.584 L 5.516 9.379 L 5.516 27.426 C 5.516 27.426 5.516 27.426 5.516 27.426 C 5.516 27.426 5.516 27.426 5.516 27.426 C 5.516 27.426 5.516 27.426 5.516 27.426 C 5.516 27.426 5.516 27.426 5.516 27.426 L 5.516 2.584 C 5.516 2.584 5.516 2.584 5.516 2.584 C 5.516 2.584 5.516 2.584 5.516 2.584 Z"></path>
    </IconSvg>
  );
};
