import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CloseIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" data-testid="dialog-close-icon">
      <path fill="none" strokeWidth="3.5" strokeLinecap="round" d="M 28.5 1.5 L 1.5 28.5"></path>
      <path fill="none" strokeWidth="3.5" strokeLinecap="round" d="M 28.5 28.5 L 1.5 1.5"></path>
    </IconSvg>
  );
};
