import styled from 'styled-components';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import IconButton from '../../../components/icon-button/IconButton';
import Typography from '../../../components/typography/Typography';
import { ChevronLeft } from '../../../assets/icons/ChevronLeft';

interface Props {
  headerTitle?: string;
  onBackButton?: () => void;
  onClose?: () => void;
  subHeaderComponent?: JSX.Element;
}

const NavigationHeader = (props: Props) => {
  const { onBackButton, headerTitle, onClose, subHeaderComponent } = props;

  return (
    <Header>
      <LeftContainer>
        {onBackButton && (
          <StyledIconButton onClick={onBackButton}>
            <ChevronLeft size="16px" />
          </StyledIconButton>
        )}
        <Column>
          <Typography textAlign="left" fontWeight="bold" variant="h4" onClick={onBackButton}>
            {headerTitle}
          </Typography>
          {subHeaderComponent}
        </Column>
      </LeftContainer>
      <RightContainer>
        {onClose && (
          <StyledIconButton variant="outlined" onClick={onClose}>
            <CloseIcon size="16px" />
          </StyledIconButton>
        )}
      </RightContainer>
    </Header>
  );
};

const StyledIconButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightContainer = styled.div`
  margin-right: ${(props) => props.theme.spacing(8)};
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing(8)};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.black10};
  height: 40px;
  width: 100%;
  padding: ${(props) => props.theme.spacing(5)} 0px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export default NavigationHeader;
