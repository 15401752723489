import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const KebabMenuIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" data-testid="kebab-menu-icon">
      <circle stroke="none" cx="15" cy="4" r="3.5" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.4"></circle>
      <circle stroke="none" cx="15" cy="15" r="3.5" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.4"></circle>
      <circle stroke="none" cx="15" cy="25" r="3.5" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.4"></circle>
    </IconSvg>
  );
};
