import { useSelector } from 'react-redux';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { TechnicianIcon } from '../../assets/icons/TechnicianIcon';
import Button from '../../components/button/Button';
import { selectUserProfile } from '../../stateManagement/reducers/userProfileReducer';
import AssignCaseWorkerMenu from '../../views/task-view/task-list-view/task-list-tools/AssignCaseWorkerMenu';
import ChangeDepartmentMenu from '../../views/task-view/task-list-view/task-list-tools/ChangeDepartmentMenu';
import ChangeStatusMenu from '../../views/task-view/task-list-view/task-list-tools/ChangeStatusMenu';
import TableMenuBar, { StyledButton } from '../table/table-menu-bar/TableMenuBar';
import { useCallback, useState } from 'react';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import NotificationService from '../../services/NotificationService';
import TaskService from '../../services/TaskService';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';

interface Props {
  selectedWorkTasks: { workTaskId: string; hasSubTasks: boolean; status?: string }[];
  updateTasks: () => void;
  handleClose: () => void;
  showBookButton?: boolean;
  handleDeleteTasks?: () => void;
  setLoading: (loading: boolean) => void;
}

const WorkTaskControlBar = (props: Props) => {
  const { selectedWorkTasks, updateTasks, handleClose, showBookButton, handleDeleteTasks, setLoading } = props;

  const { getConfirmation } = useConfirmationDialog();
  const userProfile = useSelector(selectUserProfile).userProfile;
  const userDepartment = userProfile?.department;

  const [bookTechnicianIsLoading, setBookTechnicianIsLoading] = useState(false);

  const handleBookTechnician = useCallback(async () => {
    const workTaskIds = selectedWorkTasks.map((w) => w.workTaskId);

    const dialogBody: DialogBody = {
      headerText:
        workTaskIds.length > 1
          ? `Vil du sende ${workTaskIds.length} opgaver og eventuelle underopgaver til FLS?`
          : `Vil du sende opgaven og eventuelle underopgaver til FLS?`,
      bodyText: '',
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft'
    };

    const confirmation = await getConfirmation(dialogBody);

    if (confirmation === 'confirm') {
      setLoading(true);
      setBookTechnicianIsLoading(true);
      TaskService.sendToPlanning(selectedWorkTasks.map((x) => +x.workTaskId))
        .then(() => {
          updateTasks();
          NotificationService.success(
            workTaskIds.length > 1 ? `Sendte ${workTaskIds.length} opgaver til FLS.` : 'Opgaven er sendt til FLS'
          );
        })
        .catch((error) => {
          let errorMessage;
          switch (error.status) {
            case 400:
              errorMessage = 'Ugyldig status på en eller flere opgaver. Opdater din driftsliste.';
              break;
            case 404:
              errorMessage = 'Opgavetypen findes ikke i FLS.';
              break;
            default:
              errorMessage = `Der opstod en fejl i afsendelsen af opgaver: ${error}`;
              break;
          }
          NotificationService.error(errorMessage);
          setLoading(false);
        })
        .finally(() => {
          setBookTechnicianIsLoading(false);
        });
    }
  }, [getConfirmation, selectedWorkTasks, setLoading, updateTasks]);

  return (
    <TableMenuBar
      selectedItems={selectedWorkTasks.map((x) => ({ id: x.workTaskId }))}
      rowNameSingle={'opgave'}
      rowNameMultiple={'opgaver'}
      onClose={handleClose}
    >
      <ChangeStatusMenu
        fetchTasksCallback={updateTasks}
        workTaskIds={selectedWorkTasks.map((x) => ({
          id: +x.workTaskId,
          hasSubTasks: x.hasSubTasks ?? false,
          status: x.status
        }))}
        setIsLoading={(isLoading: boolean) => setLoading(isLoading)}
      />
      <ChangeDepartmentMenu
        fetchTasksCallback={updateTasks}
        selectedRows={selectedWorkTasks.map((x) => ({ id: x.workTaskId, hasSubTasks: x.hasSubTasks ?? false }))}
        userDepartment={userDepartment}
        setIsLoading={(isLoading: boolean) => setLoading(isLoading)}
      />
      <AssignCaseWorkerMenu
        selectedRows={selectedWorkTasks.map((x) => ({ id: x.workTaskId, hasSubTasks: x.hasSubTasks ?? false }))}
        fetchTasksCallback={updateTasks}
        setIsLoading={(isLoading: boolean) => setLoading(isLoading)}
      />
      {showBookButton && (
        <Button onClick={handleBookTechnician} isLoading={bookTechnicianIsLoading} variant="primary" square>
          <TechnicianIcon size="18px" />
          Send til FLS
        </Button>
      )}
      {handleDeleteTasks && (
        <StyledButton onClick={handleDeleteTasks} variant="tertiary" square data-testid="delete-task">
          <DeleteIcon size="18px" />
          Slet
        </StyledButton>
      )}
    </TableMenuBar>
  );
};

export default WorkTaskControlBar;
