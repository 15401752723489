export const MessageIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 - 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8513_551)">
        <path
          d="M14 10.0005C14 10.3541 13.8595 10.6932 13.6095 10.9433C13.3594 11.1933 13.0203 11.3338 12.6667 11.3338H4.66667L2 14.0005V3.33382C2 2.9802 2.14048 2.64106 2.39052 2.39101C2.64057 2.14096 2.97971 2.00049 3.33333 2.00049H12.6667C13.0203 2.00049 13.3594 2.14096 13.6095 2.39101C13.8595 2.64106 14 2.9802 14 3.33382V10.0005Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8513_551">
          <rect width="16" height="16" fill="white" transform="translate(0 0.000488281)" />
        </clipPath>
      </defs>
    </svg>
  );
};
