import styled from 'styled-components';
import { TabProps } from '../../../../../../blocks/tabs-vertical/TabsVertical';
import {
  MeterIdentifiersDTO,
  MeterWORKASSUMPTION,
  MeterWorkTaskDTO,
  RequiredReadingDTO,
  MeterStatus,
  ValueListName,
  ValueListValueDTO
} from '../../../../../../api/api';
import TextField from '../../../../../../components/text-field/TextField';
import { Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import MetertaskService from '../../../../../../services/MeterTasksService';
import Button from '../../../../../../components/button/Button';
import { EyeIcon } from '../../../../../../assets/icons/EyeIcon';
import useMeterTableInstance from '../Components/useMeterTableInstance';
import Table from '../../../../../../blocks/table/TableClient';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import NotificationService from '../../../../../../services/NotificationService';
import AutoComplete from '../../../../../../components/auto-complete/AutoComplete';
import MeterResume, { MeterInstallation } from '../Components/MeterResume';
import StatusIndicator, { STATUS } from '../../../../../../components/status-indicator/StatusIndicator';
import { log } from '../../../../../../utils/logging/log';
import MeterComponents, { ComponentType } from './MeterComponents';

interface Props extends TabProps {
  task?: MeterWorkTaskDTO;
}

export interface ExtendedMeter extends MeterWORKASSUMPTION {
  selected?: boolean;
}

const MeterInstallationStep = (props: Props) => {
  const { task } = props;

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isScanButtonLoading, setIsScanButtonLoading] = useState(false);
  const [isSearchButtonLoading, setIsSearchButtonLoading] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [meterNumber, setMeterNumber] = useState('');
  const [meters, setMeters] = useState<ExtendedMeter[]>([]);
  const [isMissingBarcode, setIsMissingBarcode] = useState(false);
  const [noDataText, setNoDataText] = useState('Indtast eller scan målernummer');
  const [continueWithoutVerification, setContinueWithoutVerification] = useState(false);
  const [requiredReadings, setRequiredReadings] = useState<RequiredReadingDTO[]>([]);
  const [meterRegistrationIsCompleted, setMeterRegistrationIsCompleted] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [completedMeterInstallation, setCompletedMeterInstallation] = useState<MeterInstallation>();
  const [valueList, setValueList] = useState<ValueListValueDTO[]>([]);
  const [selectedMeterRatio, setSelectedMeterRatio] = useState<ValueListValueDTO | null>(null);

  useEffect(() => {
    if (!task?.id) return;

    setIsPageLoading(true);

    if (task.meterStatus === MeterStatus.None) {
      MetertaskService.getRequiredReadings(parseInt(task.id))
        .then((data) => {
          setRequiredReadings(data.filter((r) => r.meterReadingRequired));
        })
        .catch((error) => {
          log(error);
          NotificationService.error('Kunne ikke hente tællerstande');
        })
        .finally(() => {
          setIsPageLoading(false);
        });

      MetertaskService.getValueListByName(ValueListName.ValueRatioCt)
        .then((data) => {
          setValueList(data);
        })
        .catch((error) => {
          log(error);
          NotificationService.error('Kunne ikke hente omsætningsforhold');
        });
    }
  }, []);

  const scan = async (_meterNumber: string) => {
    if (!task?.id) return;
    return MetertaskService.getMeters(_meterNumber)
      .then((data) => {
        if (data.notInstalledMeters?.length === 0) {
          setMeters([]);
          setNoDataText('Måler ikke fundet');
        } else {
          const result = data.notInstalledMeters?.map((obj) => obj.meterMasterData) ?? [];
          setMeters(result);
        }
      })
      .catch(() => {
        setNoDataText('Måler ikke fundet');
        NotificationService.error('Kunne ikke hente målere.');
      });
  };

  const isMissingBarcodeHandler = (value: boolean) => {
    setMeters([]);
    setIsMissingBarcode(value);
    setContinueWithoutVerification(false);
    setMeterNumber('');
    setNoDataText('Indtast eller scan målernummer');
  };

  const continueWithoutVerificationHandler = (value: boolean) => {
    if (value) {
      setMeters([]);
      setNoDataText('Måler identificeres ud fra det indtastede/scannede målernummer');
    } else {
      setIsScanButtonLoading(true);
      scan(meterNumber).finally(() => {
        setIsScanButtonLoading(false);
      });
    }
    setContinueWithoutVerification(value);
  };

  const setRequiredReadingHandler = (name: string | undefined, readingValue: number) => {
    if (!name) return;
    setRequiredReadings((prev) =>
      prev.map((r) => {
        if (r.name === name) {
          return { ...r, readingValue };
        } else {
          return r;
        }
      })
    );
  };

  const submit = () => {
    if (!task?.id) return;

    let meterData: MeterIdentifiersDTO;
    const selectedMeter = meters.find((meter) => meter.selected);
    if (continueWithoutVerification) {
      meterData = {
        meterId: undefined,
        meterNumber
      };
    } else {
      if (selectedMeter?.meterId && selectedMeter?.meterNumber) {
        meterData = {
          meterId: selectedMeter.meterId,
          meterNumber: selectedMeter.meterNumber
        };
      } else {
        NotificationService.error('Fejl i måler data. Den valgte måler mangler måler ID eller måler nummer.');
        return;
      }
    }

    setIsSubmitButtonLoading(true);
    MetertaskService.installMeter(parseInt(task.id), meterData)
      .then(() => {
        if (!task?.id || !selectedMeterRatio) return;
        Promise.all([
          MetertaskService.updateRequiredReadings(parseInt(task.id), requiredReadings),
          MetertaskService.updateMeterRatio(parseInt(task.id), selectedMeterRatio)
        ])
          .then(() => {
            NotificationService.success('Målerregistreringen blev gennemført!');
            setMeterRegistrationIsCompleted(true);
            setCompletedMeterInstallation({
              meter: selectedMeter,
              requiredReadings: requiredReadings,
              meterRatio: selectedMeterRatio
            });
          })
          .catch((error) => {
            NotificationService.error('Kunne ikke opdatere tællerstande eller omsætningsforhold.');
            log(error);
          })
          .finally(() => {
            setIsSubmitButtonLoading(false);
          });
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke gennemføre måleropsætningen.');
        log(error);
      });
  };

  const onCodeScannedHandler = (code: string) => {
    setIsScanButtonLoading(true);
    setShowCamera(false);
    setMeterNumber(code);
    scan(code).finally(() => {
      setIsScanButtonLoading(false);
    });
  };

  const onSearchMeterNumberHandler = (code: string) => {
    setIsSearchButtonLoading(true);
    scan(code).finally(() => {
      setIsSearchButtonLoading(false);
    });
  };

  const meterSelectedHandler = (selectedMeter: ExtendedMeter) => {
    setMeters((prevMeters) =>
      prevMeters.map((meter) => {
        if (meter.fabricationNumber === selectedMeter.fabricationNumber) {
          if (meter.selected) {
            return { ...meter, selected: false };
          } else {
            return {
              ...meter,
              selected: true
            };
          }
        } else {
          return {
            ...meter,
            selected: false
          };
        }
      })
    );
  };

  const { tableInstance } = useMeterTableInstance(meters);

  const getContent = (status: MeterStatus) => {
    switch (status) {
      case MeterStatus.None:
        return (
          <Container>
            {meterRegistrationIsCompleted ? (
              <>
                <MeterResume completedMeterInstallation={completedMeterInstallation} sectionHeader="Installeret måler" />
                <MeterComponents type={ComponentType.COMPONENT} task={task} />
                <MeterComponents type={ComponentType.EQUIPMENT} task={task} />
              </>
            ) : (
              <Section>
                <Row>
                  <StatusIndicator status={STATUS.GREY} />
                  <SectionHeader>Målerregistrering </SectionHeader>
                </Row>
                <Row>
                  {meterNumber && !isMissingBarcode && (
                    <TextColumnContainer>
                      <Label>Målernummer</Label>
                      <Value>{meterNumber}</Value>
                    </TextColumnContainer>
                  )}
                  <ScanButton
                    isLoading={isScanButtonLoading}
                    onClick={() => setShowCamera(true)}
                    disabled={isMissingBarcode}
                    variant="secondary"
                  >
                    <EyeIcon />
                    Scan måler
                  </ScanButton>
                  <Checkbox
                    checked={isMissingBarcode}
                    onChange={(event) => isMissingBarcodeHandler(event.target.checked)}
                    label="Kan ikke scanne stregkode"
                  />
                </Row>
                <Row style={{ display: isMissingBarcode ? 'flex' : 'none' }}>
                  <StyledTextField
                    label="Indtast målernummer"
                    disabled={continueWithoutVerification}
                    value={meterNumber}
                    onChange={(e) => setMeterNumber(e.target.value)}
                    fullWidth
                  ></StyledTextField>
                  <SearchButton
                    disabled={!meterNumber || continueWithoutVerification}
                    isLoading={isSearchButtonLoading}
                    onClick={() => onSearchMeterNumberHandler(meterNumber)}
                    variant="secondary"
                  >
                    <EyeIcon />
                    Søg
                  </SearchButton>
                </Row>
                <Row>
                  <SubsectionHeader>Vælg korrekt måler</SubsectionHeader>
                </Row>
                <Row>
                  <StyledTable
                    noPadding
                    loading={isSearchButtonLoading}
                    tableInstance={tableInstance}
                    noDataText={noDataText}
                    onClickRow={(data) => meterSelectedHandler(data)}
                  />
                </Row>
                <Row>
                  <Checkbox
                    disabled={!meterNumber}
                    label="Fortsæt uden verifikation fra målerlager"
                    onChange={(e) => continueWithoutVerificationHandler(e.target.checked)}
                    checked={continueWithoutVerification}
                  />
                </Row>
                {requiredReadings.length > 0 && (
                  <Subsection>
                    <SubsectionHeader>Tællerværk</SubsectionHeader>
                    <SubHeader>Tilføj tællerværk</SubHeader>
                    {requiredReadings.map((reading, index) => (
                      <GreyContainer>
                        <Row>
                          <SubsectionHeader>
                            Tællerværk
                            <span>{` ${index + 1} - ${reading.name} (${reading.code})`}</span>
                          </SubsectionHeader>
                        </Row>
                        <Divider />
                        <Row>
                          <SubHeader>Tællerstand</SubHeader>
                        </Row>
                        <Row>
                          <StyledTextField
                            required
                            fullWidth
                            value={reading.readingValue}
                            type="number"
                            onChange={(e) => setRequiredReadingHandler(reading.name, parseInt(e.target.value))}
                          ></StyledTextField>
                        </Row>
                        <Row>
                          <SubHeader>Billededokumentation: Billede af tællerstand</SubHeader>
                        </Row>
                        <Row>
                          <Button variant="secondary">Upload</Button>
                        </Row>
                      </GreyContainer>
                    ))}
                  </Subsection>
                )}
                <AutoComplete
                  loadingText="Henter"
                  fullWidth
                  options={valueList}
                  onChange={(_, value) => setSelectedMeterRatio(value)}
                  value={selectedMeterRatio}
                  renderInput={(params) => <TextField {...params} label={'Vælg målerens omsætningsforhold'} fullWidth />}
                  noOptionsText={'Ingen omsætningsforhold' ?? ''}
                  getOptionLabel={(valueListValueDTO) => valueListValueDTO.displayValue ?? ''}
                  disableClearable
                />
              </Section>
            )}
            <Footer>
              <Button
                disabled={(!meters.some((meter) => meter.selected) && !continueWithoutVerification) || !selectedMeterRatio}
                isLoading={isSubmitButtonLoading}
                onClick={submit}
              >
                Registrer ny måler
              </Button>
            </Footer>
          </Container>
        );
      case MeterStatus.InstallationRegistered:
        return (
          <>
            <Container>
              <Section>
                <Row>
                  <StatusIndicator status={STATUS.GREEN} />
                  <SectionHeader>Måler er allerede registreret</SectionHeader>
                </Row>
              </Section>
            </Container>
            <MeterComponents type={ComponentType.COMPONENT} task={task} />
            <MeterComponents type={ComponentType.EQUIPMENT} task={task} />
          </>
        );
      case MeterStatus.UninstallationRegistered:
    }
  };

  return <>{showCamera ? <>not implemented</> : task?.meterStatus && getContent(task?.meterStatus)}</>;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ScanButton = styled(Button)`
  padding: 0px ${(props) => props.theme.spacing(5)};
  margin: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(2)} 0px;
`;

export const SearchButton = styled(Button)`
  padding: 0px ${(props) => props.theme.spacing(5)};
  margin: 0px 0px 0px ${(props) => props.theme.spacing(3)};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.spacing(2)};
  width: 100%;
  flex-wrap: wrap;
`;

export const SectionHeader = styled(Typography)<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.palette.functions.error.primary : theme.palette.grey.black90)}};
  font-weight: 600 !important;
  font-size: 18px;
  font-size: 1.2rem !important;
  
  & {
    span {
      font-weight: 400 !important;
    }
  }
`;

export const SubsectionHeader = styled(Typography)<{ hasError?: boolean }>`
  color: ${({ hasError, theme }) => (hasError ? theme.palette.functions.error.primary : 'black')};
  font-weight: 600 !important;
  padding: 0px;
  font-size: 1rem !important;

  span {
    font-weight: 400;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(5)};
  margin-top: ${(props) => props.theme.spacing(7.5)};
  border: ${(props) => `1px solid ${props.theme.palette.grey.black20}`};
`;

export const Subsection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(5)};
  margin: ${(props) => props.theme.spacing(5)} 0px;
  border: ${(props) => `1px solid ${props.theme.palette.grey.black20}`};
`;

export const StyledTextField = styled(TextField)`
  background-color: white;
  margin: ${(props) => props.theme.spacing(2.5)} 0px !important;
`;

export const GreyContainer = styled.div`
  border-radius: 20px;
  border: ${(props) => `1px solid ${props.theme.palette.grey.black20}`};
  background-color: ${(props) => props.theme.palette.background.tertiary};
  min-height: 200px;
  margin-top: ${(props) => props.theme.spacing(5)};
  padding: ${(props) => props.theme.spacing(5)};
`;

export const SubHeader = styled.div`
  color: ${(props) => props.theme.palette.grey.black60};
  margin: ${(props) => props.theme.spacing(3)} 0px ${(props) => props.theme.spacing(2)} 0px;
  font-size: 16px;
`;

export const StyledTable = styled(Table)`
  min-height: 120px;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  margin: ${(props) => props.theme.spacing(5)} 0px;
`;

export const TextColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing(5)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  font-size: 16px;
  width: 50%;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.grey.black60};
`;

export const Value = styled.label`
  font-weight: 800;
`;

export default MeterInstallationStep;
