import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { CreateInternalTaskTimeRegistrationDTO2, DetailedTimeRegistrationDTO, TaskTypeBaseDTO } from '../../api/api';
import NotificationService from '../../services/NotificationService';
import TextField from '../../components/text-field/TextField';
import Button from '../../components/button/Button';
import { log } from '../../utils/logging/log';
import DatePicker from '../../components/date-picker/DatePicker';
import { SendIcon } from '../../assets/icons/SendIcon';
import DialogView from '../../components/dialog-view/DialogView';
import { DialogContent, DialogFooter, DialogHeader } from '../../views/goods-view/components/Styles';
import Typography from '../../components/typography/Typography';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import IconButton from '../../components/icon-button/IconButton';
import TimeRegistrationService from '../../services/TimeRegistrationService';
import TimePicker from '../../components/time-picker/TimePicker';
import { hoursToMinutes } from 'date-fns';

interface Props {
  handleReturn?: () => void;
  open: boolean;
  taskId?: number;
  taskType?: TaskTypeBaseDTO;
  task?: DetailedTimeRegistrationDTO;
  disabled?: boolean;
}

const InternalTimeRegForm = (props: Props) => {
  const { handleReturn, open, taskId, taskType, task, disabled } = props;
  const [submitting, setSubmitting] = useState(false);
  const [hours, setHours] = useState(task?.hours ?? 0);
  const [body, setBody] = useState<CreateInternalTaskTimeRegistrationDTO2>({
    date: task?.date,
    description: task?.description ?? '',
    hours: task?.hours,
    id: taskId ?? task?.id,
    projectNumber: task?.project?.id
  });

  const handleSave = () => {
    if (!taskId || (!body.hours && !hours) || !body.date) {
      NotificationService.error('Udfyld venligst alle nødvendige felter.');
      return;
    }

    const newBody = { ...body, hours };

    newBody.projectNumber = taskType?.projectNumber_OneERP;
    if (task) newBody.projectNumber = task.project?.id;
    setBody(newBody);

    setSubmitting(true);
    TimeRegistrationService.addInternalTime(newBody)
      .then((res) => {
        NotificationService.success('Tiden blev registreret.');
        handleReturn && handleReturn();
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Der opstod en fejl i registreringen af tid.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleTimeChange = useCallback(
    (hours: number, minutes: number) => {
      const totalHours = minutes / 60 + hours;
      setHours(totalHours);
    },
    [setHours]
  );

  return (
    <>
      {open && (
        <DialogView>
          <Container>
            <StyledDialogHeader>
              <Typography variant="h4" fontWeight="bold">
                {disabled ? 'Registreret tid' : 'Registrer tid'}
              </Typography>
              <IconButton variant="outlined" onClick={handleReturn}>
                <CloseIcon size="16px" />
              </IconButton>
            </StyledDialogHeader>

            <StyledDialogContent>
              <Row>
                <DatePicker
                  label="Registreringsdato"
                  onDateChanged={(date) => setBody({ ...body, date: date.toISOString() })}
                  disabled={disabled}
                  required
                />
                <TimePicker
                  initialHours={hours | 0}
                  initialMinutes={hoursToMinutes(hours) % 60}
                  onTimeChange={handleTimeChange}
                  disabled={disabled}
                />
              </Row>
              <Row>
                <TextField
                  fullWidth
                  value={body.description}
                  multiline
                  maxRows={4}
                  minRows={4}
                  onChange={(e) => setBody({ ...body, description: e.target.value })}
                  disabled={disabled}
                  label="Bemærkning"
                  placeholder="Skriv bemærkning"
                />
              </Row>
            </StyledDialogContent>
            {!disabled && (
              <DialogFooter>
                <StyledRow>
                  <CancelButton onClick={handleReturn}>Annuller</CancelButton>
                  <StyledButton isLoading={submitting} onClick={handleSave}>
                    <SendIcon size="16px" />
                    Send timer
                  </StyledButton>
                </StyledRow>
              </DialogFooter>
            )}
          </Container>
        </DialogView>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  margin: 10px;
  width: 600px;
`;

const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

const StyledDialogHeader = styled(DialogHeader)`
  width: 100%;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  height: 280px;
`;

const StyledButton = styled(Button)`
  margin: 0px 10px;
`;

const CancelButton = styled(Button)`
  margin: 0px 10px;
  background: white;
  border: 1px solid #b8b8b8;

  :hover {
    background: lightgrey;
  }
`;

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export default InternalTimeRegForm;
