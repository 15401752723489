import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const StopIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M0.382812 11.1486V2.18762C0.382812 1.53658 0.580729 1.01835 0.976562 0.632935C1.3724 0.24231 1.91927 0.0469971 2.61719 0.0469971H11.3906C12.0833 0.0469971 12.6276 0.24231 13.0234 0.632935C13.4193 1.01835 13.6172 1.53658 13.6172 2.18762V11.1486C13.6172 11.7996 13.4193 12.3178 13.0234 12.7032C12.6276 13.0887 12.0833 13.2814 11.3906 13.2814H2.61719C1.91927 13.2814 1.3724 13.0887 0.976562 12.7032C0.580729 12.3178 0.382812 11.7996 0.382812 11.1486ZM2.875 10.2111C2.875 10.4142 2.92969 10.573 3.03906 10.6876C3.15365 10.8022 3.3125 10.8595 3.51562 10.8595H10.6016C10.8047 10.8595 10.9635 10.8022 11.0781 10.6876C11.1927 10.573 11.25 10.4142 11.25 10.2111V3.13293C11.25 2.92981 11.1927 2.77096 11.0781 2.65637C10.9635 2.54179 10.8047 2.4845 10.6016 2.4845H3.51562C3.3125 2.4845 3.15365 2.54179 3.03906 2.65637C2.92969 2.77096 2.875 2.92981 2.875 3.13293V10.2111Z"
      />
    </IconSvg>
  );
};
