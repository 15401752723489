import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const LayerIcon = (props: IconProps) => {
  const { color, ...otherProps } = props;
  const defaultColor = color ?? '#000';
  return (
    <IconSvg {...otherProps} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2L2 7L12 12L22 7L12 2Z"
        stroke={defaultColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M2 17L12 22L22 17"
        stroke={defaultColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M2 12L12 17L22 12"
        stroke={defaultColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </IconSvg>
  );
};
