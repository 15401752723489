import { MeterDTO, RequiredReadingDTO, ValueListValueDTO } from '../../../../../../api/api';
import { Label, Row, Section, SectionHeader, TextColumnContainer, Value } from '../MeterInstallation/MeterInstallationStep';
import StatusIndicator, { STATUS } from '../../../../../../components/status-indicator/StatusIndicator';

export interface MeterInstallation {
  requiredReadings?: RequiredReadingDTO[];
  meter?: MeterDTO;
  meterRatio?: ValueListValueDTO;
}

interface Props {
  completedMeterInstallation?: MeterInstallation;
  sectionHeader?: string;
}

const MeterResume = (props: Props) => {
  const { completedMeterInstallation, sectionHeader } = props;

  return (
    <Section>
      <Row>
        <StatusIndicator status={STATUS.GREEN} />
        <SectionHeader>
          {sectionHeader} <span>{completedMeterInstallation?.meter?.meterNumber}</span>
        </SectionHeader>
      </Row>

      <Row>
        <TextColumnContainer>
          <Label>Måler</Label>
          <Value>
            {`${completedMeterInstallation?.meter?.manufacturer} ${completedMeterInstallation?.meter?.fabricationType}` ??
              'Ikke verificeret fra målerlager'}
          </Value>
        </TextColumnContainer>
        {completedMeterInstallation?.meterRatio && (
          <TextColumnContainer>
            <Label>Omsætningsforhold</Label>
            <Value>{completedMeterInstallation?.meterRatio?.displayValue ?? 'Ikke understøttet'}</Value>
          </TextColumnContainer>
        )}
      </Row>
      <Row>
        {completedMeterInstallation?.requiredReadings?.map((reading, index) => (
          <TextColumnContainer>
            <Label>{`Tællerværk ${index + 1} - ${reading.name} (${reading.code})`}</Label>
            <Value>Registreret</Value>
          </TextColumnContainer>
        ))}
      </Row>
    </Section>
  );
};

export default MeterResume;
