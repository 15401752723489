import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';

import DialogView from '../../components/dialog-view/DialogView';
import Typography from '../../components/typography/Typography';
import technicianAvatar from '../../assets/images/technicianAvatar.png';
import officeAvatar from '../../assets/images/officeAvatar.png';
import TechnicianSetupForm from './profile-setup-forms/TechnicianSetupForm';
import OfficeSetupForm from './profile-setup-forms/OfficeSetupForm';
import { BackArrowIcon } from '../../assets/icons/BackArrowIcon';
import IconButton from '../../components/icon-button/IconButton';
import { DepartmentDTO, ProfileDTO2Role, SaveProfileDTO2, SaveProfileDTO2Role } from '../../api/api';
import Button from '../../components/button/Button';
import UserService from '../../services/UserService';
import NotificationService from '../../services/NotificationService';
import LoadingOverlay from '../../components/loading-overlay/LoadingOverlay';
import { setUserProfileState } from '../../stateManagement/reducers/userProfileReducer';

export type Errors = { [key in keyof SaveProfileDTO2]: boolean | undefined };

export interface ProfileSetupProps {
  onChangeValue: (key: keyof SaveProfileDTO2, value: string | number) => void;
  departmentId?: number;
  errors?: Errors;
}

type UserType = SaveProfileDTO2Role.Technician | SaveProfileDTO2Role.Office | undefined;

interface Props {
  onSetupFinish: () => void;
}

const ProfileSetupDialog = (props: Props) => {
  const { onSetupFinish } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState<UserType>();
  const [values, setValues] = useState<SaveProfileDTO2>();
  const [errors, setErrors] = useState<Errors>({});
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);

  const dispatch = useDispatch();

  const handleReturn = () => {
    setUserType(undefined);
    setErrors({});
    setValues({});
  };

  const clearError = useCallback((key: keyof SaveProfileDTO2) => {
    setErrors((errors) => ({ ...errors, [key]: false }));
  }, []);

  const handleChange = useCallback(
    (key: keyof SaveProfileDTO2, value: string | number) => {
      setValues((prevState) => {
        const newValues = prevState ? { ...prevState, [key]: value } : { [key]: value };
        return newValues;
      });
      clearError(key);
    },
    [clearError]
  );

  const handleSave = () => {
    setErrors({});
    let tmpErrors: Errors = {};

    if (!values?.name) tmpErrors.name = true;
    if (!values?.phone) tmpErrors.phone = true;
    if (values?.departmentId === undefined && userType === 'Office') tmpErrors.departmentId = true;
    if (Object.keys(tmpErrors).length) return setErrors(tmpErrors);

    setIsLoading(true);
    const body: SaveProfileDTO2 = { ...values, role: userType ? userType : undefined };
    UserService.saveProfile(body)
      .then((res) => {
        NotificationService.success(`Indstillinger gemt for ${values?.name}`);
        userType &&
          dispatch(
            setUserProfileState({
              department: departments.find((d) => d.departmentId === body.departmentId),
              firstLoad: false,
              role: ProfileDTO2Role[userType]
            })
          );
        onSetupFinish();
      })
      .catch((error) => {
        NotificationService.error(`Der opstod en fejl. Prøv at genindlæse siden. ${error}`, 10000);
        dispatch(setUserProfileState({}));
      })
      .finally(() => setIsLoading(false));
  };

  if (!userType) {
    return (
      <WelcomeContainer>
        <Typography component="h4" variant="h1" color="textSecondary" fontWeight="bold" marginBottom={16}>
          Velkommen til Work
        </Typography>
        <Typography variant="h4" color="textSecondary">
          Er du tekniker eller kontorpersonale?
        </Typography>
        <AvatarContainer>
          <Avatar onClick={() => setUserType(SaveProfileDTO2Role.Technician)}>
            <AvatarImage img={technicianAvatar} />
            <Typography variant="h4" color="textSecondary" textAlign="center">
              Tekniker
            </Typography>
          </Avatar>
          <Avatar onClick={() => setUserType(SaveProfileDTO2Role.Office)}>
            <AvatarImage img={officeAvatar} />
            <Typography variant="h4" color="textSecondary" textAlign="center">
              Kontorpersonale
            </Typography>
          </Avatar>
        </AvatarContainer>
      </WelcomeContainer>
    );
  }

  return (
    <DialogView>
      <FormContainer>
        <FormHeader>
          <IconButton onClick={handleReturn}>
            <BackArrowIcon size="18px" />
          </IconButton>
          <Typography variant="h4">{userType === 'Technician' ? 'Min tekniker profil' : 'Min kontor profil'}</Typography>
        </FormHeader>
        {isLoading && <LoadingOverlay />}
        <HR />
        {userType === 'Technician' ? (
          <TechnicianSetupForm onChangeValue={handleChange} errors={errors} />
        ) : (
          <OfficeSetupForm
            onDepartmentsLoaded={setDepartments}
            onChangeValue={handleChange}
            departmentId={values?.departmentId}
            errors={errors}
          />
        )}
        <HR />
        <FormFooter>
          <Button onClick={handleSave}>Gem indstillinger</Button>
        </FormFooter>
      </FormContainer>
    </DialogView>
  );
};

const FormContainer = styled.div`
  padding: 32px;
  padding-bottom: 16px;
  position: relative;
  box-sizing: border-box;
  min-width: 700px;
`;

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HR = styled.hr`
  margin: 32px -32px;
  height: 1px;
  border: none;
  background-color: ${(props) => props.theme.palette.grey.black10};
`;

const WelcomeContainer = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.dialog};
  inset: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;

  background: rgba(0, 0, 0, 0.75);
`;

const AvatarContainer = styled.div`
  display: flex;
  column-gap: 120px;
  margin-top: 56px;
`;

const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  cursor: pointer;
`;

const AvatarImage = styled.div<{ img: string }>`
  width: 200px;
  height: 200px;

  border-radius: 50%;
  border: 10px solid #ffffff;
  background: url(${(props) => props.img});
  background-size: cover;
  background-position-x: 60%;
`;

export default ProfileSetupDialog;
