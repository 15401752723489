import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ProgressIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 2.625 16.815 C 2.017 16.815 1.502 16.603 1.078 16.178 C 0.653 15.754 0.441 15.238 0.441 14.632 C 0.441 14.025 0.653 13.509 1.078 13.085 C 1.502 12.661 2.017 12.448 2.625 12.448 C 3.231 12.448 3.746 12.661 4.171 13.085 C 4.596 13.509 4.808 14.025 4.808 14.632 C 4.808 15.238 4.596 15.754 4.171 16.178 C 3.746 16.603 3.231 16.815 2.625 16.815 Z M 14.997 16.815 C 14.39 16.815 13.876 16.603 13.451 16.178 C 13.026 15.754 12.814 15.238 12.814 14.632 C 12.814 14.025 13.026 13.509 13.451 13.085 C 13.876 12.661 14.39 12.448 14.997 12.448 C 15.605 12.448 16.119 12.661 16.544 13.085 C 16.969 13.509 17.181 14.025 17.181 14.632 C 17.181 15.238 16.969 15.754 16.544 16.178 C 16.119 16.603 15.605 16.815 14.997 16.815 Z M 27.37 16.815 C 26.763 16.815 26.249 16.603 25.824 16.178 C 25.399 15.754 25.187 15.238 25.187 14.632 C 25.187 14.025 25.399 13.509 25.824 13.085 C 26.249 12.661 26.763 12.448 27.37 12.448 C 27.977 12.448 28.492 12.661 28.917 13.085 C 29.342 13.509 29.554 14.025 29.554 14.632 C 29.554 15.238 29.342 15.754 28.917 16.178 C 28.492 16.603 27.977 16.815 27.37 16.815 Z"></path>
    </IconSvg>
  );
};
