import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TransferOrderDTO, WarehouseDTO } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import { HorizontalTabProps } from '../../../../components/tabs-horizontal/TabsHorizontal';
import GoodsService from '../../../../services/GoodsService';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';
import useOrderTable from './useOrderTable';
import AutoComplete from '../../../../components/auto-complete/AutoComplete';
import TextField from '../../../../components/text-field/TextField';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../../stateManagement/reducers/userProfileReducer';

interface Props extends HorizontalTabProps {}

const OrdersTab = (props: Props) => {
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [orders, setOrders] = useState<TransferOrderDTO[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseDTO[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseDTO | null>();
  const warehouseId = useSelector(selectUserProfile).userProfile.vehicle?.vehicleId;

  const getWarehouses = useCallback(() => {
    setLoadingWarehouses(true);
    GoodsService.getWarehouses()
      .then((_warehouses) => {
        const filteredWarehouses = _warehouses.filter((w) => w.warehouseId?.startsWith('V-'));
        filteredWarehouses && setWarehouses(filteredWarehouses);
        const myWarehouse = filteredWarehouses.find((warehouse) => warehouse.warehouseId === warehouseId);
        setSelectedWarehouse(myWarehouse);
      })
      .catch((e) => {
        log(e);
      })
      .finally(() => {
        setLoadingWarehouses(false);
      });
  }, [warehouseId]);

  const fetchTransferOrders = useCallback(() => {
    if (!warehouseId && !selectedWarehouse?.warehouseId) return;

    setLoadingOrders(true);
    GoodsService.getTransferOrders(selectedWarehouse?.warehouseId ?? warehouseId ?? '')
      .then((res) => {
        setOrders(res);
      })
      .catch((err) => {
        log(err);
        NotificationService.showToast({
          severity: 'error',
          text: 'Kunne ikke hente bestillinger',
          duration: 5000
        });
      })
      .finally(() => setLoadingOrders(false));
  }, [selectedWarehouse?.warehouseId, warehouseId]);

  useEffect(() => {
    fetchTransferOrders();
  }, [fetchTransferOrders]);

  useEffect(() => {
    getWarehouses();
  }, [getWarehouses]);

  const tableInstance = useOrderTable(orders, fetchTransferOrders);

  return (
    <Container>
      <AutoComplete
        value={selectedWarehouse}
        onChange={(_, value) => setSelectedWarehouse(value)}
        options={warehouses}
        getOptionLabel={(value) => value.name ?? ''}
        renderInput={(params) => <TextField {...params} label={'Lager'} />}
        loading={loadingWarehouses}
        noOptionsText={'Kunne ikke finde lager'}
        disableClearable={true}
        style={{ marginBottom: 10 }}
      />
      <Table loading={loadingOrders} tableInstance={tableInstance} alwaysShowSort noPadding useExpand showPagination />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .table-footer {
    justify-content: end;
  }
`;

export default OrdersTab;
