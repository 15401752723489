import styled from 'styled-components';
import { TaskComponentAttributeDTO } from '../../../../../../api/api';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import KebabMenu from '../../../../../../components/kebab-menu2/KebabMenu';
import { MenuItem } from '../../../../../../components/select/Select';
import { useConfirmationDialog } from '../../../../../../hooks/useConfirmationDialog';

import { DialogBody } from '../../../../../../stateManagement/reducers/confirmDialogReducer';
import Typography from '../../../../../../components/typography/Typography';

interface Props {
  attribute: TaskComponentAttributeDTO;
  setAttribute: (attribute: TaskComponentAttributeDTO) => void;
  removeAttribute: () => void;
}

const navigateDialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at du vil slette dette felt? Handlingen kan ikke fortrydes.',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const Field = (props: Props) => {
  const { attribute, setAttribute, removeAttribute } = props;
  const { getConfirmation } = useConfirmationDialog();

  const updateField = (field: keyof TaskComponentAttributeDTO, checked: boolean) => {
    if (!attribute.attributeId) return;

    setAttribute({
      ...attribute,
      [field]: checked
    });
  };

  const deleteField = async () => {
    if (!attribute.attributeId) return;

    const confirmation = await getConfirmation(navigateDialogBody);

    if (confirmation === 'confirm') {
      removeAttribute();
    }
  };

  return (
    <FieldContainer>
      <Row>
        <StyledTypography>id-{attribute.attributeId}</StyledTypography>
        <LeftButtons>
          <DynamicFieldContainer>
            <FieldName>{attribute.label}</FieldName>
            {attribute.hintText && <HintText>{attribute.hintText}</HintText>}
          </DynamicFieldContainer>
        </LeftButtons>
        <RightButtons>
          <StyledCheckbox
            onChange={(e) => updateField('isMandatory', e.target.checked)}
            checked={attribute.isMandatory}
            label="Obligatorisk"
          />
          <StyledCheckbox
            onChange={(e) => updateField('isEditable', e.target.checked)}
            checked={attribute.isEditable}
            label="Redigerbar"
          />
          <KebabMenu>
            <MenuItem onClick={deleteField}>Slet felt</MenuItem>
          </KebabMenu>
        </RightButtons>
      </Row>
    </FieldContainer>
  );
};

const LeftButtons = styled.div`
  width: 60%;
  display: flex;
`;

const StyledTypography = styled(Typography)`
  width: 75px;
  margin: auto;
  text-align: center;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

const FieldContainer = styled.div`
  display: 'flex';
  flex-direction: 'column';
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed black;
  position: relative;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 50px;
`;

const DynamicFieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #000000;
  width: 300px;
  margin: 20px 0px;
`;

const FieldName = styled.h1`
  font-size: 15px;
  background-color: #f2f2f2;
  padding: 18px;
  border-radius: 10px;
  width: 100%;
  color: #343434;
`;

const HintText = styled.p`
  font-size: 12px;
  margin: 15px 0px 0px 12px;
  color: #a5a5a5;
`;

export default Field;
