import { memo } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  text: string;
  hidden?: boolean;
  xPosition?: 'center' | 'left' | 'right';
  yPosition?: 'center' | 'top' | 'bottom';
}

const ToolTip = (props: Props) => {
  const { children, text, hidden, xPosition = 'center', yPosition = 'bottom' } = props;

  if (hidden) return <>{children}</>;

  return (
    <Container content={text} xPosition={xPosition} yPosition={yPosition}>
      {children}
    </Container>
  );
};

const Container = styled.div<{
  content: string;
  xPosition: 'center' | 'left' | 'right';
  yPosition: 'center' | 'top' | 'bottom';
}>`
  position: relative;
  width: 100%;

  &:hover:after {
    content: '${(props) => props.content}';
    position: absolute;
    border-radius: 16px;
    background: #000000;
    color: #ffffff;
    padding: 16px;
    font-size: 14px;
    width: max-content;
    max-width: 250px;
    top: 20px;
    left: 50%;
    transform: ${(prop) =>
        prop.yPosition === 'bottom'
          ? 'translateY(´+105%)'
          : prop.yPosition === 'top'
          ? 'translateY(-105%)'
          : 'translateY(-50%)'}
      ${(prop) =>
        prop.xPosition === 'right'
          ? 'translateX(-105%)'
          : prop.xPosition === 'left'
          ? 'translateX(+105%)'
          : 'translateX(-50%)'};

    z-index: ${(props) => props.theme.zIndex.tooltip};
  }
`;

const preventUpdate = (prevProps: Props, props: Props) => {
  return prevProps.text === props.text && prevProps.children === props.children;
};
export default memo(ToolTip, preventUpdate);
