import styled from 'styled-components';
import { UpdateIcon } from '../../assets/icons/UpdateIcon';
import Typography from '../typography/Typography';
import Button from '../button/Button';

interface Props {
  handleUpdateTasks: () => void;
  header: string;
  subheader?: string;
  button: JSX.Element;
}

const TechnicianHeader = (props: Props) => {
  const { handleUpdateTasks, header, button, subheader } = props;

  const onClickUpdateTasks: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    handleUpdateTasks();
  };

  return (
    <StyledShell data-testid="dayOverviewHeader">
      <TextContainer>
        <Typography variant="h4" fontWeight="bold">
          {header}
        </Typography>
        {subheader && <Typography>{subheader}</Typography>}
      </TextContainer>
      <StyledRightDiv>
        <StyledUpdateButton variant="secondary" onClick={onClickUpdateTasks}>
          <UpdateIcon size="20px" />
        </StyledUpdateButton>
        {button}
      </StyledRightDiv>
    </StyledShell>
  );
};

export const StyledShell = styled.div`
  height: 100px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  background-color: ${(props) => props.theme.palette.grey.black5};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const StyledRightDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUpdateButton = styled(Button)`
  border-radius: 100px;
  padding: 10px;
  margin: 0px 20px;
`;

export default TechnicianHeader;
