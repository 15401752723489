import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

export type ToolTipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface Props extends TooltipProps {
  children: JSX.Element;
  title: string;
  hidden?: boolean;
  placement?: ToolTipPlacement;
}

const ToolTip = (props: Props) => {
  const { children, title, hidden, placement, ...rest } = props;

  if (hidden) return <>{children}</>;

  return <Tooltip {...rest} placement={placement} children={children} title={title} />;
};

export default ToolTip;
