import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SignIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 21.734 2.338 C 22.571 1.397 23.782 0.857 25.055 0.857 C 27.489 0.857 29.464 2.79 29.464 5.173 C 29.464 6.42 28.912 7.606 27.951 8.425 L 16.387 18.288 L 11.608 13.659 L 21.734 2.338 Z M 15.125 19.528 L 14.053 24.085 C 13.83 25.019 13.104 25.762 12.161 26.019 L 2.245 28.741 L 7.567 23.527 C 7.718 23.566 7.869 23.588 8.031 23.588 C 9.018 23.588 9.817 22.806 9.817 21.839 C 9.817 20.873 9.018 20.091 8.031 20.091 C 7.042 20.091 6.245 20.873 6.245 21.839 C 6.245 21.998 6.265 22.145 6.305 22.293 L 0.982 27.5 L 3.759 17.747 C 4.023 16.873 4.782 16.163 5.737 15.944 L 10.392 14.894 L 15.125 19.528 Z"
      ></path>
    </IconSvg>
  );
};
