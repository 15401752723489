import {
  AccessInformation,
  ContactInformationDTO,
  EquipmentDTO,
  MeterIdentifiersDTO,
  RequiredReadingDTO,
  SmileComponentDTO,
  ValueListName,
  ValueListValueDTO
} from '../api/api';
import WorkClient from '../api/workClient';
import { getGuid } from '../utils/guidGenerating';

const getMeterTaskById = (taskId: number) => {
  return WorkClient.getMeterTaskById(taskId);
};

const getMeters = (meterNumber: string, correlationId?: string) => {
  return WorkClient.getMetersByMeterNumber(correlationId, meterNumber);
};

const addContactPerson = (meterTaskId: number, body: ContactInformationDTO) => {
  return WorkClient.createContactInformation(meterTaskId, body);
};

const updateAccessInformation = (meterTaskId: number, body: AccessInformation) => {
  return WorkClient.updateAccessInformation(meterTaskId, body);
};

const getRequiredReadings = (meterTaskId: number, correlationId?: string) => {
  return WorkClient.getRequiredReadings(correlationId, meterTaskId);
};

const installMeter = (meterTaskId: number, body: MeterIdentifiersDTO) => {
  return WorkClient.updateMeterIdsToInstall(meterTaskId, body);
};

const updateRequiredReadings = (meterTaskId: number, body: RequiredReadingDTO[]) => {
  return WorkClient.installMeter(getGuid(), meterTaskId, body);
};

const getComponentsBySerialNumber = (serialNumber: string, correlationId?: string | undefined) => {
  return WorkClient.getComponentsBySerialNumber(correlationId, serialNumber);
};

const getComponentsByMeterId = (meterId: string, correlationId?: string | undefined) => {
  return WorkClient.getComponentsByMeterId(correlationId, meterId);
};

const installComponent = (meterTaskId: number, body: SmileComponentDTO[], correlationId?: string | undefined) => {
  return WorkClient.installComponent(correlationId, meterTaskId, body);
};

const getEquipmentBySerialNumber = (serialNumber: string, correlationId?: string | undefined) => {
  return WorkClient.getEquipmentsByNumber(correlationId, serialNumber);
};

const getEquipmentByWorkOrderId = (meterTaskId: number, correlationId?: string | undefined) => {
  return WorkClient.getEquipmentsByWorkOrderId(correlationId, meterTaskId);
};

const installEquipment = (meterTaskId: number, body: EquipmentDTO[], correlationId?: string | undefined) => {
  return WorkClient.installEquipment(correlationId, meterTaskId, body);
};

const getValueListByName = (name: ValueListName) => {
  return WorkClient.getValueListByName(name);
};

const updateMeterRatio = (meterTaskId: number, value: ValueListValueDTO) => {
  return WorkClient.updateMeterRatio(getGuid(), meterTaskId, value);
};

const getMeterByMeterId = (meterId: string) => {
  return WorkClient.getMeterByMeterId(getGuid(), meterId);
};

const uninstallMeter = (meterTaskId: number, body: RequiredReadingDTO[]) => {
  return WorkClient.uninstallMeter(getGuid(), meterTaskId, body);
};

const MetertaskService = {
  getMeterTaskById,
  addContactPerson,
  updateAccessInformation,
  getRequiredReadings,
  getMeters,
  installMeter,
  updateRequiredReadings,
  getComponentsBySerialNumber,
  installComponent,
  getComponentsByMeterId,
  getEquipmentBySerialNumber,
  getEquipmentByWorkOrderId,
  installEquipment,
  getValueListByName,
  updateMeterRatio,
  getMeterByMeterId,
  uninstallMeter
};

export default MetertaskService;
