import { useRef, useState } from 'react';
import styled from 'styled-components';
import { KebabMenuIcon } from '../../assets/icons/KebabMenuIcon';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Grow from '../grow';
import IconButton from '../icon-button/IconButton';

interface Props {
  children?: React.ReactNode;
}

const KebabMenu = (props: Props) => {
  const { children } = props;

  const [open, setOpen] = useState(false);

  const handleOnClick = (e: any) => {
    setOpen((prev) => !prev);
    e.stopPropagation();
  };

  let ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref}>
      <IconButton padding="8px" onClick={handleOnClick}>
        <KebabMenuIcon size="16px" />
      </IconButton>
      <Grow in={open}>
        <DropdownContainer>{children}</DropdownContainer>
      </Grow>
    </div>
  );
};

const DropdownContainer = styled.div`
  position: absolute;
  top: 0;
  right: 35px;
  display: flex;
  flex-direction: column;
  padding: 18px 8px;
  z-index: ${(props) => props.theme.zIndex.kebabMenu};

  border-radius: 24px;
  border: 1px solid #dbdbdb;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 7%);
`;

export default KebabMenu;
