import {
  CreateOrUpdateReasonCodeDTO,
  CreateReusableComponentDTO,
  TaskTypeSectionDTO,
  CreateQuestionDTO,
  TaskTypeCheckListDTO
} from '../api/api';
import WorkClient from '../api/workClient';

const getReusableComponents = () => {
  return WorkClient.reusableComponentsAll();
};

const addReusableComponent = (dto: CreateReusableComponentDTO) => {
  return WorkClient.reusableComponentsPOST(dto);
};

const updateReusableComponent = (id: number, dto: CreateReusableComponentDTO) => {
  return WorkClient.reusableComponentsPUT(id, dto);
};

const getChecklistsForTaskType = (taskTypeId: number) => {
  return WorkClient.getChecklistsForTaskType(taskTypeId);
};

const setChecklistsForTaskType = (taskTypeId: number, body: TaskTypeCheckListDTO[]) => {
  return WorkClient.setChecklistsForTaskType(taskTypeId, body);
};

const getChecklistQuestions = () => {
  return WorkClient.getAllQuestions();
};

const createChecklistQuestion = (dto: CreateQuestionDTO) => {
  return WorkClient.createQuestion(dto);
};

const updateChecklistQuestion = (id: number, dto: CreateQuestionDTO) => {
  return WorkClient.updateQuestion(id, dto);
};

const getAllReasonCodes = () => {
  return WorkClient.allReasonCodes();
};

const createReasonCode = (body: CreateOrUpdateReasonCodeDTO) => {
  return WorkClient.createReasonCode(body);
};

const updateReasonCode = (reasonCodeId: number, body: CreateOrUpdateReasonCodeDTO) => {
  return WorkClient.updateReasonCode(reasonCodeId, body);
};

const deleteReasonCodes = (ids: number[]) => {
  return WorkClient.deleteReasonCodes(ids);
};

const getQuestions = (taskTypeId: number) => {
  return WorkClient.getSectionsForTaskType(taskTypeId);
};

const setSectionsForTaskType = (taskTypeId: number, body: TaskTypeSectionDTO[]) => {
  return WorkClient.setSectionsForTaskType(taskTypeId, body);
};

const getDepartments = () => {
  return WorkClient.departmentAll();
};

const updateSectionName = (sectionId: number, name: string) => {
  return WorkClient.updateSectionName(sectionId, name);
};

const updateTaskTypeCheckListName = (taskTypeCheckListId: number, checkListName: string) => {
  return WorkClient.updateTaskTypeCheckListName(taskTypeCheckListId, checkListName);
};

const AdminService = {
  getReusableComponents,
  addReusableComponent,
  updateReusableComponent,
  getChecklistsForTaskType,
  setChecklistsForTaskType,
  getChecklistQuestions,
  createChecklistQuestion,
  updateChecklistQuestion,
  getAllReasonCodes,
  createReasonCode,
  updateReasonCode,
  deleteReasonCodes,
  getQuestions,
  setSectionsForTaskType,
  getDepartments,
  updateSectionName,
  updateTaskTypeCheckListName
};

export default AdminService;
