import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const PauseIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 27 28" xmlns="http://www.w3.org/2000/svg" data-testid="pause-icon">
      <path
        d="M0.500122 19.3346H5.83346V0.667969H0.500122V19.3346ZM11.1668 0.667969V19.3346H16.5001V0.667969H11.1668Z"
        fill="#currentColor"
      />
    </IconSvg>
  );
};
