import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Section = styled.div<{ direction?: string; noBorder?: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'column'};
  border-top: 1px solid ${(props) => (props.noBorder ? 'transparent' : props.theme.palette.grey.black10)};
  padding-bottom: 32px;
`;

export const SectionHeader = styled.h3`
  margin: 24px 0;
`;

export const SectionContent = styled.div<{ direction?: string; rowGap?: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'column'};
  row-gap: 28px;
  column-gap: 12px;
`;

export const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const FullWidthComponent = styled.div`
  width: 100%;
`;
