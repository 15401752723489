import styled from 'styled-components';
import Typography from '../../components/typography/Typography';
import { useState, useEffect } from 'react';
import LoadingOverlay from '../../components/loading-overlay/LoadingOverlay';
import News from './News';
import NewsPreview from './NewsPreview';
import { useDispatch, useSelector } from 'react-redux';
import { selectNews, setNews, setShowNotification } from '../../stateManagement/reducers/newsReducer';
import NewsService from '../../services/NewsService';
import { NewsDTO } from '../../api/api';
import { log } from '../../utils/logging/log';
import NotificationService from '../../services/NotificationService';
import { sortDateTime } from '../../utils/dateHandling';

const NewsView = () => {
  const [loading, setLoading] = useState(false);
  const [selectedNews, setSelectedNews] = useState<NewsDTO>();
  const dispatch = useDispatch();
  const news = useSelector(selectNews);

  const onNewsClickedHandler = async (_selectedNews: NewsDTO) => {
    setSelectedNews(_selectedNews);

    if (!_selectedNews.id) return;
    if (!_selectedNews.isRead) {
      await NewsService.updateRead(_selectedNews.id);
      const updatedNews = news.map((obj) => {
        if (_selectedNews.id === obj.id) {
          return {
            ...obj,
            isRead: true
          };
        } else {
          return obj;
        }
      });
      dispatch(setNews(updatedNews));
    }
  };

  useEffect(() => {
    dispatch(setShowNotification({ show: false }));
    setLoading(true);
    NewsService.getUserNews()
      .then((response) => {
        dispatch(setNews(response));
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke hente nyheder');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <Header>
        <Typography fontWeight="bold" variant="h3">
          Nyheder og vigtige beskeder i Work
        </Typography>
      </Header>
      <Content>
        <LeftContainer>
          <Typography fontWeight="bold">Beskeder</Typography>
          <NewsContainer>
            {[...news]
              .sort((a, b) => sortDateTime(b.lastUpdated ?? '', a.lastUpdated ?? ''))
              .map((news) => (
                <News isSelected={news.id === selectedNews?.id} onClick={(news) => onNewsClickedHandler(news)} news={news} />
              ))}
          </NewsContainer>
        </LeftContainer>
        <RightContainer>
          {selectedNews ? (
            <NewsPreview news={selectedNews} />
          ) : (
            <Typography fontWeight="bold">Tryk på en besked for at åbne den</Typography>
          )}
        </RightContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const Header = styled.div`
  background-color: ${(props) => props.theme.palette.background.tertiary};
  padding: ${(props) => props.theme.spacing(5)};
  border-bottom: 2px solid ${(props) => props.theme.palette.grey.black10};
  border-left: 2px solid ${(props) => props.theme.palette.grey.black10};
`;

const Content = styled.div`
  display: flex;
  width: calc(100% - 2px);
  height: 100%;
  height: calc(100vh - 81px);
  flex-direction: row;
  border-left: 2px solid ${(props) => props.theme.palette.grey.black10};
`;

const LeftContainer = styled.div`
  width: 50%;
  background-color: ${(props) => props.theme.palette.grey.black5};
  padding: ${(props) => props.theme.spacing(5)};
  overflow-y: scroll;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: ${(props) => props.theme.palette.grey.black10};
`;

const NewsContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(5)};
`;

export default NewsView;
