import { useRef, useState, useCallback } from 'react';

import Grow from '../../../../components/grow';
import { mapEnum } from '../../../../utils/enumUtils';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { WorkTaskStatusDTO, WorkTaskStatus } from '../../../../api/api';
import StatusTag from '../../../../components/status-tag/StatusTag';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import { TagIcon } from '../../../../assets/icons/TagIcon';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import NotificationService from '../../../../services/NotificationService';
import { Container, ExpandedToolMenu, MenuTrigger } from '../../../../styling/ListToolsStyling';
import InternalTaskService from '../../../../services/InternalTaskService';
import { useDispatch } from 'react-redux';
import { setShouldUpdateNumberOfInternalTasksCreated } from '../../../../stateManagement/reducers/taskListReducer';

interface Props {
  setIsLoading: (isLoading: boolean) => void;
  fetchTasksCallback: () => void;
  selectedRows: { id: number; hasSubTasks: boolean }[];
}

const InternalTaskChangeStatusMenu = (props: Props) => {
  const { selectedRows, fetchTasksCallback, setIsLoading } = props;

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { getConfirmation } = useConfirmationDialog();

  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(rootRef, () => setOpen(false));

  const handleChangeStatus = useCallback(
    async (newStatus: WorkTaskStatus) => {
      const body: WorkTaskStatusDTO[] = selectedRows.map((row) => ({
        workTaskId: row.id,
        status: newStatus
      }));

      if (body.length >= 10) {
        const dialogBody: DialogBody = {
          headerText: `Vil du ændre status på ${body.length} opgaver til "${getEnumDisplayValue(newStatus)}"?`,
          bodyText: '',
          declineButtonText: 'Fortryd',
          confirmButtonText: 'Bekræft'
        };
        const confirmation = await getConfirmation(dialogBody);
        if (confirmation !== 'confirm') return;
      }

      setIsLoading(true);
      InternalTaskService.setInternalTaskStatus(body)
        .then((response) => {
          NotificationService.success(`Opdaterede status på ${body.length} opgaver til "${getEnumDisplayValue(newStatus)}"`);

          dispatch(setShouldUpdateNumberOfInternalTasksCreated(true));

          fetchTasksCallback();
        })
        .catch((error) => {
          NotificationService.error(`Kunne ikke ændre status: ${error}`);
          setIsLoading(false);
        });
      setOpen(false);
    },
    [selectedRows, setIsLoading, fetchTasksCallback, getConfirmation, dispatch]
  );

  return (
    <Container ref={rootRef}>
      <MenuTrigger onClick={() => setOpen(!open)} variant="tertiary" square data-testid="change-status-button">
        <TagIcon size="18px" />
        Skift status
      </MenuTrigger>

      <Grow in={open}>
        <ExpandedToolMenu pointerOnItems>
          {mapEnum(WorkTaskStatus).map((status, i) => {
            return (
              <StatusTag
                data-testid={`${status}-status-item`}
                status={WorkTaskStatus[status]}
                key={i}
                onClick={() => handleChangeStatus(WorkTaskStatus[status])}
              >
                {getEnumDisplayValue(WorkTaskStatus[status])}
              </StatusTag>
            );
          })}
        </ExpandedToolMenu>
      </Grow>
    </Container>
  );
};

export default InternalTaskChangeStatusMenu;
