import { useCallback, useState } from 'react';
import { FormHelperText } from '@mui/material';

import TimeOfDayDropdown from '../../components/time-of-day-dropdown/TimeOfDayDropdown';

import { CustomerAppointmentDTO } from '../../api/api';
import { AppointmentErrors } from '../../models/CreationErrors';
import Alert from '../../components/alert/Alert';
import DatePicker from '../../components/date-picker/DatePicker';
import {
	CustomerAppointmentFormHeader,
	CustomerAppointmentFormRow,
	CustomerAppointmentFormSection
} from './CustomerAppointmentContactForm';
import { formatDateString } from '../../utils/dateHandling';

interface Props {
	errors?: AppointmentErrors;
	onChange?: (key: keyof CustomerAppointmentDTO, value: string) => void;
	initialValue?: CustomerAppointmentDTO;
}

export const CustomerAppointmentDateForm = (props: Props) => {
	const { errors, onChange, initialValue } = props;

	const [values, setValues] = useState<CustomerAppointmentDTO>(initialValue ?? {});

	const handleChange = useCallback(
		(key: keyof CustomerAppointmentDTO, value: string) => {
			onChange?.(key, value);

			setValues((prevState) => (prevState ? { ...prevState, [key]: value } : { [key]: value }));
		},
		[onChange]
	);

	return (
		<CustomerAppointmentFormSection>
			<CustomerAppointmentFormHeader>Vælg ønskede tid til aftalen</CustomerAppointmentFormHeader>

			<Alert severity="warning" height="47px" margin="0 0 32px 0">
				<p>Tidsaftalen skal først godkendes af Planlægning, før den træder i kraft</p>
			</Alert>

			<CustomerAppointmentFormRow>
				<DatePicker
					label="Start dato"
					value={formatDateString(values?.startDate ?? '')}
					disableWeekends
					earliestStartDate={new Date()}
					onDateChanged={(date) => handleChange('startDate', date.toISOString())}
				/>
				<DatePicker
					label="Slut dato"
					value={formatDateString(values?.endDate ?? '')}
					disableWeekends
					earliestStartDate={values?.startDate ? new Date(values.startDate) : undefined}
					onDateChanged={(date) => handleChange('endDate', date.toISOString())}
				/>
			</CustomerAppointmentFormRow>

			<CustomerAppointmentFormRow direction="column">
				<TimeOfDayDropdown
					label="Ønsket tidsrum på dagen"
					value={values?.preferredTimeOfDay}
					onChange={(event) => handleChange('preferredTimeOfDay', event.target.value)}
				/>

				<FormHelperText error={errors?.preferredTimeOfDay}>Vælg tidsrum fra listen</FormHelperText>
			</CustomerAppointmentFormRow>
		</CustomerAppointmentFormSection>
	);
};

export default CustomerAppointmentDateForm;
