import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const MinimizeIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="20.757 39.151 35 35" xmlns="http://www.w3.org/2000/svg" data-testid="dialog-minimize-icon">
      <line
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="4"
        x1="22.044"
        y1="56.471"
        x2="54.412"
        y2="56.471"
      />
    </IconSvg>
  );
};
