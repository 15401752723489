import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const TechnicianIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.45663, 0, 0, 1.45663, -2.254993, -2.500015)">
        <circle stroke="none" cx="12" cy="4" r="2"></circle>
        <path
          stroke="none"
          d="M15.89,8.11C15.5,7.72,14.83,7,13.53,7c-0.21,0-1.42,0-2.54,0C8.24,6.99,6,4.75,6,2H4c0,3.16,2.11,5.84,5,6.71V22h2v-6h2 v6h2V10.05L18.95,14l1.41-1.41L15.89,8.11z"
        ></path>
      </g>
    </IconSvg>
  );
};
