import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Container, ExpandedToolMenu, MenuTrigger } from '../../../../styling/ListToolsStyling';
import Grow from '../../../../components/grow';
import { AssignWorkerIcon } from '../../../../assets/icons/AssignWorkerIcon';
import TextField from '../../../../components/text-field/TextField';
import { InputAdornment } from '@mui/material';
import { SearchIcon } from '../../../../assets/icons/SearchIcon';
import UserService from '../../../../services/UserService';
import TaskService from '../../../../services/TaskService';
import { UserDTO } from '../../../../api/api';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import NotificationService from '../../../../services/NotificationService';
import styled from 'styled-components';
import { log } from '../../../../utils/logging/log';
import { TableToolsProps } from '../../../../blocks/table/TableUtils';
import { selectUserProfile } from '../../../../stateManagement/reducers/userProfileReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setListShouldUpdate } from '../../../../stateManagement/reducers/taskListReducer';

const AssignCaseWorkerMenu = <T extends { id: string }>(props: TableToolsProps<T>) => {
  const { selectedRows, fetchTasksCallback } = props;

  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [error, setError] = useState('');
  const [spinning, setSpinning] = useState(false);
  const { name } = useSelector(selectUserProfile).userProfile;
  const rootRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open && users.length === 0) {
      setSpinning(true);
      UserService.getAllKnownUsers()
        .then((res) => {
          setUsers(res.sort((a, b) => sortByFirstName(a, b)));
        })
        .catch((err) => {
          log(err);
          setError('Kunne ikke hente brugere');
        })
        .finally(() => setSpinning(false));
    }
  }, [open, users.length]);

  const sortByFirstName = (a: UserDTO, b: UserDTO) => {
    if (!a.name) return 1;
    if (!b.name) return -1;

    return a.name.localeCompare(b.name);
  };

  useOnClickOutside(rootRef, () => {
    setOpen(false);
    setSearchInput('');
  });

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSelectCaseWorker = (e: Event, email: string | undefined, name: string | undefined) => {
    e.stopPropagation();
    const tasks = selectedRows.map((row) => parseInt(row.id));
    setSpinning(true);
    TaskService.assignToCaseWorker(email, name, tasks)
      .then(() => {
        setOpen(false);
        const text =
          name || email
            ? `Tildelte ${tasks.length} ${tasks.length === 1 ? 'opgave' : 'opgaver'} til ${name}`
            : `Sagsbehandler fjernet fra ${tasks.length} ${tasks.length === 1 ? 'opgave' : 'opgaver'}.`;
        NotificationService.success(text);
        setSearchInput('');
        fetchTasksCallback();
        dispatch(setListShouldUpdate(true));
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Der opstod en fejl - opgaverne er ikke tildelt en ny medarbejder');
      })
      .finally(() => setSpinning(false));
  };

  return (
    <Container ref={rootRef}>
      <MenuTrigger onClick={() => setOpen(!open)} variant="tertiary" square>
        <AssignWorkerIcon size="16px" />
        Tildel
      </MenuTrigger>

      <Grow in={open}>
        <ExpandedToolMenu>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon size="18px" />
                </InputAdornment>
              )
            }}
            label="Søg på en medarbejder"
            onChange={handleSearchInputChange}
            value={searchInput}
          />
          {error ? (
            <div>{error}</div>
          ) : (
            <ResultContainer>
              {spinning ? (
                <LoadingOverlay width="calc(100% - 32px)" position="" />
              ) : (
                <>
                  <CustomOption onClick={(e: any) => handleSelectCaseWorker(e, undefined, name)}>{name} (mig)</CustomOption>
                  <CustomOption border onClick={(e: any) => handleSelectCaseWorker(e, undefined, undefined)}>
                    Ingen sagsbehandler
                  </CustomOption>
                  {users
                    .filter((user) => user.name && user.name?.toLowerCase().includes(searchInput.toLowerCase()))
                    .map((user) => (
                      <div key={user.email} onClick={(e: any) => handleSelectCaseWorker(e, user.email, user.name)}>
                        {user.name}
                      </div>
                    ))}
                </>
              )}
            </ResultContainer>
          )}
        </ExpandedToolMenu>
      </Grow>
    </Container>
  );
};

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.grey.black90};
  padding: 18px 8px 0px 8px;
  height: 168px;
  overflow-y: auto;
  width: 250px;

  > * {
    padding: 12px 16px;
    border-radius: 8px;
  }

  > * {
    :hover {
      background: #f2f4fe;
      cursor: pointer;
    }
  }
`;

const CustomOption = styled.div<{ border?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-bottom: ${(props) => (props.border ? '1px' : '0px')} solid ${(props) => props.theme.palette.grey.black5};
`;

export default AssignCaseWorkerMenu;
