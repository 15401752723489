import addBusinessDays from 'date-fns/addBusinessDays';
import endOfWeek from 'date-fns/endOfWeek';
import getWeek from 'date-fns/getWeek';
import startOfWeek from 'date-fns/startOfWeek';
import Holidays from 'date-holidays';

export const getDistanceInDays = (from: Date, to: Date): number => {
  const diffTime = to.getTime() - from.getTime();
  return Math.round(diffTime / (1000 * 60 * 60 * 24));
};

export const getDistanceInDaysFromToday = (to: Date): number => {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setMilliseconds(0);
  today.setSeconds(0);
  let copyOfTo = new Date(to.getFullYear(), to.getMonth(), to.getDate());
  return getDistanceInDays(today, copyOfTo);
};

export const formatDateString = (dateString: string): string => {
  if (dateString === '') return '';

  const date: Date = new Date(dateString);
  return date.toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const formatDateStringISO = (dateString: string, format?: 'yyyymmdd' | 'ddmmyyyy'): string => {
  const formattedDate = formatDateString(dateString).split('.');
  const year = formattedDate[2];
  const month = formattedDate[1];
  const day = formattedDate[0];
  return format === 'ddmmyyyy' ? `${day}-${month}-${year}` : `${year}-${month}-${day}`;
};

export const formatTimeAndDateString = (timestamp: string) => {
  return `Kl. ${formatDateHourString(timestamp ?? '')} - d. ${formatDateString(timestamp ?? '')}`;
};

export const formatDateHourString = (dateString: string): string => {
  const date: Date = new Date(dateString);
  return date.toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' });
};

export const formatDate = (date: Date | undefined): string | undefined => {
  return date?.toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const formatDateWithHours = (date: Date): string => {
  return date.toLocaleDateString('da-DK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });
};

export const formatDateWithTime = (timeStamp?: string): string => {
  if (!timeStamp) return '';

  const date = new Date(timeStamp);

  return date.toLocaleDateString('da-DK', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const convertDateToTimeAndDate = (date: Date | undefined): string | undefined => {
  if (!date) return '';
  return `Kl. ${formatDateHourString(date.toString())} - d. ${formatDate(date)}`;
};

export const getFullDateString = (date: Date): string => {
  return `${date.toLocaleString('da-DK', { weekday: 'long' })} ${date.getDate()}. ${getMonthString(
    date.getMonth()
  )} ${date.getFullYear()}`;
};

const Months = [
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december'
];
export const DaysCapital = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
export const DaysLower = ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'];

export const getMonthString = (month: number): string => {
  return Months[month];
};

export const getDayString = (day: number): string => {
  return DaysCapital[day];
};

export const addBusinessAndHolidays = (date: Date, daysToAdd: number): Date => {
  const hd = new Holidays();
  hd.init('DK', { types: ['public'] });

  //Check next year in case SLA days goes into a new year
  let holidays = hd.getHolidays(new Date().getFullYear()).concat(hd.getHolidays(new Date().getFullYear() + 1));

  let newDate = addBusinessDays(date, daysToAdd);

  let holidaysNumber = 0;
  for (let i = date; i <= newDate; i.setDate(i.getDate() + 1)) {
    //If date is found in holidays and neither is a saturday or sunday, it will be added
    if (
      holidays.find(
        (h) =>
          h.start.getDate() === i.getDate() &&
          h.start.getMonth() === i.getMonth() &&
          h.start.getFullYear() === i.getFullYear()
      ) &&
      i.getDay() !== 0 &&
      i.getDay() !== 6
    ) {
      holidaysNumber++;
    }
  }

  //Add holidays as ekstra days
  if (holidaysNumber > 0) return addBusinessAndHolidays(newDate, holidaysNumber);

  return newDate;
  // business days: https://stackoverflow.com/questions/40739059/add-working-days-to-a-date-using-javascript
  // holidays: https://stackoverflow.com/questions/32342753/calculate-holidays-in-javascript
};

export type WeekDays = { start: Date; end: Date; week: number };

export const getCurrentWeek = (date: Date = new Date()): WeekDays => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });
  const week = getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 });
  return { start, end, week };
};

export const addWeeks = (date: Date, weeks: number) => {
  date.setDate(date.getDate() + 7 * weeks);
  return date;
};

export const lastDayOfWeek = (date: Date) => {
  const weekDay = date.getDay();
  date.setDate(date.getDate() + 7 - weekDay);
  return date;
};

export const dateStringsAreEqual = (first: string, second: string): boolean => {
  const firstAsDate = new Date(first);
  const secondAsDate = new Date(second);

  return firstAsDate.toString() === secondAsDate.toString();
};

export const sortDateTime = (a: string, b: string) => {
  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();
  return aTime > bTime ? 1 : aTime < bTime ? -1 : 0;
};
