import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ClipboardTextIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3501 2.67022L11.4695 3H11.8202H16.0002C16.8241 3 17.5002 3.67614 17.5002 4.5V18.5C17.5002 19.3239 16.8241 20 16.0002 20H2.00024C1.17639 20 0.500244 19.3239 0.500244 18.5V4.5C0.500244 3.67614 1.17639 3 2.00024 3H6.18024H6.53097L6.65038 2.67022C7.00299 1.69634 7.92305 1 9.00024 1C10.0774 1 10.9975 1.69634 11.3501 2.67022ZM10.5002 3.5C10.5002 2.67386 9.82639 2 9.00024 2C8.1741 2 7.50024 2.67386 7.50024 3.5C7.50024 4.32614 8.1741 5 9.00024 5C9.82639 5 10.5002 4.32614 10.5002 3.5ZM5.00024 17H10.0002C10.8264 17 11.5002 16.3261 11.5002 15.5C11.5002 14.6739 10.8264 14 10.0002 14H5.00024C4.1741 14 3.50024 14.6739 3.50024 15.5C3.50024 16.3261 4.1741 17 5.00024 17ZM5.00024 13H13.0002C13.8264 13 14.5002 12.3261 14.5002 11.5C14.5002 10.6739 13.8264 10 13.0002 10H5.00024C4.1741 10 3.50024 10.6739 3.50024 11.5C3.50024 12.3261 4.1741 13 5.00024 13ZM5.00024 9H13.0002C13.8264 9 14.5002 8.32614 14.5002 7.5C14.5002 6.67386 13.8264 6 13.0002 6H5.00024C4.1741 6 3.50024 6.67386 3.50024 7.5C3.50024 8.32614 4.1741 9 5.00024 9Z"
        fill="#343434"
        stroke="#343434"
      />
    </IconSvg>
  );
};
