import { IconProps } from './IconProps';

export const TargetIcon = (props: IconProps) => {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_10669_2184)">
        <g clip-path="url(#clip1_10669_2184)">
          <path
            d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 12.0002C10.2091 12.0002 12 10.2094 12 8.00024C12 5.79111 10.2091 4.00024 8 4.00024C5.79086 4.00024 4 5.79111 4 8.00024C4 10.2094 5.79086 12.0002 8 12.0002Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99984 9.33366C8.73622 9.33366 9.33317 8.73671 9.33317 8.00033C9.33317 7.26395 8.73622 6.66699 7.99984 6.66699C7.26346 6.66699 6.6665 7.26395 6.6665 8.00033C6.6665 8.73671 7.26346 9.33366 7.99984 9.33366Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10669_2184">
          <rect width="16" height="16" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
        <clipPath id="clip1_10669_2184">
          <rect width="16" height="16" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </svg>
  );
};
