import styled from 'styled-components';
import Typography from '../../../components/typography/Typography';
import { getDistanceInDaysFromToday, getFullDateString } from '../../../utils/dateHandling';
import { RegistrationsCollectionForDay } from '../TimeRegView';

interface Props {
  registrationsForDay: RegistrationsCollectionForDay;
  handleAddTime: () => void;
}

const TimeRegDayHeader = (props: Props) => {
  const { date, hoursTotal, registrationsNotApproved } = props.registrationsForDay;

  const currentDate = new Date(date);
  const daysFromToday = getDistanceInDaysFromToday(currentDate);

  const renderDayText = () => {
    if (daysFromToday === 0)
      return (
        <Typography>
          <b>{`I dag, `}</b>
          {getFullDateString(new Date(date))}
        </Typography>
      );
    if (daysFromToday === 1)
      return (
        <Typography>
          <b>{`I morgen, `}</b>
          {getFullDateString(new Date(date))}
        </Typography>
      );
    return <Typography>{getFullDateString(new Date(date))}</Typography>;
  };

  return (
    <StyledContainer data-testid="TimeRegDayHeader">
      <StyledDivForFields>
        <StyledCircleContainer>
          {registrationsNotApproved !== 0 && <StyledCircle>{registrationsNotApproved}</StyledCircle>}
        </StyledCircleContainer>
        {renderDayText()}
      </StyledDivForFields>
      <Typography> {`${hoursTotal} timer`} </Typography>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  background-color: ${(props) => props.theme.palette.grey.black5};
`;

const StyledDivForFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledCircleContainer = styled.div`
  width: 15px;
  margin-right: 12px;
`;

const StyledCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: #d32f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 0;
  color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 3px;
`;
export default TimeRegDayHeader;
