import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InternalTaskDTO, WorkTaskStatus } from '../../../api/api';
import { TechnicianIcon } from '../../../assets/icons/TechnicianIcon';
import Table from '../../../blocks/table/TableClient';
import TableMenuBar from '../../../blocks/table/table-menu-bar/TableMenuBar';
import Button from '../../../components/button/Button';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import InternalTaskService from '../../../services/InternalTaskService';
import NotificationService from '../../../services/NotificationService';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { setShouldUpdateNumberOfInternalTasksCreated } from '../../../stateManagement/reducers/taskListReducer';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import InternalTaskChangeStatusMenu from './internal-task-list-tools/InternalTaskChangeStatusMenu';
import { useInternalTaskList } from './useInternalTaskList';

const InternalTaskList = () => {
  const [tasks, setTasks] = useState<InternalTaskDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [showBookButton, setShowBookButton] = useState(false);
  const dispatch = useDispatch();

  const fetchTasks = useCallback(() => {
    setLoading(true);
    InternalTaskService.getInternalTasks()
      .then((res) => setTasks(res))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const { getConfirmation } = useConfirmationDialog();

  const { tableInstance } = useInternalTaskList(tasks);

  const { toggleAllRowsSelected, selectedFlatRows } = tableInstance;

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      setShowBookButton(
        selectedFlatRows.every(
          (row) =>
            row.original.status === getEnumDisplayValue(WorkTaskStatus.Created) ||
            row.original.status === getEnumDisplayValue(WorkTaskStatus.Pause)
        )
      );
    }
  }, [selectedFlatRows]);

  const handleBookTechnician = useCallback(async () => {
    const body: number[] = selectedFlatRows.map((row) => row.original.id ?? -1);
    const dialogBody: DialogBody = {
      headerText: body.length > 1 ? `Vil du sende ${body.length} opgaver til FLS?` : `Vil du sende opgaven til FLS?`,
      bodyText: '',
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft'
    };

    const confirmation = await getConfirmation(dialogBody);

    if (confirmation === 'confirm') {
      setLoading(true);
      InternalTaskService.sendInternalTasksToPlanning(body)
        .then(() => {
          fetchTasks();
          dispatch(setShouldUpdateNumberOfInternalTasksCreated(true));
          NotificationService.success(
            body.length > 1 ? `Sendte ${body.length} opgaver til FLS.` : 'Opgaven er sendt til FLS'
          );
        })
        .catch((error) => {
          NotificationService.error(`Der opstod en fejl i afsendelsen af opgaver: ${error}`);
          setLoading(false);
        });
    }
  }, [dispatch, fetchTasks, getConfirmation, selectedFlatRows]);

  const handleClose = useCallback(() => {
    toggleAllRowsSelected(false);
  }, [toggleAllRowsSelected]);

  const getExcelExportFileName = useCallback(() => {
    return `WORK opgaveliste interne - ${new Date().toLocaleDateString('da-DK', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}.csv`;
  }, []);

  return (
    <Table
      loading={loading}
      tableInstance={tableInstance}
      multipleTypeName="interne opgaver"
      showPagination
      showCount
      showExcelExport
      excelExportFileNameFunction={getExcelExportFileName}
      tableMenuBar={
        <TableMenuBar
          selectedItems={tableInstance.selectedFlatRows.map((x) => ({ id: x.id }))}
          rowNameSingle="opgave"
          rowNameMultiple="opgaver"
          onClose={handleClose}
        >
          <InternalTaskChangeStatusMenu
            fetchTasksCallback={fetchTasks}
            selectedRows={tableInstance.selectedFlatRows.map((r) => ({ id: r.original.id ?? -1, hasSubTasks: false }))}
            setIsLoading={(isLoading: boolean) => setLoading(isLoading)}
          />
          {showBookButton && (
            <Button onClick={handleBookTechnician} variant="primary" square>
              <TechnicianIcon size="18px" />
              Send til FLS
            </Button>
          )}
        </TableMenuBar>
      }
    />
  );
};

export default InternalTaskList;
