import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const VehicleIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        d="M11.5225 21.102C11.5225 22.8027 10.1451 24.1798 8.44816 24.1798C6.75116 24.1798 5.37383 22.8027 5.37383 21.102C5.37383 19.4013 6.75116 18.0242 8.44816 18.0242C10.1451 18.0242 11.5225 19.4013 11.5225 21.102Z"
        strokeWidth="2.4"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.081 22.9808C22.1143 22.9808 22.9553 22.1419 22.9553 21.103C22.9553 20.0641 22.1143 19.2252 21.081 19.2252C20.0476 19.2252 19.2066 20.0641 19.2066 21.103C19.2066 22.1419 20.0476 22.9808 21.081 22.9808ZM21.081 25.3808C23.4416 25.3808 25.3553 23.4655 25.3553 21.103C25.3553 18.7404 23.4416 16.8252 21.081 16.8252C18.7203 16.8252 16.8066 18.7404 16.8066 21.103C16.8066 23.4655 18.7203 25.3808 21.081 25.3808Z"
      />
      <rect stroke="none" x="5.89453" y="12.3809" width="11.9903" height="2.4" rx="1.2" />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.450445 5.16627C0.468706 4.51692 1.00037 4 1.64997 4H21.7274C22.2123 4 22.6496 4.29187 22.8356 4.73972L25.462 11.0633L29.5313 14.1781C29.828 14.4052 30.0019 14.7575 30.0019 15.131V21.2C30.0019 21.8627 29.4647 22.4 28.8019 22.4H25.1551C25.2851 21.9908 25.3552 21.555 25.3552 21.1028C25.3552 20.7215 25.3054 20.3518 25.2118 20H27.6019V15.7237L23.7478 12.7736C23.5805 12.6455 23.4498 12.4756 23.369 12.281L20.9264 6.4H2.8167L2.43422 20H4.31728C4.22392 20.3515 4.17416 20.7209 4.17416 21.1018C4.17416 21.5544 4.24439 21.9906 4.37454 22.4H1.2C0.875891 22.4 0.565564 22.2689 0.339628 22.0365C0.113693 21.8041 -0.00863643 21.4902 0.000474853 21.1663L0.450445 5.16627ZM16.9499 20H12.5797C12.6731 20.3515 12.7228 20.7209 12.7228 21.1018C12.7228 21.5544 12.6526 21.9906 12.5224 22.4H17.0066C16.8767 21.9908 16.8065 21.555 16.8065 21.1028C16.8065 20.7215 16.8564 20.3518 16.9499 20Z"
      />
    </IconSvg>
  );
};
