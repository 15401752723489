import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const FileIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 5.437 29.59 C 4.851 29.59 4.337 29.37 3.897 28.93 C 3.457 28.49 3.237 27.976 3.237 27.389 L 3.237 2.45 C 3.237 1.864 3.457 1.35 3.897 0.91 C 4.337 0.47 4.851 0.25 5.437 0.25 L 18.677 0.25 L 26.709 8.282 L 26.709 27.389 C 26.709 27.976 26.489 28.49 26.049 28.93 C 25.609 29.37 25.095 29.59 24.508 29.59 L 5.437 29.59 Z M 5.437 27.389 L 24.508 27.389 C 24.508 27.389 24.508 27.389 24.508 27.389 C 24.508 27.389 24.508 27.389 24.508 27.389 L 24.508 9.272 L 17.577 9.272 L 17.577 2.45 L 5.437 2.45 C 5.437 2.45 5.437 2.45 5.437 2.45 C 5.437 2.45 5.437 2.45 5.437 2.45 L 5.437 27.389 C 5.437 27.389 5.437 27.389 5.437 27.389 C 5.437 27.389 5.437 27.389 5.437 27.389 Z M 5.437 27.389 C 5.437 27.389 5.437 27.389 5.437 27.389 C 5.437 27.389 5.437 27.389 5.437 27.389 L 5.437 9.272 L 5.437 2.45 C 5.437 2.45 5.437 2.45 5.437 2.45 C 5.437 2.45 5.437 2.45 5.437 2.45 L 5.437 27.389 C 5.437 27.389 5.437 27.389 5.437 27.389 C 5.437 27.389 5.437 27.389 5.437 27.389 Z"
      ></path>
    </IconSvg>
  );
};
