import { useMemo } from 'react';
import useTableInstance from '../../../../../../hooks/useTableInstance';
import { CellProps, Column } from 'react-table';
import { CheckmarkIcon } from '../../../../../../assets/icons/CheckmarkIcon';
import { ComponentType, ExtendedComponent } from '../MeterInstallation/MeterComponents';

const useComponentTableInstance = (components: ExtendedComponent[], type: ComponentType) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Producent',
        accessor: 'vendor',
        disableFilters: true,
        minWidth: 100
      },
      {
        Header: 'Type',
        accessor: type === ComponentType.COMPONENT ? 'componentType' : 'equipmentType',
        disableFilters: true,
        minWidth: 100
      },
      {
        Header: 'Modelbetegnelse',
        accessor: 'description',
        disableFilters: true,
        minWidth: 100
      },
      {
        Header: '',
        accessor: 'selected',
        disableFilters: true,
        Cell: (cellProps: CellProps<ExtendedComponent>) => {
          const { cell } = cellProps;
          return cell.row.original?.selected ? <CheckmarkIcon color="green" size="20px" /> : null;
        }
      }
    ] as Column<object>[];
  }, []);

  const tableInstance = useTableInstance<object>(components, columns);

  return { tableInstance };
};

export default useComponentTableInstance;
