import styled from 'styled-components';

interface Props {
  text: string;
  onClick?: () => void;
}

const Tile = (props: Props) => {
  const { text, onClick } = props;

  return (
    <Container onClick={onClick}>
      <h4>{text}</h4>
    </Container>
  );
};

const Container = styled.div`
  svg {
    width: 25px;
    height: 25px;
  }

  display: flex;
  align-items: center;
  background: ${(props) => props.theme.palette.grey.black5};
  padding: 12px;
  column-gap: 12px;
  border-radius: 8px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);

  cursor: pointer;
`;

export default Tile;
