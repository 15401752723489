import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ChevronRight = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-right-icon">
      <polyline
        fill="none"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="1.992 8.178 15.555 21.739 29.113 8.178"
        transform="matrix(0, -1, 1, 0, 0.594, 30.511001)"
      ></polyline>
    </IconSvg>
  );
};
