import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CommentIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 20 20" data-testid="clock-icon" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1526 19.1467C18.748 19.2791 19.2791 18.7479 19.1468 18.1525L18.2322 14.0365C18.8436 12.7919 19.1667 11.4184 19.1667 9.99992C19.1667 4.93731 15.0626 0.833252 10 0.833252C4.93739 0.833252 0.833336 4.93731 0.833336 9.99992C0.833336 15.0625 4.93739 19.1666 10 19.1666C11.4185 19.1666 12.792 18.8435 14.0366 18.2321L18.1526 19.1467ZM16.6186 13.531C16.5247 13.7067 16.4968 13.9102 16.54 14.1047L17.2358 17.2357L14.1048 16.5399C13.9103 16.4967 13.7068 16.5246 13.5311 16.6185C12.4541 17.1945 11.25 17.4999 10 17.4999C5.85787 17.4999 2.5 14.1421 2.5 9.99992C2.5 5.85778 5.85787 2.49992 10 2.49992C14.1421 2.49992 17.5 5.85778 17.5 9.99992C17.5 11.2499 17.1945 12.454 16.6186 13.531Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 11.6666C8.42048 11.6666 9.16667 10.9204 9.16667 9.99992C9.16667 9.07944 8.42048 8.33325 7.5 8.33325C6.57953 8.33325 5.83334 9.07944 5.83334 9.99992C5.83334 10.9204 6.57953 11.6666 7.5 11.6666Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 11.6666C13.4205 11.6666 14.1667 10.9204 14.1667 9.99992C14.1667 9.07944 13.4205 8.33325 12.5 8.33325C11.5795 8.33325 10.8333 9.07944 10.8333 9.99992C10.8333 10.9204 11.5795 11.6666 12.5 11.6666Z"
      />
    </IconSvg>
  );
};
