import { UpdateSectionDTO } from '../api/api';
import WorkClient from '../api/workClient';

const getMeterBlueprint = (meterTaskId: number) => {
  return WorkClient.getMeterBlueprint(meterTaskId);
};

const updateSections = (meterTaskId: number, body: UpdateSectionDTO[]) => {
  return WorkClient.updateSections(meterTaskId, body);
};

const MeterBlueprintService = {
 getMeterBlueprint,
 updateSections
};

export default MeterBlueprintService;
