import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { TaskTypeBaseDTO } from '../../api/api';
import InternalTimeForm from '../../blocks/internal-time-form/InternalTimeForm';
import InternalTimePicker from '../../blocks/internal-time-picker/InternalTimePicker';
import InternalTaskList from './internal-task-list/InternalTaskList';

const InternalTimeOfficeView = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [internalTaskType, setInternalTaskType] = useState<TaskTypeBaseDTO>();

  const handleOpenDialog = useCallback((internalTaskType: TaskTypeBaseDTO) => {
    setInternalTaskType(internalTaskType);
    setDialogOpen(true);
  }, []);

  return (
    <Container>
      <InternalTimePicker onClick={handleOpenDialog} />
      <InternalTaskList />

      {dialogOpen && internalTaskType && (
        <InternalTimeForm onClose={() => setDialogOpen(false)} internalTaskType={internalTaskType} />
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding-left: 18px;
    padding-right: 18px;
  }
`;
export default InternalTimeOfficeView;
