const LogoIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 477 370"
      xmlSpace="preserve"
    >
      <path
        className="st0"
        d="M370.7,345.4h-2.1c-58.5,0-106-47.6-106-106v-68.1c0-48.8-39.7-88.4-88.4-88.4c-50.3,0-91.2,40.9-91.2,91.2
    v123.4c0,22.9-18.5,41.4-41.4,41.4S0,320.3,0,297.5V174.1C0,78.1,78.1,0,174.1,0c94.5,0,171.3,76.8,171.3,171.3v68.1
    c0,12.8,10.4,23.1,23.1,23.1h2.1c12.8,0,23.1-10.4,23.1-23.1V52.2c0-22.9,18.5-41.4,41.4-41.4s41.4,18.5,41.4,41.4v187.2
    C476.7,297.8,429.1,345.4,370.7,345.4z"
        fill="#000200"
      />
      <path
        className="st1"
        d="M435.2,71.1L435.2,71.1c-10.1,0-18.3-8.3-18.3-18.3v0c0-10.1,8.3-18.3,18.3-18.3h0c10.1,0,18.3,8.3,18.3,18.3v0
    C453.6,62.8,445.3,71.1,435.2,71.1z"
        fill="#F4D300"
      />
    </svg>
  );
};

export default LogoIcon;
