import { useState } from 'react';
import styled from 'styled-components';
import { AccessInfoDTO } from '../../api/api';
import { TabProps } from '../tabs-vertical/TabsVertical';
import TextField from '../../components/text-field/TextField';
import { log } from '../../utils/logging/log';
import NotificationService from '../../services/NotificationService';
import Button from '../../components/button/Button';
import DialogView from '../../components/dialog-view/DialogView';
import Alert from '../../components/alert/Alert';
import MetertaskService from '../../services/MeterTasksService';

interface Props extends TabProps {
  id: string;
  onClose: () => void;
  accessInfo?: AccessInfoDTO;
  setAccessInfo?: (info: AccessInfoDTO) => void;
}

const AccessConditionsMetertaskDialog = (props: Props) => {
  const { id, onClose, setAccessInfo, accessInfo } = props;

  const [accessConditionsFormBodySmile, setAccessConditionsFormBodySmile] = useState<AccessInfoDTO>(accessInfo ?? {});
  const [updatingAccessConditions, setUpdatingAccessConditions] = useState(false);

  const handleSaveAccessConditionsSmile = () => {
    if (!id) return;
    setUpdatingAccessConditions(true);
    MetertaskService.updateAccessInformation(parseInt(id), accessConditionsFormBodySmile)
      .then(() => {
        setAccessInfo && setAccessInfo(accessConditionsFormBodySmile);
        onClose();
        NotificationService.success('Opdaterede adgangsforhold', 5000);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke opdatere adgangsforhold.', 5000);
      })
      .finally(() => setUpdatingAccessConditions(false));
  };

  return (
    <DialogView>
      <AccessConditionsDialogContainer>
        <TextField
          label={'Kontakt person'}
          value={accessConditionsFormBodySmile.contactPerson ?? '-'}
          onChange={(e) =>
            setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, contactPerson: e.target.value })
          }
        />
        <TextField
          label={'Dørkode'}
          value={accessConditionsFormBodySmile.doorCode ?? ''}
          onChange={(e) => setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, doorCode: e.target.value })}
        />
        <TextField
          label={'Dørkode kommentar'}
          value={accessConditionsFormBodySmile.doorCodeComment ?? '-'}
          onChange={(e) =>
            setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, doorCodeComment: e.target.value })
          }
        />
        <TextField
          label={'Nøgle nummer'}
          value={accessConditionsFormBodySmile.keyNumber ?? '-'}
          onChange={(e) => setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, keyNumber: e.target.value })}
        />
        <TextField
          label={'Nøgle kommentar'}
          value={accessConditionsFormBodySmile.keyComment ?? '-'}
          onChange={(e) =>
            setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, keyComment: e.target.value })
          }
        />
        <TextField
          label={'Nøgle placering'}
          value={accessConditionsFormBodySmile.keyPlacement ?? '-'}
          onChange={(e) =>
            setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, keyPlacement: e.target.value })
          }
        />
        <TextField
          label={'Nøgle opbevaringsplacering'}
          value={accessConditionsFormBodySmile.keyStorageLocation ?? '-'}
          onChange={(e) =>
            setAccessConditionsFormBodySmile({ ...accessConditionsFormBodySmile, keyStorageLocation: e.target.value })
          }
        />
        <Alert severity="warning" height="42px">
          {'Bemærk: Ændringerne gemmes direkte i SMILE.'}
        </Alert>
        <AccessConditionsDialogButtonDiv>
          <Button variant="secondary" onClick={() => onClose()}>
            Annuller
          </Button>
          <Button onClick={handleSaveAccessConditionsSmile} isLoading={updatingAccessConditions}>
            Gem
          </Button>
        </AccessConditionsDialogButtonDiv>
      </AccessConditionsDialogContainer>
    </DialogView>
  );
};

const AccessConditionsDialogContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  row-gap: 24px;
`;

const AccessConditionsDialogButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AccessConditionsMetertaskDialog;
