import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const AddInternalTaskIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="150 100 400 300">
      <path d="m506.8 313.6h-280c-8.0039 0-15.398 4.2695-19.398 11.199-4.0039 6.9297-4.0039 15.469 0 22.398 4 6.9297 11.395 11.199 19.398 11.199h280c8.0039 0 15.398-4.2695 19.398-11.199 4-6.9297 4-15.469 0-22.398-4-6.9297-11.395-11.199-19.398-11.199z" />
      <path d="m394.8 168h112c8.0039 0 15.398-4.2695 19.398-11.199 4-6.9297 4-15.469 0-22.402-4-6.9297-11.395-11.199-19.398-11.199h-112c-8.0039 0-15.398 4.2695-19.398 11.199-4.0039 6.9336-4.0039 15.473 0 22.402 4 6.9297 11.395 11.199 19.398 11.199z" />
      <path d="m506.8 218.4h-112c-8.0039 0-15.398 4.2695-19.398 11.203-4.0039 6.9297-4.0039 15.469 0 22.398 4 6.9297 11.395 11.199 19.398 11.199h112c8.0039 0 15.398-4.2695 19.398-11.199s4-15.469 0-22.398c-4-6.9336-11.395-11.203-19.398-11.203z" />
      <path d="m226.8 240.8c0 8.0039 4.2695 15.398 11.199 19.398s15.469 4 22.398 0c6.9336-4 11.203-11.395 11.203-19.398v-33.602h33.602-0.003906c8.0039 0 15.398-4.2695 19.398-11.199 4.0039-6.9297 4.0039-15.469 0-22.398-4-6.9336-11.395-11.203-19.398-11.203h-33.598v-33.598c0-8.0039-4.2695-15.398-11.203-19.398-6.9297-4.0039-15.469-4.0039-22.398 0-6.9297 4-11.199 11.395-11.199 19.398v33.602l-33.602-0.003906c-8.0039 0-15.398 4.2695-19.398 11.203-4 6.9297-4 15.469 0 22.398s11.395 11.199 19.398 11.199h33.602z" />
    </IconSvg>
  );
};
