import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const BoxIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 32 36" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.72 8.05882L17.1815 0.318402C16.8198 0.109551 16.4133 0 16 0C15.5867 0 15.1802 0.109551 14.8185 0.318402L1.28 8.06204C0.893367 8.28309 0.570621 8.60855 0.345464 9.00443C0.120308 9.40032 0.0010004 9.85211 0 10.3126V25.6874C0.0010004 26.1479 0.120308 26.5997 0.345464 26.9956C0.570621 27.3915 0.893367 27.7169 1.28 27.938L14.8185 35.6816C15.1802 35.8904 15.5867 36 16 36C16.4133 36 16.8198 35.8904 17.1815 35.6816L30.72 27.938C31.1066 27.7169 31.4294 27.3915 31.6545 26.9956C31.8797 26.5997 31.999 26.1479 32 25.6874V10.3142C31.9998 9.8529 31.8809 9.4001 31.6557 9.00329C31.4305 8.60648 31.1073 8.28026 30.72 8.05882ZM16 2.56899L28.36 9.64227L23.78 12.2626L11.4185 5.18932L16 2.56899ZM16 16.7156L3.64 9.64227L8.85538 6.65863L21.2154 13.7319L16 16.7156ZM2.46154 11.8929L14.7692 18.9308V32.7221L2.46154 25.689V11.8929ZM29.5385 25.6825L17.2308 32.7221V18.9372L22.1538 16.1224V21.8598C22.1538 22.2008 22.2835 22.528 22.5143 22.7691C22.7451 23.0103 23.0582 23.1458 23.3846 23.1458C23.711 23.1458 24.0241 23.0103 24.2549 22.7691C24.4857 22.528 24.6154 22.2008 24.6154 21.8598V14.7141L29.5385 11.8929V25.6809V25.6825Z"
        fill="black"
      />
    </IconSvg>
  );
};
