import { useEffect, useState } from 'react';
import {
  MeterBlueprintSectionDTO,
  MeterTemplateSectionTypeEnum,
  MeterWorkTaskDTO,
  UpdateSectionDTO
} from '../../../../../../api/api';
import { TabProps } from '../../../../../../blocks/tabs-vertical/TabsVertical';
import MeterBlueprintService from '../../../../../../services/MeterBlueprintService';
import Button from '../../../../../../components/button/Button';
import GenericSection from './GenericSection';
import CircularProgress from '../../../../../../components/circular-progress/CircularProgress';
import styled from 'styled-components';
import NotificationService from '../../../../../../services/NotificationService';
import { log } from '../../../../../../utils/logging/log';

interface Props extends TabProps {
  meterTask: MeterWorkTaskDTO;
}
const MeterBlueprint = (props: Props) => {
  const { meterTask } = props;
  const [sections, setSections] = useState<MeterBlueprintSectionDTO[]>([]);
  const [sectionUpdates, setSectionUpdates] = useState<UpdateSectionDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!meterTask || !meterTask.id) {
          throw new Error();
        }
        const res = await MeterBlueprintService.getMeterBlueprint(+meterTask.id);
        res.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        setSections(res);
      } catch (error) {
        NotificationService.error('Datafanen kunne ikke hentes');
        log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [meterTask]);

  const updateSections = async () => {
    if (!sectionUpdates || !meterTask || !meterTask?.id) return;
    setIsLoading(true);
    const res = await MeterBlueprintService.updateSections(parseInt(meterTask.id), sectionUpdates);
    setIsLoading(false);
    setSections(res);
  };

  const handleGenericSectionChange = (update: UpdateSectionDTO) => {
    if (!update.generic) return;

    const getAllUpdatedFields = (updateSection: UpdateSectionDTO | undefined) => {
      if (!updateSection) {
        updateSection = { generic: { fields: [] } };
      }
      const fields = updateSection.generic?.fields ?? [];
      const newFields = update.generic?.fields ?? [];
      const newFieldIds = newFields.map((f) => f.meterTemplateFieldId);
      return fields
        .filter((f) => !newFieldIds.some((newFieldId) => newFieldId === f.meterTemplateFieldId))
        .concat(newFields);
    };

    setSectionUpdates((prev) => {
      const existingUpdateSection = prev.find((p) => p.generic);
      const allUpdatedFields = getAllUpdatedFields(existingUpdateSection);
      // replace or insert generic section with all updated fields
      prev = prev.filter((p) => !p.generic).concat([{ generic: { fields: allUpdatedFields } }]);
      return prev;
    });
  };

  return (
    <>
      {isLoading && <CenteredCircularProgress />}
      {sections?.map(
        (section, i) =>
          section.type === MeterTemplateSectionTypeEnum.GENERIC && (
            <GenericSection key={i} section={section} onChange={handleGenericSectionChange} />
          )
      )}

      <Button onClick={updateSections}>Gem</Button>
      <Code>{JSON.stringify(sectionUpdates, null, 2)}</Code>
    </>
  );
};

const CenteredCircularProgress = styled(CircularProgress)`
  position: absolute;
`;

const Code = styled.pre`
  background-color: #f5f5f5;
  background-color: ${(props) => props.theme.palette.background.secondary};
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Courier New', Courier, monospace;
`;

export default MeterBlueprint;
