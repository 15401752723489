import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const NavigateIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 16.902 29.551 L 12.299 17.725 L 0.473 13.122 L 0.473 11.386 L 29.535 0.489 L 18.636 29.551 L 16.902 29.551 Z M 17.627 24.949 L 25.378 4.646 L 5.115 12.396 L 14.155 15.869 L 17.627 24.949 Z M 14.155 15.869 Z"></path>
    </IconSvg>
  );
};
