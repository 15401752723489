import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ClockIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" data-testid="clock-icon" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 14.964 0.565 C 6.986 0.565 0.525 7.04 0.525 15.019 C 0.525 22.997 6.986 29.473 14.964 29.473 C 22.957 29.473 29.433 22.997 29.433 15.019 C 29.433 7.04 22.957 0.565 14.964 0.565 Z M 14.979 26.582 C 8.59 26.582 3.416 21.407 3.416 15.019 C 3.416 8.63 8.59 3.456 14.979 3.456 C 21.367 3.456 26.542 8.63 26.542 15.019 C 26.542 21.407 21.367 26.582 14.979 26.582 Z M 15.702 7.792 L 13.533 7.792 L 13.533 16.464 L 21.122 21.017 L 22.206 19.239 L 15.702 15.38 L 15.702 7.792 Z"
      ></path>
    </IconSvg>
  );
};
