import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const PenIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 18.376 10.134 L 19.865 11.623 L 5.197 26.292 L 3.707 26.292 L 3.707 24.802 L 18.376 10.134 M 24.205 0.388 C 23.8 0.388 23.379 0.55 23.072 0.858 L 20.109 3.82 L 26.179 9.891 L 29.142 6.928 C 29.774 6.297 29.774 5.277 29.142 4.645 L 25.354 0.858 C 25.03 0.533 24.625 0.388 24.205 0.388 Z M 18.376 5.552 L 0.47 23.459 L 0.47 29.53 L 6.541 29.53 L 24.448 11.623 L 18.376 5.552 Z"
      ></path>
    </IconSvg>
  );
};
