import QuestionaireSection from '../../../../../components/questionaire-section/QuestionaireSection';
import { useMemo } from 'react';
import { Question } from '../../../../../models/Question';
import { QuestionDTO, QuestionOptionDTO, TaskTypeSectionDTO } from '../../../../../api/api';
import { BackArrowIcon } from '../../../../../assets/icons/BackArrowIcon';
import Typography from '../../../../../components/typography/Typography';
import { Section } from '../../../../../models/Section';
import InformationBox from '../../../../../components/information-box/InformationBox';
import styled from 'styled-components';

interface Props {
  onBack: () => void;
  sections: TaskTypeSectionDTO[];
  questions: QuestionDTO[];
  title: string;
  isRepeatable?: boolean;
}

const Preview = (props: Props) => {
  const { onBack, questions, sections, title, isRepeatable } = props;

  const _sections = useMemo(() => {
    return sections.map((obj) => {
      return {
        header: obj.name,
        isRepeatable: obj.isRepeatable,
        sectionId: obj.sectionId,
        questions: obj.questions?.map((q: any) => {
          const options = questions?.find((option) => option.id === q.questionId)?.options ?? [];
          return {
            id: q.questionId,
            dataType: q.questionType,
            mandatory: q.isMandatory,
            sectionQuestionId: q.sectionQuestionId,
            text: q.text,
            options: options?.map((o: QuestionOptionDTO) => {
              return {
                displayValue: o.questionOptionText,
                value: o.id
              };
            })
          } as Question;
        })
      };
    }) as Section[];
  }, [sections, questions]);

  return (
    <>
      <StyledHeaderContainer>
        <Typography variant="h4" onClick={() => onBack()}>
          <BackArrowIcon size="22px" />
          Preview: {title}
        </Typography>
        <LeftRow>{isRepeatable && <InformationBox>Gentagbar</InformationBox>}</LeftRow>
      </StyledHeaderContainer>
      {_sections.map((section) => (
        <QuestionaireSection
          key={section.header + section.taskSectionId}
          header={section.header}
          questions={section.questions}
          checklistId={0}
          readOnly={false}
          isRepeatable={section.isRepeatable}
        />
      ))}
    </>
  );
};

const LeftRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export default Preview;
