import { useEffect, useState } from 'react';
import { EquipmentDTO, MeterWorkTaskDTO, RequiredReadingDTO, SmileComponentDTO } from '../../../../../../api/api';
import IconButton from '../../../../../../components/icon-button/IconButton';
import {
  ExtendedMeter,
  GreyContainer,
  Label,
  Row,
  ScanButton,
  SearchButton,
  Section,
  StyledTable,
  StyledTextField,
  Subsection,
  SubsectionHeader,
  TextColumnContainer,
  Value
} from './MeterInstallationStep';
import styled from 'styled-components';
import Button from '../../../../../../components/button/Button';
import { EyeIcon } from '../../../../../../assets/icons/EyeIcon';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import MetertaskService from '../../../../../../services/MeterTasksService';
import useComponentTableInstance from '../Components/useComponentTableInstance';
import NotificationService from '../../../../../../services/NotificationService';
import { log } from '../../../../../../utils/logging/log';
import StatusIndicator, { STATUS } from '../../../../../../components/status-indicator/StatusIndicator';
import { DeleteIcon } from '../../../../../../assets/icons/DeleteIcon';
import Typography from '../../../../../../components/typography/Typography';
import Alert from '../../../../../../components/alert/Alert';

interface Props {
  completedMeterInstallation?: {
    requiredReadings?: RequiredReadingDTO[];
    meter?: ExtendedMeter;
  };
  task?: MeterWorkTaskDTO;
  type: ComponentType;
}

export enum ComponentType {
  EQUIPMENT,
  COMPONENT
}

export interface ExtendedComponent extends SmileComponentDTO, EquipmentDTO {
  selected?: boolean;
}

const MeterComponents = (props: Props) => {
  const { task, type } = props;
  const [showCamera, setShowCamera] = useState(false);
  const [isScanButtonLoading, setIsScanButtonLoading] = useState(false);
  const [isSearchButtonLoading, setIsSearchButtonLoading] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [addingComponent, setAddingComponent] = useState(false);
  const [isMissingBarcode, setIsMissingBarcode] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const [components, setComponents] = useState<ExtendedComponent[]>([]);
  const [noDataText, setNoDataText] = useState('Indtast eller scan serienummer');
  const [continueWithoutVerification, setContinueWithoutVerification] = useState(false);
  const [installedComponents, setInstalledComponents] = useState<SmileComponentDTO[]>([]);
  const [componentsNotFound, setComponentsNotFound] = useState(false);
  const [componentNotSelected, setComponentNotSelected] = useState(false);
  const [isSerialNumbermissing, setIsSerialNumbermissing] = useState(false);

  useEffect(() => {
    if (type === ComponentType.COMPONENT) {
      if (!task?.meterIdToInstall) return;
      MetertaskService.getComponentsByMeterId(task.meterIdToInstall).then((response) => {
        setInstalledComponents(response);
      });
    } else {
      if (!task?.id) return;
      MetertaskService.getEquipmentByWorkOrderId(parseInt(task.id))
        .then((response) => {
          setInstalledComponents(response);
        })
        .finally(() => {
          setIsSearchButtonLoading(false);
        });
    }
  }, []);

  const search = async (serialNumber: string) => {
    if (!serialNumber) {
      setIsSerialNumbermissing(true);
      return;
    }

    setIsSerialNumbermissing(false);
    setComponentNotSelected(false);
    setIsSearchButtonLoading(true);

    const promise =
      type === ComponentType.COMPONENT
        ? MetertaskService.getComponentsBySerialNumber(serialNumber)
        : MetertaskService.getEquipmentBySerialNumber(serialNumber);

    return promise
      .then((response) => {
        if (response.length === 0) {
          setComponents([]);
          setNoDataText(`${type === ComponentType.COMPONENT ? 'Komponent' : 'Udstyr'} ikke fundet`);
          setComponentsNotFound(true);
        } else {
          setComponents(response);
          setNoDataText('');
          setComponentsNotFound(false);
        }
      })
      .finally(() => {
        setIsSearchButtonLoading(false);
      });
  };

  const isMissingBarcodeHandler = (value: boolean = false) => {
    setComponents([]);
    setIsMissingBarcode(value);
    setContinueWithoutVerification(false);
    setSerialNumber('');
    setNoDataText('Indtast eller scan serienummer');
  };

  const clearComponent = () => {
    setAddingComponent(false);
    isMissingBarcodeHandler();
  };

  const onCodeScannedHandler = (code: string) => {
    setIsScanButtonLoading(true);
    setShowCamera(false);
    setSerialNumber(code);
    search(code).finally(() => {
      setIsScanButtonLoading(false);
    });
  };

  const componentSelectedHandler = (_component: SmileComponentDTO) => {
    setComponents((prevComponents) =>
      prevComponents.map((component) => {
        if (component.id === _component.id) {
          if (component.selected) {
            return { ...component, selected: false };
          } else {
            setComponentNotSelected(false);
            return {
              ...component,
              selected: true
            };
          }
        } else {
          return {
            ...component,
            selected: false
          };
        }
      })
    );
  };

  const submit = () => {
    if (!task?.id) return;

    const selectedComponent = components.find((component) => component.selected);

    if (isMissingBarcode && !serialNumber) {
      setIsSerialNumbermissing(true);
      return;
    }

    if (!selectedComponent) {
      setComponentNotSelected(true);
      return;
    }

    const body = [...components, selectedComponent ?? { serialNumber }];

    setIsSubmitButtonLoading(true);
    setComponentNotSelected(false);
    const promise =
      type === ComponentType.COMPONENT
        ? MetertaskService.installComponent(parseInt(task.id), body)
        : MetertaskService.installEquipment(parseInt(task.id), body);

    promise
      .then(() => {
        if (!task.id) return;
        NotificationService.success(
          `Registrering af ${type === ComponentType.COMPONENT ? 'komponent' : 'udstyr'} blev gennemført!`
        );
        setInstalledComponents(body);
        setAddingComponent(false);
        setIsMissingBarcode(false);
        isMissingBarcodeHandler();
      })
      .catch((error) => {
        NotificationService.error('Registreringen fejlede.');
        log(error);
      })
      .finally(() => {
        setIsSubmitButtonLoading(false);
      });
  };

  const getComponentText = (component: SmileComponentDTO | EquipmentDTO) => {
    if (type === ComponentType.COMPONENT) {
      const { vendor, componentType, description } = component as SmileComponentDTO;
      if (vendor && componentType && description) {
        return `${vendor} - ${componentType} - ${description}`;
      } else {
        return `Uverificeret udstyr med serienummer: ${(component as SmileComponentDTO).serialNumber}`;
      }
    } else {
      const { vendor, equipmentNumber, equipmentType } = component as EquipmentDTO;
      if (vendor && equipmentNumber && equipmentType) {
        return `${vendor} - ${equipmentType} - ${equipmentNumber}`;
      } else {
        return `Uverificeret udstyr med id: ${(component as EquipmentDTO).id}`;
      }
    }
  };

  const { tableInstance } = useComponentTableInstance(components, type);

  return (
    <>
      {showCamera ? (
        <>Not implemented</>
      ) : (
        <Section>
          <SubsectionHeader>{type === ComponentType.COMPONENT ? 'Komponenter' : 'Udstyr'}</SubsectionHeader>

          {addingComponent ? (
            <GreyContainer>
              <Row>
                <GreyContainerTopContainer>
                  <SubsectionHeader>
                    {type === ComponentType.COMPONENT ? 'Tilføj komponenter' : 'Tilføj udstyr'}
                  </SubsectionHeader>
                  <IconButton onClick={clearComponent}>
                    <DeleteIcon size="18px" />
                  </IconButton>
                </GreyContainerTopContainer>
              </Row>
              <Row>
                {serialNumber && !isMissingBarcode && (
                  <TextColumnContainer>
                    <Label>Serienummer</Label>
                    <Value>{serialNumber}</Value>
                  </TextColumnContainer>
                )}
                <ScanButton
                  isLoading={isScanButtonLoading}
                  onClick={() => setShowCamera(true)}
                  disabled={isMissingBarcode}
                  variant="secondary"
                >
                  <EyeIcon />
                  Scan {type === ComponentType.COMPONENT ? 'komponent' : 'udstyr'}
                </ScanButton>
                <Checkbox
                  checked={isMissingBarcode}
                  onChange={(event) => isMissingBarcodeHandler(event.target.checked)}
                  label="Kan ikke scanne stregkode"
                />
              </Row>
              {isMissingBarcode && (
                <Row>
                  <StyledTextField
                    error={isSerialNumbermissing}
                    label="Indtast serienummer"
                    disabled={continueWithoutVerification}
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                    fullWidth
                  ></StyledTextField>
                  <SearchButton
                    isLoading={isSearchButtonLoading}
                    onClick={() => search(serialNumber)}
                    disabled={!serialNumber || continueWithoutVerification}
                    variant="secondary"
                  >
                    <EyeIcon />
                    Søg
                  </SearchButton>
                </Row>
              )}
              <Row>
                <SubsectionHeader hasError={componentNotSelected}>
                  Vælg korrekt {type === ComponentType.COMPONENT ? 'komponent' : 'udstyr'}
                </SubsectionHeader>
              </Row>
              <Row>
                <StyledTable
                  noPadding
                  loading={isSearchButtonLoading}
                  tableInstance={tableInstance}
                  noDataText={noDataText}
                  onClickRow={(data) => componentSelectedHandler(data)}
                />
              </Row>
              {componentsNotFound && (
                <Row>
                  <Alert margin="0px 10px" severity="warning" height="42px">
                    Komponenten kunne ikke findes. Er komponenten installeret, bør du i stedet beskrive den i “Bemærkning”
                    nederst på siden.
                  </Alert>
                </Row>
              )}
              <Row>
                <StyledAddButton onClick={submit} isLoading={isSubmitButtonLoading}>
                  Registrer {type === ComponentType.COMPONENT ? 'komponent' : 'udstyr'}
                </StyledAddButton>
              </Row>
            </GreyContainer>
          ) : (
            <>
              {installedComponents.map((component, i) => (
                <Subsection>
                  <Row>
                    <StatusIndicator status={STATUS.GREEN} />
                    <Typography variant="b">{getComponentText(component)}</Typography>
                  </Row>
                </Subsection>
              ))}
              <StyledAddButton onClick={() => setAddingComponent(true)} variant="secondary">
                Tilføj {type === ComponentType.COMPONENT ? 'komponent' : 'udstyr'}
              </StyledAddButton>
            </>
          )}
        </Section>
      )}
    </>
  );
};

const StyledAddButton = styled(Button)`
  margin: 15px 0px;
`;

const GreyContainerTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default MeterComponents;
