import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ArrowDownRightIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="m12.707 7.293-1.414 1.414L15.586 13H7V4H5v11h10.586l-4.293 4.293 1.414 1.414L19.414 14l-6.707-6.707z" />
    </IconSvg>
  );
};
