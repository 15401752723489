import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const FilterIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.41422 20.4143L3.82843 21.8285L21.8284 3.82852L20.4142 2.41431L2.41422 20.4143Z" fill="black" />
      <path d="M3 3H18.4142L16.4142 5H5V6.58579L9.91421 11.5L8.5 12.9142L3 7.41421V3Z" fill="black" />
      <path
        d="M11 19.382V16.0711L9 18.0711V22.618L15 19.618V13.4142L21 7.41421V6.07107L13 14.0711V18.382L11 19.382Z"
        fill="black"
      />
    </IconSvg>
  );
};
