import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const LockIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 11.024H5C3.89543 11.024 3 11.9195 3 13.024V20.024C3 21.1286 3.89543 22.024 5 22.024H19C20.1046 22.024 21 21.1286 21 20.024V13.024C21 11.9195 20.1046 11.024 19 11.024Z"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 11.024V7.02405C7 5.69797 7.52678 4.4262 8.46447 3.48851C9.40215 2.55083 10.6739 2.02405 12 2.02405C13.3261 2.02405 14.5979 2.55083 15.5355 3.48851C16.4732 4.4262 17 5.69797 17 7.02405V11.024"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </IconSvg>
  );
};
