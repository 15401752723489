import { useMemo } from 'react';
import styled from 'styled-components';

import Table from '../../../blocks/table/TableClient';
import Typography from '../../../components/typography/Typography';
import { SelectedGoods } from '../../../models/Goods';
import { useSelectedGoodsList } from '../hooks/useSelectedGoodsList';
import { StyledTextField } from './Styles';
import { WarehouseDTO } from '../../../api/api';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import Button from '../../../components/button/Button';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';

const clearGoodsDialog: DialogBody = {
  headerText: 'Vil du fjerne alle varer i din kurv?',
  bodyText: 'Du er ved at fjerne alle varer fra din kurv. Er du sikker på, at du vil fortsætte?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

interface Props {
  selectedGoods: SelectedGoods[];
  setGoodsAmount: (value: number, goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  removeGoods?: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  className?: string;
  showAvailable?: boolean;
  showGoodsIcon?: boolean;
  showWarehouse?: boolean;
  selectedWarehouse?: WarehouseDTO;
  clearSelectedGoods?: () => void;
  renderFullHeight?: boolean;
}

const SelectedGoodsList = (props: Props) => {
  const {
    selectedGoods,
    className,
    removeGoods,
    setGoodsAmount,
    showAvailable = true,
    showGoodsIcon = true,
    showWarehouse = false,
    selectedWarehouse,
    clearSelectedGoods,
    renderFullHeight = false
  } = props;

  const { tableInstance } = useSelectedGoodsList(selectedGoods, setGoodsAmount, removeGoods, showAvailable);
  const { setGlobalFilter } = tableInstance;
  const { getConfirmation } = useConfirmationDialog();

  const handleClearSelectedGoods = () => {
    getConfirmation(clearGoodsDialog).then((res) => {
      if (res === 'confirm') {
        clearSelectedGoods && clearSelectedGoods();
      }
    });
  };

  const selectedCount = useMemo(() => {
    return selectedGoods.reduce((acc, goods) => acc + (goods?.qty ?? 1), 0);
  }, [selectedGoods]);

  return (
    <>
      {showGoodsIcon ? (
        <Header>
          <Typography variant="h5" fontWeight="bold">
            Valgte varer
          </Typography>
          {showWarehouse && selectedWarehouse && (
            <Typography variant="h5" fontWeight="bold">
              Bestilles til: ({selectedWarehouse.warehouseId}) {selectedWarehouse.name}
            </Typography>
          )}
          {clearSelectedGoods && (
            <Button variant="secondary" onClick={handleClearSelectedGoods} disabled={selectedCount === 0}>
              <DeleteIcon size="16px" />
              Ryd kurv {selectedCount > 0 ? `(${selectedCount})` : ''}
            </Button>
          )}
        </Header>
      ) : (
        <Header>
          <StyledTextField compact onChange={(e) => setGlobalFilter(e.target.value)} label="Søg blandt varer" />
        </Header>
      )}
      <StyledTable
        tableInstance={tableInstance}
        loading={false}
        alwaysShowSort
        noDataText="Ingen valgte varer. Vælg fra listen over mulige varer."
        className={className}
        renderFullHeight={renderFullHeight}
      />
    </>
  );
};

const StyledTable = styled(Table)`
  &.table-outer-container {
    margin: 0;
    padding: 0;
  }
  .table-content-container {
    margin: 0;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export default SelectedGoodsList;
