import { useMatch, useNavigate as useNavigateReact } from 'react-router';
import styled from 'styled-components';

import { ClockIcon } from '../../../assets/icons/ClockIcon';
import { ListIcon } from '../../../assets/icons/ListIcon';
import {
  ADMIN_QUESTIONS,
  ADMIN_REASONCODES,
  ADMIN_REUSABLECOMPONENTS,
  ADMIN_TASKTYPES,
  GOODS_ROUTE,
  INTERNAL_TIME_OFFICE,
  INTERNAL_TIME_TECHNICIAN,
  PORTAL_ROUTE,
  PROFILE_ROUTE,
  TASKS_ROUTE,
  TIME_ROUTE,
  CALENDAR_ROUTE,
  WORKPLAN_ROUTE,
  TASKS_DETAILS,
  MAP_TASK_LIST_ROUTE,
  ORDER_GOODS_ROUTE,
  COUNT_GOODS_ROUTE,
  MOVE_GOODS_ROUTE,
  ADMIN_PORTAL_LINKS,
  TECHNICIAN_TASKLIST_ROUTE,
  ADMIN_NEWS,
  NEWS_ROUTE,
  RETURN_GOODS_ROUTE,
  RECEIPT_ROUTE
} from '../../../utils/constants';
import { Badge } from '@mui/material';
import { VehicleIcon } from '../../../assets/icons/VehicleIcon';
import { UserProfileState } from '../../../stateManagement/reducers/userProfileReducer';
import { memo, useCallback } from 'react';
import { WorldIcon } from '../../../assets/icons/WorldIcon';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { InternalTimeIcon } from '../../../assets/icons/InternalTimeIcon';
import { ShopIcon } from '../../../assets/icons/ShopIcon';
import LogoIcon from '../../../assets/icons/LogoIcon';
import ContextMenuTab, { ContextMenuItem } from '../../../components/context-menu-tab/ContextMenuTab';
import Avatar from '../../../components/avatar/Avatar';
import { getUserInitials, msalInstance } from '../../../utils/authProvider/authProvider';
import { useNavigate } from '../../../hooks/useNavigate';
import { ToolsIcon } from '../../../assets/icons/ToolsIcon';
import addQueryParams from '../../../utils/routing/add-query-params';
import { AddInternalTaskIcon } from '../../../assets/icons/AddInternalTaskIcon';
import useUserAccess from '../../../hooks/useUserAccess';
import { ClipboardTextIcon } from '../../../assets/icons/ClipboardTextIcon';
import { NewsIcon } from '../../../assets/icons/NewsIcon';
import { useSelector } from 'react-redux';
import { selectNews } from '../../../stateManagement/reducers/newsReducer';

interface Props {
  unapprovedTasks: number;
  userProfileState?: UserProfileState;
  createdInternalTasks: number;
}

const LeftMenu = (props: Props) => {
  const { unapprovedTasks, createdInternalTasks } = props;

  const navigateReact = useNavigateReact();
  const navigate = useNavigate();
  const { technicianUserGroupAllowed, officeUserGroupAllowed, adminUserGroupAllowed } = useUserAccess();
  const news = useSelector(selectNews)?.filter((n) => !n.isRead).length;

  const taskMatch = useMatch(TASKS_ROUTE);
  const workplanMatch = useMatch(WORKPLAN_ROUTE);
  const workplanHistoryMatch = useMatch(CALENDAR_ROUTE);
  const technicianTaskList = useMatch(TECHNICIAN_TASKLIST_ROUTE);
  const timeMatch = useMatch(TIME_ROUTE);
  const goodsMatch = useMatch(GOODS_ROUTE);
  const portalMatch = useMatch(PORTAL_ROUTE);
  const internalTimeTechnicianMatch = useMatch(INTERNAL_TIME_TECHNICIAN);
  const internalTimeOfficeMatch = useMatch(INTERNAL_TIME_OFFICE);
  const adminTaskTypesMatch = useMatch(ADMIN_TASKTYPES);
  const adminComponentsMatch = useMatch(ADMIN_REUSABLECOMPONENTS);
  const adminReasonCodesMatch = useMatch(ADMIN_REASONCODES);
  const adminQuestionsMatch = useMatch(ADMIN_QUESTIONS);
  const taskDetailMatch = useMatch(TASKS_DETAILS);
  const orderGoodsMatch = useMatch(ORDER_GOODS_ROUTE);
  const moveGoodsMatch = useMatch(MOVE_GOODS_ROUTE);
  const mapViewMatch = useMatch(MAP_TASK_LIST_ROUTE);
  const countGoodsMatch = useMatch(COUNT_GOODS_ROUTE);
  const returnGoodsMatch = useMatch(RETURN_GOODS_ROUTE);
  const receiptMatch = useMatch(RECEIPT_ROUTE);
  const newsMatch = useMatch(NEWS_ROUTE);

  const handleOnClickLogOut = useCallback(() => {
    msalInstance.logoutRedirect();
  }, []);

  const handleNavigateProfile = useCallback(() => {
    navigate(addQueryParams(undefined, undefined, PROFILE_ROUTE));
  }, [navigate]);

  const isFullscreenView =
    taskDetailMatch || orderGoodsMatch || countGoodsMatch || moveGoodsMatch || returnGoodsMatch || receiptMatch;

  return isFullscreenView ? (
    <></>
  ) : (
    <Container>
      <TopContainer>
        <LogoIcon />
      </TopContainer>
      <CenterContainer>
        <MenuItem isActive={!!newsMatch} onClick={() => navigateReact(NEWS_ROUTE)}>
          <Badge
            badgeContent={news}
            color="error"
            overlap="circular"
            sx={{
              '& .MuiBadge-badge': {
                height: 21,
                width: 21,
                fontSize: 11,
                top: -3,
                left: 20,
                position: 'absolute',
                backgroundColor: 'black'
              }
            }}
          >
            <NewsIcon />
          </Badge>

          <MenuItemText>Nyheder</MenuItemText>
        </MenuItem>
        {technicianUserGroupAllowed() && (
          <>
            <MenuItem isActive={!!workplanMatch} onClick={() => navigateReact(WORKPLAN_ROUTE)}>
              <VehicleIcon />
              <MenuItemText>Opgaver</MenuItemText>
            </MenuItem>
            <MenuItem isActive={!!workplanHistoryMatch} onClick={() => navigateReact(CALENDAR_ROUTE)}>
              <CalendarIcon />
              <MenuItemText>Kalender</MenuItemText>
            </MenuItem>
            {window._env_.TECHNICIAN_TASKLIST_ENABLED === 'TRUE' && (
              <MenuItem isActive={!!technicianTaskList} onClick={() => navigateReact(TECHNICIAN_TASKLIST_ROUTE)}>
                <ClipboardTextIcon />
                <MenuItemText>Opgaveliste</MenuItemText>
              </MenuItem>
            )}
            <MenuItem isActive={!!internalTimeTechnicianMatch} onClick={() => navigateReact(INTERNAL_TIME_TECHNICIAN)}>
              <AddInternalTaskIcon />
              <MenuItemText>Interne opgaver</MenuItemText>
            </MenuItem>
            <MenuItem isActive={!!goodsMatch} onClick={() => navigateReact(GOODS_ROUTE)}>
              <ShopIcon />
              <MenuItemText>Varer</MenuItemText>
            </MenuItem>
          </>
        )}
        {officeUserGroupAllowed() && (
          <>
            <MenuItem isActive={!!taskMatch || !!mapViewMatch} onClick={() => navigateReact(TASKS_ROUTE)}>
              <ListIcon />
              <MenuItemText>Opgaver</MenuItemText>
            </MenuItem>
            <MenuItem isActive={!!internalTimeOfficeMatch} onClick={() => navigateReact(INTERNAL_TIME_OFFICE)}>
              {createdInternalTasks > 0 ? (
                <Badge
                  badgeContent={createdInternalTasks}
                  color="error"
                  overlap="circular"
                  sx={{
                    '& .MuiBadge-badge': { height: 15, minWidth: 15, padding: 0, fontSize: 10, marginTop: '2px' }
                  }}
                >
                  <InternalTimeIcon padding="2px 0 0 0" />
                </Badge>
              ) : (
                <InternalTimeIcon />
              )}
              <MenuItemText>Interne opgaver</MenuItemText>
            </MenuItem>
          </>
        )}
        <MenuItem isActive={!!timeMatch} onClick={() => navigateReact(TIME_ROUTE)}>
          {unapprovedTasks > 0 ? (
            <Badge
              badgeContent={unapprovedTasks}
              color="error"
              overlap="circular"
              sx={{ '& .MuiBadge-badge': { height: 15, minWidth: 15, padding: 0, fontSize: 10 } }}
            >
              <ClockIcon padding="0 0 4px 0" />
            </Badge>
          ) : (
            <ClockIcon padding="0 0 0 0" />
          )}
          <MenuItemText>Tid</MenuItemText>
        </MenuItem>
      </CenterContainer>

      <BottomContainer>
        {adminUserGroupAllowed() && (
          <MenuItem
            isActive={!!adminTaskTypesMatch || !!adminComponentsMatch || !!adminReasonCodesMatch || !!adminQuestionsMatch}
          >
            <ContextMenuTab
              menuItems={
                <>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_NEWS)}>Nyheder</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_TASKTYPES)}>Opgavetyper</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_REUSABLECOMPONENTS)}>Typefelter</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_PORTAL_LINKS)}>Links</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_REASONCODES)}>Årsagsforklaringer</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_QUESTIONS)}>Tjeklistespørgsmål</ContextMenuItem>
                </>
              }
            >
              <ToolsIcon />
            </ContextMenuTab>
            <MenuItemText>Admin</MenuItemText>
          </MenuItem>
        )}
        <MenuItem isActive={false}>
          <ContextMenuTab
            menuItems={
              <>
                <ContextMenuItem onClick={handleNavigateProfile}>Profil</ContextMenuItem>
                <ContextMenuItem onClick={handleOnClickLogOut}>Log ud</ContextMenuItem>
              </>
            }
          >
            <Avatar text={getUserInitials()} />
          </ContextMenuTab>
          <MenuItemText>Profil</MenuItemText>
        </MenuItem>
        <MenuItem isActive={!!portalMatch} onClick={() => navigateReact(PORTAL_ROUTE)}>
          <WorldIcon />
          <MenuItemText>Portalen</MenuItemText>
        </MenuItem>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 12px;
`;

const TopContainer = styled.div`
  margin: 50px 0px 70px 0px;
  width: 50px;
`;

const CenterContainer = styled.div`
  margin-top: 0;
`;

const BottomContainer = styled.div`
  margin: auto 0 18px 0;
`;

const MenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  box-sizing: border-box;
  padding: 8px;

  border-radius: 10px;
  border: ${(props) => (props.isActive ? '2px solid #FFD400' : '2px solid transparent')};
  cursor: pointer;
`;

const MenuItemText = styled.span`
  margin-top: 6px;
  font-size: 12px;
  line-height: 1.2;
`;

export default memo(LeftMenu, (prevProps: Props, props: Props) => {
  return (
    prevProps.unapprovedTasks === props.unapprovedTasks &&
    prevProps.createdInternalTasks === props.createdInternalTasks &&
    prevProps.userProfileState?.isLoaded === props.userProfileState?.isLoaded &&
    prevProps.userProfileState?.userProfile?.role === props.userProfileState?.userProfile?.role
  );
});
