import styled from 'styled-components';
import { QuestionType, SectionQuestionDTO, SectionQuestionTextDTO } from '../../../../../../api/api';
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import KebabMenu from '../../../../../../components/kebab-menu2/KebabMenu';
import { MenuItem } from '../../../../../../components/select/Select';
import { useConfirmationDialog } from '../../../../../../hooks/useConfirmationDialog';
import Typography from '../../../../../../components/typography/Typography';

interface Props {
  question: SectionQuestionTextDTO;
  sectionId?: number;
  index: number;
  removeQuestion: (questionId: number) => void;
  swapQuestions: (questionIndex1: number, questionIndex2: number) => void;
  updateQuestions: (field: keyof SectionQuestionDTO, checked: boolean) => void;
  validateIfQuestinoIsDependableQuestion: (questionId: number) => number | undefined;
}

const Question = (props: Props) => {
  const {
    question,
    sectionId,
    index,
    removeQuestion,
    swapQuestions,
    updateQuestions,
    validateIfQuestinoIsDependableQuestion
  } = props;
  const { getConfirmation } = useConfirmationDialog();

  const getDialogBody = (dependingSectionId?: number) => {
    if (dependingSectionId) {
      return {
        headerText: 'Advarsel',
        bodyText: `Sektion ${dependingSectionId} vises kun hvis dette spørgsmål er besvaret. Er du sikker på at du vil slette dette spørgsmål?`,
        declineButtonText: 'Fortryd',
        confirmButtonText: 'Bekræft'
      };
    } else {
      return {
        headerText: 'Er du sikker?',
        bodyText: 'Er du sikker på at du vil slette dette spørgsmål?',
        declineButtonText: 'Fortryd',
        confirmButtonText: 'Bekræft'
      };
    }
  };

  const deleteField = async () => {
    if (!question.questionId || !sectionId) return;

    const dependingSectionId =
      question.sectionQuestionId && validateIfQuestinoIsDependableQuestion(question.sectionQuestionId);

    const confirmation = await getConfirmation(getDialogBody(dependingSectionId));

    if (confirmation === 'confirm') {
      removeQuestion(question.questionId);
    }
  };

  return (
    <FieldContainer>
      <Row>
        <StyledTypography>id-{question.questionId}</StyledTypography>
        <LeftButtons>
          <DynamicFieldContainer>
            <FieldName>{question.text}</FieldName>
          </DynamicFieldContainer>
        </LeftButtons>
        <RightButtons>
          <StyledCheckbox
            onChange={(e) => updateQuestions('isMandatory', e.target.checked)}
            checked={question.isMandatory}
            label="Obligatorisk"
            disabled={question.questionType === QuestionType.Header}
          />
          <KebabMenu>
            {index !== undefined && <MenuItem onClick={() => swapQuestions(index, index - 1)}>Ryk op</MenuItem>}
            {index !== undefined && <MenuItem onClick={() => swapQuestions(index + 1, index)}>Ryk ned</MenuItem>}
            <MenuItem onClick={deleteField}>Slet spørgsmål</MenuItem>
          </KebabMenu>
        </RightButtons>
      </Row>
    </FieldContainer>
  );
};

const LeftButtons = styled.div`
  width: 60%;
  display: flex;
`;

const StyledTypography = styled(Typography)`
  width: 75px;
  margin: auto;
  text-align: center;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

const FieldContainer = styled.div`
  display: 'flex';
  flex-direction: 'column';
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed black;
  position: relative;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 50px;
`;

const DynamicFieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #000000;
  width: 300px;
  margin: 20px 0px;
`;

const FieldName = styled.h1`
  font-size: 15px;
  background-color: #f2f2f2;
  padding: 18px;
  border-radius: 10px;
  width: 100%;
  color: #343434;
`;

export default Question;
