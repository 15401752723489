import { useMemo } from 'react';
import useTableInstance from '../../../../../../hooks/useTableInstance';
import { CellProps, Column } from 'react-table';
import { CheckmarkIcon } from '../../../../../../assets/icons/CheckmarkIcon';
import { ExtendedMeter } from '../MeterInstallation/MeterInstallationStep';

const useMeterTableInstance = (meters: ExtendedMeter[]) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Tilslutning',
        accessor: 'communicationMethod',
        disableFilters: true,
        minWidth: 100
      },
      {
        Header: 'Producent',
        accessor: 'manufacturer',
        disableFilters: true,
        minWidth: 100
      },
      {
        Header: 'Type',
        accessor: 'fabricationType',
        disableFilters: true,
        minWidth: 100
      },
      {
        Header: 'Fabrikationsnummer',
        accessor: 'fabricationNumber',
        disableFilters: true,
        minWidth: 250
      },
      {
        Header: '',
        accessor: 'selected',
        disableFilters: true,
        Cell: (cellProps: CellProps<ExtendedMeter>) => {
          const { cell } = cellProps;
          return cell.row.original?.selected ? <CheckmarkIcon color="green" size="20px" /> : null;
        }
      }
    ] as Column<object>[];
  }, []);

  const tableInstance = useTableInstance<object>(meters, columns);

  return { tableInstance };
};

export default useMeterTableInstance;
