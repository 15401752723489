import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const AddIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="add-icon" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" strokeWidth="3.5" strokeLinecap="round" d="M 15 0.692 L 15 29.271"></path>
      <path fill="none" strokeWidth="3.5" strokeLinecap="round" d="M 0.656 15 L 29.308 15"></path>
    </IconSvg>
  );
};
