import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const OrderIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 3.18 23.495 L 13.623 29.288 C 13.984 29.492 14.352 29.594 14.722 29.594 C 15.094 29.594 15.46 29.492 15.823 29.288 L 26.28 23.495 C 26.92 23.124 27.399 22.706 27.714 22.242 C 28.029 21.778 28.187 21.082 28.187 20.153 L 28.187 9.418 C 28.187 8.712 28.048 8.123 27.769 7.65 C 27.5 7.176 27.06 6.769 26.447 6.425 L 17.229 1.273 C 16.403 0.818 15.568 0.59 14.722 0.59 C 13.878 0.59 13.043 0.818 12.217 1.273 L 2.999 6.425 C 2.396 6.769 1.954 7.176 1.676 7.65 C 1.398 8.123 1.259 8.712 1.259 9.418 L 1.259 20.153 C 1.259 21.082 1.417 21.778 1.732 22.242 C 2.057 22.706 2.54 23.124 3.18 23.495 Z M 4.697 21.338 C 4.355 21.142 4.108 20.934 3.959 20.711 C 3.821 20.478 3.751 20.191 3.751 19.847 L 3.751 10.407 L 13.428 15.851 L 13.428 26.252 L 4.697 21.338 Z M 24.748 21.338 L 16.032 26.252 L 16.032 15.851 L 25.709 10.407 L 25.709 19.847 C 25.709 20.191 25.634 20.478 25.486 20.711 C 25.346 20.934 25.101 21.142 24.748 21.338 Z M 14.722 13.554 L 5.171 8.221 L 8.68 6.23 L 18.246 11.577 L 14.722 13.554 Z M 20.891 10.114 L 11.325 4.781 L 13.344 3.639 C 14.272 3.11 15.191 3.11 16.102 3.639 L 24.275 8.221 L 20.891 10.114 Z"
      ></path>
    </IconSvg>
  );
};
