import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddIcon } from '../../../assets/icons/AddIcon';
import Table from '../../../blocks/table/TableClient';
import Button from '../../../components/button/Button';
import Typography from '../../../components/typography/Typography';
import { Row } from '../../../styling/FormStyling';
import usePortalLinksList from './usePortalLinksList';
import UserService from '../../../services/UserService';
import { CreatePortalLinkDto, PortalLinkDto } from '../../../api/api';
import NotificationService from '../../../services/NotificationService';
import { log } from '../../../utils/logging/log';
import CreateEditPortalLinkForm from './CreateEditPortalLinkForm';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';

const confirmationDialog = {
  headerText: `Er du sikker`,
  bodyText: `Er du sikker på at du vil slette dette link?`,
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Slet'
} as DialogBody;

const AdminPortalLinksView = () => {
  const [links, setLinks] = useState<PortalLinkDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { getConfirmation } = useConfirmationDialog();
  const [editingPortalLink, setEditingPortalLink] = useState<PortalLinkDto>();

  useEffect(() => {
    setLoading(true);
    UserService.getAllPortalLinks()
      .then((data) => {
        setLinks(data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke hente links');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const createPortalLinkCallback = (newPortalLink: CreatePortalLinkDto) => {
    setLinks((prevLinks) => [...prevLinks, newPortalLink]);
    setShowDialog(false);
  };

  const deletePortalLinkHandler = async (id: number) => {
    const confirmation = await getConfirmation(confirmationDialog as DialogBody);

    if (confirmation === 'confirm') {
      UserService.deletePortalLink(id)
        .then(() => {
          const newLinks = links.filter((link) => link.id !== id);
          setLinks(newLinks);
          updateOrderNumbersHandler(newLinks);
        })
        .then(() => {
          NotificationService.success('Linket blev slettet');
        })
        .catch((error) => {
          NotificationService.error('Linket kunne ikke slettet');
          log(error);
        });
    }
  };

  const editPortalLinkHandler = (editingPortalLink: PortalLinkDto) => {
    setEditingPortalLink(editingPortalLink);
    setShowDialog(true);
  };

  const editPortalLinkCallback = (newPortalLink: PortalLinkDto) => {
    setLinks((prevLinks) => prevLinks.map((link) => (link.id === newPortalLink.id ? newPortalLink : link)));
    setShowDialog(false);
  };

  const { tableInstance } = usePortalLinksList(
    links,
    (_links) => updateOrderNumbersHandler(_links),
    (editingPortalLink) => editPortalLinkHandler(editingPortalLink),
    (id) => id && deletePortalLinkHandler(id)
  );

  const updateOrderNumbersHandler = (_links: PortalLinkDto[]) => {
    setLoading(true);
    const updateOrderNumbers = _links.map((link, index) => ({ ...link, order: index + 1 }));
    UserService.updateOrderNumbers(updateOrderNumbers)
      .then(() => {
        setLinks(updateOrderNumbers);
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke opdatere rækkefølge på links');
        log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDialogVisibility = (show: boolean) => {
    if (show) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
      setEditingPortalLink(undefined);
    }
  };

  return (
    <Container>
      <Header>
        <Typography fontWeight="bold" variant="h3">
          Portallinks
        </Typography>
        <Row>
          <Button onClick={() => setShowDialog(true)}>
            <AddIcon size="16px" /> Opret link
          </Button>
        </Row>
      </Header>
      <Table
        loading={loading}
        tableInstance={tableInstance}
        onDoubleClickRow={(rowData) => editPortalLinkHandler(rowData)}
        showPagination
      />
      <CreateEditPortalLinkForm
        editingPortalLink={editingPortalLink}
        editPortalLinkCallback={editPortalLinkCallback}
        allPortalLinks={links}
        createPortalLinkCallback={createPortalLinkCallback}
        closeDialog={() => handleDialogVisibility(false)}
        open={showDialog}
      />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(10)};
`;

export default AdminPortalLinksView;
