import styled from 'styled-components';

import { LinkIcon } from '../../assets/icons/LinkIcon';

interface Props {
  label?: string;
  link?: string;
}

const Hyperlink = (props: Props) => {
  const { link, label } = props;

  return (
    <Link href={link} target="_blank" clickable={!!link}>
      <LinkIcon size="10px" padding="0px 5px 0px 0px" />
      {label ?? 'Link'}
    </Link>
  );
};

const Link = styled.a<{ clickable: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 3px 9px 3px 10px;
  font-size: 12px;
  color: ${(props) => props.theme.palette.grey.black90};
  margin: ${(props) => props.theme.spacing(3)} 0px;
  width: fit-content;
  text-decoration: none;

  :hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  }
`;

export default Hyperlink;
