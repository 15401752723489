import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const LocationIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g clip-path="url(#clip0_6830_1780)">
        <path
          d="M16.0012 32L15.2619 31.1725C14.8007 30.6556 4.00012 18.4825 4.00012 12.0643C4.00012 5.64615 9.38274 0 16.0012 0C22.6197 0 28.0023 5.41098 28.0023 12.0643C28.0023 18.7177 17.2018 30.6578 16.7405 31.1725L16.0012 32ZM16.0012 1.99667C10.4796 1.99667 5.98633 6.51137 5.98633 12.0643C5.98633 14.3516 7.82467 18.132 11.3027 22.9928C13.1477 25.5707 15.0059 27.8181 15.999 28.985C16.9965 27.8136 18.8614 25.5596 20.7063 22.9795C24.1778 18.1253 26.0117 14.3494 26.0117 12.0643C26.0117 6.51359 21.5207 1.99667 15.9968 1.99667H16.0012Z"
          stroke="none"
        />
        <path
          d="M16.001 17.211C12.9246 17.211 10.422 14.6952 10.422 11.6026C10.422 8.50995 12.9246 5.99414 16.001 5.99414C19.0774 5.99414 21.5801 8.50995 21.5801 11.6026C21.5801 14.6952 19.0774 17.211 16.001 17.211ZM16.001 7.99081C14.0192 7.99081 12.4082 9.61034 12.4082 11.6026C12.4082 13.5948 14.0192 15.2143 16.001 15.2143C17.9828 15.2143 19.5939 13.5948 19.5939 11.6026C19.5939 9.61034 17.9828 7.99081 16.001 7.99081Z"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_6830_1780">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
