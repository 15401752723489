import { useContext } from 'react';
import { ConditionalQuestion, Question } from '../../models/Question';
import QuestionWrapper from '../question-wrapper/QuestionRenderer';
import { FileAnswerDTO, FileAnswerUploadDTO, UpdateAnswerDTO } from '../../api/api';
import { log } from '../../utils/logging/log';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import NotificationService from '../../services/NotificationService';
import TaskChecklistService from '../../services/TaskChecklistService';
import { TaskChecklistDispatchContext } from '../../stateManagement/TaskChecklistProvider';
import ExpandCollapseTaskChecklistHeaders from '../../views/task-view/task-details-view2/checklist-step/ExpandCollapseTaskChecklistHeaders';
import useChecklistLock from '../../hooks/useChecklistLock';

interface Props {
  header: string;
  questions: Question[];
  updateConditionalQuestionValue?: (conditionalQuestion: ConditionalQuestion) => void;
  workTaskSectionId?: number;
  readOnly: boolean;
  checklistId: number;
  onDelete?: () => void;
  isRepeatable?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: () => void;
  showIsCompleted?: boolean;
  isCompleted?: boolean;
  toggleIsCompleted?: () => void;
}

const dialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at du vil slette denne fil?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const QuestionaireSection = (props: Props) => {
  const {
    header,
    workTaskSectionId,
    checklistId,
    readOnly,
    onDelete,
    isRepeatable,
    isExpanded,
    setIsExpanded,
    showIsCompleted,
    isCompleted,
    toggleIsCompleted
  } = props;
  const { getConfirmation } = useConfirmationDialog();

  const dispatch = useContext(TaskChecklistDispatchContext);
  const { addChecklistEdited } = useChecklistLock();

  const saveValue = (answer: UpdateAnswerDTO) => {
    answer.answerTimeStampValue = new Date().toISOString();
    Promise.resolve(
      TaskChecklistService.updateAnswer({ ...answer, answerTimeStampValue: answer.answerTimeStampValue })
        .then(() => {
          dispatch({
            type: 'update-answer',
            payload: {
              checklistId,
              answerId: answer.answerId ?? 0,
              value: answer.answerTextValue || answer.answerIntValue || answer.answerDoubleValue,
              sectionId: workTaskSectionId ?? 0
            }
          });
          addChecklistEdited(checklistId);
        })
        .catch((error) => log(error))
    );
  };

  const handleSaveValueFiles = (answer: FileAnswerUploadDTO) => {
    const changedQuestion = props.questions.filter((q) => q.id === answer.answerId && answer.answerId != null)[0];
    const i = props.questions.indexOf(changedQuestion);
    if (i !== -1 && answer.files) {
      if (!props.questions[i].value) {
        NotificationService.error('Der skete en fejl. Kunne ikke gemme svaret korrekt.');
        return;
      }
      answer.answerTimeStampValue = new Date().toISOString();
      dispatch({
        type: 'update-answer',
        payload: {
          checklistId,
          answerId: answer.answerId ?? 0,
          value: [...props.questions[i].value, ...answer.files],
          sectionId: workTaskSectionId ?? 0
        }
      });
      addChecklistEdited(checklistId);
    }
  };

  const handleDeleteFile = async (fileAnswerId: number, questionId: number, taskId: number) => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm' && fileAnswerId) {
      const foundAnswer = props.questions.find((question) => question.id === questionId);
      if (!foundAnswer) {
        NotificationService.error('Der skete en fejl. Kunne ikke slette billedet.');
        return;
      }

      TaskChecklistService.deleteFileAnswer(taskId, fileAnswerId)
        .then(() => {
          dispatch({
            type: 'update-answer',
            payload: {
              checklistId,
              answerId: questionId,
              value: foundAnswer.value.filter((_file: FileAnswerDTO) => _file.fileAnswerId !== fileAnswerId),
              sectionId: workTaskSectionId ?? 0
            }
          });
          addChecklistEdited(checklistId);
        })
        .catch((error) => {
          NotificationService.error('Der skete en fejl. Kunne ikke slette billedet fra tjeklisten.');
          log(error);
        });
    }
  };

  return (
    <ExpandCollapseTaskChecklistHeaders
      isCollapsable={true}
      onDelete={onDelete}
      header={header}
      readOnly={readOnly}
      isRepeatable={isRepeatable}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      showIsCompleted={showIsCompleted}
      isCompleted={isCompleted}
      toggleIsCompleted={toggleIsCompleted}
    >
      {props.questions.map((q) => (
        <QuestionWrapper
          question={q}
          key={q.id ?? q.sectionQuestionId}
          handleSaveValue={saveValue}
          handleSaveValueFiles={handleSaveValueFiles}
          handleDeleteFile={handleDeleteFile}
          readOnly={readOnly}
        />
      ))}
    </ExpandCollapseTaskChecklistHeaders>
  );
};

export default QuestionaireSection;
