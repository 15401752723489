import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TaskTypeBaseDTO, TaskTypeDetailedDTO2 } from '../../api/api';

import TileButton from '../../components/tile-button/TileButton';
import TaskTypeService from '../../services/TaskTypeService';

interface Props {
  onClick: (internalTaskType: TaskTypeBaseDTO) => void;
}

const InternalTimePicker = (props: Props) => {
  const { onClick } = props;

  const [internalTaskTypes, setInternalTaskTypes] = useState<TaskTypeDetailedDTO2[]>([]);

  useEffect(() => {
    TaskTypeService.getInternalTaskTypes().then((res) => setInternalTaskTypes(res));
  }, []);

  return (
    <LinkRow>
      {internalTaskTypes.map((t) => (
        <TileButton onClick={() => onClick(t)} text={t.name ?? ''} key={t.id}></TileButton>
      ))}
    </LinkRow>
  );
};

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  margin-top: 12px;
  flex-wrap: wrap;
  row-gap: 12px;
`;

export default InternalTimePicker;
