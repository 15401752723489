import WorkClient from '../api/workClient';

const GetDepartments = () => {
  return WorkClient.departmentAll();
};

const DepartmentService = {
    GetDepartments
};

export default DepartmentService;
