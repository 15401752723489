import { CreateTimeRegistrationDTO, UpdateTimeRegistrationDTO, CreateInternalTaskTimeRegistrationDTO2 } from '../api/api';
import WorkClient from '../api/workClient';
import { setNumberOfNotApprovedRegistrations } from '../stateManagement/reducers/timeRegistrationReducer';
import { store } from '../stateManagement/store';
import { formatDateStringISO } from '../utils/dateHandling';

const getCategories = (projectId: string | undefined) => {
  return WorkClient.getTimeCategoriesByProject(projectId);
};

const getCategoryAndProjectFromGroup = (groupId: string) => {
  return WorkClient.getEmployeeCategoriesAndProjectByGroup(groupId);
};

const addTimeRegistration = (timeRegistration: CreateTimeRegistrationDTO) => {
  return WorkClient.timeRegistrationPOST(timeRegistration).then(() =>
    getTimeRegistrationsNotApproved().then((regs) => store.dispatch(setNumberOfNotApprovedRegistrations(regs)))
  );
};

const addInternalTime = (internalTime: CreateInternalTaskTimeRegistrationDTO2) => {
  return WorkClient.internalTime2(internalTime);
};

const getTimeRegistrationsForPeriod = (from: string, to: string) => {
  return WorkClient.timeRegistrationAll(formatDateStringISO(from), formatDateStringISO(to));
};

const getTaskTimeRegistrations = (taskId: number) => {
  return WorkClient.task(taskId);
};

const approveTimeRegistrations = (ids: number[]) => {
  return WorkClient.approveHours(ids).then(() =>
    getTimeRegistrationsNotApproved().then((regs) => store.dispatch(setNumberOfNotApprovedRegistrations(regs)))
  );
};

const deleteTimeRegistration = (taskId: number) => {
  return WorkClient.timeRegistrationDELETE(taskId).then(() =>
    getTimeRegistrationsNotApproved().then((regs) => store.dispatch(setNumberOfNotApprovedRegistrations(regs)))
  );
};

const editTimeRegistration = (taskId: number, body: UpdateTimeRegistrationDTO | undefined) => {
  return WorkClient.timeRegistrationPUT(taskId, body);
};

const getTimeRegistrationsNotApproved = () => {
  return WorkClient.notApprovedRegistrations();
};

const checkIfWorkTaskHasTimeRegistrations = (workTaskId: number) => {
  return WorkClient.checkIfWorkTaskHasTimeRegistrations(workTaskId);
};

const moveTimeRegistrationsToWorkInvoiceProject = (workTaskId: number) => {
  return WorkClient.moveTimeRegistrationsToWorkInvoiceProject(workTaskId);
};

const TimeRegistrationService = {
  getCategories,
  getCategoryAndProjectFromGroup,
  addTimeRegistration,
  addInternalTime,
  getTaskTimeRegistrations,
  deleteTimeRegistration,
  getTimeRegistrationsForPeriod,
  editTimeRegistration,
  getTimeRegistrationsNotApproved,
  approveTimeRegistrations,
  checkIfWorkTaskHasTimeRegistrations,
  moveTimeRegistrationsToWorkInvoiceProject
};

export default TimeRegistrationService;
