import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const WorldIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 15.009 0.349 C 6.946 0.349 0.402 6.893 0.402 14.956 C 0.402 23.019 6.946 29.563 15.009 29.563 C 23.072 29.563 29.617 23.019 29.617 14.956 C 29.617 6.893 23.072 0.349 15.009 0.349 Z M 3.324 14.956 C 3.324 14.065 3.441 13.189 3.63 12.357 L 10.613 19.338 L 10.613 20.799 C 10.613 22.405 11.927 23.721 13.534 23.721 L 13.534 26.54 C 7.794 25.809 3.324 20.901 3.324 14.956 Z M 23.613 22.844 C 23.233 21.66 22.153 20.799 20.838 20.799 L 19.377 20.799 L 19.377 16.417 C 19.377 15.614 18.72 14.956 17.916 14.956 L 9.151 14.956 L 9.151 12.034 L 12.073 12.034 C 12.877 12.034 13.534 11.377 13.534 10.574 L 13.534 7.653 L 16.455 7.653 C 18.062 7.653 19.377 6.337 19.377 4.73 L 19.377 4.132 C 23.657 5.856 26.695 10.063 26.695 14.956 C 26.695 17.994 25.511 20.77 23.613 22.844 Z"
      ></path>
    </IconSvg>
  );
};
