import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import addDays from 'date-fns/addDays';
import { useLocation, useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DetailedTimeRegistrationDTO } from '../../api/api';
import TimeRegTableUser from '../../blocks/time-registration-list/TimeRegTableUser';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import Typography from '../../components/typography/Typography';
import TimeRegistrationService from '../../services/TimeRegistrationService';
import { StyledCircularProgress } from '../../styling/Styling';
import n1LightTheme from '../../theme/light-theme';
import { getCurrentWeek } from '../../utils/dateHandling';
import TimeRegDayHeader from './time-reg-day-header/TimeRegDayHeader';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import NotificationService from '../../services/NotificationService';
import { useNavigate } from '../../hooks/useNavigate';
import { ADD_TIME_ROUTE, TIME_ROUTE } from '../../utils/constants';
import { AddIcon } from '../../assets/icons/AddIcon';
import {
  addTimeRegistration,
  editTimeRegistration,
  TimeRegistrationSO
} from '../../stateManagement/reducers/timeRegistrationReducer';
import Button from '../../components/button/Button';
import WeekDatePicker from '../../components/week-date-picker/WeekDatePicker';
import { log } from '../../utils/logging/log';
import addQueryParams from '../../utils/routing/add-query-params';

const AccordionSummaryStyle = {
  backgroundColor: n1LightTheme.palette.grey.black5,
  border: `1px solid ${n1LightTheme.palette.grey.black10}`
};

const AccordionStyle = {
  '&:before': { display: 'none' }
};

const dialogBodyApprove: DialogBody = {
  headerText: '',
  bodyText: 'Vil du godkende timer for den valgte dag?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const dialogBodyDelete: DialogBody = {
  headerText: '',
  bodyText: 'Vil du slette den valgte tidsregistrering?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

export interface RegistrationsCollectionForDay {
  day: number;
  hoursTotal: number;
  hoursApproved: number;
  registrations: DetailedTimeRegistrationDTO[];
  date: string;
  registrationsNotApproved: number;
}

const dayComparer = (a: RegistrationsCollectionForDay, b: RegistrationsCollectionForDay) => {
  if (a.day === 0 && b.day !== 0) return 1;
  if (a.day !== 0 && b.day === 0) return -1;
  if (a.day > b.day) return 1;
  if (a.day < b.day) return -1;
  return 0;
};

const getInitialRegistrationCollection = (from: Date): RegistrationsCollectionForDay[] => {
  const list: RegistrationsCollectionForDay[] = [];
  for (let i = 0; i < 7; i++) {
    const date = addDays(from, i);
    list.push({
      date: date.toString(),
      day: date.getDay(),
      hoursApproved: 0,
      hoursTotal: 0,
      registrations: [],
      registrationsNotApproved: 0
    });
  }
  return list;
};

const TimeRegView = () => {
  const [registrationsForDay, setRegistrationsForDay] = useState<RegistrationsCollectionForDay[]>([]);
  const [expandedDays, setExpandedDays] = useState<number[]>([]);
  const [currentWeekDays, setCurrentWeekDays] = useState<{ start: Date; end: Date; week: number }>(getCurrentWeek());
  const [sumOfApprovedHours, setSumOfApprovedHours] = useState(0);
  const [approving, setApproving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getConfirmation } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const matchTaskRoute = useMatch(TIME_ROUTE);

  useEffect(() => {
    if (matchTaskRoute) {
      fetchRegistrations();
    }
  }, [location]);

  useEffect(() => {
    fetchRegistrations();
    setExpandedDays([]);
  }, [currentWeekDays]);

  const fetchRegistrations = () => {
    setIsLoading(true);
    const { start, end } = currentWeekDays;
    TimeRegistrationService.getTimeRegistrationsForPeriod(start.toISOString(), end.toISOString())
      .then((res) => {
        setIsLoading(false);
        const tmpRegistrationsCollectionForDay: RegistrationsCollectionForDay[] = getInitialRegistrationCollection(start);
        let tmpSumOfApprovedHours = 0;
        res.filter(t => !t.isDeleted).forEach((r: DetailedTimeRegistrationDTO) => {
          if (!r.date) return;
          const hours = r.hours ?? 0;
          let day = new Date(r.date).getDay();
          const registrationCollectionForDay = tmpRegistrationsCollectionForDay.find(
            (registration: RegistrationsCollectionForDay) => registration.day === day
          );
          if (registrationCollectionForDay) {
            registrationCollectionForDay.registrations.push(r);
            registrationCollectionForDay.hoursTotal += hours;
            registrationCollectionForDay.hoursApproved += r.approvedByUser ? hours : 0;
            !r.approvedByUser && registrationCollectionForDay.registrationsNotApproved++;
          } else {
            tmpRegistrationsCollectionForDay.push({
              day,
              registrations: [r],
              hoursTotal: hours,
              hoursApproved: hours,
              date: r.date,
              registrationsNotApproved: r.approvedByUser ? 0 : 1
            });
          }
          tmpSumOfApprovedHours += r.approvedByUser ? hours : 0;
          
        });

        tmpRegistrationsCollectionForDay.sort((a, b) => dayComparer(a, b));
        setRegistrationsForDay(tmpRegistrationsCollectionForDay);
        setSumOfApprovedHours(tmpSumOfApprovedHours);
        if (
          tmpRegistrationsCollectionForDay.some(
            (x) => new Date(x.date).toDateString() === new Date().toDateString() && expandedDays.length === 0
          )
        ) {
          setExpandedDays([new Date().getDay()]);
        }
      })
      .catch((err) => {
        log(err);
        setIsLoading(false);
      });
  };

  const toggleDayExpansion = (day: number) => {
    const index = expandedDays.findIndex((e) => e === day);
    const tmpExpandedDays = [...expandedDays];
    if (index < 0) {
      tmpExpandedDays.push(day);
    } else {
      tmpExpandedDays.splice(index, 1);
    }
    setExpandedDays(tmpExpandedDays);
  };

  const handleApproveDay = async (registrations: DetailedTimeRegistrationDTO[]) => {
    const confirmResult = await getConfirmation(dialogBodyApprove);

    if (confirmResult === 'confirm') {
      setApproving(true);
      const ids = registrations
        .map((r) => (!r.approvedByUser && !r.isSentToErp ? r.id : undefined))
        .filter(Boolean) as number[];

      if (ids.length < 1) {
        return;
      }

      TimeRegistrationService.approveTimeRegistrations(ids)
        .then(() => {
          NotificationService.success('Tiden blev godkendt');
          setApproving(false);
          fetchRegistrations();
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Der opstod en fejl i godkendelse af tidsregistreringerne');
          setApproving(false);
        });
    }
  };

  const handleDeleteRegistration = async (regId: number) => {
    const confirmed = await getConfirmation(dialogBodyDelete);

    if (confirmed === 'confirm') {
      setIsLoading(true);
      TimeRegistrationService.deleteTimeRegistration(regId)
        .then((result) => {
          fetchRegistrations();
          NotificationService.success(`Timeregistrering blev slettet`);
          setIsLoading(false);
        })
        .catch((error) => {
          log(error);
          NotificationService.error(`Kunne ikke slette timeregistrering ${regId}: ${error}`);
          setIsLoading(false);
        });
    }
  };

  const handleEditRegistration = (timeReg: TimeRegistrationSO) => {
    dispatch(editTimeRegistration(timeReg));
    navigate(addQueryParams(undefined, undefined, ADD_TIME_ROUTE));
  };

  const handleAddRegistration = () => {
    dispatch(addTimeRegistration({}));
    navigate(addQueryParams(undefined, undefined, ADD_TIME_ROUTE));
  };

  return (
    <StyledContainer>
      <Accordion disableGutters sx={AccordionStyle}>
        <AccordionSummary sx={AccordionSummaryStyle}>
          <StyledHeader>
            <Typography>
              <b>Tidsoversigt</b>
            </Typography>
            <StyledIconButton onClick={handleAddRegistration} data-testid="add-timereg-user-button">
              <AddIcon size="20px" />
            </StyledIconButton>
            <WeekDatePicker currentWeekDays={currentWeekDays} onDateChange={setCurrentWeekDays} />
            <Typography>
              <b>{sumOfApprovedHours}</b> timer
            </Typography>
          </StyledHeader>
        </AccordionSummary>
      </Accordion>
      {isLoading ? (
        <CircularProgress />
      ) : (
        registrationsForDay.map((r) => {
          return (
            <Accordion
              disableGutters
              sx={{ '&:before': { display: 'none' } }}
              expanded={expandedDays.includes(r.day)}
              key={r.day}
            >
              <AccordionSummary sx={AccordionSummaryStyle} onClick={() => toggleDayExpansion(r.day)}>
                <TimeRegDayHeader registrationsForDay={r} handleAddTime={() => log('needs implementation')} />
              </AccordionSummary>
              <AccordionDetails sx={{ border: `1px solid ${n1LightTheme.palette.grey.black10}` }}>
                <StyledAccordionDetailsDiv>
                  <TimeRegTableUser
                    handleEdit={(timeReg: TimeRegistrationSO) => handleEditRegistration(timeReg)}
                    handleDelete={(id) => handleDeleteRegistration(id)}
                    timeRegistrations={r.registrations}
                  ></TimeRegTableUser>
                  {r.registrations.length > 0 && (
                    <StyledFooterDiv>
                      <Button
                        disabled={!r.registrations.some((reg) => !reg.approvedByUser)}
                        onClick={() => handleApproveDay(r.registrations)}
                      >
                        {approving ? <StyledCircularProgress size={16} /> : <div>Godkend</div>}
                      </Button>
                    </StyledFooterDiv>
                  )}
                </StyledAccordionDetailsDiv>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </StyledContainer>
  );
};

const StyledFooterDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
`;

const StyledContainer = styled.div`
  overflow: auto;
`;

const StyledAccordionDetailsDiv = styled.div`
  padding: 0px 16px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${(props) => props.theme.palette.grey.black5};
  padding-left: 16px;
  align-items: center;
  cursor: auto;
  column-gap: 0.75rem;
`;

const StyledIconButton = styled((props) => <IconButton {...props} />)`
  && {
    margin-left: auto;
  }
`;

export default TimeRegView;
