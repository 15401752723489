import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SettingsIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="add-icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.14 10.9399C17.18 10.6399 17.2 10.3299 17.2 9.9999C17.2 9.6799 17.18 9.3599 17.13 9.0599L19.16 7.4799C19.34 7.3399 19.39 7.0699 19.28 6.8699L17.36 3.5499C17.24 3.3299 16.99 3.2599 16.77 3.3299L14.38 4.2899C13.88 3.9099 13.35 3.5899 12.76 3.3499L12.4 0.809902C12.36 0.569902 12.16 0.399902 11.92 0.399902H8.07999C7.83999 0.399902 7.64999 0.569902 7.60999 0.809902L7.24999 3.3499C6.65999 3.5899 6.11999 3.9199 5.62999 4.2899L3.23999 3.3299C3.01999 3.2499 2.76999 3.3299 2.64999 3.5499L0.739988 6.8699C0.619988 7.0799 0.659988 7.3399 0.859988 7.4799L2.88999 9.0599C2.83999 9.3599 2.79999 9.6899 2.79999 9.9999C2.79999 10.3099 2.81999 10.6399 2.86999 10.9399L0.839988 12.5199C0.659988 12.6599 0.609988 12.9299 0.719988 13.1299L2.63999 16.4499C2.75999 16.6699 3.00999 16.7399 3.22999 16.6699L5.61999 15.7099C6.11999 16.0899 6.64999 16.4099 7.23999 16.6499L7.59999 19.1899C7.64999 19.4299 7.83999 19.5999 8.07999 19.5999H11.92C12.16 19.5999 12.36 19.4299 12.39 19.1899L12.75 16.6499C13.34 16.4099 13.88 16.0899 14.37 15.7099L16.76 16.6699C16.98 16.7499 17.23 16.6699 17.35 16.4499L19.27 13.1299C19.39 12.9099 19.34 12.6599 19.15 12.5199L17.14 10.9399ZM9.99999 13.5999C8.01999 13.5999 6.39999 11.9799 6.39999 9.9999C6.39999 8.0199 8.01999 6.3999 9.99999 6.3999C11.98 6.3999 13.6 8.0199 13.6 9.9999C13.6 11.9799 11.98 13.5999 9.99999 13.5999Z" />
    </IconSvg>
  );
};
