import { useCallback, useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import { CreateTransferJournalItemDTO, StockDTO } from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import useTableInstance from '../../../../hooks/useTableInstance';
import { GoodsData } from '../../../../models/Goods';
import IconButton from '../../../../components/icon-button/IconButton';
import styled from 'styled-components';

export const useMoveGoodsList = (
  goods: StockDTO[],
  selectedGoods: CreateTransferJournalItemDTO[],
  selectGoods: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void
) => {
  const hasAvailableGoods = useCallback(
    (goodsId?: string, goodsBatch?: string, goodsSerial?: string, goodsAmount?: number): boolean => {
      if (!goodsId || !goodsAmount) return false;

      if (goodsAmount === 0) return false;

      const item = selectedGoods.find(
        (item) => item.itemId === goodsId && item.batchNumber === goodsBatch && item.serialNumber === goodsSerial
      );
      if ((item?.qty ?? 0) >= goodsAmount) return false;

      return true;
    },
    [selectedGoods]
  );

  const data = useMemo((): GoodsData[] => {
    if (!goods.length) return [];

    return goods.map((item) => {
      const idSuffix = item.product?.batchNumber || item.product?.serialNumber;
      return {
        goodsId: item.product?.itemId ?? '-',
        goodsBatch: item.product?.batchNumber,
        goodsSerial: item.product?.serialNumber,
        goodsIdDisplayName: item.product?.itemId + (idSuffix ? ` (${idSuffix})` : ''),
        goodsName: item.product?.name ?? '-',
        goodsAmount: item.available ?? '0'
      } as GoodsData;
    });
  }, [goods]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Varenummer',
        accessor: 'goodsIdDisplayName',
        disableFilters: true,
        maxWidth: 200,
        width: 200,
        minWidth: 200
      },
      {
        Header: 'Varenavn',
        accessor: 'goodsName',
        disableFilters: true,
        maxWidth: 175,
        width: 175,
        minWidth: 175
      },
      {
        Header: 'Antal på lager',
        accessor: 'goodsAmount',
        disableFilters: true,
        maxWidth: 100,
        width: 100,
        minWidth: 100
      },
      {
        Header: '',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<GoodsData>) => {
          const { cell } = cellProps;

          return (
            <AddButton
              disabled={
                !hasAvailableGoods(
                  cell.row.original.goodsId ?? '',
                  cell.row.original.goodsBatch ?? '',
                  cell.row.original.goodsSerial ?? '',
                  cell.row.original.goodsAmount
                )
              }
              padding="8px"
              variant="outlined"
              onClick={() =>
                selectGoods(cell.row.original.goodsId, cell.row.original.goodsBatch, cell.row.original.goodsSerial)
              }
            >
              <AddIcon size="12px" />
            </AddButton>
          );
        }
      }
    ] as Column<GoodsData>[];
  }, [hasAvailableGoods, selectGoods]);

  const tableInstance = useTableInstance<GoodsData>(data, columns, {
    initialState: {
      pageSize: 50,
      pageIndex: 0
    }
  });

  return { tableInstance };
};

const AddButton = styled(IconButton)`
  float: right;
  margin-right: ${(props) => props.theme.spacing(12)};
`;
