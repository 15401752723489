import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TaskTypeCategoryDTO, TaskTypeDetailedDTO2 } from '../../../api/api';
import Table from '../../../blocks/table/TableClient';
import Button from '../../../components/button/Button';
import TaskTypeService from '../../../services/TaskTypeService';
import { Row } from '../../../styling/FormStyling';
import useTaskTypeList, { TaskTypeData } from './useTaskTypeList';
import NotificationService from '../../../services/NotificationService';
import { log } from '../../../utils/logging/log';
import { CreateAndUpdateTaskTypeDTO2Extended } from '../../../models/CreateAndUpdateTaskTypeDTOExtended';
import Typography from '../../../components/typography/Typography';
import { AddIcon } from '../../../assets/icons/AddIcon';
import AdminTaskTypesDialog from './AdminTaskTypesDialog';

const AdminTaskTypesView = () => {
  const [taskTypes, setTaskTypes] = useState<TaskTypeDetailedDTO2[]>([]);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [taskTypeCategories, setTaskTypeCategories] = useState<TaskTypeCategoryDTO[]>([]);
  const [specificAssetTypes, setSpecificAssetTypes] = useState<string[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [taskType, setTaskType] = useState<CreateAndUpdateTaskTypeDTO2Extended>({
    isDisabled: true,
    requiresPreparationTask: false,
    enableContractorAgreements: false,
    emergencyOperationalFailure: false
  });
  const { tableInstance } = useTaskTypeList(taskTypes);

  const loadTasks = useCallback(() => {
    setLoadingTypes(true);
    TaskTypeService.getAllTaskTypes()
      .then((res) => {
        setTaskTypes(res.taskTypeDTOs ?? []);
        setTaskTypeCategories(res.taskTypeCategories ?? []);
        setSpecificAssetTypes(res.specificAssetTypes ?? []);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke hente opgavetyper');
      })
      .finally(() => setLoadingTypes(false));
  }, []);

  useEffect(() => {
    loadTasks();
  }, []);

  const handleOpenCreateForm = () => {
    setTaskType({
      isDisabled: true,
      requiresPreparationTask: false,
      enableContractorAgreements: false,
      emergencyOperationalFailure: false,
      ignoreDrivingTimeWhenPlanned: false
    });
    setShowDialog(true);
  };

  const handleDoubleClick = useCallback(
    (rowData: TaskTypeData) => {
      const mappedBody = taskTypes
        .filter((t) => t.id === rowData.id)
        .map((res) => {
          return {
            ...res,
            defaultDepartmentId: res.defaultDepartment?.departmentId,
            taskTypeCategoryIds: res.taskTypeCategories?.map((obj) => obj.id)
          } as CreateAndUpdateTaskTypeDTO2Extended;
        })[0];
      setTaskType(mappedBody);
      setShowDialog(true);
    },
    [taskTypes]
  );

  const closeDialog = (reload?: boolean) => {
    setTaskType({
      isDisabled: true,
      requiresPreparationTask: false,
      enableContractorAgreements: false,
      emergencyOperationalFailure: false
    });
    reload && loadTasks();
    setShowDialog(false);
  };

  const setTaskTypeId = (id: number) => {
    setTaskType((prev) => {
      return { ...prev, id };
    });
  };

  return (
    <Container>
      <Header>
        <Typography fontWeight="bold" variant="h3">
          Opgavetyper
        </Typography>
        <Row>
          <Button onClick={handleOpenCreateForm}>
            <AddIcon size="16px" /> Opret opgavetype
          </Button>
        </Row>
      </Header>
      <Table loading={loadingTypes} tableInstance={tableInstance} showPagination onDoubleClickRow={handleDoubleClick} />

      {showDialog && (
        <AdminTaskTypesDialog
          taskType={taskType}
          closeDialog={(reload) => closeDialog(reload)}
          taskTypeCategories={taskTypeCategories}
          specificAssetTypes={specificAssetTypes}
          setTaskTypeId={setTaskTypeId}
        />
      )}
    </Container>
  );
};

export const FormDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding: ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(7)} 0px
      ${(props) => props.theme.spacing(7)};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: ${(props) => props.theme.spacing(5)};
`;

export const StyledTable = styled(Table)`
  height: 100%;
`;

export default AdminTaskTypesView;
