import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ImageIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 5.137 23.249 L 24.91 23.249 L 18.966 15.325 L 13.628 22.239 L 9.868 17.104 L 5.137 23.249 Z M 2.873 29.598 C 2.226 29.598 1.66 29.355 1.175 28.87 C 0.69 28.385 0.447 27.819 0.447 27.172 L 0.447 2.911 C 0.447 2.264 0.69 1.698 1.175 1.213 C 1.66 0.728 2.226 0.485 2.873 0.485 L 27.134 0.485 C 27.781 0.485 28.347 0.728 28.832 1.213 C 29.317 1.698 29.56 2.264 29.56 2.911 L 29.56 27.172 C 29.56 27.819 29.317 28.385 28.832 28.87 C 28.347 29.355 27.781 29.598 27.134 29.598 L 2.873 29.598 Z M 2.873 27.172 L 27.134 27.172 C 27.134 27.172 27.134 27.172 27.134 27.172 C 27.134 27.172 27.134 27.172 27.134 27.172 L 27.134 2.911 C 27.134 2.911 27.134 2.911 27.134 2.911 C 27.134 2.911 27.134 2.911 27.134 2.911 L 2.873 2.911 C 2.873 2.911 2.873 2.911 2.873 2.911 C 2.873 2.911 2.873 2.911 2.873 2.911 L 2.873 27.172 C 2.873 27.172 2.873 27.172 2.873 27.172 C 2.873 27.172 2.873 27.172 2.873 27.172 Z M 2.873 2.911 C 2.873 2.911 2.873 2.911 2.873 2.911 C 2.873 2.911 2.873 2.911 2.873 2.911 L 2.873 27.172 C 2.873 27.172 2.873 27.172 2.873 27.172 C 2.873 27.172 2.873 27.172 2.873 27.172 C 2.873 27.172 2.873 27.172 2.873 27.172 C 2.873 27.172 2.873 27.172 2.873 27.172 L 2.873 2.911 C 2.873 2.911 2.873 2.911 2.873 2.911 C 2.873 2.911 2.873 2.911 2.873 2.911 Z"></path>
    </IconSvg>
  );
};
