import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const SendIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 0.895 11.026 L 28.095 0.569 C 28.687 0.276 29.375 0.733 29.333 1.392 C 29.328 1.48 29.309 1.566 29.277 1.648 L 18.871 28.992 C 18.637 29.61 17.821 29.743 17.403 29.231 C 17.359 29.178 17.322 29.12 17.293 29.058 L 13.099 20.382 L 21.423 8.469 L 9.533 16.768 L 0.831 12.601 C 0.236 12.315 0.173 11.491 0.719 11.118 C 0.774 11.08 0.833 11.05 0.895 11.026 Z"></path>
    </IconSvg>
  );
};
