import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const VisitIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 14.974 20.859 C 16.562 20.859 17.909 20.305 19.016 19.198 C 20.123 18.092 20.676 16.745 20.676 15.157 C 20.676 13.568 20.123 12.221 19.016 11.115 C 17.909 10.008 16.562 9.454 14.974 9.454 C 13.387 9.454 12.039 10.008 10.932 11.115 C 9.825 12.221 9.271 13.568 9.271 15.157 C 9.271 16.745 9.825 18.092 10.932 19.198 C 12.039 20.305 13.387 20.859 14.974 20.859 Z M 14.974 18.913 C 13.923 18.913 13.034 18.55 12.307 17.823 C 11.58 17.097 11.217 16.208 11.217 15.157 C 11.217 14.105 11.58 13.216 12.307 12.49 C 13.034 11.762 13.923 11.399 14.974 11.399 C 16.025 11.399 16.914 11.762 17.64 12.49 C 18.368 13.216 18.731 14.105 18.731 15.157 C 18.731 16.208 18.368 17.097 17.64 17.823 C 16.914 18.55 16.025 18.913 14.974 18.913 Z M 14.974 25.22 C 11.709 25.22 8.757 24.292 6.118 22.435 C 3.479 20.58 1.512 18.153 0.214 15.157 C 1.512 12.16 3.479 9.733 6.118 7.877 C 8.757 6.021 11.709 5.093 14.974 5.093 C 18.239 5.093 21.191 6.021 23.829 7.877 C 26.469 9.733 28.436 12.16 29.734 15.157 C 28.436 18.153 26.469 20.58 23.829 22.435 C 21.191 24.292 18.239 25.22 14.974 25.22 Z M 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 C 14.974 15.157 14.974 15.157 14.974 15.157 Z M 14.974 23.207 C 17.68 23.207 20.168 22.475 22.438 21.01 C 24.707 19.546 26.435 17.594 27.62 15.157 C 26.435 12.719 24.707 10.767 22.438 9.302 C 20.168 7.838 17.68 7.106 14.974 7.106 C 12.268 7.106 9.78 7.838 7.51 9.302 C 5.24 10.767 3.502 12.719 2.294 15.157 C 3.502 17.594 5.24 19.546 7.51 21.01 C 9.78 22.475 12.268 23.207 14.974 23.207 Z"></path>
    </IconSvg>
  );
};
