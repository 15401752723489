import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const TagIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 28.448 15.663 L 15.535 28.577 C 15.017 29.094 14.3 29.409 13.512 29.409 L 3.466 29.409 C 1.888 29.409 0.597 28.118 0.597 26.54 L 0.597 16.494 C 0.597 15.706 0.912 14.989 1.443 14.457 L 14.357 1.543 C 14.874 1.026 15.592 0.711 16.381 0.711 C 17.17 0.711 17.888 1.026 18.404 1.558 L 28.448 11.602 C 28.98 12.118 29.295 12.836 29.295 13.625 C 29.295 14.414 28.966 15.146 28.448 15.663 Z M 16.381 3.566 L 3.466 16.494 L 3.466 26.54 L 13.512 26.54 L 13.512 26.554 L 26.425 13.639 L 16.381 3.566 Z"
        transform="matrix(-1, 0, 0, -1, 29.892, 30.12)"
      ></path>
      <circle stroke="none" cx="-22.838" cy="7.168" r="2.152" transform="matrix(-1, 0, 0, 1, 0, 0)"></circle>
    </IconSvg>
  );
};
