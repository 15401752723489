import { useRef, useState } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Grow } from '../grow';

interface Props {
  children?: React.ReactNode;
  menuItems: JSX.Element;
}

const ContextMenuTab = (props: Props) => {
  const { children, menuItems } = props;
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref} onClick={() => setOpen((open) => !open)}>
      {children}
      <Grow in={open}>
        <TabsVerticalToolsMenu>{menuItems}</TabsVerticalToolsMenu>
      </Grow>
    </div>
  );
};

const TabsVerticalToolsMenu = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.grey.black90};
  width: max-content;
  padding: 18px 8px;

  > * {
    padding: 12px 16px;
    border-radius: 8px;
  }

  position: absolute;
  bottom: 120px;
  left: 80px;
  z-index: ${(props) => props.theme.zIndex.dialogTools};

  background-color: white;
  border-radius: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
  border: 1px solid ${(props) => props.theme.palette.grey.black10};
`;

export const ContextMenuItem = styled.span`
  :hover {
    background: #f2f4fe;
    cursor: pointer;
  }
`;

export default ContextMenuTab;
