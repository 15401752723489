import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { DateRangeColumnFilter, dateRangeFilterFn, SearchColumnFilter } from '../../../blocks/table/TableFilterUtils';
import useTableInstance from '../../../hooks/useTableInstance';
import KebabMenu from '../../../components/kebab-menu2/KebabMenu';
import { MenuItem } from '../../../components/select/Select';
import { NewsDTO } from '../../../api/api';
import { formatDateHourString, formatDateString, sortDateTime } from '../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../utils/enumUtils';

interface ExtendedNewsDTO extends NewsDTO {
  lastUpdatedHumanReadable: string;
  newsTypeHumanReadable: string;
}

const useNewsList = (newsData: NewsDTO[], deleteNews: (id: number) => void, editNews: (editingNews: NewsDTO) => void) => {
  const data = useMemo(() => {
    return newsData.map((news) => {
      return {
        ...news,
        lastUpdatedHumanReadable: ` ${formatDateString(news.lastUpdated ?? '')} - Kl. ${formatDateHourString(
          news.lastUpdated ?? ''
        )}`,
        newsTypeHumanReadable: news.newsType && getEnumDisplayValue(news.newsType)
      } as ExtendedNewsDTO;
    });
  }, [newsData]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Overskrift',
        accessor: 'title',
        minWidth: 250,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Type',
        accessor: 'newsTypeHumanReadable',
        minWidth: 90,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: 'Link',
        accessor: 'link',
        minWidth: 200,
        Filter: SearchColumnFilter,
        filter: 'text',
        Cell: (cellProps: CellProps<NewsDTO>) => {
          const { cell } = cellProps;
          const news = cell.row.original;
          return news.link && news.link.length > 40 ? `${news.link?.substring(0, 40)}...` : news.link;
        }
      },
      {
        Header: 'Sidst opdateret',
        accessor: 'lastUpdatedHumanReadable',
        minWidth: 100,
        Filter: (tableInstance, x) => DateRangeColumnFilter(tableInstance, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.lastUpdated, b.values.lastUpdated)
      },
      {
        Header: '',
        accessor: 'button',
        minWidth: 90,
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps: CellProps<NewsDTO>) => {
          const { cell } = cellProps;
          const news = cell.row.original;
          return (
            <KebabMenu>
              <MenuItem onClick={() => editNews(news)}>Rediger</MenuItem>
              <MenuItem onClick={() => news.id && deleteNews(news.id)}>Slet</MenuItem>
            </KebabMenu>
          );
        }
      }
    ] as Column<NewsDTO>[];
  }, [data]);

  const tableInstance = useTableInstance<any>(data, columns, {
    initialState: {
      sortBy: [
        {
          id: 'lastUpdatedHumanReadable',
          desc: true
        }
      ]
    }
  });

  return { tableInstance };
};

export default useNewsList;
