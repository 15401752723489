import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const PeopleIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 10.507 17.282 C 7.016 17.282 0.063 18.986 0.063 22.378 L 0.063 24.929 L 20.953 24.929 L 20.953 22.378 C 20.953 18.986 13.999 17.282 10.507 17.282 Z M 3.555 22.015 C 4.808 21.17 7.837 20.194 10.507 20.194 C 13.178 20.194 16.206 21.17 17.461 22.015 L 3.555 22.015 Z M 10.507 14.731 C 13.387 14.731 15.729 12.445 15.729 9.634 C 15.729 6.823 13.387 4.536 10.507 4.536 C 7.628 4.536 5.285 6.823 5.285 9.634 C 5.285 12.445 7.628 14.731 10.507 14.731 Z M 10.507 7.449 C 11.745 7.449 12.745 8.425 12.745 9.634 C 12.745 10.844 11.745 11.82 10.507 11.82 C 9.269 11.82 8.27 10.844 8.27 9.634 C 8.27 8.425 9.269 7.449 10.507 7.449 Z M 21.012 17.369 C 22.743 18.592 23.937 20.224 23.937 22.378 L 23.937 24.929 L 29.904 24.929 L 29.904 22.378 C 29.904 19.437 24.682 17.762 21.012 17.369 Z M 19.46 14.731 C 22.34 14.731 24.682 12.445 24.682 9.634 C 24.682 6.823 22.34 4.536 19.46 4.536 C 18.654 4.536 17.907 4.726 17.222 5.046 C 18.162 6.342 18.714 7.93 18.714 9.634 C 18.714 11.339 18.162 12.926 17.222 14.222 C 17.907 14.543 18.654 14.731 19.46 14.731 Z"
      ></path>
    </IconSvg>
  );
};
