import { useCallback, useState } from 'react';

import { WarehouseDTO } from '../../../../api/api';
import { StyledTable } from '../../components/Styles';
import { useGoodsList } from './useGoodsList';
import AutoComplete from '../../../../components/auto-complete/AutoComplete';
import TextField from '../../../../components/text-field/TextField';
import styled from 'styled-components';
import Typography from '../../../../components/typography/Typography';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { CheckmarkIcon } from '../../../../assets/icons/CheckmarkIcon';
import Button from '../../../../components/button/Button';
import { ProductDetails } from '../../../../models/Goods';

interface Props {
  goods: ProductDetails[];
  selectGoods: (goodsId?: string, amount?: number) => void;
  className?: string;
  warehouses?: WarehouseDTO[];
  selectedWarehouse?: WarehouseDTO;
  setSelectedWarehouseId?: (warehouseId: string) => void;
}

const GoodsList = (props: Props) => {
  const { goods, selectGoods, className, warehouses, setSelectedWarehouseId, selectedWarehouse } = props;

  const [editWarehouse, setEditWarehouse] = useState(false);

  const handleSelectGoods = useCallback(
    (goodsId?: string, amount?: number) => {
      selectGoods(goodsId, amount);
    },
    [selectGoods]
  );

  const { tableInstance } = useGoodsList(goods, handleSelectGoods);

  const handleSelectWarehouse = useCallback(
    (_warehouseId: string) => {
      if (!_warehouseId || _warehouseId === undefined || _warehouseId === '') return;
      setSelectedWarehouseId && setSelectedWarehouseId(_warehouseId);
    },
    [setSelectedWarehouseId]
  );

  return (
    <>
      <Header>
        {selectedWarehouse && !editWarehouse && (
          <>
            <Typography variant="h5" fontWeight="bold">
              Bestilles til: ({selectedWarehouse.warehouseId}) {selectedWarehouse.name}
            </Typography>
            {window._env_.ORDER_GOODS_TO_OTHER_WAREHOUSES_DISABLED === 'false' && warehouses && (
              <StyledButton variant="secondary" onClick={() => setEditWarehouse(true)}>
                <EditIcon /> Rediger
              </StyledButton>
            )}
          </>
        )}
        {window._env_.ORDER_GOODS_TO_OTHER_WAREHOUSES_DISABLED === 'false' && editWarehouse && (
          <>
            <AutoCompleteContainer>
              <AutoComplete
                fullWidth
                onChange={(_, value) => handleSelectWarehouse(value?.warehouseId ?? '')}
                getOptionLabel={(warehouse) => `(${warehouse.warehouseId}) ${warehouse.name}`}
                options={warehouses ?? []}
                value={selectedWarehouse}
                defaultValue={selectedWarehouse}
                isOptionEqualToValue={(option, value) => option.warehouseId === value?.warehouseId}
                renderInput={(params) => <TextField {...params} label={'Til lager'} />}
              />
            </AutoCompleteContainer>
            <StyledCheckmark onClick={() => setEditWarehouse(false)}>
              <CheckmarkIcon />
            </StyledCheckmark>
          </>
        )}
      </Header>
      <StyledTable tableInstance={tableInstance} loading={false} alwaysShowSort showPagination className={className} />
    </>
  );
};

export default GoodsList;

const AutoCompleteContainer = styled.div`
  width: 50%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.spacing(3)} 0;
`;

const StyledButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing(3)};
`;
const StyledCheckmark = styled.div`
  margin-left: ${(props) => props.theme.spacing(3)};
`;
