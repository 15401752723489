import {
  ADD_TIME_ROUTE,
  EDIT_TASK_ROUTE,
  TASKS_DETAILS_DIALOG_ROUTE,
  PROFILE_ROUTE,
  MASS_CREATION,
  TASKS_DETAILS
} from '../constants';

export const routeInPathname = (pathname: string): string => {
  const dialogPaths = [
    TASKS_DETAILS,
    TASKS_DETAILS_DIALOG_ROUTE,
    PROFILE_ROUTE,
    ADD_TIME_ROUTE,
    EDIT_TASK_ROUTE,
    MASS_CREATION
  ];
  let route = '';

  dialogPaths.forEach((path) => {
    if (pathname.includes(path)) {
      route = path;
    }
  });

  return route;
};

export default routeInPathname;
