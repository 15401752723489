import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CalendarIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 25.002 3.332 L 23.546 3.332 L 23.546 0.42 L 20.634 0.42 L 20.634 3.332 L 8.985 3.332 L 8.985 0.42 L 6.073 0.42 L 6.073 3.332 L 4.617 3.332 C 3.001 3.332 1.72 4.643 1.72 6.244 L 1.705 26.629 C 1.705 28.231 3.001 29.541 4.617 29.541 L 25.002 29.541 C 26.604 29.541 27.914 28.231 27.914 26.629 L 27.914 6.244 C 27.914 4.643 26.604 3.332 25.002 3.332 Z M 25.002 26.629 L 4.617 26.629 L 4.617 12.069 L 25.002 12.069 L 25.002 26.629 Z M 25.002 9.156 L 4.617 9.156 L 4.617 6.244 L 25.002 6.244 L 25.002 9.156 Z M 14.81 16.437 L 22.09 16.437 L 22.09 23.717 L 14.81 23.717 L 14.81 16.437 Z"
      ></path>
    </IconSvg>
  );
};
