import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const DoneIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.8334 4.90797L11.6201 20.1346L5.96675 14.4813L7.84675 12.6013L11.6201 16.3746L24.9534 3.0413L26.8334 4.90797ZM23.8867 11.628C24.0601 12.388 24.1667 13.188 24.1667 14.0013C24.1667 19.8946 19.3934 24.668 13.5001 24.668C7.60675 24.668 2.83341 19.8946 2.83341 14.0013C2.83341 8.10797 7.60675 3.33464 13.5001 3.33464C15.6067 3.33464 17.5534 3.94797 19.2067 5.0013L21.1267 3.0813C18.9667 1.5613 16.3401 0.667969 13.5001 0.667969C6.14008 0.667969 0.166748 6.6413 0.166748 14.0013C0.166748 21.3613 6.14008 27.3346 13.5001 27.3346C20.8601 27.3346 26.8334 21.3613 26.8334 14.0013C26.8334 12.4146 26.5401 10.8946 26.0334 9.4813L23.8867 11.628Z" />
    </IconSvg>
  );
};
