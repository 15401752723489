import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CalendarCheckmark = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5401 13.7454L17.1268 12.332L10.6201 18.8387L7.79346 16.012L6.38012 17.4254L10.6201 21.6654L18.5401 13.7454ZM21.8335 2.9987H20.5001V0.332031H17.8335V2.9987H7.16679V0.332031H4.50012V2.9987H3.16679C1.68679 2.9987 0.513455 4.1987 0.513455 5.66536L0.500122 24.332C0.500122 25.7987 1.68679 26.9987 3.16679 26.9987H21.8335C23.3001 26.9987 24.5001 25.7987 24.5001 24.332V5.66536C24.5001 4.1987 23.3001 2.9987 21.8335 2.9987ZM21.8335 24.332H3.16679V9.66536H21.8335V24.332Z" />
    </IconSvg>
  );
};
