import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const RelationIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M 23.864 9.418 C 22.855 9.418 21.929 9.218 21.088 8.815 C 20.247 8.404 19.58 7.86 19.087 7.185 C 18.581 6.499 18.328 5.741 18.328 4.909 C 18.328 4.086 18.581 3.338 19.087 2.662 C 19.58 1.976 20.247 1.433 21.088 1.032 C 21.929 0.621 22.855 0.414 23.864 0.414 C 24.885 0.414 25.815 0.621 26.656 1.032 C 27.497 1.433 28.164 1.976 28.657 2.662 C 29.15 3.338 29.398 4.086 29.398 4.909 C 29.398 5.741 29.15 6.499 28.657 7.185 C 28.164 7.86 27.497 8.404 26.656 8.815 C 25.815 9.218 24.885 9.418 23.864 9.418 Z M 25.512 9.665 C 25.512 11.091 24.997 12.284 23.964 13.243 C 22.933 14.201 21.415 14.892 19.406 15.311 L 11.365 17.065 C 8.83 17.623 7.563 18.655 7.563 20.163 L 7.563 21.287 L 4.284 21.287 L 4.284 20.163 C 4.284 18.738 4.799 17.549 5.832 16.6 C 6.863 15.641 8.381 14.947 10.39 14.517 L 18.431 12.763 C 20.966 12.213 22.233 11.182 22.233 9.665 L 22.233 8.541 L 25.512 8.541 L 25.512 9.665 Z M 5.915 20.397 C 6.936 20.397 7.867 20.602 8.708 21.013 C 9.538 21.424 10.205 21.973 10.709 22.657 C 11.215 23.335 11.468 24.089 11.468 24.919 C 11.468 25.749 11.215 26.503 10.709 27.181 C 10.205 27.865 9.538 28.41 8.708 28.812 C 7.867 29.213 6.936 29.414 5.915 29.414 C 4.895 29.414 3.97 29.213 3.14 28.812 C 2.299 28.41 1.632 27.865 1.139 27.181 C 0.646 26.503 0.398 25.749 0.398 24.919 C 0.398 24.089 0.646 23.335 1.139 22.657 C 1.632 21.973 2.299 21.424 3.14 21.013 C 3.97 20.602 4.895 20.397 5.915 20.397 Z M 5.915 22.945 C 5.243 22.945 4.677 23.138 4.218 23.52 C 3.746 23.895 3.509 24.361 3.509 24.919 C 3.509 25.466 3.746 25.929 4.218 26.302 C 4.688 26.686 5.254 26.879 5.915 26.879 C 6.611 26.879 7.189 26.686 7.648 26.302 C 8.107 25.929 8.337 25.466 8.337 24.919 C 8.337 24.361 8.107 23.895 7.648 23.52 C 7.189 23.138 6.611 22.945 5.915 22.945 Z"
        stroke="none"
      ></path>
    </IconSvg>
  );
};
