import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from '../../assets/icons/CloseIcon';
import IconButton from '../../components/icon-button/IconButton';
import n1LightTheme from '../../theme/light-theme';

interface Props {
  children?: React.ReactNode;
  onClickHandler: () => void;
  onCloseHandler: () => void;
}

const MinimizedTab = (props: Props) => {
  const { children, onClickHandler, onCloseHandler } = props;

  const closeIconClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCloseHandler && onCloseHandler();
  };

  return (
    <StyledTab onClick={onClickHandler}>
      <StyledContentContainer>{children}</StyledContentContainer>
      <StyledButtonContainer>
        <IconButton padding="4px" onClick={(event: React.MouseEvent<HTMLElement>) => closeIconClickHandler(event)}>
          <CloseIcon size="12px" padding="0" color={n1LightTheme.palette.text.secondary} />
        </IconButton>
      </StyledButtonContainer>
    </StyledTab>
  );
};
const StyledTab = styled((props) => <div {...props} />)`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;
  margin: 0 0.25rem;
  max-width: 225px;

  border-radius: 15px 15px 0 0;
  background-color: ${(props) => props.theme.palette.functions.action.primary};
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => ({ ...props.theme.typography.span })};

  &:hover {
    cursor: pointer;
    padding: 0.75rem 1.25rem;
  }
`;

const StyledContentContainer = styled((props) => <div {...props} />)`
  margin-right: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledButtonContainer = styled((props) => <div {...props} />)`
  margin-left: auto;
`;

export default MinimizedTab;
