import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const PlayIconOutline = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 4.171 29.609 L 4.171 0.412 L 27.111 15.011 L 4.171 29.609 Z M 7.299 15.011 Z M 7.299 23.926 L 21.324 15.011 L 7.299 6.095 L 7.299 23.926 Z"></path>
    </IconSvg>
  );
};
