import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { InfoIcon } from '../../assets/icons/InfoIcon';
import ToolTip from '../tool-tip/ToolTip';
import Typography from '../typography/Typography';

interface Props {
  label?: string;
  icon?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helpertext?: string;
}

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, checked, disabled, onChange, error, icon, helpertext, ...rest } = props;

  return (
    <Label>
      <Input checked={checked} ref={ref} disabled={disabled} onChange={onChange} {...rest} type="checkbox" error={error} />
      {icon}
      <Text error={error} disabled={disabled}>
        <Typography>{label}</Typography>
        {helpertext && (
          <ToolTip text={helpertext} xPosition="right" yPosition="top">
            <StyledInfoIcon size="16px" />
          </ToolTip>
        )}
      </Text>
    </Label>
  );
});

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.p<{ error?: boolean; disabled?: boolean }>`
  color: ${(props) => props.error && `red`};
  color: ${(props) => props.disabled && `grey`};
  display: flex;
`;

const Input = styled.input<{ error?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-bottom: 0.45rem;
  outline: ${(props) => props.error && '2px solid red'};
  outline-offset: ${(props) => props.error && '-2px'};
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
  display: flex;
  align-items: center;
`;

export default Checkbox;
