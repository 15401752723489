import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ScheduleIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 20.882 15.585 C 17.045 15.585 13.932 18.698 13.932 22.534 C 13.932 26.371 17.045 29.484 20.882 29.484 C 24.718 29.484 27.831 26.371 27.831 22.534 C 27.831 18.698 24.718 15.585 20.882 15.585 Z M 23.175 25.801 L 20.187 22.812 L 20.187 18.365 L 21.576 18.365 L 21.576 22.242 L 24.148 24.814 L 23.175 25.801 Z M 22.271 3.075 L 17.851 3.075 C 17.268 1.463 15.739 0.295 13.932 0.295 C 12.125 0.295 10.596 1.463 10.012 3.075 L 5.592 3.075 C 4.063 3.075 2.812 4.326 2.812 5.855 L 2.812 26.704 C 2.812 28.233 4.063 29.484 5.592 29.484 L 14.085 29.484 C 13.265 28.692 12.597 27.747 12.111 26.704 L 5.592 26.704 L 5.592 5.855 L 8.372 5.855 L 8.372 10.025 L 19.492 10.025 L 19.492 5.855 L 22.271 5.855 L 22.271 12.916 C 23.258 13.055 24.19 13.347 25.051 13.75 L 25.051 5.855 C 25.051 4.326 23.8 3.075 22.271 3.075 Z M 13.932 5.855 C 13.167 5.855 12.542 5.229 12.542 4.465 C 12.542 3.7 13.167 3.075 13.932 3.075 C 14.696 3.075 15.322 3.7 15.322 4.465 C 15.322 5.229 14.696 5.855 13.932 5.855 Z"
      ></path>
    </IconSvg>
  );
};
