import { TaskCreationTask } from '../../../models/TaskCreationTask';
import { MassCreationTask } from '../../../models/MassCreationTask';
import { TabProps } from '../../../blocks/tabs-vertical/TabsVertical';
import FileUpload from '../../../components/file-upload/FileUpload';

import { Container, Section, SectionHeader } from '../../../styling/FormStyling';

import AssetDataSection from './sections/asset-data/AssetDataSection';
import DetailsSection from './sections/details/DetailsSection';
import NotesSection from './sections/notes/NotesSection';
import CustomerAppointmentSection from './sections/customer-appointment/CustomerAppointmentSection';
import TaskTypeTemplateComponent from '../../../blocks/tasktype-template-component/TaskTypeTemplateComponent';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateMassCreationTask } from '../../../stateManagement/reducers/massCreationReducer';
import { updateTask } from '../../../stateManagement/reducers/taskCreationReducer';
import { extractAttributeValuesFromComponents } from '../../../utils/componentHandling';
import StretchesSection, { StretchContainer } from './sections/stretches/StretchesSection';
import { StretchDTO } from '../../../api/api';

export interface CreationStatus {
  show: boolean;
  text: string;
}

interface Props extends TabProps {
  task: TaskCreationTask | MassCreationTask;
  onFileError?: (errors: Map<number, string>) => void;
  handleFileChanges?: (files: File[]) => void;
  files?: File[];
  isMassCreation?: boolean;
  handleGoToAsset?: () => void;
}

const DetailsStep = (props: Props) => {
  const { task, onFileError, handleFileChanges, files, isMassCreation, handleGoToAsset } = props;

  const dispatch = useDispatch();
  const handleComponentChange = useCallback(
    (componentId: number, fieldId: number, value: any) => {
      if (!task.components) return;

      const newComponents = [...task.components];
      const compIndex = newComponents.findIndex((c) => c.id === componentId);
      const comp = newComponents[compIndex];
      let attributeIndex = comp?.attributes?.findIndex((a) => a.attributeId === fieldId);
      if (comp?.attributes && attributeIndex != null && comp?.attributes[attributeIndex]) {
        let attribute = comp?.attributes[attributeIndex] ?? undefined;
        let newAttributes = [...comp.attributes];
        newAttributes[attributeIndex] = { ...attribute, value: value };

        newComponents[compIndex] = { ...newComponents[compIndex], attributes: newAttributes };
        if (isMassCreation) {
          dispatch(
            updateMassCreationTask({
              components: newComponents,
              componentValues: extractAttributeValuesFromComponents(newComponents)
            })
          );
        } else {
          dispatch(updateTask({ id: (task as TaskCreationTask).taskCreationId ?? '', task: { components: newComponents } }));
        }
      }
    },
    [dispatch, isMassCreation, task]
  );

  const onStretchChangeHandler = (stretches: StretchDTO[]) => {
    dispatch(
      updateTask({
        id: (task as TaskCreationTask).taskCreationId ?? '',
        task: { stretches }
      })
    );
  };

  return (
    <Container>
      <Section noBorder>
        <SectionHeader>Detaljer</SectionHeader>
        <DetailsSection task={task} isMassCreation={isMassCreation} />
      </Section>
      {!isMassCreation && (
        <Section>
          <SectionHeader>Er der ønske om at få lavet en tidsaftale?</SectionHeader>
          <CustomerAppointmentSection task={task} />
        </Section>
      )}
      {!isMassCreation && (
        <Section>
          <SectionHeader>Vedhæft filer</SectionHeader>
          <FileUpload
            files={files ?? []}
            onError={onFileError}
            onFileSelection={handleFileChanges}
            onRemoveSelectedFile={handleFileChanges}
          />
        </Section>
      )}
      <Section>
        <SectionHeader>Bemærkninger til planlægning</SectionHeader>
        <NotesSection task={task} isMassCreation={isMassCreation} />
      </Section>
      {!isMassCreation && (
        <Section>
          <SectionHeader>Asset data</SectionHeader>
          <AssetDataSection task={task} />
        </Section>
      )}
      {task.components &&
        task.components.map((c) => (
          <TaskTypeTemplateComponent
            component={c}
            onBlur={handleComponentChange}
            mode={'create'}
            showError={task.dynamicFieldsErrors}
            key={c.id}
          />
        ))}

      {task?.taskType?.enableStretches && !isMassCreation && (
        <Section>
          <SectionHeader>Strækninger</SectionHeader>
          <StretchContainer>
            <StretchesSection
              onStretchChange={(value) => onStretchChangeHandler(value)}
              value={(task as TaskCreationTask).stretches}
              mode={'create'}
              isEditable={true}
              readonlyMode={false}
              handleGoToAsset={handleGoToAsset}
            />
          </StretchContainer>
        </Section>
      )}
    </Container>
  );
};

export default DetailsStep;
