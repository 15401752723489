import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveChecklistLockId,
  selectConnection,
  selectLockedChecklist,
  setActiveChecklistLockId
} from '../stateManagement/reducers/signalRReducer';
import { getInitialsFromEmail } from '../utils/initialsUtils';

const useChecklistLock = () => {
  const connection = useSelector(selectConnection);
  const lockedChecklists = useSelector(selectLockedChecklist);
  const activeChecklistLock = useSelector(selectActiveChecklistLockId);

  const dispatch = useDispatch();

  const addChecklistLock = useCallback(
    (checklistId: number) => {
      connection?.send('AddChecklistLock', checklistId);
      dispatch(setActiveChecklistLockId(checklistId));
    },
    [connection, dispatch]
  );

  const releaseChecklistLock = useCallback(
    (checklistId?: number) => {
      if (!checklistId && activeChecklistLock === -1) return;
      connection?.send('ReleaseChecklistLock', checklistId ?? activeChecklistLock);
      dispatch(setActiveChecklistLockId(-1));
    },
    [activeChecklistLock, connection, dispatch]
  );

  const checklistLockedBy = useCallback(
    (checklistId: number) => {
      const emailList: string[] = (lockedChecklists ?? [])
        .filter((checklist) => checklist.workTaskChecklistId === checklistId && checklist.email)
        .map((checklist) => getInitialsFromEmail(checklist.email ?? ''));
      return emailList;
    },
    [lockedChecklists]
  );

  const addChecklistEdited = useCallback(
    (checklistId: number) => {
      connection?.send('AddChecklistEditedLock', checklistId);
    },
    [connection]
  );

  return {
    addChecklistLock,
    releaseChecklistLock,
    checklistLockedBy,
    addChecklistEdited
  };
};

export default useChecklistLock;
