import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { TaskTypeBaseDTO } from '../../api/api';

import InternalTimeForm from '../../blocks/internal-time-form/InternalTimeForm';
import InternalTimePicker from '../../blocks/internal-time-picker/InternalTimePicker';
import Typography from '../../components/typography/Typography';
import { WhiteAreaHorizontalPadding, WhiteAreaTopPadding } from '../../styling/StylingConstants';

const InternalTimeTechnicianView = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [internalTaskType, setInternalTaskType] = useState<TaskTypeBaseDTO>();

  const handleOpenDialog = useCallback((internalTaskType: TaskTypeBaseDTO) => {
    setInternalTaskType(internalTaskType);
    setDialogOpen(true);
  }, []);

  return (
    <Container>
      <Typography variant="h2">Interne opgaver</Typography>
      <Typography variant="p">Vælg hvad du gerne vil melde til kontoret</Typography>
      <InternalTimePicker onClick={handleOpenDialog} />

      {dialogOpen && internalTaskType && (
        <InternalTimeForm onClose={() => setDialogOpen(false)} internalTaskType={internalTaskType} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: ${WhiteAreaTopPadding}px ${WhiteAreaHorizontalPadding}px 0px;
`;

export default InternalTimeTechnicianView;
