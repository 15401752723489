import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const NewsIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 13C18.7261 13 18.5 12.7739 18.5 12.5C18.5 12.2261 18.7261 12 19 12H21C21.2739 12 21.5 12.2261 21.5 12.5C21.5 12.7739 21.2739 13 21 13H19Z"
        fill="#343434"
        stroke="#343434"
      />
      <path
        d="M16.59 17.32C16.26 17.76 16.35 18.37 16.79 18.69C17.32 19.08 17.88 19.5 18.41 19.9C18.85 20.23 19.47 20.14 19.79 19.7C19.79 19.69 19.8 19.69 19.8 19.68C20.13 19.24 20.04 18.62 19.6 18.3C19.07 17.9 18.51 17.48 17.99 17.09C17.55 16.76 16.93 16.86 16.6 17.3C16.6 17.31 16.59 17.32 16.59 17.32Z"
        fill="#343434"
      />
      <path
        d="M19.8098 5.31004C19.8098 5.30004 19.7998 5.30004 19.7998 5.29004C19.4698 4.85004 18.8498 4.76005 18.4198 5.09005C17.8898 5.49005 17.3198 5.91004 16.7998 6.31004C16.3598 6.64004 16.2798 7.26005 16.6098 7.69005C16.6098 7.70005 16.6198 7.70005 16.6198 7.71005C16.9498 8.15005 17.5598 8.24004 17.9998 7.91004C18.5298 7.52004 19.0898 7.09004 19.6198 6.69004C20.0498 6.37004 20.1298 5.75004 19.8098 5.31004Z"
        fill="#343434"
      />
      <path
        d="M8 9.5H4C2.9 9.5 2 10.4 2 11.5V13.5C2 14.6 2.9 15.5 4 15.5H5V18.5C5 19.05 5.45 19.5 6 19.5C6.55 19.5 7 19.05 7 18.5V15.5H8L13 18.5V6.5L8 9.5Z"
        fill="#343434"
      />
      <path d="M15.5 12.5C15.5 11.17 14.92 9.97002 14 9.15002V15.84C14.92 15.03 15.5 13.83 15.5 12.5Z" fill="#343434" />
    </IconSvg>
  );
};
