import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WorkPlanItemDTO, WorkTaskStatus } from '../../../../../api/api';
import Typography from '../../../../../components/typography/Typography';
import { getDistanceInDaysFromToday, getFullDateString } from '../../../../../utils/dateHandling';

interface Props {
  tasks: WorkPlanItemDTO[];
  handleOnClick: () => void;
  expanded?: boolean;
  date?: Date;
  isCalendar?: boolean;
  isUnfinished?: boolean;
}

const getText = (date: Date) => {
  let prefix;
  switch (getDistanceInDaysFromToday(date)) {
    case 0:
      prefix = 'I dag, ';
      break;
    case 1:
      prefix = 'I morgen, ';
      break;
    case -1:
      prefix = 'I går, ';
      break;
    default:
      prefix = '';
  }
  return prefix + getFullDateString(date ?? new Date());
};

const DayOverviewHeader = (props: Props) => {
  const { tasks, handleOnClick, expanded, date, isCalendar, isUnfinished } = props;
  const [dateText, setDateText] = useState('');

  useEffect(() => {
    setDateText(getText(date ?? new Date()));
  }, [date]);

  const finished = tasks.reduce((sum, task) => {
    if (
      task.status === WorkTaskStatus.Completed ||
      task.status === WorkTaskStatus.Pause ||
      task.status === WorkTaskStatus.Processed
    ) {
      return sum + 1;
    }
    return sum;
  }, 0);

  const onClickHeader: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    handleOnClick();
  };

  return (
    <StyledShell expanded={expanded} onClick={onClickHeader} data-testid="dayOverviewHeader" isMarked={isUnfinished}>
      <Typography variant="h4" fontWeight="bold" color={expanded ? 'textPrimary' : 'greyedOut'}>
        {isUnfinished ? 'Uafsluttede opgaver' : dateText}
      </Typography>
      <StyledRightDiv>
        {date && getDistanceInDaysFromToday(date) === 0 && !isCalendar ? (
          <>
            {tasks.length !== 0 && (
              <StyledRightDiv>
                <Typography variant="span">{`${finished} af ${tasks.length} afsluttet i dag`}</Typography>
              </StyledRightDiv>
            )}
          </>
        ) : (
          <StyledRightDiv>
            <Typography>
              <b>{tasks.length}</b> {tasks.length === 1 ? 'opgave ' : 'opgaver '}
              {isUnfinished ? 'kræver din opmærksomhed' : ''}
            </Typography>
          </StyledRightDiv>
        )}
      </StyledRightDiv>
    </StyledShell>
  );
};

export const StyledShell = styled.div<{ expanded?: boolean; isMarked?: boolean }>`
  height: 70px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;

  text-align: start;
  border-top: 0;

  background-color: ${(props) =>
    props.isMarked ? props.theme.palette.main.yellow.tertiary : props.theme.palette.grey.black5};

  &:hover {
    cursor: pointer;
  }
`;

const StyledRightDiv = styled.div`
  display: flex;
  align-items: center;
`;

export default DayOverviewHeader;
