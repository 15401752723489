import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import IconButton from '../icon-button/IconButton';
import { ProgressIcon } from '../../assets/icons/ProgressIcon';

type Direction = 'left' | 'right' | 'bottom-left' | 'bottom-right';

interface Props {
  input?: React.ReactNode;
  children?: React.ReactNode;
  direction?: Direction;
}

const DropdownComponent = (props: Props) => {
  const { input, children, direction } = props;

  const [open, setOpen] = useState<boolean>(false);

  let btnRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(btnRef, () => setOpen(false));

  const handleToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    if (open) {
      (document.activeElement as HTMLElement)?.blur();
    }
    setOpen((open) => !open);
  };

  return (
    <Container ref={btnRef} direction={direction ?? 'left'}>
      <InputContainer onClick={handleToggle}>
        {input ? (
          input
        ) : (
          <IconButton variant="outlined">
            <ProgressIcon size="16px" />
          </IconButton>
        )}
      </InputContainer>

      <RelativeContainer open={open}>
        <DropdownContainer direction={direction ?? 'left'}>
          <DropdownContent>{children}</DropdownContent>
        </DropdownContainer>
      </RelativeContainer>
    </Container>
  );
};

interface DirectionProps {
  direction?: Direction;
}

const Container = styled.div<DirectionProps>`
  display: flex;
  width: fit-content;
  flex-direction: ${(props) => (props.direction === 'bottom-left' || props.direction === 'bottom-right' ? 'column' : '')};
  align-items: ${(props) => (props.direction === 'bottom-left' ? 'flex-end' : '')};
`;

const InputContainer = styled.div`
  cursor: pointer;
  border-radius: 22px;
`;

const expand = keyframes`
    0% {
        transform: scaleY(0);
        opacity: 0;
    }
    80% {
        transform: scaleY(1.05);
    }
    100% {
        transform: scaleY(1);
        opacity: 1;
    }
`;

const RelativeContainer = styled.div<{ open: boolean }>`
  position: relative;
  display: flex;
  z-index: ${(props) => props.theme.zIndex.kebabMenu};
  transform: scaleY(0);
  opacity: 0;

  ${({ open }) =>
    open &&
    css`
      animation: ${expand} 200ms ease-in-out forwards;
      transform-origin: top center;
    `}
`;

const rightStyles = css<DirectionProps>`
  left: ${(props) => (props.direction === 'right' ? '16px' : '')};
`;
const leftStyles = css<DirectionProps>`
  right: ${(props) => (props.direction === 'left' ? '50px' : '')};
`;
const bottomRightStyles = css<DirectionProps>`
  margin-top: 16px;
  left: 0;
`;
const bottomLeftStyles = css<DirectionProps>`
  margin-top: 16px;
  right: 0;
`;

const DropdownContainer = styled.div<DirectionProps>`
  z-index: ${(props) => props.theme.zIndex.dialogTools};
  position: absolute;
  top: 0;

  overflow: hidden;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.grey.black10};

  ${(props) => props.direction === 'right' && rightStyles};
  ${(props) => props.direction === 'left' && leftStyles};
  ${(props) => props.direction === 'bottom-right' && bottomRightStyles};
  ${(props) => props.direction === 'bottom-left' && bottomLeftStyles};
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: 60vh;
  width: max-content;
  row-gap: 16px;

  background: ${(props) => props.theme.palette.background.primary};
  padding: 16px;
  border-radius: 8px;
`;

export default DropdownComponent;
