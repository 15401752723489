import styled from 'styled-components';
import {
  ComponentCommentResponseDTO,
  ComponentFieldChangeDTO,
  ComponentFileUploadResponseDTO,
  ComponentPropertyDTO,
  FileParameter,
  StationComponentDTO
} from '../../../../../api/api';
import { useCallback, useEffect, useState } from 'react';
import AssetService from '../../../../../services/AssetService';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '../../../../../components/icon-button/IconButton';
import { BackArrowIcon } from '../../../../../assets/icons/BackArrowIcon';
import Typography from '../../../../../components/typography/Typography';
import { log } from '../../../../../utils/logging/log';
import NotificationService from '../../../../../services/NotificationService';
import DataFieldUpdateDialog from './DataFieldUpdateDialog';
import { SettingsIcon } from '../../../../../assets/icons/SettingsIcon';
import IconBadge from '../../../../../components/icon-badge/IconBadge';
import { TargetIcon } from '../../../../../assets/icons/TargetIcon';
import { PageType } from './GisView';
import CommentsSection from './CommentsSection';
import GisComponentService from '../../../../../services/GisComponentService';
import Button from '../../../../../components/button/Button';
import Form from '../../../../../components/form/Form';
import UploadedFilesSection from '../UploadedFilesSection';
import { renameFile } from '../../../../../utils/fileUtils';
import { getGuid } from '../../../../../utils/guidGenerating';
import { useConfirmationDialog } from '../../../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../../../stateManagement/reducers/confirmDialogReducer';
import { formatDateString } from '../../../../../utils/dateHandling';
import { DownloadInfo, ImageType } from '../../../../../models/DownloadInfo';

interface Props {
  component: StationComponentDTO;
  componentFieldChanges: ComponentFieldChangeDTO[];
  componentComments: ComponentCommentResponseDTO[];
  onSubmitComment: (comment: ComponentCommentResponseDTO) => void;
  onDeleteComment: (commentId: number) => void;
  componentFiles: ComponentFileUploadResponseDTO[];
  onDeleteFile: (file: number) => void;
  workTaskId: string | undefined;
  assetId: string | undefined;
  fieldUpdated: () => void;
  setPage: (page: PageType) => void;
}

const dialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at du vil slette denne fil?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const ComponentDetails = (props: Props) => {
  const {
    component,
    componentFieldChanges,
    componentComments,
    onSubmitComment,
    onDeleteComment,
    componentFiles,
    onDeleteFile,
    workTaskId,
    assetId,
    fieldUpdated,
    setPage
  } = props;
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<ComponentPropertyDTO | undefined>(undefined);
  const [properties, setProperties] = useState<ComponentPropertyDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    if (!component.objectId || !component.componentType) return;
    setLoading(true);
    AssetService.getComponentDetails(component.objectId, component.componentType)
      .then((data) => {
        if (!data.properties) return;
        setProperties(data.properties);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente felter.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [component.componentType, component.objectId]);

  const getChanges = (comp: ComponentPropertyDTO) => {
    if (!componentFieldChanges) return [];
    return componentFieldChanges
      .filter((change) => change.fieldName === comp.name)
      .map((change) => ({
        id: change.fieldName,
        from: change.oldValue ?? '-- Ingen værdi --',
        to: change.newValue,
        date: formatDateString(change?.createdDateTime ?? '')
      }));
  };

  const openModal = (componentProperty: ComponentPropertyDTO | undefined) => {
    setSelectedProperty(componentProperty);
    setVisibleModal(true);
  };

  const onClose = (propertyIsChanged: boolean) => {
    setSelectedProperty(undefined);
    setVisibleModal(false);
    if (propertyIsChanged) {
      fieldUpdated();
    }
  };

  const submitComment = (comment: string) => {
    if (!component || !workTaskId) return;
    setButtonLoading(true);
    GisComponentService.addComponentComment({
      workTaskId: parseInt(workTaskId),
      componentObjectId: component.objectId?.toString(),
      value: comment
    })
      .then((submittedComment: ComponentCommentResponseDTO) => {
        onSubmitComment(submittedComment);
        setAddingComment(false);
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke sende kommentar');
        log(error);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const deleteComment = (componentCommentId: number) => {
    if (!component || componentCommentId === -1) return;
    setButtonLoading(true);
    GisComponentService.deleteComponentComment(componentCommentId)
      .then(() => {
        onDeleteComment(componentCommentId);
      })
      .catch((error) => {
        NotificationService.error('Kunne ikke slette kommentar');
        log(error);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const uploadFiles = async (files: FileList | null) => {
    if (!workTaskId || !assetId || !component?.objectId || !files) return;
    setLoading(true);
    const componentObjectIdString = component.objectId?.toString() ?? '';
    const filesToUpload: FileParameter[] = [];
    Array.from(files).forEach((file) => {
      if (file.name === 'image.jpg') file = renameFile(file, getGuid());
      filesToUpload.push({ data: file, fileName: file.name });
    });

    if (filesToUpload.length > 0) {
      const promises: Array<Promise<void | ComponentFileUploadResponseDTO>> = [];

      filesToUpload.forEach((file) => {
        promises.push(GisComponentService.addComponentFile(parseInt(workTaskId), assetId, componentObjectIdString, file));
        promises.push(
          GisComponentService.addComponentFileData({
            workTaskId: parseInt(workTaskId),
            assetId: assetId,
            componentId: componentObjectIdString,
            fileName: file.fileName
          })
        );
      });
      Promise.allSettled(promises)
        .then((results) => {
          const rejectedPromises = results.filter((r) => r.status === 'rejected');
          const fulfilledPromises = results.filter((r) => r.status === 'fulfilled');
          if (rejectedPromises.length > 0) {
            NotificationService.error(`Der opstod fejl under upload af en eller flere filer`);
          } else {
            NotificationService.success(`Der blev uploaded ${fulfilledPromises.length / 2} fil(er)`);
          }
        })
        .catch((e) => {
          log(e);
          NotificationService.error(`Der opstod fejl under upload af en eller flere filer`);
        })
        .finally(() => {
          setLoading(false);
          fieldUpdated();
        });
    }
  };

  const downloadFile = useCallback(
    (fileName: string): DownloadInfo => {
      if (workTaskId === undefined || !assetId || !component.objectId || !fileName)
        throw new Error('if (workTaskId === undefined || !assetId || !component.objectId || !fileName)');
      return {
        imageType: ImageType.GisComponentDownloadInfo,
        taskId: +workTaskId,
        assetId: assetId,
        objectId: component.objectId,
        filename: fileName
      };
    },
    [workTaskId, assetId, component.objectId]
  );

  const getThumbNail = useCallback(async (fileName: string) => {
    if (workTaskId === undefined || !assetId || !component.objectId)
      throw new Error('if (workTaskId === undefined || !assetId || !component.objectId)');
    try {
      let res = await GisComponentService.getComponentThumbnail(
        parseInt(workTaskId),
        assetId,
        component.objectId.toString(),
        fileName
      );
      return URL.createObjectURL(res.data);
    } catch (error) {
      log(error);
      NotificationService.error('Kunne ikke downloade billede.');
      throw error;
    }
  }, []);

  const deleteFile = async (fileId: number) => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      setLoading(true);
      GisComponentService.deleteComponentFile(fileId)
        .then(() => {
          onDeleteFile(fileId);
        })
        .catch((error) => {
          NotificationService.error('Kunne ikke slette fil');
          log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          {properties.length === 0 ? (
            <Typography>Der er ikke nogen felter at vise</Typography>
          ) : (
            <Container>
              <Title variant="h5" fontWeight="bold">
                <IconButton onClick={() => setPage('standard')}>
                  <BackArrowIcon size="18px" />
                </IconButton>
                {component?.name} {component?.objectId}
              </Title>
              {properties.map((componentProperty: ComponentPropertyDTO) => (
                <ComponentProperty key={componentProperty.name}>
                  <Row>
                    <LeftAlign>
                      <Typography variant="span">{componentProperty.name}</Typography>{' '}
                    </LeftAlign>
                    {getChanges(componentProperty)?.length > 0 && (
                      <RightAlign>
                        <IconBadge Icon={() => <TargetIcon width="16" height="16" />} text="Data opdateret" />
                      </RightAlign>
                    )}
                    <RightAlign onClick={() => openModal(componentProperty)}>
                      <SettingsIcon size="18px" />
                    </RightAlign>
                  </Row>
                  <Row>
                    <Typography fontWeight="bold">{componentProperty.value ?? '-- Ingen værdi --'}</Typography>
                  </Row>
                  {getChanges(componentProperty).map((dataChange) => (
                    <DataFieldUpdate key={dataChange.id}>
                      <Row>
                        <LeftAlign>
                          <Typography fontWeight="bold">Data opdatering</Typography>
                          <Typography>{dataChange.from}</Typography>
                          <Typography> → </Typography>
                          <Typography>{dataChange.to}</Typography>
                        </LeftAlign>
                        <RightAlign>{dataChange.date}</RightAlign>
                      </Row>
                    </DataFieldUpdate>
                  ))}
                </ComponentProperty>
              ))}
              {visibleModal && (
                <DataFieldUpdateDialog
                  onClose={onClose}
                  component={component}
                  property={selectedProperty}
                  workTaskId={workTaskId}
                />
              )}
              <CommentsSection
                comments={componentComments}
                description="Kommentaren bruges af dokumentationsafdelingen, hvorfra de kan bruge dit input til at opdatatere GIS data."
                onDeleteComment={(id) => deleteComment(id)}
              >
                {addingComment ? (
                  <Form
                    handleSubmitCallback={(text) => submitComment(text)}
                    cancelButtonText="Fortryd"
                    handleCancelCallback={() => setAddingComment(false)}
                    isLoading={buttonLoading}
                    submitButtonText="Send kommentar"
                  />
                ) : (
                  <Button variant="secondary" onClick={() => setAddingComment(true)}>
                    Tilføj Kommentar
                  </Button>
                )}
              </CommentsSection>
              <UploadedFilesSection
                description="Tilknyt billede til kommentar"
                files={componentFiles}
                handleDownload={(fileName) => downloadFile(fileName)}
                handleRemove={(fileId) => deleteFile(fileId)}
                handleUpload={uploadFiles}
                handleThumbnail={(fileName: string) => getThumbNail(fileName)}
              ></UploadedFilesSection>
            </Container>
          )}
        </>
      )}
    </>
  );
};

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(4)} 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(5)};
`;

const DataFieldUpdate = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey.black40};
  background-color: ${(props) => props.theme.palette.grey.black5};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(4)};
  font-size: 13px;
`;

const ComponentProperty = styled.div`
  position: relative;
  margin: ${(props) => props.theme.spacing(4)} 0;
  border: 1px solid ${(props) => props.theme.palette.grey.black20};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(4)};
`;

const CenteredCircularProgress = styled(CircularProgress)`
  position: sticky;
  top: 40%;
  left: 45%;
`;

const LeftAlign = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${(props) => props.theme.spacing(2)};
`;

const RightAlign = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(4)};
`;

export default ComponentDetails;
