import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const UnlockIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 11.024H5C3.89543 11.024 3 11.9195 3 13.024V20.024C3 21.1286 3.89543 22.024 5 22.024H19C20.1046 22.024 21 21.1286 21 20.024V13.024C21 11.9195 20.1046 11.024 19 11.024Z"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 11.024V7.02405C6.99876 5.7841 7.45828 4.58791 8.28938 3.66772C9.12047 2.74752 10.2638 2.16895 11.4975 2.04434C12.7312 1.91972 13.9671 2.25795 14.9655 2.99336C15.9638 3.72877 16.6533 4.80889 16.9 6.02405"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </IconSvg>
  );
};
