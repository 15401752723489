import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const DeleteIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="delete-icon" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 21.86 10.078 L 21.86 26.355 L 8.839 26.355 L 8.839 10.078 L 21.86 10.078 M 19.42 0.312 L 11.281 0.312 L 9.653 1.939 L 3.956 1.939 L 3.956 5.195 L 26.745 5.195 L 26.745 1.939 L 21.047 1.939 L 19.42 0.312 Z M 25.116 6.823 L 5.583 6.823 L 5.583 26.355 C 5.583 28.146 7.048 29.611 8.839 29.611 L 21.86 29.611 C 23.65 29.611 25.116 28.146 25.116 26.355 L 25.116 6.823 Z"
      ></path>
    </IconSvg>
  );
};
