import { useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import {
  WorkTaskStatus,
  WorkTaskListItemDTO3,
  TaskListResponseDTO,
  OrderByColumnEnum,
  BaseWorkTaskTypeEnum
} from '../../../api/api';
import StatusTag from '../../../components/status-tag/StatusTag';
import useTableInstance from '../../../hooks/useTableInstance';
import { formatDate, sortDateTime } from '../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import { getLocationString } from '../../../utils/location/locationHandling';
import { SearchColumnFilter, SelectColumnFilter, DateRangeColumnFilter } from './../task-list-view/TaskListFilterUtils';
import { dateRangeFilterFn, IconCell, multiSelectFilterFn } from '../../../blocks/table/TableFilterUtils';

export enum ColumnAccessors {
  Id = 'id',
  TaskType = 'taskType',
  Location = 'location',
  AssetId = 'assetId',
  Status = 'status',
  ProjectNumber = 'projectNumber',
  CompletedAt = 'completedAt'
}

export interface TaskData {
  id: string;
  taskType: string;
  location: string;
  assetId: string;
  status: string;
  projectNumber: string;
  completedAt?: string;
  baseWorkTaskType?: BaseWorkTaskTypeEnum;
}

export const useTechnicianTaskList = (tasks: WorkTaskListItemDTO3[]) => {
  const [tableConfig, setTableConfig] = useState<TaskListResponseDTO>({} as TaskListResponseDTO);

  const data = useMemo(() => {
    if (!tasks) return [];

    return tasks.map((task) => {
      return {
        id: task.id ?? '-',
        taskType: task.type ?? '-',
        location: task.taskLocation ? getLocationString(task.taskLocation) : '-',
        assetId: task.assetId ?? '-',
        status: task.status ? getEnumDisplayValue(WorkTaskStatus[task.status]) : '-',
        projectNumber: task.projectNumber ?? '-',
        completedAt: task.completedDate,
        baseWorkTaskType: task.baseWorkTaskType
      } as TaskData;
    });
  }, [tasks]);

  const columns = useMemo(() => {
    return [
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.WorkTaskId),
        accessor: ColumnAccessors.Id,
        width: 160,
        minWidth: 160,
        maxWidth: 160,
        Filter: SearchColumnFilter,
        filter: 'text',
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <IconCell>{cell.value}</IconCell>;
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.Type),
        accessor: ColumnAccessors.TaskType,
        width: 150,
        minWidth: 150,
        Filter: (_tableInstance) => SelectColumnFilter(_tableInstance, false),
        filter: multiSelectFilterFn,
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <IconCell>{cell.value}</IconCell>;
        }
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.AssetId),
        accessor: ColumnAccessors.AssetId,
        width: 125,
        minWidth: 125,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.ProjectNumber),
        accessor: ColumnAccessors.ProjectNumber,
        width: 175,
        minWidth: 175,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.TaskLocation),
        accessor: ColumnAccessors.Location,
        width: 150,
        minWidth: 150,
        Filter: SearchColumnFilter,
        filter: 'text'
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.Status),
        accessor: ColumnAccessors.Status,
        width: 125,
        minWidth: 125,
        Filter: SelectColumnFilter,
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <StatusTag statusValue={cell.value}>{cell.value}</StatusTag>;
        },
        filter: multiSelectFilterFn
      },
      {
        Header: getEnumDisplayValue(OrderByColumnEnum.CompletedDate),
        accessor: ColumnAccessors.CompletedAt,
        width: 175,
        minWidth: 175,
        Filter: (_tableInstance, x) => DateRangeColumnFilter(_tableInstance, true),
        filter: dateRangeFilterFn,
        sortType: (a, b) => sortDateTime(a.values.completedAt, b.values.plannedArrival),
        Cell: (cellProps: CellProps<TaskData>) => {
          const { cell } = cellProps;
          return <div>{cell.value ? formatDate(new Date(cell.value)) ?? '-' : '-'}</div>;
        }
      }
    ] as Column<TaskData>[];
  }, []);

  const tableInstance = useTableInstance<TaskData>(data, columns, {
    initialState: {
      pageSize: 25,
      pageIndex: 0
    }
  });

  return {
    tableInstance,
    tableConfig,
    setTableConfig
  };
};
