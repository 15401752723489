import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ClipboardIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        d="M10.6666 2.66675H12C12.3536 2.66675 12.6927 2.80722 12.9428 3.05727C13.1928 3.30732 13.3333 3.64646 13.3333 4.00008V13.3334C13.3333 13.687 13.1928 14.0262 12.9428 14.2762C12.6927 14.5263 12.3536 14.6667 12 14.6667H3.99996C3.64634 14.6667 3.3072 14.5263 3.05715 14.2762C2.8071 14.0262 2.66663 13.687 2.66663 13.3334V4.00008C2.66663 3.64646 2.8071 3.30732 3.05715 3.05727C3.3072 2.80722 3.64634 2.66675 3.99996 2.66675H5.33329"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill="none"
        d="M10 1.33325H6.00004C5.63185 1.33325 5.33337 1.63173 5.33337 1.99992V3.33325C5.33337 3.70144 5.63185 3.99992 6.00004 3.99992H10C10.3682 3.99992 10.6667 3.70144 10.6667 3.33325V1.99992C10.6667 1.63173 10.3682 1.33325 10 1.33325Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </IconSvg>
  );
};
