import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FromToDTO,
  WorkTaskListItemDTO3,
  WorkTaskStatus,
  TaskListRequestDTO,
  OrderByEnum,
  OrderByColumnEnum
} from '../../../api/api';
import TaskService from '../../../services/TaskService';
import MapComponent, { MapMarker } from '../../../components/map-component/MapComponent';
import styled from 'styled-components';
import Select, { MenuItem } from '../../../components/select/Select';
import { selectDepartments, selectTaskTypes } from '../../../stateManagement/reducers/taskListReducer';
import { useSelector } from 'react-redux';
import { getEnumDisplayValue } from '../../../utils/enumUtils';
import NotificationService from '../../../services/NotificationService';
import Button from '../../../components/button/Button';
import DateRangePicker from '../../../components/date-range-picker/DateRangePicker';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import { log } from '../../../utils/logging/log';
import { useNavigate } from '../../../hooks/useNavigate';
import { TASKS_ROUTE } from '../../../utils/constants';
import WorkTaskControlBar from '../../../blocks/worktask-control-bar/WorkTaskControlBar';

const MapView = () => {
  const [tasks, setTasks] = useState<WorkTaskListItemDTO3[]>([]);
  const [taskListFilters, setTaskListFilters] = useState<TaskListRequestDTO>({
    page: 1,
    pageSize: 100000,
    sortOrder: OrderByEnum.Desc,
    orderByColumn: OrderByColumnEnum.WorkTaskId,
    taskStatusFilter: [WorkTaskStatus.Created]
  });

  const [selectedWorkTasks, setSelectedWorkTaskIds] = useState<
    { workTaskId: string; hasSubTasks: boolean; status: WorkTaskStatus }[]
  >([]);
  const [showBookButton, setShowBookButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const taskTypes = useSelector(selectTaskTypes);
  const departments = useSelector(selectDepartments);

  const controllerRef = useRef<AbortController | null>(null);

  const navigate = useNavigate();

  const handleFilterChange = useCallback((key: keyof TaskListRequestDTO, value: any) => {
    setTaskListFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleCloseMenuBar = useCallback(() => {
    setSelectedWorkTaskIds([]);
  }, []);

  const handleSelectWorkTask = useCallback(
    (workTask: { workTaskId: string; hasSubTasks: boolean; status: WorkTaskStatus }) => {
      let prevSelected = [...selectedWorkTasks];
      let index = prevSelected.findIndex((x) => x.workTaskId === workTask.workTaskId);
      if (index !== -1) {
        prevSelected.splice(index, 1);
        setSelectedWorkTaskIds(prevSelected);
      } else {
        const newArray = [...prevSelected, workTask];
        setSelectedWorkTaskIds(newArray);
      }
    },
    [selectedWorkTasks]
  );

  const fetchTasks = useCallback(() => {
    setLoading(true);

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    TaskService.getWorkTaskList(taskListFilters, controllerRef.current)
      .then((res) => {
        setTasks(res.tasks ?? []);
        setSelectedWorkTaskIds([]);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          NotificationService.error('Kunne ikke hente opgaver');
          log(err);
        }
      })
      .finally(() => setLoading(false));

    return () => {
      // Abort the request when the component unmounts or when a dependency changes
      controllerRef.current?.abort();
    };
  }, [taskListFilters]);

  const mapMarkers = useMemo(() => {
    if (!tasks) return [];

    return tasks.map((t) => {
      return {
        lat: t.taskLocation?.latitude,
        lng: t.taskLocation?.longitude,
        markerType: selectedWorkTasks.find((x) => x.workTaskId === t.id) !== undefined ? 'FocusedMain' : t.status,
        onClick: () =>
          handleSelectWorkTask({
            workTaskId: t.id ?? '',
            hasSubTasks: t.hasSubTasks ?? false,
            status: t.status ?? WorkTaskStatus.Created
          }),
        key: t.id,
        hoverText: `${t.id} - ${t.type}`
      } as MapMarker;
    });
  }, [handleSelectWorkTask, selectedWorkTasks, tasks]);

  const handleDateChange = useCallback(
    (from?: Date, to?: Date) => {
      if (from || to) {
        const selectedDates = { from: from?.toISOString() + '', to: to?.toISOString() + '' } as FromToDTO;
        handleFilterChange('deadlineFilter', selectedDates);
      }
      if (!from && !to) {
        handleFilterChange('deadlineFilter', undefined);
      }
    },
    [handleFilterChange]
  );

  const handleOnClickListView = useCallback(() => {
    navigate('/' + TASKS_ROUTE);
  }, [navigate]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    if (selectedWorkTasks.length > 0) {
      setShowBookButton(
        selectedWorkTasks.every((w) => w.status === WorkTaskStatus.Created || w.status === WorkTaskStatus.Pause)
      );
    }
  }, [selectedWorkTasks]);

  return (
    <Container>
      {loading && <LoadingOverlay />}
      <MapContainer>
        <MapComponent markers={mapMarkers} disableAutoFit zoom={8} />
      </MapContainer>
      <FilterContainer>
        <ButtonContainer>
          <Button variant="secondary" onClick={() => handleOnClickListView()}>
            Listevisning
          </Button>
        </ButtonContainer>
        <div>
          <Select
            onChange={(e) => handleFilterChange('taskTypesFilter', e.target.value || undefined)}
            multiple
            label={'Opgavetype'}
            compact
            fullWidth
            inputProps={{ 'aria-label': 'Without label' }}
            value={taskListFilters.taskTypesFilter ?? []}
          >
            {[...taskTypes].map((t) => {
              return (
                <MenuItem key={t.id} style={{ whiteSpace: 'pre-wrap' }} value={t.id}>
                  {t.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div>
          <Select
            onChange={(e) => handleFilterChange('departmentIdFilter', e.target.value || undefined)}
            multiple
            label={'Afdeling'}
            compact
            fullWidth
            inputProps={{ 'aria-label': 'Without label' }}
            value={taskListFilters.departmentIdFilter ?? []}
          >
            {departments.map((d) => {
              return (
                <MenuItem key={d.departmentId} style={{ whiteSpace: 'pre-wrap' }} value={d.departmentId}>
                  {d.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div>
          <Select
            onChange={(e) => handleFilterChange('taskStatusFilter', e.target.value || undefined)}
            multiple
            label={'Opgavestatus'}
            compact
            fullWidth
            inputProps={{ 'aria-label': 'Without label' }}
            value={taskListFilters.taskStatusFilter ?? []}
          >
            {Object.values(WorkTaskStatus).map((c) => {
              return (
                <MenuItem key={c} style={{ whiteSpace: 'pre-wrap' }} value={c}>
                  {getEnumDisplayValue(c)}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div>
          <DateRangePicker
            compact
            right={'0'}
            label={'Slutdato'}
            onDateChanged={handleDateChange}
            hasDeadlineDate
            disabled={false}
            filterValue={taskListFilters.deadlineFilter}
          />
        </div>
      </FilterContainer>
      {!!Object.keys(selectedWorkTasks).length && (
        <WorkTaskControlBar
          selectedWorkTasks={selectedWorkTasks}
          updateTasks={fetchTasks}
          handleClose={handleCloseMenuBar}
          setLoading={(isLoading: boolean) => setLoading(isLoading)}
          showBookButton={showBookButton}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const MapContainer = styled.div`
  height: 100%;
  width: 75%;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-right: 12px;
  > * {
    padding: 12px;
    border-radius: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default MapView;
