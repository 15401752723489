import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { CreateTransferJournalDTO, StockDTO, WarehouseExtendedDTO } from '../../../api/api';
import Button from '../../../components/button/Button';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import Select, { MenuItem } from '../../../components/select/Select';
import Typography from '../../../components/typography/Typography';
import GoodsService from '../../../services/GoodsService';
import NotificationService from '../../../services/NotificationService';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import { log } from '../../../utils/logging/log';
import MoveGoodsList from './move-goods-list/MoveGoodsList';
import { ButtonContainer, Container, DialogContent, DialogFooter, StyledButton } from '../components/Styles';
import { SelectedGoods } from '../../../models/Goods';
import SelectedGoodsList from '../components/SelectedGoodsList';
import AutoComplete from '../../../components/auto-complete/AutoComplete';
import TextField from '../../../components/text-field/TextField';
import NavigationHeader from '../components/NavigationHeader';
import {
  selectFromLocation,
  selectFromWarehouse,
  selectSelectedGoods,
  selectToLocation,
  selectToWarehouse,
  setFromLocation,
  setFromWarehouse,
  setSelectedGoods,
  setToLocation,
  setToWarehouse
} from '../../../stateManagement/reducers/moveGoodsReducer';
import Alert from '../../../components/alert/Alert';

enum WarehouseType {
  Storage = 'Beredskabslager',
  Veichle = 'Personlig',
  Drop = 'Dropsted'
}

interface Props {
  onClose: () => void;
}

const MoveGoodsContent = (props: Props) => {
  const { onClose } = props;

  const userVehicle = useSelector(selectUserProfile).userProfile?.vehicle;

  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [page, setPage] = useState<'select' | 'order'>('select');

  const [goods, setGoods] = useState<StockDTO[]>([]);
  const selectedGoods = useSelector(selectSelectedGoods);
  const selectedFromWarehouse = useSelector(selectFromWarehouse);
  const selectedFromLocation = useSelector(selectFromLocation);
  const selectedToWarehouse = useSelector(selectToWarehouse);
  const selectedToLocation = useSelector(selectToLocation);
  const dispatch = useDispatch();

  const [warehouses, setWarehouses] = useState<WarehouseExtendedDTO[]>([]);

  const getWarehouseType = useCallback((id: string) => {
    if (id.startsWith('BE-')) {
      return WarehouseType.Storage;
    } else if (id.startsWith('V-')) {
      return WarehouseType.Veichle;
    } else if (id.startsWith('D-')) {
      return WarehouseType.Drop;
    }
  }, []);

  const handlerSelectFromWarehouse = useCallback(
    (value?: WarehouseExtendedDTO | null, location?: string) => {
      if (!value || !value.warehouseId) return;

      const warehouseId = value.warehouseId;
      const locationId = location
        ? location
        : getWarehouseType(warehouseId) === WarehouseType.Veichle
        ? value.defaultIssueLocationId ?? ''
        : value.defaultReceiptLocationId ?? '';

      dispatch(setSelectedGoods([]));
      setIsLoading(true);
      dispatch(setFromLocation(locationId));

      dispatch(setFromWarehouse(value));
      GoodsService.getItemsOnHand3(value.legalEntityId!, value.warehouseId!, locationId!)
        .then((res) => {
          setGoods(res);
        })
        .finally(() => {
          setIsLoading(false);
          setFirstLoad(false);
        });
    },
    [dispatch, getWarehouseType]
  );

  const handlerSelectToWarehouse = useCallback(
    (warehouseId?: string, locationId?: string) => {
      if (!warehouseId) return;

      dispatch(setToWarehouse(warehouseId));
      dispatch(setToLocation(locationId ?? ''));
    },
    [dispatch]
  );

  const selectedCount = useMemo(() => {
    let count = 0;
    selectedGoods.forEach((item) => {
      count = count + (item?.qty ?? 1);
    });
    return count;
  }, [selectedGoods]);

  const handleSelectGoods = useCallback(
    (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => {
      if (!goodsId) return;

      const { product, available } =
        goods.find(
          (item) =>
            item?.product?.itemId === goodsId &&
            item.product.batchNumber === goodsBatch &&
            item.product.serialNumber === goodsSerial
        ) ?? {};
      const itemToAdd: SelectedGoods = {
        itemId: goodsId,
        qty: 1,
        name: product?.name,
        available,
        cableCut: !!goodsBatch && 1 !== available,
        batchNumber: goodsBatch,
        serialNumber: goodsSerial
      };

      if (
        selectedGoods.some(
          (item) => item?.itemId === goodsId && item.batchNumber === goodsBatch && item.serialNumber === goodsSerial
        )
      ) {
        let tmpSelectedGoods = selectedGoods.map((selectedItem) => {
          return selectedItem.itemId === goodsId &&
            selectedItem.batchNumber === goodsBatch &&
            selectedItem.serialNumber === goodsSerial
            ? {
                ...selectedItem,
                qty: selectedItem?.qty ? selectedItem.qty + 1 : 1,
                cableCut: !!selectedItem.batchNumber && (selectedItem?.qty ? selectedItem.qty + 1 : 1) !== available
              }
            : selectedItem;
        });
        dispatch(setSelectedGoods(tmpSelectedGoods));
      } else {
        dispatch(setSelectedGoods([...selectedGoods, { ...itemToAdd }]));
      }
    },
    [dispatch, goods, selectedGoods]
  );

  const submitOrder = useCallback(() => {
    if (selectedGoods.some((item) => (item.qty && item?.qty < 1) || !item.qty)) {
      NotificationService.error('Det er ikke muligt at flytte mindre end 1 af en vare');
      return;
    }
    if (selectedFromWarehouse && selectedToWarehouse) {
      setIsLoading(true);
      let body: CreateTransferJournalDTO = {
        fromWarehouseId: selectedFromWarehouse.warehouseId,
        fromLocationId: selectedFromLocation,
        toWarehouseId: selectedToWarehouse,
        toLocationId: selectedToLocation,
        items: selectedGoods.map((item) => ({
          itemId: item.itemId,
          qty: item.qty,
          batchNumber: item.batchNumber,
          serialNumber: item.serialNumber,
          cableCut: item.cableCut
        }))
      };
      GoodsService.createTransferJournal(body)
        .then(() => {
          NotificationService.showToast({
            severity: 'success',
            text: `${selectedGoods.length} varer flyttet`,
            duration: 5000
          });
          dispatch(setSelectedGoods([]));
          onClose();
        })
        .catch((error) => {
          NotificationService.showToast({
            severity: 'error',
            text: `Kunne ikke oprette ordren: ${error}`,
            duration: 5000
          });
          log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    selectedGoods,
    selectedFromWarehouse,
    selectedToWarehouse,
    selectedFromLocation,
    selectedToLocation,
    dispatch,
    onClose
  ]);

  useEffect(() => {
    setIsLoading(true);
    GoodsService.getJournalWarehouses()
      .then((warehouses) => {
        setWarehouses(warehouses);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente beredskabslagre', 5000);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const setToWarehouseToUser = useCallback(() => {
    if (!userVehicle) return;
    dispatch(setToWarehouse(userVehicle.vehicleId ?? ''));
    dispatch(setToLocation(userVehicle.defaultIssueLocationId ?? ''));
  }, [dispatch, userVehicle]);

  useEffect(() => {
    if (!selectedFromWarehouse) {
      setToWarehouseToUser();
    }
  }, [selectedFromWarehouse, setToWarehouseToUser]);

  useEffect(() => {
    if (firstLoad && goods.length === 0 && selectedFromWarehouse) {
      GoodsService.getItemsOnHand3(
        selectedFromWarehouse.legalEntityId!,
        selectedFromWarehouse.warehouseId,
        selectedFromLocation
      )
        .then((res) => {
          setGoods(res);
        })
        .finally(() => {
          setIsLoading(false);
          setFirstLoad(false);
        });
    }
  }, [firstLoad, goods, selectedFromLocation, selectedFromWarehouse]);

  const ActionButtons = useCallback(() => {
    if (page === 'select') {
      return (
        <ButtonContainer>
          <StyledButton onClick={() => setPage('order')}>
            Gå til flyt {selectedCount > 0 ? `(${selectedCount})` : ''}
          </StyledButton>
        </ButtonContainer>
      );
    } else {
      const error = selectedGoods.some((g) => (g?.qty ?? 0) > (g?.available ?? 0));
      return (
        <ButtonContainer>
          <Button variant="secondary" onClick={() => setPage('select')}>
            Tilbage
          </Button>
          {error && (
            <Alert margin="0px 10px" severity="error" height="42px">
              Du kan ikke flytte de valgte varer, før antallet af varer stemmer overens med hvad der er på det valgte lager.
            </Alert>
          )}
          <StyledButton disabled={selectedGoods.length === 0 || error} onClick={submitOrder} isLoading={isLoading}>
            Flyt varer
          </StyledButton>
        </ButtonContainer>
      );
    }
  }, [page, selectedCount, selectedGoods, submitOrder, isLoading]);

  const handleSetGoodsAmount = useCallback(
    (value: number, goodsId?: string, goodsBatch?: string, goodsSerial?: string) => {
      if (!goodsId) return;

      const { available } =
        goods.find(
          (item) =>
            item?.product?.itemId === goodsId &&
            item.product.batchNumber === goodsBatch &&
            item.product.serialNumber === goodsSerial
        ) ?? {};

      const tmpSelectedGoods = selectedGoods.map((selectedItem) => {
        return selectedItem.itemId === goodsId &&
          selectedItem.batchNumber === goodsBatch &&
          selectedItem.serialNumber === goodsSerial
          ? {
              ...selectedItem,
              qty: value,
              cableCut: !!goodsBatch && value !== available
            }
          : selectedItem;
      });
      dispatch(setSelectedGoods(tmpSelectedGoods));
    },
    [goods, selectedGoods, dispatch]
  );

  const removeSelectedGoods = useCallback(
    (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => {
      if (!goodsId) return;
      const tmpSelectedGoods = selectedGoods.filter((selectedItem) => {
        return !(
          selectedItem.itemId === goodsId &&
          selectedItem.batchNumber === goodsBatch &&
          selectedItem.serialNumber === goodsSerial
        );
      });
      dispatch(setSelectedGoods(tmpSelectedGoods));
    },
    [dispatch, selectedGoods]
  );

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <NavigationHeader headerTitle="Flyt varer" onClose={onClose} />

      <DialogContent>
        {page === 'select' && (
          <Row>
            <AutoCompleteContainer>
              <AutoComplete
                fullWidth
                value={selectedFromWarehouse}
                onChange={(_, value) => {
                  handlerSelectFromWarehouse(value);
                }}
                renderInput={(params) => <TextField {...params} label={'Fra lager'} fullWidth />}
                options={warehouses}
                isOptionEqualToValue={(option, value) => option.warehouseId === value.warehouseId}
                getOptionLabel={(warehouse) => `${warehouse?.name} (${warehouse?.warehouseId})`}
                disableClearable
              />
            </AutoCompleteContainer>
            {warehouses?.length > 0 &&
              getWarehouseType(selectedFromWarehouse?.warehouseId ?? '') !== WarehouseType.Storage &&
              warehouses
                .filter((w) => w.warehouseId === selectedFromWarehouse?.warehouseId)
                .map((warehouse) => {
                  return (
                    <StyledSelect
                      compact
                      label={'Fra lokation'}
                      value={selectedFromLocation}
                      defaultValue={selectedFromLocation}
                      disabled={!warehouse.defaultIssueLocationId && !warehouse.defaultReceiptLocationId}
                    >
                      {warehouse.defaultIssueLocationId &&
                        getWarehouseType(selectedFromWarehouse?.warehouseId ?? '') !== WarehouseType.Drop && (
                          <MenuItem
                            onClick={() =>
                              handlerSelectFromWarehouse(selectedFromWarehouse, warehouse.defaultIssueLocationId)
                            }
                            value={warehouse?.defaultIssueLocationId ?? ''}
                          >
                            {warehouse.defaultIssueLocationId}
                          </MenuItem>
                        )}
                      {warehouse.defaultReceiptLocationId && (
                        <MenuItem
                          onClick={() =>
                            handlerSelectFromWarehouse(selectedFromWarehouse, warehouse.defaultReceiptLocationId)
                          }
                          value={warehouse?.defaultReceiptLocationId ?? ''}
                        >
                          {warehouse.defaultReceiptLocationId}
                        </MenuItem>
                      )}
                    </StyledSelect>
                  );
                })}
          </Row>
        )}
        {page === 'order' && (
          <>
            <StyledTypography variant="h6" fontWeight="bold">
              Fra lager:
            </StyledTypography>
            <Row>
              <StyledTypography variant="h5" fontWeight="bold">
                {selectedFromWarehouse?.name} ({selectedFromWarehouse?.warehouseId})
              </StyledTypography>
              <StyledTypography variant="h5" fontWeight="bold">
                {selectedFromLocation}
              </StyledTypography>
            </Row>

            <Row>
              <StyledSelect compact label={'Til lager'} value={selectedToWarehouse}>
                {warehouses?.length > 0 &&
                  warehouses
                    .filter((w) => {
                      if (
                        selectedFromWarehouse?.warehouseId === w.warehouseId &&
                        selectedFromLocation === w.defaultIssueLocationId
                      ) {
                        return false;
                      } else {
                        let type = getWarehouseType(w.warehouseId ?? '');
                        return (
                          type === WarehouseType.Veichle || type === WarehouseType.Storage || type === WarehouseType.Drop
                        );
                      }
                    })
                    .map((warehouse, i) => {
                      return (
                        <MenuItem
                          onClick={(e) =>
                            handlerSelectToWarehouse(e.currentTarget.dataset.value, warehouse.defaultIssueLocationId ?? '')
                          }
                          value={warehouse?.warehouseId ?? ''}
                          key={`warehouse-option-${i}`}
                        >
                          {warehouse?.name} ({warehouse?.warehouseId})
                        </MenuItem>
                      );
                    })}
              </StyledSelect>
              {warehouses?.length > 0 &&
                warehouses
                  .filter((w) => w.warehouseId === selectedToWarehouse)
                  .map((warehouse) => {
                    return (
                      <StyledSelect
                        compact
                        label={'Til lokation'}
                        value={selectedToLocation}
                        defaultValue={userVehicle?.vehicleId ?? ''}
                        disabled={!warehouse.defaultIssueLocationId && !warehouse.defaultReceiptLocationId}
                      >
                        {((warehouse.defaultIssueLocationId &&
                          warehouse.warehouseId !== selectedFromWarehouse?.warehouseId) ||
                          (selectedFromLocation !== warehouse.defaultIssueLocationId &&
                            warehouse.warehouseId === selectedFromWarehouse?.warehouseId)) && (
                          <MenuItem
                            onClick={() => handlerSelectToWarehouse(selectedToWarehouse, warehouse.defaultIssueLocationId)}
                            value={warehouse?.defaultIssueLocationId ?? ''}
                          >
                            {warehouse.defaultIssueLocationId}
                          </MenuItem>
                        )}
                      </StyledSelect>
                    );
                  })}
              {userVehicle?.vehicleId && selectedToWarehouse !== userVehicle.vehicleId && (
                <Button variant="secondary" onClick={setToWarehouseToUser}>
                  Vælg mit lager
                </Button>
              )}
            </Row>
          </>
        )}

        {page === 'select' && (
          <StyledGoodsList
            goods={goods}
            selectGoods={handleSelectGoods}
            selectedCount={selectedCount}
            selectedGoods={selectedGoods}
          />
        )}
        {page === 'order' && (
          <StyledSelectedGoodsList
            setGoodsAmount={(value, goodsId, goodsBatch, goodsSerial) =>
              handleSetGoodsAmount(value, goodsId, goodsBatch, goodsSerial)
            }
            removeGoods={removeSelectedGoods}
            selectedGoods={selectedGoods}
            showAvailable
          />
        )}
        <DialogFooter>
          <ActionButtons />
        </DialogFooter>
      </DialogContent>
    </Container>
  );
};

const Row = styled.div`
  display: flex;
  column-gap: 24px;
  padding: 10px 0;
`;

const StyledGoodsList = styled(MoveGoodsList)`
  min-height: 250px;

  .table-footer {
    justify-content: end;
  }
`;

const StyledTypography = styled(Typography)`
  margin-left: 5px;
  width: 400px;
`;

const StyledSelectedGoodsList = styled(SelectedGoodsList)`
  min-height: 250px;
`;

const StyledSelect = styled(Select)`
  width: 400px;
  height: 45px;
`;

const AutoCompleteContainer = styled.div`
  width: 400px;
`;

export default MoveGoodsContent;
