import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const AssignWorkerIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        stroke="none"
        d="M 18.943 14.938 C 21.882 14.938 24.262 11.674 24.262 7.637 C 24.262 3.607 21.882 0.337 18.943 0.337 C 16.001 0.337 13.619 3.607 13.619 7.637 C 13.619 11.674 16.001 14.938 18.943 14.938 Z M 18.943 3.99 C 20.405 3.99 21.604 5.63 21.604 7.637 C 21.604 9.646 20.405 11.29 18.943 11.29 C 17.476 11.29 16.28 9.646 16.28 7.637 C 16.28 5.63 17.476 3.99 18.943 3.99 Z M 18.943 18.592 C 15.39 18.592 8.299 21.035 8.299 25.891 L 8.299 29.541 L 29.583 29.541 L 29.583 25.891 C 29.583 21.035 22.491 18.592 18.943 18.592 Z M 10.959 25.891 C 11.254 24.576 15.363 22.239 18.943 22.239 C 22.533 22.239 26.654 24.596 26.923 25.891 L 10.959 25.891 Z M 6.971 20.416 L 6.971 14.938 L 10.959 14.938 L 10.959 11.29 L 6.971 11.29 L 6.971 5.813 L 4.311 5.813 L 4.311 11.29 L 0.318 11.29 L 0.318 14.938 L 4.311 14.938 L 4.311 20.416 L 6.971 20.416 Z"
      ></path>
    </IconSvg>
  );
};
