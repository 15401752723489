import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ListIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="26.536 29.585 32.8 32.8" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.4"
        d="M35.17 38.44l-7.41.06M58.11 38.44h-16.5M35.17 45.82l-7.41.07M58.11 45.82h-16.5M35.17 53.21l-7.41.06M58.11 53.21h-16.5"
      />
    </IconSvg>
  );
};
