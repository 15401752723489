import { useCallback, useState } from 'react';
import { HeaderGroup } from 'react-table';
import styled, { css } from 'styled-components';
import Column from './Column';
import { OrderByEnum } from '../../../api/api';

interface Props<T extends object> {
  headers: HeaderGroup<T>[];
  getHeaderGroupProps: any;
  disabledColumns?: string[];
  alwaysShowSort?: boolean;
  sortingColumn?: string;
  sortingOrder?: OrderByEnum;
  onColumnSwap?: (dragItem?: number, dragOverItem?: number) => void;
  onSortingOrder?: (orderByColumn: string, sortOrder: OrderByEnum) => void;
}

const TableHeader = <T extends object>(props: Props<T>) => {
  const {
    headers,
    getHeaderGroupProps,
    disabledColumns,
    alwaysShowSort,
    onColumnSwap,
    sortingOrder,
    sortingColumn,
    onSortingOrder
  } = props;

  const sortColumn = headers.find((columns) => columns.isSorted);
  const sortColumnId = sortingColumn ?? sortColumn?.id;

  const [activeColumnHover, setActiveColumnHover] = useState<number>();

  const [dragItem, setDragItem] = useState<number>();
  const [dragOverItem, setDragOverItem] = useState<number>();

  const dragStart = useCallback((e: any, columnAccessor: number) => {
    if (columnAccessor === 0) return;
    e.dataTransfer.effectAllowed = 'copyMove';
    setDragItem(columnAccessor);
  }, []);

  const dragEnter = useCallback(
    (e: any, columnAccessor: number) => {
      if (!dragItem || !columnAccessor) return;
      e.dataTransfer.dropEffect = 'copy';
      if (columnAccessor < dragItem) {
        setDragOverItem(columnAccessor - 1);
      } else {
        setDragOverItem(columnAccessor);
      }
    },
    [dragItem]
  );

  const drop = useCallback(
    (e: any) => {
      if (!dragItem) return;

      const tmpDragOverItem = dragOverItem === 0 ? 1 : dragOverItem;
      onColumnSwap && onColumnSwap(dragItem, tmpDragOverItem);

      setDragItem(undefined);
      setDragOverItem(undefined);
    },
    [onColumnSwap, dragItem, dragOverItem]
  );

  return (
    <THead>
      <tr {...getHeaderGroupProps()}>
        {headers.map((column: HeaderGroup<T>, i) => (
          <HeaderCell
            {...column.getHeaderProps()}
            active={onColumnSwap && dragItem === i}
            hoverActive={onColumnSwap && dragOverItem === i}
            onDragStart={(e) => onColumnSwap && dragStart(e, i)}
            onDragEnter={(e) => onColumnSwap && dragEnter(e, i)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={onColumnSwap && drop}
            draggable={onColumnSwap && i !== 0 ? 'true' : 'false'}
            data-testid={`${column.id}-column`}
            onMouseEnter={() => setActiveColumnHover(i)}
            onMouseLeave={() => setActiveColumnHover(undefined)}
          >
            <Column
              filterValue={column.filterValue}
              column={column as any}
              isActive={alwaysShowSort || i === activeColumnHover}
              key={`${i}-column`}
              disabledColumns={disabledColumns}
              sortingColumn={sortColumnId}
              isSorted={sortColumnId !== undefined ?? column.isSorted}
              isSortedDesc={sortingColumn ? sortingOrder === OrderByEnum.Desc : column.isSortedDesc}
              onSortingOrder={onSortingOrder}
            />
          </HeaderCell>
        ))}
      </tr>
    </THead>
  );
};

const THead = styled.thead`
  th {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.black10};
    background: ${(props) => props.theme.palette.grey.black5};
  }
  th:first-child {
    border-radius: 16px 0 0 0;
    display: flex;
  }
  th:last-child {
    border-radius: 0 16px 0 0;
  }
`;

const HeaderCell = styled.th<{ active?: boolean; hoverActive?: boolean }>`
  position: relative;
  cursor: ${(props) => props.draggable === 'true' && 'grab'};

  ${({ active }) =>
    active &&
    css`
      opacity: 0.4;
    `};

  ${({ hoverActive }) =>
    hoverActive &&
    css`
      padding-right: 5px;
      :after {
        content: '';
        margin-right: 5px;
        height: 70%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-right: ${(props) => `2px dashed ${props.theme.palette.grey.black60}`};
      }
    `};
`;

export default TableHeader;
