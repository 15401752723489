import { addDays } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import MapComponent, { MapMarker } from '../../../components/map-component/MapComponent';
import WeekDatePicker from '../../../components/week-date-picker/WeekDatePicker';
import TaskService from '../../../services/TaskService';
import { getCurrentWeek, WeekDays } from '../../../utils/dateHandling';
import TechnicianHeader from '../../../components/technician-header/WorkPlanHeader';
import DayOverview from '../workplan-view/day-overview/DayOverview';
import { log } from '../../../utils/logging/log';
import CircularProgress from '../../../components/circular-progress/CircularProgress';
import { Coords } from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedDate,
  getSelectedTaskId,
  setSelectedTaskId
} from '../../../stateManagement/reducers/workPlanViewReducer';
import { store } from '../../../stateManagement/store';
import NotificationService from '../../../services/NotificationService';
import { WorkPlanItemDTOExtended } from '../../../models/WorkPlanItem';
import { selectedWeek, setSelectedWeek } from '../../../stateManagement/reducers/calendarReducer';
import { useLocation } from 'react-router-dom';
import { TASKS_DETAILS } from '../../../utils/constants';

interface WeekDay {
  date?: Date;
  tasks: WorkPlanItemDTOExtended[];
}
const getInitialWeekdayCollection = (from: Date) => {
  let weekdayCollection: { [key: number]: WeekDay } = {};
  for (let i = 0; i < 7; i++) {
    const date = addDays(from, i);
    weekdayCollection[i] = {
      tasks: [],
      date
    };
  }
  return weekdayCollection;
};

const handleOnSelectTask = (taskId: number | undefined) => {
  store.dispatch(setSelectedTaskId(taskId));
};

const CalendarView = () => {
  const [currentWeekDays, setCurrentWeekDays] = useState<WeekDays>(getCurrentWeek());
  const [weeklyTasksCollection, setWeeklyTasksCollection] = useState<{ [key: number]: WeekDay }>({});
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [points, setPoints] = useState<Coords[]>();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const selectedTaskId = useSelector(getSelectedTaskId);
  const selectedDate = useSelector(getSelectedDate);
  const _selectedWeek = useSelector(selectedWeek);
  const prevLocation = useLocation()?.state?.prevLocation?.pathname ?? undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    const weekday = (selectedDate ? selectedDate.getUTCDay() : new Date().getUTCDay()) % 7;
    const tasksOfSelectedDay = weeklyTasksCollection[weekday]?.tasks;
    if (tasksOfSelectedDay && tasksOfSelectedDay.length > 0) {
      let mapMarkersTmp: MapMarker[] = [];
      let tmpPoints: Coords[] = [];
      tasksOfSelectedDay.forEach((task) => {
        if (!!task.tasklocation?.latitude && !!task.tasklocation.longitude) {
          mapMarkersTmp.push({
            key: task.workTaskId?.toString() ?? '',
            lat: task.tasklocation.latitude,
            lng: task.tasklocation.longitude,
            markerType: task.workTaskId === selectedTaskId ? 'FocusedMain' : 'Main',
            onClick: () => handleOnSelectTask(task.workTaskId)
          });
          tmpPoints.push({ lat: task.tasklocation.latitude, lng: task.tasklocation.longitude });
        }
      });

      setPoints(tmpPoints);
      setMapMarkers(mapMarkersTmp);
    }
  }, [selectedDate, selectedTaskId, weeklyTasksCollection]);

  const fetchTasks = useCallback(
    (weekdays?: WeekDays) => {
      setIsSearching(true);
      if (!weekdays) weekdays = currentWeekDays;

      const tmpWeekdayCollection = getInitialWeekdayCollection(weekdays.start);

      TaskService.getTechnicianCalendarTasks(weekdays.start.toISOString(), weekdays.end.toISOString())
        .then((res) => {
          res
            .sort((a, b) => {
              if (!!a.plannedArrival && !!b.plannedArrival) {
                return new Date(a.plannedArrival).getTime() - new Date(b.plannedArrival).getTime();
              }
              if (!!a.plannedArrival) {
                return -1;
              }
              if (!!b.plannedArrival) {
                return 1;
              }
              return 0;
            })
            .forEach((task) => {
              const date = task.plannedArrival && new Date(task.plannedArrival);
              if (weekdays && date && weekdays?.start <= date && date <= weekdays?.end) {
                const taskDay = task.plannedArrival ? new Date(task.plannedArrival).getUTCDay() - 1 : undefined;
                if (taskDay !== undefined) {
                  tmpWeekdayCollection[taskDay < 0 ? 6 : taskDay].tasks.push(task);
                }
              }
            });
        })
        .catch((error) => {
          NotificationService.error('Kunne ikke hente kalendar opgaver.');
          log(error);
        })
        .finally(() => {
          setWeeklyTasksCollection(tmpWeekdayCollection);
          setIsSearching(false);
        });
    },
    [currentWeekDays]
  );

  // Set current week to selected week (saved in redux state) if we are navigated back from /task-details
  useEffect(() => {
    if (_selectedWeek && prevLocation?.includes(TASKS_DETAILS)) {
      setCurrentWeekDays(_selectedWeek);
      fetchTasks(_selectedWeek);
    }
  }, [_selectedWeek, fetchTasks, prevLocation]);

  const handleOnDateChange = useCallback(
    (weekdays: WeekDays) => {
      setCurrentWeekDays(weekdays);
      dispatch(setSelectedWeek(weekdays));
      fetchTasks(weekdays);
    },
    [fetchTasks]
  );

  return (
    <Container>
      <CalendarContainer>
        <TechnicianHeader
          header="Kalender"
          subheader={'Uge ' + { currentWeekDays }.currentWeekDays.week}
          handleUpdateTasks={fetchTasks}
          button={<WeekDatePicker currentWeekDays={currentWeekDays} onDateChange={handleOnDateChange} />}
        />
        {isSearching ? (
          <CircularProgress />
        ) : (
          <>
            {Object.keys(weeklyTasksCollection).map((key, index) => {
              return (
                <DayOverview
                  fetchTasks={fetchTasks}
                  key={index}
                  tasks={weeklyTasksCollection[parseInt(key)].tasks}
                  date={weeklyTasksCollection[parseInt(key)]?.date}
                  isCalendar={true}
                />
              );
            })}
          </>
        )}
      </CalendarContainer>

      <MapContainer>
        <MapComponent coords={points} markers={mapMarkers} />
      </MapContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const CalendarContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  flex: 3;
`;

const MapContainer = styled.div`
  width: 100%;
  flex: 2;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: clip;
`;

export default CalendarView;
