import { WorkTaskDTO2 } from '../../../../api/api';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import MeterInstallationStep from './Meter/MeterInstallation/MeterInstallationStep';
import GisView from './GIS/GisView';
import MeterUninstallationStep from './Meter/MeterUninstallation/MeterUninstallationStep';
import MeterBlueprint from './Meter/Components/MeterBlueprint';
interface Props extends TabProps {
  task?: WorkTaskDTO2;
  onAssetComponentChanges: () => void;
}

const TaskAssetStep = (props: Props) => {
  const { task, onAssetComponentChanges } = props;

  const getContent = () => {
    if (!task?.taskType?.id) return <h1>Kunne ikke finde id på opgavetypen</h1>;

    switch (task?.taskType?.id) {
      case 263:
        return <MeterUninstallationStep task={task} />;
      case 264:
        return <MeterBlueprint meterTask={task} />;
      case 265:
        return <MeterInstallationStep task={task} />;
      default:
        return <GisView onAssetComponentChanges={onAssetComponentChanges} task={task} />;
    }
  };

  return <>{getContent()}</>;
};

export default TaskAssetStep;
