import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CreateOrUpdateReasonCodeDTO, ReasonCodeDTO } from '../../../api/api';
import { AddIcon } from '../../../assets/icons/AddIcon';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import Table from '../../../blocks/table/TableClient';
import TableMenuBar, { StyledButton } from '../../../blocks/table/table-menu-bar/TableMenuBar';
import Button from '../../../components/button/Button';
import DialogView from '../../../components/dialog-view/DialogView';
import Typography from '../../../components/typography/Typography';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { FormMode } from '../../../models/FormMode';
import ReasonCode from '../../../models/ReasonCode';
import AdminService from '../../../services/AdminService';
import NotificationService from '../../../services/NotificationService';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { Row } from '../../../styling/FormStyling';
import { log } from '../../../utils/logging/log';
import ReasonCodeForm from './reason-code-form/ReasonCodeForm';
import useAdminReasonCodesList, { ReasonCodeData } from './useAdminReasonCodesList';

const AdminReasonCodesView = () => {
  const [reasonCodes, setReasonCodes] = useState<ReasonCodeDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedReasonCode, setSelectedReasonCode] = useState<ReasonCodeDTO>();
  const [mode, setMode] = useState<FormMode>('create');
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    setLoading(true);
    AdminService.getAllReasonCodes()
      .then((res) => setReasonCodes(res))
      .catch((err) => {
        NotificationService.error('Kunne ikke hente årsagsforklaringer');
        log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleOpenForm = useCallback((reasonCode: ReasonCodeDTO | undefined, mode: FormMode) => {
    setSelectedReasonCode(reasonCode);
    setMode(mode);
    setShowDialog(true);
  }, []);

  const handleCancelCreation = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleDoubleClick = useCallback(
    (rowData: ReasonCodeData) => {
      handleOpenForm(
        reasonCodes.find((a) => a.reasonCodeId === rowData.id),
        'update'
      );
    },
    [reasonCodes, handleOpenForm]
  );

  const handleSubmit = useCallback(
    (body: ReasonCode) => {
      if (mode === 'create') {
        return Promise.resolve(
          AdminService.createReasonCode(body as CreateOrUpdateReasonCodeDTO)
            .then((res) => {
              setReasonCodes((prev) => [...prev, res]);
              setShowDialog(false);
              NotificationService.success('Oprettede årsagsforklaring');
            })
            .catch((err) => {
              log(err);
              NotificationService.error('Kunne ikke oprette årsagsforklaring');
            })
        );
      }

      if (!body.id) throw new Error('Trying to update field without id');

      return Promise.resolve(
        AdminService.updateReasonCode(body.id, body as CreateOrUpdateReasonCodeDTO)
          .then((res) => {
            setReasonCodes((prev) => prev.map((r) => (r.reasonCodeId === res.reasonCodeId ? res : r)));
            setShowDialog(false);
            NotificationService.success('Opdaterede årsagsforklaring');
          })
          .catch((err) => {
            log(err);
            NotificationService.error('Kunne ikke oprette årsagsforklaring');
          })
      );
    },
    [mode]
  );

  const { tableInstance } = useAdminReasonCodesList(reasonCodes);
  const { toggleAllRowsSelected } = tableInstance;
  const { selectedRowIds } = tableInstance.state;

  const handleDeleteReasonCodes = useCallback(async () => {
    const dialogBody = {
      headerText:
        tableInstance.selectedFlatRows.length > 1
          ? 'Vil du slette disse årsagsforklaringer?'
          : 'Vil du slette denne årsagsforklaring?',
      bodyText: ' ',
      declineButtonText: 'Fortryd',
      confirmButtonText: 'Bekræft sletning'
    } as DialogBody;

    const confirm = await getConfirmation(dialogBody);

    if (confirm !== 'confirm') return;

    setLoading(true);
    const idsForDeletion = tableInstance.selectedFlatRows.map((x) => x.original.id).filter((id): id is number => !!id);
    AdminService.deleteReasonCodes(idsForDeletion)
      .then(() => {
        NotificationService.success(
          `Slettede ${idsForDeletion.length} ${idsForDeletion.length === 1 ? 'årsagsforklaring' : 'årsagsforklaringer'} `
        );
        setReasonCodes((prev) => prev.filter((r) => !idsForDeletion.find((id) => id === r.reasonCodeId)));
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke slette årsagsforklaringer');
      })
      .finally(() => setLoading(false));
  }, [tableInstance.selectedFlatRows, getConfirmation]);

  return (
    <Container>
      <Header>
        <Typography fontWeight="bold" variant="h3">
          Årsagsforklaringer
        </Typography>
        <Row>
          <Button onClick={() => handleOpenForm({}, 'create')}>
            <AddIcon size="16px" /> Opret årsagsforklaring
          </Button>
        </Row>
      </Header>
      <Table
        loading={loading}
        tableInstance={tableInstance}
        onDoubleClickRow={(row) => handleDoubleClick(row)}
        showPagination
        tableMenuBar={
          <TableMenuBar
            selectedItems={Object.keys(selectedRowIds).map((x) => ({ id: x }))}
            rowNameSingle="årsagsforklaring"
            rowNameMultiple="årsagsforklaringer"
            onClose={() => toggleAllRowsSelected(false)}
          >
            <StyledButton onClick={handleDeleteReasonCodes} variant="tertiary" square>
              <DeleteIcon size="18px" />
              Slet
            </StyledButton>
          </TableMenuBar>
        }
      />
      {showDialog && (
        <DialogView>
          <ReasonCodeForm
            handleCancel={() => handleCancelCreation()}
            initialBody={{
              id: selectedReasonCode?.reasonCodeId,
              text: selectedReasonCode?.reasonText,
              status: selectedReasonCode?.status
            }}
            handleSubmit={handleSubmit}
          />
        </DialogView>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding: 30px 30px 0px 30px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AdminReasonCodesView;
