import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const InternalTimeIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 1.272 29.797 C 1.623 29.797 1.919 29.651 2.161 29.36 C 2.412 29.078 2.537 28.738 2.537 28.339 L 2.537 3.717 C 2.537 3.202 2.746 2.944 3.163 2.944 L 26.76 2.944 C 27.177 2.944 27.386 3.202 27.386 3.717 L 27.386 28.339 C 27.386 28.738 27.507 29.078 27.749 29.36 C 28 29.651 28.3 29.797 28.651 29.797 C 28.993 29.797 29.285 29.651 29.528 29.36 C 29.778 29.078 29.903 28.738 29.903 28.339 L 29.903 3.411 C 29.903 2.39 29.636 1.574 29.102 0.961 C 28.567 0.349 27.849 0.043 26.947 0.043 L 2.963 0.043 C 2.069 0.043 1.355 0.349 0.821 0.961 C 0.287 1.574 0.02 2.39 0.02 3.411 L 0.02 28.339 C 0.02 28.738 0.141 29.078 0.383 29.36 C 0.633 29.651 0.93 29.797 1.272 29.797 Z M 4.328 10.802 L 25.595 10.802 C 25.845 10.802 25.971 10.656 25.971 10.364 L 25.971 5.043 C 25.971 4.752 25.845 4.606 25.595 4.606 L 4.328 4.606 C 4.069 4.606 3.94 4.752 3.94 5.043 L 3.94 10.364 C 3.94 10.656 4.069 10.802 4.328 10.802 Z M 12.607 8.455 C 12.415 8.455 12.256 8.387 12.131 8.25 C 12.014 8.105 11.956 7.92 11.956 7.697 C 11.956 7.473 12.014 7.293 12.131 7.157 C 12.256 7.011 12.415 6.938 12.607 6.938 L 17.316 6.938 C 17.508 6.938 17.667 7.011 17.792 7.157 C 17.917 7.293 17.98 7.473 17.98 7.697 C 17.98 7.92 17.917 8.105 17.792 8.25 C 17.667 8.387 17.508 8.455 17.316 8.455 L 12.607 8.455 Z"
      ></path>
    </IconSvg>
  );
};
