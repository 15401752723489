import styled from 'styled-components';
import Grow from '../../../components/grow';
import Button from '../../../components/button/Button';
import { ConstructionIcon } from '../../../assets/icons/ContructionIcon';

interface Props {
  children?: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TabsVerticalTools = (props: Props) => {
  const { children, open, setOpen } = props;

  return (
    <TabsVerticalToolsRoot>
      <TabsVerticalToolsTrigger active={open} onClick={() => setOpen(!open)} variant="secondary">
        <ConstructionIcon size="20px" />
        Værktøjer
      </TabsVerticalToolsTrigger>

      <Grow in={open}>
        <TabsVericalToolsMenu>{children}</TabsVericalToolsMenu>
      </Grow>
    </TabsVerticalToolsRoot>
  );
};

const TabsVerticalToolsRoot = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
`;

const TabsVerticalToolsTrigger = styled(Button)`
  margin-top: auto;
`;

const TabsVericalToolsMenu = styled.div`
  position: absolute;
  bottom: 64px;
  left: 0;
  width: 350px;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
  padding: 16px;
  border: 1px solid #dbdbdb;
  z-index: ${(props) => props.theme.zIndex.dialogTools};
`;

export default TabsVerticalTools;
