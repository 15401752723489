import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  AccessConditionsDTO,
  AccessInfoDTO,
  AssetResultDTO,
  AssetType2,
  BaseWorkTaskTypeEnum,
  CustomerAppointmentDTO,
  DepartmentDTO,
  LocationDTO,
  ProjectDTO2,
  SmileContactInformation,
  StretchDTO,
  SubWorkTaskDTO,
  TaskComponentValueDTO,
  UpdateAttributeValueDTO,
  UpdateWorkTaskDTO2,
  WorkTaskStatus
} from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { CheckmarkIcon } from '../../../../assets/icons/CheckmarkIcon';
import { CloseIcon } from '../../../../assets/icons/CloseIcon';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import AccessConditionDialog from '../../../../blocks/access-conditions-dialog/AccessConditionDialog';
import AccessConditionsMetertaskDialog from '../../../../blocks/access-conditions-dialog/AccessConditionsMetertaskDialog';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import TaskTypeTemplateComponent from '../../../../blocks/tasktype-template-component/TaskTypeTemplateComponent';
import AddressAutocomplete from '../../../../components/address-autocomplete/AddressAutocomplete';
import Button from '../../../../components/button/Button';
import DepartmentDropdown from '../../../../components/department-dropdown/DepartmentDropdown';
import IconButton from '../../../../components/icon-button/IconButton';
import MapComponent from '../../../../components/map-component/MapComponent';
import ProjectNumberDropdown, {
  MappedProjectNumber,
  projectNumberToOption
} from '../../../../components/project-number-dropdown/ProjectNumberDropdown';
import SimpleTime from '../../../../components/simple-time/SimpleTime';
import TextField from '../../../../components/text-field/TextField';
import TimeOfDayDropdown from '../../../../components/time-of-day-dropdown/TimeOfDayDropdown';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import useFormIsDirty from '../../../../hooks/useFormIsDirty';
import useWorkTaskLock from '../../../../hooks/useWorkTaskLock';
import { WorkTaskDTOExtended } from '../../../../models/TaskType';
import { TaskComponent } from '../../../../models/TaskTypeComponent';
import AssetService from '../../../../services/AssetService';
import MetertaskService from '../../../../services/MeterTasksService';
import NotificationService from '../../../../services/NotificationService';
import ProjectsService from '../../../../services/ProjectsService';
import TaskService from '../../../../services/TaskService';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { setListShouldUpdate } from '../../../../stateManagement/reducers/taskListReducer';
import { Container, Row, Section, SectionContent, SectionHeader } from '../../../../styling/FormStyling';
import { ColumnFlex2, ColumnFlex3, StyledCircularProgress } from '../../../../styling/Styling';
import { dateStringsAreEqual } from '../../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import { getLocationString, locationsAreEqual } from '../../../../utils/location/locationHandling';
import { log } from '../../../../utils/logging/log';
import { convertHoursAndMinutesToMinutes, convertMinutesToHoursAndMinutes } from '../../../../utils/timeHandling';
import { Form } from '../../../admin/admin-task-types/tabs/TaskTypeChecklist/Sections';
import StretchesSection, {
  StretchContainer
} from '../../../create-task-view/details-step/sections/stretches/StretchesSection';
import TextRow, { RowLabel, RowValue } from '../components/TextRow';
import { TaskDetailsStepRelatedTask, TaskRelationType } from './TaskDetailsStepRelatedTask';
import Typography from '../../../../components/typography/Typography';
import BasicPopover from '../../../../components/popover/Popover';
import ContactCard from '../../../../components/contact-card/ContactCard';
import Person from '../../../../components/person/Person';
import { getCreatedByText } from '../../../../utils/stringHandling';
import NearbyTasks from '../../../../blocks/nearby-tasks/NearbyTasks';

interface Props extends TabProps {
  task?: WorkTaskDTOExtended;
  customerAppointment?: CustomerAppointmentDTO;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setWorkTaskCallback: Dispatch<SetStateAction<WorkTaskDTOExtended | undefined>>;
  scrollIntoPreviousPosition: (id: string) => void;
  workInvoice?: number;
  workInvoiceProjectNumber?: string;
}

type AppointmentErrors = { [key in keyof CustomerAppointmentDTO]: boolean | undefined };
type TaskErrors = { description?: boolean };

const TaskDetailsStep = (props: Props) => {
  const {
    editable,
    setEditable,
    setWorkTaskCallback,
    tabId,
    scrollIntoPreviousPosition,
    workInvoice,
    workInvoiceProjectNumber
  } = props;
  const [task, setTask] = useState(props.task);
  const [subWorkTasks, setSubWorkTasks] = useState(props.task?.subWorkTasks);
  const [selectedDepartment, setSelectedDepartment] = useState(task?.assignedToDepartment);
  const [description, setDescription] = useState(task?.description);
  const [earliestStartDate, setEarliestStartDate] = useState(task?.earliestStartDate);
  const [deadline, setDeadline] = useState(task?.deadline);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const [customerAppointment, setCustomerAppointment] = useState<CustomerAppointmentDTO | undefined>(
    props.customerAppointment
  );
  const [appointmentErrors, setAppointmentErrors] = useState<AppointmentErrors>({});
  const [workTaskErrors, setWorkTaskErrors] = useState<TaskErrors>({});
  const [dynamicFieldErrors, setDynamicFieldErrors] = useState(false);
  const [remainingTime, setRemainingTime] = useState(task?.remainingTimeMin);
  const [notesForPlanning, setNotesForPlanning] = useState(task?.notesForPlanning);
  const [taskLocation, setTaskLocation] = useState<LocationDTO>(task?.taskLocation ?? {});

  const [contactPersons, setContactPersons] = useState<SmileContactInformation[]>(task?.contactInformations ?? []);

  const [accessInfo, setAccessInfo] = useState<AccessInfoDTO | undefined>(task?.accessInfo);
  const [accessConditions, setAccessConditions] = useState<AccessConditionsDTO | undefined>({});
  const [isAccessConditionsLoading, setIsAccessConditionsLoading] = useState(false);
  const [showAccessConditionsDialog, setShowAccessConditionsDialog] = useState(false);

  const [components, setComponents] = useState<TaskComponentValueDTO[] | undefined>([]);

  const [isProjectNumberIsEditable, setIsProjectNumberEditable] = useState(false);
  const [projectNumberObject, setProjectNumberObject] = useState<MappedProjectNumber>();
  const [externalProjects, setExternalProjects] = useState<ProjectDTO2[]>([]);
  const [isLoadingProjectNumbers, setIsLoadingProjectNumbers] = useState<boolean>(false);
  const [stretches, setStretches] = useState<StretchDTO[]>(task?.stretches ?? []);
  const [projectNumberAndName, setProjectNumberAndName] = useState<string>('-');
  const [isAddingContact, setIsAddingContact] = useState(false);

  const contactFormRef = useRef<null | HTMLFormElement>(null);

  const [newContactName, setNewContactName] = useState('');
  const [newContactDetails, setNewContactDetails] = useState('');
  const { releaseWorkTaskLock } = useWorkTaskLock();

  useEffect(() => {
    setTask(props.task);
  }, [props.task]);

  useEffect(() => {
    if (task && task.assetId && task.assetType) {
      setIsAccessConditionsLoading(true);
      AssetService.getAssets(task.assetId, task.assetType)
        .then((res) => {
          const assetResultDto: AssetResultDTO[] = res;
          setAccessConditions(assetResultDto[0].accessConditions);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke hente adgangsforhold fra GIS.', 5000);
        })
        .finally(() => {
          setIsAccessConditionsLoading(false);
        });
    }
  }, [task]);

  useEffect(() => {
    if (props.task?.components) {
      const newValues = props.task.components.map((component) => {
        return { ...component };
      });
      setComponents(newValues);
    }
  }, [props.task?.components]);

  useEffect(() => {
    if (projectNumberAndName === '-' && task?.projectNumber) {
      TaskService.getProjectById(task.projectNumber)
        .then((res) => {
          setProjectNumberAndName(`${res.id} - ${res.name}`);
        })
        .catch((err) => {
          setProjectNumberAndName(task?.projectNumber ?? '');
          log(err);
        })
        .finally(() => {
          tabId && scrollIntoPreviousPosition(tabId);
        });
    }
  }, [projectNumberAndName, task?.projectNumber]);

  const { getConfirmation } = useConfirmationDialog();

  const { isDirty, toggleFieldIsDirty, resetFields } = useFormIsDirty();

  const dispatch = useDispatch();

  const handleChangeCustomerAppointmentField = useCallback(
    (key: keyof CustomerAppointmentDTO, value: string | boolean) => {
      if (!props.customerAppointment) return;

      setCustomerAppointment((prevState) => {
        const newValues = prevState ? { ...prevState, [key]: value } : { [key]: value };

        return newValues;
      });

      toggleFieldIsDirty(key, value !== props.customerAppointment[key]);
    },
    [toggleFieldIsDirty, props.customerAppointment]
  );

  const handleChangeDynamicField = useCallback(
    (componentId: number, fieldId: number, value: any) => {
      toggleFieldIsDirty('dynamicFields', true);

      //Both components and attributes arrays and specific attribute must be copied
      setComponents((prev) => {
        if (!prev) return;

        const comp = prev.find((c) => c.id === componentId);
        if (!comp || !comp.attributes) return;

        let newComp = { ...comp, attributes: [...comp?.attributes] };
        const att = newComp.attributes?.find((a) => a.attributeId === fieldId);
        const attIndex = newComp.attributes?.findIndex((a) => a.attributeId === fieldId);

        if (att) {
          newComp.attributes[attIndex] = { ...att, value };
        }

        return prev.map((c) => {
          return c.id === newComp.id ? newComp : c;
        });
      });
    },
    [toggleFieldIsDirty]
  );

  const handleValidateTask = (): TaskErrors => {
    let errors: TaskErrors = {};

    if (!description) errors.description = true;

    return errors;
  };

  const handleValidateAppointment = (): AppointmentErrors => {
    let errors: AppointmentErrors = {};
    if (!customerAppointment?.customerName) errors.customerName = true;

    if (!customerAppointment?.customerPhoneNumber || customerAppointment?.customerPhoneNumber.length !== 8)
      errors.customerPhoneNumber = true;

    return errors;
  };

  const handleChangeDepartment = (department: DepartmentDTO) => {
    toggleFieldIsDirty('department', department !== task?.assignedToDepartment);
    setSelectedDepartment(department);
  };

  const handleStartDateChange = (date: string | undefined) => {
    if (!!date) {
      const dateAsDate = new Date(date);
      const earliestStartDateAsDate = new Date(task?.earliestStartDate ?? '');

      setEarliestStartDate(date);
      toggleFieldIsDirty('earliestStartDate', dateAsDate.toString() !== earliestStartDateAsDate.toString());
    }
  };

  const handleDeadlineChange = (date: string | undefined) => {
    if (!!date && task?.deadline) {
      setDeadline(date);
      toggleFieldIsDirty('deadlineDate', !dateStringsAreEqual(date, task?.deadline));
    }
  };

  const handleRemainingTimeChange = (minutes: string | undefined) => {
    const parsedMinutes = minutes ? parseInt(minutes) : 0;
    setRemainingTime(parsedMinutes);
    toggleFieldIsDirty('remainingTimeInMinutes', parsedMinutes !== remainingTime);
  };

  const getNewWorkTaskDto = (componentAttributes?: UpdateAttributeValueDTO[]) => {
    const { startDate, endDate } = customerAppointment ?? {};
    const newStartDate = customerAppointment?.approvedByPlanning ? startDate : earliestStartDate;
    const newEndDate = customerAppointment?.approvedByPlanning ? endDate : deadline;

    return {
      description: description !== task?.description ? description : undefined,
      assignedToDepartmentId:
        selectedDepartment !== task?.assignedToDepartment ? selectedDepartment?.departmentId : undefined,
      earliestStartDate: newStartDate !== task?.earliestStartDate ? newStartDate : undefined,
      deadline: newEndDate !== task?.deadline ? newEndDate : undefined,
      customerAppointment,
      remainingTimeMin: remainingTime !== task?.remainingTimeMin ? remainingTime : undefined,
      taskLocation: !locationsAreEqual(taskLocation, task?.taskLocation ?? {}) ? taskLocation : undefined,
      notesForPlanning: notesForPlanning !== task?.notesForPlanning ? notesForPlanning : undefined,
      componentAttributes,
      projectLegalEntityId:
        projectNumberObject?.value.legalEntityId !== task?.projectLegalEntityId
          ? projectNumberObject?.value.legalEntityId
          : undefined,
      projectNumber: projectNumberObject?.value.id !== task?.projectNumber ? projectNumberObject?.value.id : undefined,
      stretches
    } as UpdateWorkTaskDTO2;
  };

  const saveUpdatedTask = () => {
    if (!task?.id) return;
    if (earliestStartDate && deadline && new Date(earliestStartDate) > new Date(deadline)) {
      NotificationService.error('Startdatoen ligger efter slutdatoen. Ændre dette og prøv igen');
      return;
    }

    const workTaskErrors = handleValidateTask();
    let customerAppointmentErrors = {};

    setWorkTaskErrors(workTaskErrors);

    if (customerAppointment) {
      customerAppointmentErrors = handleValidateAppointment();
      setAppointmentErrors(customerAppointmentErrors);
    }

    let mandatoryFieldsFilled = true;

    const componentAttributes = components?.reduce((acc, component): UpdateAttributeValueDTO[] => {
      component.attributes?.forEach((a) => {
        if (a.isMandatory && !a.value) mandatoryFieldsFilled = false;

        if (a.isEditable) acc.push({ value: a.value, id: a.id });
      });
      return acc;
    }, [] as UpdateAttributeValueDTO[]);

    if (!mandatoryFieldsFilled) {
      setDynamicFieldErrors(true);
    }

    if (Object.keys(customerAppointmentErrors).length || !mandatoryFieldsFilled || Object.keys(workTaskErrors).length)
      return;

    const diff = getNewWorkTaskDto(componentAttributes);
    setIsUpdatingTask(true);

    TaskService.updateWorkTask(parseInt(task?.id), diff)
      .then((newTask) => {
        setWorkTaskCallback(newTask);
        setCustomerAppointment(newTask.customerAppointment);
        setEditable(false);
        resetFields();
        dispatch(setListShouldUpdate(true));
        NotificationService.success('Opgaven blev opdateret.');
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Der opstod en fejl i opdateringen af arbejdskortet - ændringerne er ikke gemt');
      })
      .finally(() => {
        setIsUpdatingTask(false);
      });
  };

  const cancelChanges = async () => {
    if (!isDirty) {
      setEditable(false);
      setIsProjectNumberEditable(false);
      setWorkTaskErrors({});
      setAppointmentErrors({});
      return;
    }

    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      setComponents(task?.components);
      setDescription(task?.description);
      setSelectedDepartment(task?.assignedToDepartment);
      setEarliestStartDate(task?.earliestStartDate);
      setTaskLocation(task?.taskLocation ?? {});
      setNotesForPlanning(task?.notesForPlanning);
      setDeadline(task?.deadline);
      setEditable(false);
      setIsProjectNumberEditable(false);
      setCustomerAppointment(task?.customerAppointment);
      const selectedProject = externalProjects.find((p) => p.id === task?.projectNumber);
      selectedProject && setProjectNumberObject(projectNumberToOption(selectedProject));
      setWorkTaskErrors({});
      setAppointmentErrors({});
      resetFields();
    }
  };

  const handleSelectAddress = useCallback(
    (location?: LocationDTO) => {
      if (!location) return;

      setTaskLocation(location);
      toggleFieldIsDirty('taskLocation', true);
    },
    [toggleFieldIsDirty]
  );

  const splitSubWorkTasks = useCallback(() => {
    const lausTasks: SubWorkTaskDTO[] = subWorkTasks
      ? subWorkTasks?.filter((sw) => sw.taskTypeId === parseInt(window._env_.LAUS_TASK_ID))
      : [];
    const otherTasks: SubWorkTaskDTO[] = subWorkTasks
      ? subWorkTasks?.filter((sw) => sw.taskTypeId !== parseInt(window._env_.LAUS_TASK_ID))
      : [];

    return { lausTasks, otherTasks };
  }, [subWorkTasks]);

  useEffect(() => {
    if (task?.subWorkTasks) {
      splitSubWorkTasks();
    }
  }, [splitSubWorkTasks, task?.subWorkTasks]);

  const removeSubWorkTaskFromList = useCallback(
    async (subworkId: SubWorkTaskDTO['id']) => {
      if (task && task.id && task?.subWorkTasks && task.subWorkTasks.length > 0) {
        let newTask = task;
        newTask.subWorkTasks = (await TaskService.getTask(parseInt(task.id))).subWorkTasks;
        setSubWorkTasks(newTask.subWorkTasks);

        if (subWorkTasks?.length === 0) {
          dispatch(setListShouldUpdate(true));
        }
      }
    },
    [dispatch, subWorkTasks, task]
  );

  const handleProjectNumberChange = (value: MappedProjectNumber | null, reason: string) => {
    if (!value) return;
    setProjectNumberObject(value);
    toggleFieldIsDirty('projectNumber', task?.projectNumber !== value?.value.id);
  };

  useEffect(() => {
    if (externalProjects.length === 0 && task && (editable || isProjectNumberIsEditable)) {
      setIsLoadingProjectNumbers(true);
      ProjectsService.getProjectsByCategory(undefined)
        .then((_externalProjects: ProjectDTO2[]) => {
          setExternalProjects(_externalProjects);
          if (task.projectNumber) {
            const selectedProject = _externalProjects.find((p) => p.id === task?.projectNumber);
            selectedProject && setProjectNumberObject(projectNumberToOption(selectedProject));
          }
        })
        .catch((err) => {
          log(err);
        })
        .finally(() => {
          setIsLoadingProjectNumbers(false);
        });
    }
  }, [editable, externalProjects, isProjectNumberIsEditable, task]);

  const saveNewProjectNumber = () => {
    if (!task?.id) return;
    setIsUpdatingTask(true);
    TaskService.updateProjectNumber(parseInt(task.id), {
      projectLegalEntityId: projectNumberObject?.value.legalEntityId,
      projectNumber: projectNumberObject?.value.id
    })
      .then(() => {
        setTask({
          ...task,
          projectNumber: projectNumberObject?.value.id,
          projectLegalEntityId: projectNumberObject?.value.id
        });
        setProjectNumberAndName(
          `${projectNumberObject?.value.id} - ${projectNumberObject?.value.name} (${projectNumberObject?.value.legalEntityId})`
        );
        setEditable(false);
        setIsProjectNumberEditable(false);
        resetFields();
        dispatch(setListShouldUpdate(true));
        setIsProjectNumberEditable(false);
        setEditable(false);
        NotificationService.success('Projektnummeret blev opdateret.');
      })
      .catch((err) => {
        NotificationService.error('Der skete en fejl. Projektnummeret blev ikke opdateret.');
        log(err);
      })
      .finally(() => {
        setIsUpdatingTask(false);
      });
  };

  const getTimeText = (hours: number, minutes: number) => {
    const hoursWord = hours === 1 ? 'time' : 'timer';
    const minuteWord = minutes === 1 ? 'minut' : 'minutter';

    if (hours === 0) return `${minutes} ${minuteWord}`;
    if (minutes === 0) return `${hours} ${hoursWord}`;

    return `${hours} ${hoursWord} og ${minutes} ${minuteWord}`;
  };

  const getAccessConditions = () => {
    if (task?.baseWorkTaskType === BaseWorkTaskTypeEnum.MeterWorkTask) {
      return (
        <AccessConditionsSection>
          <TextRow label={'Kontakt person'} value={accessInfo?.contactPerson ?? '-'} isLoading={isAccessConditionsLoading} />
          <TextRow label={'Dørkode'} value={accessInfo?.doorCode ?? ''} isLoading={isAccessConditionsLoading} />
          <TextRow
            label={'Dørkode kommentar'}
            value={accessInfo?.doorCodeComment ?? '-'}
            isLoading={isAccessConditionsLoading}
          />
          <TextRow label={'Nøgle nummer'} value={accessInfo?.keyNumber ?? '-'} isLoading={isAccessConditionsLoading} />
          <TextRow label={'Nøgle kommentar'} value={accessInfo?.keyComment ?? '-'} isLoading={isAccessConditionsLoading} />
          <TextRow label={'Nøgle placering'} value={accessInfo?.keyPlacement ?? '-'} isLoading={isAccessConditionsLoading} />
          <TextRow
            label={'Nøgle opbevaringsplacering'}
            value={accessInfo?.keyStorageLocation ?? '-'}
            isLoading={isAccessConditionsLoading}
          />
          <Button variant="secondary" onClick={() => setShowAccessConditionsDialog(true)}>
            Rediger
          </Button>
        </AccessConditionsSection>
      );
    } else {
      return (
        <AccessConditionsSection>
          <TextRow label={'Nøgleboks'} value={accessConditions?.keyBoxInfo ?? ''} isLoading={isAccessConditionsLoading} />
          <TextRow label={'Adgang'} value={accessConditions?.accessInfo ?? ''} isLoading={isAccessConditionsLoading} />
          <TextRow
            label={'Teknikergodkendelse'}
            value={accessConditions?.technicianApproval ?? ''}
            isLoading={isAccessConditionsLoading}
          />
          <Button
            variant="secondary"
            onClick={() => setShowAccessConditionsDialog(true)}
            disabled={
              !task?.assetId || (task?.assetType !== AssetType2.Netstation && task.assetType !== AssetType2.CabelBox)
            }
          >
            Rediger
          </Button>
        </AccessConditionsSection>
      );
    }
  };

  const resetNewContact = () => {
    setNewContactName('');
    setNewContactDetails('');
    setIsAddingContact(false);
  };

  const submitNewContact = (event: any) => {
    event.preventDefault();
    if (!task?.id) return;

    MetertaskService.addContactPerson(parseInt(task?.id), { name: newContactName, details: newContactDetails })
      .then(() => {
        NotificationService.success('Der blev tilføjet et nyt kontakt-felt.');
        setContactPersons((prevContactPersons) => [
          ...prevContactPersons,
          {
            smileContactPersonId: 123,
            name: newContactName,
            details: newContactDetails
          }
        ]);
        resetNewContact();
      })
      .catch(() => {
        NotificationService.error('Der skete en fejl. Kontaktoplysninger kunne ikke opdateres.');
      });
  };

  return (
    <Container>
      <Section direction="row" noBorder>
        <ColumnFlex3>
          <SectionHeader>Information</SectionHeader>
          <StyledSectionContent>
            <ColumnFlex3>
              {editable ? (
                <AddressAutocomplete onSelect={handleSelectAddress} label={'Adresse'} prevLocation={task?.taskLocation} />
              ) : (
                <TextRow
                  inputMode={editable}
                  disabled
                  label="Adresse"
                  value={task?.taskLocation ? getLocationString(task?.taskLocation) : '-'}
                />
              )}
              <TextRow
                inputMode={editable}
                label="Bemærkninger til planlægning"
                value={task?.notesForPlanning ?? '-'}
                onBlur={(text: string) => setNotesForPlanning(text)}
                isDirty={toggleFieldIsDirty}
                disabled={task?.status !== WorkTaskStatus.Created && task?.status !== WorkTaskStatus.Pause}
              />
              {task?.baseWorkTaskType === BaseWorkTaskTypeEnum.MeterWorkTask ? (
                <>
                  <TextRow inputMode={editable} disabled label={'Målernummer'} value={task.meterNumber ?? '-'} />
                  <TextRow
                    inputMode={editable}
                    disabled
                    label={'Installationspunkt nummer'}
                    value={task.connectionPointNumber ?? '-'}
                  />
                </>
              ) : (
                <TextRow
                  inputMode={editable}
                  disabled
                  label={task?.assetType ? getEnumDisplayValue(task?.assetType) : 'Asset'}
                  value={task?.assetId ?? '-'}
                />
              )}
              {editable ? (
                <>
                  <TextRow
                    inputMode={editable}
                    disabled
                    label="Oprettet af"
                    value={getCreatedByText(task?.createdByName, task?.createdByEmail)}
                  />
                  <TextRow inputMode disabled label="Tildelt tekniker" value={task?.assignedToName ?? '-'} />
                </>
              ) : (
                <>
                  <StyledRowBase>
                    <RowLabel>Oprettet af</RowLabel>
                    <RowValue>
                      {task?.createdByName && (
                        <BasicPopover
                          buttonElement={<Person name={task.createdByName} />}
                          popoverElement={<ContactCard email={task.createdByEmail} />}
                        />
                      )}
                    </RowValue>
                  </StyledRowBase>
                  <StyledRowBase>
                    <RowLabel>Tildelt tekniker</RowLabel>
                    <RowValue>
                      {task?.assignedToName && (
                        <BasicPopover
                          buttonElement={<Person name={task.assignedToName} />}
                          popoverElement={<ContactCard email={task.assignedToEmail} />}
                        />
                      )}
                    </RowValue>
                  </StyledRowBase>
                </>
              )}
              {task?.reasonCode && (
                <TextRow inputMode={editable} disabled label="Årsagsforklaring" value={task?.reasonCode.reasonText ?? '-'} />
              )}
              {task?.statusTransactionMessage && (
                <TextRow
                  inputMode={editable}
                  disabled
                  label="Uddybende årsagsforklaring"
                  value={task?.statusTransactionMessage}
                />
              )}
            </ColumnFlex3>
          </StyledSectionContent>
        </ColumnFlex3>
        <OuterMapContainer>
          <InnerMapContainer>
            <MapComponent
              markers={
                !!task?.taskLocation?.latitude && !!task?.taskLocation?.longitude
                  ? [
                      {
                        markerType: 'Main',
                        lat: task?.taskLocation?.latitude,
                        lng: task?.taskLocation?.longitude,
                        key: 'TaskDetailsStepDetails'
                      }
                    ]
                  : []
              }
              options={{
                keyboardShortcuts: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false
              }}
            />
          </InnerMapContainer>
        </OuterMapContainer>
      </Section>
      {task && task.subTaskId && (
        <Section>
          <SectionHeader>Hovedopgave</SectionHeader>
          <StyledSectionContent>
            <ColumnFlex3>
              <TaskDetailsStepRelatedTask
                taskId={parseInt(task.id ?? '')}
                taskStatus={task.mainTaskStatus as WorkTaskStatus}
                assignedTechnician={task.mainTaskAssignedToName}
                index={0}
                relationDescription="Tekniker"
                taskRelationType={TaskRelationType.MAINTASK}
                key={task.id}
                releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.subTaskId ?? ''))}
              />
            </ColumnFlex3>
          </StyledSectionContent>
        </Section>
      )}
      {task &&
        !task.subTaskId &&
        ((task?.subWorkTasks && task.subWorkTasks.length > 0) || (task.relatedTasks && task.relatedTasks.length > 1)) && (
          <Section>
            <SectionHeader>Relaterede opgaver</SectionHeader>
            {task?.subWorkTasks && task.subWorkTasks.length > 0 && (
              <>
                <Typography variant="h6" fontWeight="bold">
                  Underopgaver
                </Typography>
                <StyledSectionContent>
                  <ColumnFlex3>
                    {splitSubWorkTasks().lausTasks.length > 0 &&
                      splitSubWorkTasks().lausTasks.map((sw, index) => {
                        return (
                          <TaskDetailsStepRelatedTask
                            taskId={sw.id}
                            taskStatus={sw.status}
                            assignedTechnician={sw.assignedTechnician}
                            index={index}
                            isSubTask
                            relationDescription="LAUS-mand"
                            taskRelationType={TaskRelationType.SUBTASK}
                            key={sw.id}
                            removeSubWorkTask={(subworkId) => removeSubWorkTaskFromList(subworkId)}
                            releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.id ?? ''))}
                          />
                        );
                      })}
                    {splitSubWorkTasks().otherTasks.length > 0 &&
                      splitSubWorkTasks().otherTasks.map((sw, index) => {
                        return (
                          <TaskDetailsStepRelatedTask
                            taskId={sw.id}
                            taskStatus={sw.status}
                            assignedTechnician={sw.assignedTechnician}
                            isSubTask
                            index={index}
                            relationDescription="Tekniker"
                            taskRelationType={TaskRelationType.SUBTASK}
                            key={sw.id}
                            releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.id ?? ''))}
                          />
                        );
                      })}
                  </ColumnFlex3>
                </StyledSectionContent>
              </>
            )}
            {task?.relatedTasks && task.relatedTasks?.length > 1 && (
              <>
                <SubSectionHeader variant="h6" fontWeight="bold">
                  Relationsopgaver
                </SubSectionHeader>
                <StyledSectionContent>
                  <ColumnFlex3>
                    {task.relatedTasks.map((rt, index) => {
                      return (
                        <TaskDetailsStepRelatedTask
                          taskId={rt.workTaskId}
                          taskStatus={rt.status}
                          assignedTechnician={rt.assignedTechnician}
                          index={index}
                          relationDescription={
                            rt.workTaskId === parseInt(task?.id ?? '') ? 'Denne opgave' : rt.taskTypeName ?? ''
                          }
                          taskRelationType={TaskRelationType.RELATIONALTASK}
                          key={rt.workTaskId}
                          removeSubWorkTask={(subworkId) => removeSubWorkTaskFromList(subworkId)}
                          releaseWorkTask={() => releaseWorkTaskLock(parseInt(task.id ?? ''))}
                          level={rt.level}
                          activeTask={rt.workTaskId === parseInt(task?.id ?? '')}
                        />
                      );
                    })}
                  </ColumnFlex3>
                </StyledSectionContent>
              </>
            )}
          </Section>
        )}
      <Section>
        <SectionHeader>Arbejdsbeskrivelse{editable && '*'}</SectionHeader>
        <StyledSectionContent>
          <TextRow
            inputMode={editable}
            type="multilineText"
            label="Arbejdsbeskrivelse"
            value={task?.description ?? '-'}
            onBlur={(text: string) => setDescription(text)}
            isDirty={toggleFieldIsDirty}
            error={workTaskErrors.description}
            hideLabel
          />
        </StyledSectionContent>
      </Section>
      <Section>
        <SectionHeader>Adgangsforhold</SectionHeader>
        {getAccessConditions()}
      </Section>
      <Section>
        <SectionHeader>Detaljer</SectionHeader>
        <StyledSectionContent>
          <StyledColumnFlex>
            {!editable ? (
              <TextRow
                type="number"
                label="Normtid"
                value={
                  task?.normTimeMin
                    ? getTimeText(
                        convertMinutesToHoursAndMinutes(task?.normTimeMin).hours,
                        convertMinutesToHoursAndMinutes(task?.normTimeMin).minutes
                      )
                    : '-'
                }
              />
            ) : (
              <SimpleTime
                label="Normtid"
                initialHours={convertMinutesToHoursAndMinutes(task?.normTimeMin ?? 0).hours}
                initialMinutes={convertMinutesToHoursAndMinutes(task?.normTimeMin ?? 0).minutes}
                disabled
              />
            )}

            {!editable ? (
              <TextRow
                type="number"
                label="Resterende tid"
                onChange={handleRemainingTimeChange}
                value={
                  task?.remainingTimeMin
                    ? getTimeText(
                        convertMinutesToHoursAndMinutes(task?.remainingTimeMin).hours,
                        convertMinutesToHoursAndMinutes(task?.remainingTimeMin).minutes
                      )
                    : '-'
                }
              />
            ) : (
              <SimpleTime
                label="Resterende tid"
                initialHours={convertMinutesToHoursAndMinutes(task?.remainingTimeMin ?? 0).hours}
                initialMinutes={convertMinutesToHoursAndMinutes(task?.remainingTimeMin ?? 0).minutes}
                onTimeChange={(hours, minutes) =>
                  handleRemainingTimeChange(convertHoursAndMinutesToMinutes(hours, minutes).toString())
                }
              />
            )}
            <TextRow
              inputMode={editable}
              type="date"
              label="Startdato"
              value={task?.earliestStartDate ?? ''}
              onChange={handleStartDateChange}
              disabled={!!customerAppointment}
            />
            <TextRow
              inputMode={editable}
              type="date"
              label="Slutdato"
              value={task?.deadline ?? ''}
              onChange={handleDeadlineChange}
              disabled={!!customerAppointment}
            />
            <TextRow
              inputMode={editable}
              disabled
              label="Spænding"
              value={task?.primaryVoltage ? task?.primaryVoltage / 1000 + ' kV' : '-'}
            />
            {editable ? (
              <DepartmentDropdown selectDepartment={handleChangeDepartment} value={selectedDepartment?.departmentId} />
            ) : (
              <TextRow label="Send til" value={task?.assignedToDepartment?.name ? task?.assignedToDepartment.name : '-'} />
            )}

            <Row>
              {(editable || isProjectNumberIsEditable) && !isLoadingProjectNumbers && (
                <>
                  <ProjectNumberDropdown
                    value={projectNumberObject}
                    projectNumberChange={(value: any, reason: string) => handleProjectNumberChange(value, reason)}
                    options={externalProjects}
                    isLoadingProjectNumbers={isLoadingProjectNumbers}
                    disableClearable
                  />
                  {isProjectNumberIsEditable && !editable && (
                    <ButtonContainer>
                      <StyledIconButton onClick={saveNewProjectNumber} disabled={!isDirty}>
                        <CheckmarkIcon size="18px" />
                      </StyledIconButton>
                      <StyledIconButton onClick={cancelChanges}>
                        <CloseIcon size="16px" />
                      </StyledIconButton>
                    </ButtonContainer>
                  )}
                </>
              )}
              {!isProjectNumberIsEditable && !editable && (
                <ProjectNumberContainer>
                  <TextRow
                    inputMode={editable}
                    disabled
                    label={workInvoice ? 'Oprindeligt projektnummer' : 'Projektnummer'}
                    value={projectNumberAndName}
                  />
                  {!workInvoice && (
                    <StyledIconButton onClick={() => setIsProjectNumberEditable(true)}>
                      <EditIcon size="30px" />
                    </StyledIconButton>
                  )}
                </ProjectNumberContainer>
              )}
            </Row>
            {workInvoice && (
              <Row>
                <TextRow
                  inputMode={false}
                  disabled
                  label={'Skadevolder/regningsarbejde projektnummer'}
                  value={workInvoiceProjectNumber ?? '-'}
                />
              </Row>
            )}
          </StyledColumnFlex>
        </StyledSectionContent>
      </Section>
      {task?.baseWorkTaskType === BaseWorkTaskTypeEnum.MeterWorkTask && (
        <Section>
          <StyledSectionHeader>
            Kontaktoplysninger
            {!isAddingContact && (
              <StyledIconButton onClick={() => setIsAddingContact(true)}>
                <AddIcon size="18px" />
              </StyledIconButton>
            )}
          </StyledSectionHeader>
          <StyledSectionContent>
            <ColumnFlex3>
              {contactPersons?.map((contact) => (
                <TextRow inputMode={false} label={contact.name ?? ''} value={contact.details ?? ''} required />
              ))}
              <>
                {isAddingContact && (
                  <Form ref={contactFormRef} onSubmit={(e) => submitNewContact(e)}>
                    <TextField
                      label="Navn"
                      autoFocus
                      required
                      value={newContactName}
                      onChange={(e) => setNewContactName(e.target.value)}
                    />
                    <TextField
                      label="Oplysning"
                      required
                      value={newContactDetails}
                      onChange={(e) => setNewContactDetails(e.target.value)}
                    />
                    <ButtonContainer>
                      <StyledIconButton
                        disabled={!newContactName || !newContactDetails}
                        onClick={(e) => submitNewContact(e)}
                      >
                        <CheckmarkIcon size="18px" />
                      </StyledIconButton>
                      <StyledIconButton onClick={resetNewContact}>
                        <CloseIcon size="18px" />
                      </StyledIconButton>
                    </ButtonContainer>
                  </Form>
                )}
              </>
            </ColumnFlex3>
          </StyledSectionContent>
        </Section>
      )}
      {customerAppointment && (
        <Section>
          <SectionHeader>Kundeaftale</SectionHeader>
          <StyledSectionContent>
            <ColumnFlex3>
              <h4>Kontaktoplysninger</h4>
              <TextRow
                inputMode={editable}
                label="Kontaktperson"
                value={customerAppointment?.customerName ?? ''}
                onChange={(value) => handleChangeCustomerAppointmentField('customerName', value)}
                error={appointmentErrors.customerName}
                required
              />
              <TextRow
                inputMode={editable}
                label="Kontaktpersons tlf. nr."
                value={customerAppointment?.customerPhoneNumber ?? ''}
                onChange={(value) => handleChangeCustomerAppointmentField('customerPhoneNumber', value)}
                required
              />
              <TextRow
                inputMode={editable}
                label="Email"
                value={customerAppointment?.customerEmail ?? ''}
                onChange={(value) => handleChangeCustomerAppointmentField('customerEmail', value)}
              />
              <TextRow
                inputMode={editable}
                type="multilineText"
                label="Bemærkninger"
                value={customerAppointment?.customerRemarks ?? ''}
                onChange={(value) => handleChangeCustomerAppointmentField('customerRemarks', value)}
              />
            </ColumnFlex3>
            {(customerAppointment?.startDate || customerAppointment?.endDate || customerAppointment?.preferredTimeOfDay) && (
              <ColumnFlex3>
                <h4>Ønske til tidsaftalen</h4>
                <TextRow
                  inputMode={editable}
                  required
                  type="date"
                  label="Start dato"
                  value={customerAppointment?.startDate ?? ''}
                  onChange={(value) => handleChangeCustomerAppointmentField('startDate', value)}
                />
                <TextRow
                  inputMode={editable}
                  required
                  type="date"
                  label="Slut dato"
                  value={customerAppointment?.endDate ?? ''}
                  onChange={(value) => handleChangeCustomerAppointmentField('endDate', value)}
                />
                {customerAppointment?.preferredTimeOfDay && editable ? (
                  <TimeOfDayDropdown
                    required
                    label={'Ønsket tidsrum på dagen'}
                    value={customerAppointment.preferredTimeOfDay}
                    onChange={(e) => handleChangeCustomerAppointmentField('preferredTimeOfDay', e.target.value)}
                  />
                ) : (
                  customerAppointment?.preferredTimeOfDay && (
                    <TextRow
                      inputMode={editable}
                      label="Ønsket tidsrum på dagen"
                      value={getEnumDisplayValue(customerAppointment?.preferredTimeOfDay)}
                      required
                    />
                  )
                )}
                <TextRow
                  inputMode={editable}
                  onChange={(e) => {
                    handleChangeCustomerAppointmentField('approvedByPlanning', e === 'true');
                  }}
                  type="checkbox"
                  label="Tidsaftale godkendt af planlægning"
                  value={customerAppointment?.approvedByPlanning?.toString() ?? ''}
                />
              </ColumnFlex3>
            )}
          </StyledSectionContent>
        </Section>
      )}
      {task?.stretches && task.stretches?.length > 0 && (
        <Section>
          <SectionHeader>Strækninger</SectionHeader>
          <StretchContainer>
            <StretchesSection
              isEditable={editable}
              mode={editable ? 'write' : 'read'}
              value={stretches}
              onStretchChange={(value) => setStretches(value)}
            />
          </StretchContainer>
        </Section>
      )}
      {components &&
        components.map((c) => (
          <TaskTypeTemplateComponent
            component={c as TaskComponent}
            onChange={handleChangeDynamicField}
            mode={editable ? 'write' : 'read'}
            key={c.id}
            showError={dynamicFieldErrors}
          />
        ))}
      {editable && (
        <StyledFooter>
          <Button onClick={() => cancelChanges()} variant="secondary" data-testid="cancel-update-task-button">
            {<div>Annuller</div>}
          </Button>
          <Button onClick={() => saveUpdatedTask()} data-testid="confirm-update-task-button" disabled={!isDirty}>
            {isUpdatingTask ? <StyledCircularProgress size={16} /> : <div>Gem</div>}
          </Button>
        </StyledFooter>
      )}
      {showAccessConditionsDialog && (
        <>
          {task?.baseWorkTaskType === BaseWorkTaskTypeEnum.MeterWorkTask ? (
            <AccessConditionsMetertaskDialog
              id={task?.id ?? ''}
              onClose={() => setShowAccessConditionsDialog(false)}
              accessInfo={accessInfo}
              setAccessInfo={setAccessInfo}
            />
          ) : (
            <AccessConditionDialog
              id={task?.id ?? ''}
              onClose={() => setShowAccessConditionsDialog(false)}
              assetId={task?.assetId ?? ''}
              assetType={task?.assetType}
              assetLocation={task?.taskLocation}
              accessConditions={accessConditions}
              setAccessConditions={setAccessConditions}
            />
          )}
        </>
      )}
      <Section>
        <NearbyTasks
          assetId={task?.assetId ?? ''}
          coordinates={{
            longitude: task?.taskLocation?.longitude ?? 0,
            latitude: task?.taskLocation?.latitude ?? 0
          }}
          workTaskId={task?.id}
        />
      </Section>
    </Container>
  );
};

const dialogBody: DialogBody = {
  headerText: 'Vil du annullere dine ændringer til arbejdskortet?',
  bodyText: '',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const StyledSectionContent = styled((props) => <SectionContent {...props} direction="row" />)`
  div {
    row-gap: 24px;
  }
`;

const InnerMapContainer = styled.div`
  margin-left: 12px;
  height: 250px;
`;

const OuterMapContainer = styled(ColumnFlex2)`
  justify-content: center;
`;

const AccessConditionsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 12px 4px 12px;

  position: sticky;
  bottom: 0;

  z-index: ${(props) => props.theme.zIndex.main};
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
  background-color: white;
  && > * {
    margin-left: 12px;
  }
`;

export const ProjectNumberContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 10px 10px;
`;

const StyledColumnFlex = styled(ColumnFlex3)`
  justify-content: space-between;
  width: 50%;
`;

const StyledSectionHeader = styled(SectionHeader)`
  display: flex;
  justify-content: space-between;
`;

const SubSectionHeader = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const StyledRowBase = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

export default TaskDetailsStep;
