import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const CheckmarkIcon = (props: IconProps) => {
  return (
    <IconSvg
      {...props}
      viewBox="33.949 36.365 15.124 12.282"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="checkmark-icon"
    >
      <path fill="none" strokeWidth={props.strokeWidth} d="M35.28 43.33l4.8 4.01 7.68-9.64"></path>
    </IconSvg>
  );
};
