import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const UpdateIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M 14.627 4.383 L 14.627 0.398 L 9.313 5.711 L 14.627 11.025 L 14.627 7.04 C 19.023 7.04 22.597 10.613 22.597 15.01 C 22.597 16.352 22.265 17.627 21.667 18.73 L 23.607 20.669 C 24.642 19.035 25.254 17.095 25.254 15.01 C 25.254 9.138 20.498 4.383 14.627 4.383 Z M 14.627 22.98 C 10.23 22.98 6.657 19.407 6.657 15.01 C 6.657 13.668 6.989 12.393 7.587 11.29 L 5.647 9.351 C 4.611 10.985 4 12.924 4 15.01 C 4 20.881 8.756 25.637 14.627 25.637 L 14.627 29.622 L 19.94 24.309 L 14.627 18.995 L 14.627 22.98 Z"></path>
    </IconSvg>
  );
};
