import styled from 'styled-components';
import { PersonIcon } from '../../assets/icons/PersonIcon';
import { formatTimeAndDateString } from '../../utils/dateHandling';
import { getInitialsFromEmail } from '../../utils/initialsUtils';
import ToolTip, { ToolTipPlacement } from '../tool-tip2/ToolTip';

interface Props {
  updatedByEmail?: string;
  updatedTimeStamp?: string;
  size: string;
  placement?: ToolTipPlacement;
}

const AuditInfo = (props: Props) => {
  const { updatedByEmail, updatedTimeStamp, size, placement } = props;
  const formattedDate = formatTimeAndDateString(updatedTimeStamp ?? '');
  const formattedInitials = getInitialsFromEmail(updatedByEmail ?? '');
  const formattedToolTipText = `${formattedInitials} - ${formattedDate}`;
  const isDisabled = !updatedByEmail;
  return (
    <ToolTip placement={placement} hidden={isDisabled} title={formattedToolTipText}>
      <IconContainer>
        <PersonIcon disabled={isDisabled} size={size} />
      </IconContainer>
    </ToolTip>
  );
};

const IconContainer = styled.div`
  margin: 0px 15px;
`;

export default AuditInfo;
