import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const WarningIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M 14.774 29.584 C 16.803 29.584 18.705 29.198 20.48 28.426 C 22.264 27.654 23.834 26.59 25.19 25.232 C 26.555 23.874 27.618 22.301 28.379 20.514 C 29.149 18.726 29.534 16.821 29.534 14.799 C 29.534 12.767 29.149 10.858 28.379 9.07 C 27.608 7.282 26.541 5.71 25.175 4.352 C 23.82 2.994 22.25 1.929 20.465 1.158 C 18.69 0.386 16.788 0 14.76 0 C 12.731 0 10.825 0.386 9.04 1.158 C 7.265 1.929 5.695 2.994 4.33 4.352 C 2.974 5.71 1.911 7.282 1.141 9.07 C 0.38 10.858 0 12.767 0 14.799 C 0 16.821 0.385 18.726 1.156 20.514 C 1.926 22.301 2.989 23.874 4.345 25.232 C 5.7 26.59 7.265 27.654 9.04 28.426 C 10.825 29.198 12.736 29.584 14.774 29.584 Z M 14.774 16.704 C 13.682 16.704 13.102 16.162 13.034 15.078 L 12.814 9.539 C 12.775 8.972 12.936 8.518 13.297 8.176 C 13.668 7.825 14.15 7.649 14.745 7.649 C 15.35 7.649 15.833 7.825 16.193 8.176 C 16.564 8.528 16.735 8.982 16.705 9.539 L 16.471 15.048 C 16.413 16.152 15.847 16.704 14.774 16.704 Z M 14.774 21.833 C 14.18 21.833 13.682 21.666 13.282 21.334 C 12.883 21.002 12.683 20.553 12.683 19.986 C 12.683 19.429 12.883 18.985 13.282 18.653 C 13.682 18.321 14.18 18.155 14.774 18.155 C 15.369 18.155 15.862 18.321 16.252 18.653 C 16.642 18.985 16.837 19.429 16.837 19.986 C 16.837 20.553 16.637 21.002 16.237 21.334 C 15.847 21.666 15.36 21.833 14.774 21.833 Z"
      />
    </IconSvg>
  );
};
