import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const AddDocumentIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} data-testid="add-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33341 1.33301H4.00008C3.64646 1.33301 3.30732 1.47348 3.05727 1.72353C2.80722 1.97358 2.66675 2.31272 2.66675 2.66634V13.333C2.66675 13.6866 2.80722 14.0258 3.05727 14.2758C3.30732 14.5259 3.64646 14.6663 4.00008 14.6663H12.0001C12.3537 14.6663 12.6928 14.5259 12.9429 14.2758C13.1929 14.0258 13.3334 13.6866 13.3334 13.333V5.33301L9.33341 1.33301Z"
        stroke={props.color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M9.3335 1.33301V5.33301H13.3335"
        stroke={props.color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M8.00012 12V8"
        stroke={props.color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M6.00012 10H10.0001"
        stroke={props.color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </IconSvg>
  );
};
