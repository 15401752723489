import { memo, useState } from 'react';
import { ColumnInstance, IdType } from 'react-table';
import { InputAdornment } from '@mui/material';
import DropdownComponent from '../../../components/dropdown-component/DropdownComponent';
import { ColumnFilterIcon } from '../../../assets/icons/ColumnFilterIcon';
import TextField from '../../../components/text-field/TextField';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import Checkbox from '../../../components/checkbox/Checkbox';
import styled from 'styled-components';
import IconButton from '../../../components/icon-button/IconButton';

interface Props<T extends object> {
  allColumns: ColumnInstance<T>[];
  activeColumns: number;
  onColumFilterChange?: (columns: IdType<T>[]) => void;
}

const ColumnFilterButton = <T extends object>(props: Props<T>) => {
  const { allColumns, onColumFilterChange } = props;

  const [columnSearchInput, setColumnSearchInput] = useState('');

  const filteredColumns: ColumnInstance<T>[] = allColumns.filter((column) =>
    column.Header?.toString().toLowerCase().includes(columnSearchInput.toLowerCase())
  );
  const handleColumnFilterChange = (checked: boolean, selectedColumn: string) => {
    let hiddenColumns = allColumns.filter((column) => !column.isVisible).map((column) => column.id);

    if (checked) {
      hiddenColumns = hiddenColumns.filter((column) => column !== selectedColumn);
    } else {
      hiddenColumns = [...hiddenColumns, selectedColumn];
    }
    onColumFilterChange && onColumFilterChange(hiddenColumns);
  };

  return (
    <DropdownComponent
      direction="left"
      input={
        <StyledIconButton size="37px" padding="8px" variant="outlined" data-testid="column-filter-button">
          <ColumnFilterIcon size="18px" zIndex={0} />
        </StyledIconButton>
      }
    >
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon size="18px" />
            </InputAdornment>
          )
        }}
        label="Søg på kolonne"
        onChange={(e) => setColumnSearchInput(e.target.value)}
        value={columnSearchInput}
      />
      <h4>Kolonner</h4>
      {filteredColumns?.length ? (
        filteredColumns.map(
          (column) =>
            column.canFilter && (
              <div key={column.id}>
                <Checkbox
                  data-testid={`${column.id}-column-filter`}
                  {...column.getToggleHiddenProps()}
                  label={column.Header?.toString() ?? column.id}
                  onChange={(e) => {
                    column.getToggleHiddenProps().onChange(e);
                    handleColumnFilterChange(e.target.checked, column.id);
                  }}
                />
              </div>
            )
        )
      ) : (
        <span>Fandt ingen kolonner</span>
      )}
    </DropdownComponent>
  );
};

const StyledIconButton = styled(IconButton)`
  && {
    border-radius: 8px;
    padding: 10px;
  }
`;

export default memo(ColumnFilterButton, <T extends object>(prevProps: Props<T>, props: Props<T>) => {
  return prevProps.activeColumns === props.activeColumns;
});
